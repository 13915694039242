import { SettingsPage } from "components/shared/SettingPagesVarious";
import React from "react";
import BookingManagerVisibilitySettings from "./displaySettings.BookingManagerVisibility.Settings";


const BookingManagerVisibilitySettingsPage: React.FC<{}> = props => {
  return <SettingsPage Component={BookingManagerVisibilitySettings} Label="Booking Manager Visibility" />;
};

export default BookingManagerVisibilitySettingsPage;
