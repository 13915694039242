import { SimplePaletteColorOptions, ThemeOptions } from "@material-ui/core";

import { PaletteOptions } from "@material-ui/core/styles/createPalette";

const red: SimplePaletteColorOptions = { main: "#5a7393" };

const defaultPalette: PaletteOptions = {
  primary: red
};

const DefaultTheme: ThemeOptions = {
  palette: defaultPalette,
  // overrides: { MuiSelect: { icon: { position: 'relative', }, }, }
};

export default DefaultTheme;
