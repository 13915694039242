import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";
import HomeGettingStartedPage from "./home.Welcome";


interface IHomeProps { }
const Home: React.FC<IHomeProps> = props => {
  let { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/gettingstarted`} />
      </Route>
      <Route exact path={`${path}/gettingstarted`} component={HomeGettingStartedPage} />

    </Switch>
  );
};

export default Home;
