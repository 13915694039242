import * as FilterActions from "actions/FilterActions";
import { produce } from "immer";
import { Reducer } from "redux";
import { DefaultFilterState, FilterState } from "types/FilterTypes";
import { ActionType, getType } from "typesafe-actions";

export type FilterAction = ActionType<typeof FilterActions>;

const reducer: Reducer<FilterState, FilterAction> = (
  state = DefaultFilterState,
  action: FilterAction
) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(FilterActions.Filter_Reducer_Reset): {
        return DefaultFilterState;
      }
      case getType(FilterActions.Filter_Reducer_SetWebLevel): {
        draft.Selection = action.payload;
        return;
      }

    }
  });

export { reducer as FilterReducer };

