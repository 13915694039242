import { SettingsPage } from "components/shared/SettingPagesVarious";
import React from "react";
import CorporateIdentityWaitscreenSettings from "./corporateIdentity.waitscreen.Settings";
interface ICorporateIdentityWaitscreenSettingsPageProps { }

const CorporateIdentityWaitscreenSettingsPage: React.FC<ICorporateIdentityWaitscreenSettingsPageProps> = props => {
  return <SettingsPage Component={CorporateIdentityWaitscreenSettings} Label="Corporate Identity Waitscreen" />;
};

export default CorporateIdentityWaitscreenSettingsPage;
