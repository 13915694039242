import React from "react";
import { SettingsOrder } from "WebApiClient";

interface IWebLevelLabelProps {
  Level: SettingsOrder;
  Name: string;
}

export function GetWebLevelString(order: SettingsOrder): string {
  let result = "";
  switch (order) {
    case SettingsOrder.Web:
      result = "Web";
      break;
    case SettingsOrder.BranchGroup:
      result = "Branchgroup";
      break;
    case SettingsOrder.Branch:
      result = "Branch";
      break;
  }
  return result;
}

export const WebLevelLabel: React.FC<IWebLevelLabelProps> = props => {
  return (
    <span>
      <span>{GetWebLevelString(props.Level)}</span>
      {props.Level !== SettingsOrder.Web && <span> {props.Name}</span>}
    </span>
  );
};
