import React from "react"

export const TitleHelpText = <div>
    <p>
        <b>Title</b>
    </p>
    <ul>
        <li>
            Optional title to identity data
        </li>
    </ul>
</div>

export const ValidUntilHelpText = <div>
    <p>
        <b>Valid until</b>
    </p>
    <ul>
        <li>
            Specifiy when data should be deleted. Defaults to 24 hours.
        </li>
    </ul>
</div>

export const PassengertypeHelpText = <div>
    <p>
        <b>Passenger type</b>
    </p>
    <ul>
        <li>
            3-letter passenger type of passengers (ADT,CHD...)
        </li>
    </ul>
</div>
export const DepartureHelpText = <div>
    <p>
        <b>Departure</b>
    </p>
    <ul>
        <li>
            3-letter code of Airport or city
        </li>
    </ul>
</div>
export const ArrivalHelpText = <div>
    <p>
        <b>Arrival</b>
    </p>
    <ul>
        <li>
            3-letter code of Airport or city
        </li>
    </ul>
</div>

export const IncludeCarrierHelpText = <div>
    <p>
        <b>Include Carriers</b>
    </p>
    <ul>
        <li>
            List of carrier codes to include. Seperate multiple entries with whitespace.
        </li>
    </ul>
</div>
export const ExcludeCarrierHelpText = <div>
    <p>
        <b>Exclude Carriers</b>
    </p>
    <ul>
        <li>
            List of carrier codes to exclude. Seperate multiple entries with whitespace.
        </li>
    </ul>
</div>
export const TargetMultichannelBranchgroupHelpText = <div>
    <p>
        <b>Target Multichannel Branchgroup</b>
    </p>
    <ul>
        <li>
            Name of MCRE Branchgroup rule to target. Defaults to Branchgroup name of User
        </li>
    </ul>
</div>
export const TargetMultichannelBranchHelpText = <div>
    <p>
        <b>Target Multichannel Branch</b>
    </p>
    <ul>
        <li>
            Name of MCRE Branch rule to target. Defaults to Branch name of User
        </li>
    </ul>
</div>