import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";
import GettingStarted from "./corporateIdentity.GettingStarted";
import Logos from "./logos/corporateIdentity.logos.Page";
import Waitscreen from "./waitscreen/corporateIdentity.waitscreen.Page";
import EmailTemplate from "./emailTemplate/corporateIdentity.emailTemplate.Page";

interface ICorporateIdentityProps {}
const CorporateIdentity: React.FC<ICorporateIdentityProps> = (props) => {
  let { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/gettingstarted`} />
      </Route>
      <Route exact path={`${path}/gettingstarted`} component={GettingStarted} />
      <Route exact path={`${path}/waitscreen`} component={Waitscreen} />
      <Route exact path={`${path}/logos`} component={Logos} />
      <Route exact path={`${path}/emailTemplates`} component={EmailTemplate} />
    </Switch>
  );
};

export default CorporateIdentity;
