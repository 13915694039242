import React from "react"

export const BaseRuleActiveHelptext = <div><p>
    <b>Active</b>
</p>

    <ul>

        <li>
            Activates or deactivates the filter.
        </li>


    </ul>
</div>

export const BaseRuleValidFromHelptext = <div>
    <p>
        <b>Valid from</b>
    </p>

    <ul>
        <li>The specified day is fully included into the period of validity.</li>
        <li>
            The time zone used is given by the point of sale.
        </li>
        <li>Please use the input format YYYY-MM-DD.</li>


    </ul>

</div>

export const BaseRuleValidUntilHelptext = <div>
    <p>
        <b>Valid until</b>
    </p>

    <ul>
        <li>The specified day is fully included into the period of validity.</li>
        <li>
            The time zone used is given by the point of sale.
        </li>
        <li>Please use the input format YYYY-MM-DD.</li>


    </ul>

</div>

export const BaseRulePassengersHelptext = <div>
    <p>
        <b>Passengers </b>
    </p>

    <ul>
        <li>
            Allowed values for the passenger field is  <br />
            ADT, ITX, ... only main types (adult passengers) <br />
            are allowed. CHD and INF types are automatically <br />
            derived from the main type.
        </li>



    </ul>

    <br />
    <div>

        <table>
            <thead>
                <tr>
                    <th>Passenger type</th>
                    <th>Code</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Adult</td>
                    <td>ADT</td>
                </tr>
                <tr>
                    <td>Agent</td>
                    <td>AGT</td>
                </tr>
                <tr>
                    <td>Category Z</td>
                    <td>CTZ</td>
                </tr>
                <tr>
                    <td>Contract bulk adult</td>
                    <td>JCB</td>
                </tr>
                <tr>
                    <td>Ethnic</td>
                    <td>LBR</td>
                </tr>
                <tr>
                    <td>Group</td>
                    <td>GRP</td>
                </tr>
                <tr>
                    <td>Internet fares</td>
                    <td>WEB</td>
                </tr>
                <tr>
                    <td>ITX</td>
                    <td>ITX</td>
                </tr>
                <tr>
                    <td>Military</td>
                    <td>MIL</td>
                </tr>
                <tr>
                    <td>Partner</td>
                    <td>CMP</td>
                </tr>
                <tr>
                    <td>Sailor</td>
                    <td>SEA</td>
                </tr>
                <tr>
                    <td>Senior</td>
                    <td>SEN</td>
                </tr>
                <tr>
                    <td>Sports</td>
                    <td>SPT</td>
                </tr>
                <tr>
                    <td>Teacher</td>
                    <td>TEA</td>
                </tr>
                <tr>
                    <td>Timesaver</td>
                    <td>TIM</td>
                </tr>
                <tr>
                    <td>Youth</td>
                    <td>YTH</td>
                </tr>
                <tr>
                    <td>Youth/ Student</td>
                    <td>YST</td>
                </tr>

            </tbody>
        </table>
    </div>
</div>

export const BaseRuleOriginsHelptext = <div>
    <p>
        <b>Origins</b>
    </p>

    Possible values are
    <ul>
        <li>
            Three letter code (Airport code or City code)
        </li>
        <li>
            ISO Country Codes (e.g. "/DE" for Germany)
        </li>
        <li>
            ISO Continent Codes (e.g. "#EU" for Europe, "#SA" for South America, "#NA" for North America
            "#AF" for Africa, "#AS" for Asia and "#OC" for Australia / Oceania, "#AN" for Antarctica)
        </li>


    </ul>

</div>

export const BaseRuleDestinationHelptext = <div>
    <p>
        <b>Destinations</b>
    </p>

    Possible values are
    <ul>
        <li>
            Three letter code (Airport code or City code)
        </li>
        <li>
            ISO Country Codes (e.g. "/DE" for Germany)
        </li>
        <li>
            ISO Continent Codes (e.g. "#EU" for Europe, "#SA" for South America, "#NA" for North America
            "#AF" for Africa, "#AS" for Asia and "#OC" for Australia / Oceania, "#AN" for Antarctica)
        </li>


    </ul>

</div>

export const BaseRuleTariffCarrierHelptext = <div >
    <p>
        <b>Tariff carriers</b>
    </p>

    <ul>
        <li>
            Airline code (two character IATA code) or alliance code.
        </li>



    </ul>

    <br />
    <div>
        <table>
            <thead>
                <tr>
                    <th>Alliance</th>
                    <th>Code</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Star Alliance</td>
                    <td>*A</td>
                </tr>
                <tr>
                    <td>Oneworld</td>
                    <td>*O</td>
                </tr>
                <tr>
                    <td>Skyteam</td>
                    <td>*S</td>
                </tr>

            </tbody>
        </table>
    </div>

</div>

export const BaseRuleCabinClassesHelptext = <div>
    <p>
        <b>Cabin classes</b>
    </p>

    <br />
    <div>

        <table>
            <thead>
                <tr>
                    <th>Cabinclass</th>
                    <th>Code</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Business</td>
                    <td>C</td>
                </tr>
                <tr>
                    <td>Economy</td>
                    <td>Y</td>
                </tr>
                <tr>
                    <td>First</td>
                    <td>F</td>
                </tr>
                <tr>
                    <td>Premium Economy</td>
                    <td>W</td>
                </tr>
            </tbody>
        </table>
    </div>



</div>

export const BaseRuleFirstDepartureDateHelptext = <div>
    <p>
        <b>First departure date</b>
    </p>

    <ul>
        <li>This is the local time of the origin given by the FAPI response.</li>
    </ul>


</div>

export const BaseRuleLastDepartureHelptext = <div>
    <p>
        <b>Last departure date</b>
    </p>

    <ul>
        <li>This is the local time of the origin given by the FAPI response.</li>
    </ul>

</div>

export const BaseRuleFirstTravelCompletionDateHelptext = <div>
    <p>
        <b>First travel completion date</b>
    </p>

    <ul>
        <li>This is the local time of the destination given by the FAPI response.</li>
    </ul>

</div>

export const BaseRuleLastTravelCompletionDateHelptext = <div>
    <p>
        <b>Last travel completion date</b>
    </p>

    <ul>
        <li>This is the local time of the destination given by the FAPI response.</li>
    </ul>

</div>

export const BaseRuleSingleMultiLegHelptext = <div>
    <p>
        <b>Single/ Multi leg</b>
    </p>

    <ul>
        <li>
            The flight contains only one leg or multiple legs.
        </li>



    </ul>

</div>

export const BaseRuleDomesticFaresHelptext = <div>
    <p>
        <b>Domestic fares</b>
    </p>

    <ul>
        <li>
            A domestic fare describes a flight that begins and ends in the same country without leaving it. <br />
            {`E.g. FRA -> BER is domestic, FRA via AMS to MUC is not domestic`}
        </li>



    </ul>

</div>

export const BaseRuleFareTypeCodesHelptext = <div>
    <p>
        <b>Fare type codes</b>
    </p>
    <br />
    <div>

        <table>
            <thead>
                <tr>
                    <th>Fare type</th>
                    <th>Code</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Charter fare</td>
                    <td>CHARTER</td>
                </tr>
                <tr>
                    <td>Corporate fare</td>
                    <td>CORPORATE</td>
                </tr>
                <tr>
                    <td>Mixed fare</td>
                    <td>MIXED</td>
                </tr>
                <tr>
                    <td>Negotiated fare</td>
                    <td>NEGOTIATED</td>
                </tr>
                <tr>
                    <td>Net fare</td>
                    <td>NET</td>
                </tr>
                <tr>
                    <td>Published fare</td>
                    <td>PUBLISHED</td>
                </tr>
                <tr>
                    <td>Web fare</td>
                    <td>WEB</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

export const BaseRuleMarketingCarriersHelptext = <div>
    <p>
        <b>Marketing carriers</b>
    </p>

    <ul>
        <li>
            Airline code (two character IATA code) or alliance code.
        </li>



    </ul>
    <br />
    <div>
        <table>
            <thead>
                <tr>
                    <th>Alliance</th>
                    <th>Code</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Star Alliance</td>
                    <td>*A</td>
                </tr>
                <tr>
                    <td>Oneworld</td>
                    <td>*O</td>
                </tr>
                <tr>
                    <td>Skyteam</td>
                    <td>*S</td>
                </tr>

            </tbody>
        </table>
    </div>

</div>

export const BaseRuleOperatingCarriersHelptext = <div>
    <p>
        <b>Operating carriers</b>
    </p>

    <ul>
        <li>
            Airline code (two character IATA code) or alliance code.
        </li>



    </ul>
    <br />
    <div>
        <table>
            <thead>
                <tr>
                    <th>Alliance</th>
                    <th>Code</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Star Alliance</td>
                    <td>*A</td>
                </tr>
                <tr>
                    <td>Oneworld</td>
                    <td>*O</td>
                </tr>
                <tr>
                    <td>Skyteam</td>
                    <td>*S</td>
                </tr>

            </tbody>
        </table>
    </div>

</div>

export const BaseRuleBookingClassesHelptext = <div>
    <p>
        <b>Booking classes</b>
    </p>

    <ul>
        <li>
            One letter character code
        </li>



    </ul>

</div>

export const BaseRuleFareBasisHelptext = <div>
    <p>
        <b>Fare basis codes</b>
    </p>

    <ul>
        <li>
            Multi character codes
        </li>



    </ul>

</div>

export const BaseRuleGDSCodesHelptext = <div>
    <p>
        <b>GDS codes</b>
    </p>
    <br />
    <div>

        <table>
            <thead>
                <tr>
                    <th>GDS</th>
                    <th>Prod-code</th>
                    <th>Test-code</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Amadeus</td>
                    <td>1A</td>
                    <td>TA</td>
                </tr>
                <tr>
                    <td>Berlogic</td>
                    <td>0R</td>
                    <td>TR</td>
                </tr>
                <tr>
                    <td>Charter</td>
                    <td>+C</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Farelogix</td>
                    <td>F1</td>
                    <td>T1</td>
                </tr>
                <tr>
                    <td>Galileo</td>
                    <td>1G</td>
                    <td>TG</td>
                </tr>
                <tr>
                    <td>Sabre</td>
                    <td>1S</td>
                    <td>TS</td>
                </tr>
                <tr>
                    <td>Webfare</td>
                    <td>+W</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Swiss.NDC</td>
                    <td>LX</td>
                    <td>LX</td>
                </tr>
                <tr>
                    <td>FlightRoutes24</td>
                    <td>1Y</td>
                    <td>TY</td>
                </tr>
                <tr>
                    <td>Flighthaus</td>
                    <td>H1</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Unififi</td>
                    <td>1U</td>
                    <td>TU</td>
                </tr>
                <tr>
                    <td>PartoCRS</td>
                    <td>1O</td>
                    <td>TO</td>
                </tr>
                <tr>
                    <td>AeroCRS</td>
                    <td>1E</td>
                    <td>TE</td>
                </tr>


            </tbody>
        </table>
    </div>
</div>

export const BaseRulePCCHelptext = <div>
    <p>
        <b>PCC codes</b>
    </p>

    <ul>
        <li>
            Multi character codes
        </li>



    </ul>

</div>

export const BaseRuleCorporateCodesHelptext = <div>
    <p>
        <b>Corporate codes</b>
    </p>

    <ul>
        <li>
            Multi character codes
        </li>



    </ul>

</div>

export const BaseRuleMatchCodesHelptext = <div>
    <p>
        <b>Match codes</b>
    </p>

    <ul>
        <li>
            Multi character codes
        </li>



    </ul>

</div>

export const BaseRuleBrandNamesHelptext = <div>
    <p>
        <b>Brand names</b>
    </p>

    <ul>
        <li>
            Name of a branded fare. e.g. Light, Classic, Flex
        </li>



    </ul>

</div>

export const BaseRuleViasHelptext = <div>
    <p>
        <b>Vias</b>
    </p>

    Possible values are
    <ul>
        <li>
            Three letter code (Airport code or City code)
        </li>
        <li>
            ISO Country Codes (e.g. "/DE" for Germany)
        </li>
        <li>
            ISO Continent Codes (e.g. "#EU" for Europe, "#SA" for South America, "#NA" for North America
            "#AF" for Africa, "#AS" for Asia and "#OC" for Australia / Oceania, "#AN" for Antarctica)
        </li>


    </ul>

</div>

export const BaseRuleStopsHelptext = <div>
    <p>
        <b>Stops</b>
    </p>

    <ul>
        <li>
            A stop is a via point with more than 24 hour stopover.
        </li>
    </ul>

</div>

export const BaseRuleFlightSegmentsHelptext = <div>

    <p>
        <b>Example: FlightSegment Sequence Instance = FRA-LHR-LH-Q-4700 LHR-JFK-UA-T-372</b>
    </p>

    <table>
        <thead>
            <tr>
                <td>#</td>
                <td>Definition</td>
                <td>Match</td>
                <td>Remark</td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td><span >FRA-LHR-LH-Q-4700 LHR-JFK-UA-T-372</span></td>
                <td>yes</td>
                <td>The first segment of the Instance matches the first segment of the Definition. The second segment of the Instance matches the second segment of the Definition.</td>
            </tr>
            <tr>
                <td>2</td>
                <td><span >LHR-JFK-UA-T-372 FRA-LHR-LH-Q-4700</span></td>
                <td>no</td>
                <td>The first segment of the Instance doesn't match the first segment of the Definition.</td>
            </tr>
            <tr>
                <td>3</td>
                <td><span >FRA-LHR-LH-Q-4700</span></td>
                <td>no</td>
                <td>The first segment of the Instance matches the first segment of the Definition. For the second segment of the Instance, there's no match.</td>
            </tr>
            <tr>
                <td>4</td>
                <td><span >FRA-LHR-LH-Q-4700 *</span></td>
                <td>yes</td>
                <td>The first segment of the Instance matches the first segment of the Definition. The Definition's second segment is a Wildcard-Definition which allows an arbitrary number of follow-up segments - so the second segment of the Instance is allowed, too.</td>
            </tr>
            <tr>
                <td>5</td>
                <td><span >* LHR-JFK-UA-T-372</span></td>
                <td>yes</td>
                <td>The first segment of the Instance is covered by the the Definition's first segment, which is a Wildcard-Definition. The second segment of the Instance matches the second segment of the Definition.</td>
            </tr>
            <tr>
                <td>6</td>
                <td><span >----</span></td>
                <td>no</td>
                <td>The first segment of the Instance matches the first segment of the Definition. For the second segment of the Instance, there's no match.</td>
            </tr>
            <tr>
                <td>7</td>
                <td><span >---- ----</span></td>
                <td>yes</td>
                <td>The first segment of the Instance matches the first segment of the Definition. The second segment of the Instance matches the second segment of the Definition. </td>
            </tr>
            <tr>
                <td>8</td>
                <td><span >---- ---- ----</span></td>
                <td>no</td>
                <td>
                    The first segment of the Instance matches the first segment of the Definition. The second segment of the Instance matches the second segment of the Definition.
                    For the third segment of the Definition the Instance contains no matching segment, because there's no third segment in the Instance.
                </td>
            </tr>
            <tr>
                <td>9</td>
                <td><span >*</span></td>
                <td>yes</td>
                <td>The first segment of the Instance is covered by the the first segment of the Definition, which is a Wildcard-Definition. The second segment of the Instance is a direct succesor of the first segment and so it is covered by the same Wildcard-Definition as the first segment. Both segments of the Instance are covered by first segment of the Definition.</td>
            </tr>
            <tr>
                <td>10</td>
                <td><span >* * *</span></td>
                <td>yes</td>
                <td>
                    The first segment of the Instance is covered by the the first segment of the Definition, which is a Wildcard-Definition. The second segment of the Instance is a direct succesor of the first segment and so it is covered by the same Wildcard-Definition as the first segment. Both segments of the Instance are covered by first segment of the Definition.
                    The Definition's second and third segment are not matched by an Instance segment. The expression remains true, because both segments are Wildcard-Definitions. Wildcard-Definitions can cover an arbitrary number N of Instance segments, where N can be zero.
                </td>
            </tr>
            <tr>
                <td>11</td>
                <td><span >* /DE---- *</span></td>
                <td>yes</td>
                <td>
                    The first segment of the Instance matches the second segment of the Definition. The match result considers the airport code FRA is covered by the county code /DE.
                    The Definition's third segment is a Wildcard-Definition which can cover an arbitrary number of follow-up segments - so the second segment of the Instance is allowed, too.
                    The Definition's first segment is not matched by an Instance segment. The expression remains true, because the first segment is a Wildcard-Definition and can cover an arbitrary number N of Instance segments, where N can be zero.
                </td>
            </tr>
            <tr>
                <td>12</td>
                <td><span >* LHR-/US--- *</span></td>
                <td>yes</td>
                <td>
                    The first segment of the Instance is covered by the the first segment of the Definition, which is a Wildcard-Definition.  The second segment of the Instance matches the second segment of the Definition.
                    The Definition's third segment is not matched by an Instance segment. The expression remains true, because the third segment is a Wildcard-Definition and can cover an arbitrary number N of Instance segments, where N can be zero.
                </td>
            </tr>
            <tr>
                <td>13</td>
                <td><span >FRA-LHR-LH-Q-4700</span><br /><span >LHR-JFK-UA-T-372</span></td>
                <td>no</td>
                <td>
                    The Definition contains two FlightSegment Sequence Definitions (i) FRA-LHR-LH-Q-4700 and (ii) LHR-JFK-UA-T-372.
                    Neither the first nor the second is matched by the instance.
                </td>
            </tr>
            <tr>
                <td>14</td>
                <td><span >----</span><br /><span >* LHR-JFK-UA-T-372</span></td>
                <td>yes</td>
                <td>
                    The Definition contains two FlightSegment Sequence Definitions (i) ---- and (ii) * LHR-JFK-UA-T-372
                    The Instance matches Definition (ii).
                </td>
            </tr>
        </tbody>
    </table>


</div>

export const BaseRuleFlightSegmentRemarksHelptext = <div>
    <p>
        <b>Remarks</b>
    </p>

    <ul>
        <li>
            Free text field for documentation purposes.
        </li>
    </ul>

</div>

export const BaseRuleNameHelptext = <div>
    <p>
        <b>Name</b>
    </p>

    <ul>
        <li>
            Free text field for documentation purposes.
        </li>
    </ul>

</div>



