import DateFnsUtils from "@date-io/date-fns";
import { Box, Checkbox, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import TimezoneSelect from "components/shared/inputs/TimezoneSelect";
import { HelpTextButton } from "components/shared/various";
import * as moment from "moment";
import React from "react";
import { DomesticFareRestriction, SingleOrMultiLeg, UpdateBaseRuleModel } from "WebApiClient";
import * as HelpTexts from "./BaseRule.HelpTexts";
interface IBaseRuleEditProps {
    Data: UpdateBaseRuleModel;
    OnUpdate: (newData: UpdateBaseRuleModel) => void
    DisablePassengers: boolean
}
export const BaseRuleInputComponent: React.FC<IBaseRuleEditProps> = ({ Data: data, OnUpdate, DisablePassengers }) => {

    function setData(updated: UpdateBaseRuleModel) {
        OnUpdate(updated)
    }

    return <React.Fragment>
        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>

                    <TextField
                        label="Name"
                        value={data.name}
                        onChange={e =>
                            setData({
                                ...data,
                                name: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleNameHelptext} />
                        }}
                    />
                </Box>
            </Grid>


        </Grid>
        <Grid item>
            <Box marginBottom={2}>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data.active}
                            onChange={() =>
                                setData({
                                    ...data,
                                    active: !data.active
                                })
                            }
                        />
                    }
                    label={<span>Active <HelpTextButton HelpText={HelpTexts.BaseRuleActiveHelptext} /></span>}

                />
            </Box>

        </Grid>
        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <TimezoneSelect
                        Value={data.utcOffsetMinutes}
                        OnChange={e =>
                            setData({
                                ...data,
                                utcOffsetMinutes: e
                            })
                        }
                    />
                </Box>
            </Grid>
        </Grid>

        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                InputProps={{
                                    endAdornment: (
                                        <HelpTextButton HelpText={HelpTexts.BaseRuleValidFromHelptext} />
                                    )
                                }}
                                onFocus={() => { }}
                                onBlur={() => { }}
                                margin="normal"
                                label={"Valid from"}
                                format="yyyy-MM-dd"
                                required={false}
                                placeholder="YYYY-MM-DD"
                                value={
                                    data.validFromDate === undefined ? null : data.validFromDate
                                }
                                onChange={e => {
                                    setData({
                                        ...data,
                                        validFromDate: e === null ? undefined : e
                                    });
                                }}
                                InputAdornmentProps={{ position: "start" }}
                            />
                        </MuiPickersUtilsProvider>

                    </FormControl>
                </Box>
            </Grid>

            <Grid item>
                <Box marginBottom={2}>
                    <FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker

                                margin="normal"
                                label="Valid until"
                                format="yyyy-MM-dd"
                                placeholder="YYYY-MM-DD"
                                required={false}
                                value={
                                    data.validUntilDate === undefined ? null : data.validUntilDate
                                }
                                onFocus={() => { }}
                                onBlur={() => { }}
                                onChange={e => {
                                    setData({
                                        ...data,
                                        validUntilDate: e === null ? undefined : e
                                    });
                                }}
                                InputAdornmentProps={{ position: "start" }}
                                InputProps={{
                                    endAdornment: (
                                        <HelpTextButton HelpText={HelpTexts.BaseRuleValidUntilHelptext} />
                                    )
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Passengers *"
                        value={data.passengers}
                        onChange={e =>
                            setData({
                                ...data,
                                passengers: e.target.value
                            })
                        }
                        // disabled={DisablePassengers}
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRulePassengersHelptext} />
                        }}
                        // helperText={DisablePassengers ? "Currently not supported" : undefined}
                    />
                </Box>
            </Grid>
        </Grid>

        <Grid item xs={12}>
            <Box m={2}>
                <Divider></Divider>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h5">Main</Typography>

        </Grid>
        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Origins *"
                        value={data.origins}
                        onChange={e =>
                            setData({
                                ...data,
                                origins: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleOriginsHelptext} />
                        }}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Destinations *"
                        value={data.destinations}
                        onChange={e =>
                            setData({
                                ...data,
                                destinations: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleDestinationHelptext} />
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Tariff carriers *"
                        value={data.tariffCarrier}
                        onChange={e =>
                            setData({
                                ...data,
                                tariffCarrier: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleTariffCarrierHelptext} />
                        }}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Cabin classes *"
                        value={data.cabinClass}
                        onChange={e =>
                            setData({
                                ...data,
                                cabinClass: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleCabinClassesHelptext} />
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                InputAdornmentProps={{ position: "start" }}
                                label="First departure date"
                                format="yyyy-MM-dd"
                                placeholder="YYYY-MM-DD"
                                required={false}
                                onFocus={() => { }}
                                onBlur={() => { }}
                                value={
                                    data.firstDepartureDate === ""
                                        ? null
                                        : data.firstDepartureDate
                                }
                                onChange={e => {
                                    setData({
                                        ...data,
                                        firstDepartureDate: moment(e?.toDateString()).format("yyyy-MM-dd")
                                    });
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <HelpTextButton HelpText={HelpTexts.BaseRuleFirstDepartureDateHelptext} />
                                    )
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item>
                <Box marginBottom={2}>
                    <FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Last departure date"
                                InputAdornmentProps={{ position: "start" }}
                                format="yyyy-MM-dd"
                                placeholder="YYYY-MM-DD"
                                onFocus={() => { }}
                                onBlur={() => { }}
                                required={false}
                                value={
                                    data.lastDepartureDate === ""
                                        ? null
                                        : data.lastDepartureDate
                                }
                                onChange={e => {
                                    setData({
                                        ...data,
                                        lastDepartureDate: moment(e?.toDateString()).format("yyyy-MM-dd")
                                    });
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <HelpTextButton HelpText={HelpTexts.BaseRuleLastDepartureHelptext} />
                                    )
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>

        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="First travel completion date"
                                placeholder="YYYY-MM-DD"
                                InputAdornmentProps={{ position: "start" }}
                                format="yyyy-MM-dd"
                                required={false}
                                onFocus={() => { }}
                                onBlur={() => { }}
                                value={
                                    data.firstTravelCompletionDate === ""
                                        ? null
                                        : data.firstTravelCompletionDate
                                }
                                onChange={e => {
                                    setData({
                                        ...data,
                                        firstTravelCompletionDate: moment(e?.toDateString()).format("yyyy-MM-dd")
                                    });
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <HelpTextButton HelpText={HelpTexts.BaseRuleFirstTravelCompletionDateHelptext} />
                                    )
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item>
                <Box marginBottom={2}>
                    <FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Last travel completion date"
                                format="yyyy-MM-dd"
                                InputAdornmentProps={{ position: "start" }}
                                placeholder="YYYY-MM-DD"
                                required={false}
                                onFocus={() => { }}
                                onBlur={() => { }}
                                value={
                                    data.lastTravelCompletionDate === ""
                                        ? null
                                        : data.lastTravelCompletionDate
                                }
                                onChange={e => {
                                    setData({
                                        ...data,
                                        lastTravelCompletionDate: moment(e?.toDateString()).format("yyyy-MM-dd")
                                    });
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <HelpTextButton HelpText={HelpTexts.BaseRuleLastTravelCompletionDateHelptext} />
                                    )
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Box>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Box m={2}>
                <Divider></Divider>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h5">Travel details</Typography>

        </Grid>
        <Grid item xs={12}>
            <Box marginBottom={2}>
                <TextField
                    label="Fare type codes *"
                    value={data.fareTypes}
                    onChange={e =>
                        setData({
                            ...data,
                            fareTypes: e.target.value
                        })
                    }
                    InputProps={{
                        endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleFareTypeCodesHelptext} />
                    }}
                />
            </Box>
        </Grid>
        <Grid container item xs={12} spacing={6}>
            <Grid item xs={12} md={4}>
                <Box marginBottom={2}>
                    <FormControl fullWidth>
                        <InputLabel>Single / Mult leg <HelpTextButton HelpText={HelpTexts.BaseRuleSingleMultiLegHelptext} /></InputLabel>
                        <Select
                            value={data.singleLegOrMultiLeg}
                            onChange={e =>
                                setData({
                                    ...data,
                                    singleLegOrMultiLeg: e.target.value as any as SingleOrMultiLeg
                                })
                            }

                        >

                            <MenuItem value={SingleOrMultiLeg.NoRestriction}>
                                No restriction
                            </MenuItem>
                            <MenuItem value={SingleOrMultiLeg.SingleLegOnly}>
                                Single leg only
                            </MenuItem>
                            <MenuItem value={SingleOrMultiLeg.MultipleLegsOnly}>
                                Multiple legs only
                            </MenuItem>

                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                <Box marginBottom={2}>
                    <FormControl fullWidth>
                        <InputLabel>Domestic fares <HelpTextButton HelpText={HelpTexts.BaseRuleDomesticFaresHelptext} /></InputLabel>
                        <Select
                            value={data.domesticFaresOrNonDomesticFares}
                            onChange={e =>
                                setData({
                                    ...data,
                                    domesticFaresOrNonDomesticFares: e.target.value as any as DomesticFareRestriction
                                })
                            }

                        >

                            <MenuItem value={DomesticFareRestriction.NoRestriction}>
                                No restriction
                            </MenuItem>
                            <MenuItem value={DomesticFareRestriction.DomesticFaresOnly}>
                                Domestic fares only
                            </MenuItem>
                            <MenuItem value={DomesticFareRestriction.NonDomesticFaresOnly}>
                                Non- domestic fares only1
                            </MenuItem>

                        </Select>
                    </FormControl>
                </Box>
            </Grid>


        </Grid>
        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Marketing carriers *"
                        value={data.marketingCarrier}
                        onChange={e =>
                            setData({
                                ...data,
                                marketingCarrier: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleMarketingCarriersHelptext} />
                        }}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Operating carriers *"
                        value={data.operatingCarrier}
                        onChange={e =>
                            setData({
                                ...data,
                                operatingCarrier: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleOperatingCarriersHelptext} />
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Booking classes *"
                        value={data.bookingClass}
                        onChange={e =>
                            setData({
                                ...data,
                                bookingClass: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleBookingClassesHelptext} />
                        }}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Fare basis codes *"
                        value={data.farebase}
                        onChange={e =>
                            setData({
                                ...data,
                                farebase: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleFareBasisHelptext} />
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <Box m={2}>
                <Divider></Divider>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h5">Restrictions</Typography>

        </Grid>
        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="GDS codes *"
                        value={data.gds}
                        onChange={e =>
                            setData({
                                ...data,
                                gds: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleGDSCodesHelptext} />
                        }}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="PCC codes *"
                        value={data.pcc}
                        onChange={e =>
                            setData({
                                ...data,
                                pcc: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRulePCCHelptext} />
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Corporate codes *"
                        value={data.corporateCodes}
                        onChange={e =>
                            setData({
                                ...data,
                                corporateCodes: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleCorporateCodesHelptext} />
                        }}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Match codes *"
                        value={data.matchCodes}
                        onChange={e =>
                            setData({
                                ...data,
                                matchCodes: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleMatchCodesHelptext} />
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Brand names *"
                        value={data.brandNames}
                        onChange={e =>
                            setData({
                                ...data,
                                brandNames: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleBrandNamesHelptext} />
                        }}
                    />
                </Box>
            </Grid>

        </Grid>
        <Grid item xs={12}>
            <Box m={2}>
                <Divider></Divider>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h5">Routing</Typography>

        </Grid>
        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Vias *"
                        value={data.vias}
                        onChange={e =>
                            setData({
                                ...data,
                                vias: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleViasHelptext} />
                        }}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Stops *"
                        value={data.stops}
                        onChange={e =>
                            setData({
                                ...data,
                                stops: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleStopsHelptext} />
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
        <Grid container item xs={12} spacing={6}>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Flight segments"
                        value={data.flightsegments}
                        multiline

                        onChange={e =>
                            setData({
                                ...data,
                                flightsegments: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleFlightSegmentsHelptext} />
                        }}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box marginBottom={2}>
                    <TextField
                        label="Remarks"
                        value={data.flightsegmentsRemarks}
                        multiline
                        onChange={e =>
                            setData({
                                ...data,
                                flightsegmentsRemarks: e.target.value
                            })
                        }
                        InputProps={{
                            endAdornment: <HelpTextButton HelpText={HelpTexts.BaseRuleFlightSegmentRemarksHelptext} />
                        }}
                    />
                </Box>
            </Grid>
        </Grid>


    </React.Fragment>
}