import { Card, CardContent, CardHeader, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { InvariantDateInput } from "components/shared/inputs/invariantDateInput";
import { HelpTextButton } from "components/shared/various";
import React from "react";
import { CustomerProfileSegment } from "WebApiClient";
import { CustomerProfileDataCabinClasses } from "./CustomerProfileDataCabinClasses";
import * as HelpTexts from "./customerprofiles.HelpTexts";

export const CustomerProfileDataSegment: React.FC<{ data: CustomerProfileSegment; OnUpdate: (data: CustomerProfileSegment) => void; OnRemove: () => void; index: number; }> = props => {
    const { arrival, departure, departureDate, radius, cabinClasses } = props.data;
    return <Card elevation={6}>
        <CardHeader
            title={<Typography variant="h5">Segment #{props.index}</Typography>}
            action={<IconButton onClick={() => { props.OnRemove(); }}
                color="secondary"><DeleteIcon /></IconButton>} />
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <TextField
                        value={departure ? departure : ""}
                        onChange={e => props.OnUpdate({
                            ...props.data,
                            departure: e.target.value
                        })}
                        label="Departure"
                        InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.DepartureHelpText} /> }} />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        value={arrival ? arrival : ""}
                        onChange={e => props.OnUpdate({
                            ...props.data,
                            arrival: e.target.value
                        })}
                        label="Arrival"
                        InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.ArrivalHelpText} /> }} />

                </Grid>
                <Grid item xs={2}>
                    <InvariantDateInput date={departureDate} label="Departure date" helpButton={undefined} OnUpdate={(e) => {
                        props.OnUpdate({
                            ...props.data,
                            departureDate: e
                        });
                    }} />
                </Grid>

                <Grid item xs={2}>
                    <TextField
                        type="number"
                        value={radius}
                        onChange={e => props.OnUpdate({
                            ...props.data,
                            radius: parseInt(e.target.value)
                        })}
                        label="Radius (in km)" />
                </Grid>
                <Grid item xs={12}>
                    <CustomerProfileDataCabinClasses OnUpdate={(e) => props.OnUpdate({ ...props.data, cabinClasses: e })} data={cabinClasses} Title={`Cabin classes for Segment ${props.index}`} />
                </Grid>
            </Grid>
        </CardContent>
    </Card>;

};
