import { Box, Card, CardContent, CardHeader, Divider, Grid, LinearProgress, TextField, Typography } from "@material-ui/core";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { SettingsActions } from "components/shared/CardActions";
import { ErrorMessages } from "components/shared/ErrorMessages";
import { FlightApiUserInformationFetchComponent } from "components/shared/FlightApiUserInformation";
import { ApplyIndicator, CreateSettingsButton, ISettingsComponentProps, OverriddenIndicator } from "components/shared/SettingPagesVarious";
import { WebLevelLabel } from "components/shared/WebLevelLabel";
import { useFlightApiClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, CreateSettingsRequest, SettingsOrder, UpdateFlightApiSettingsModel } from "WebApiClient";

const MultichannelSettings: React.FC<ISettingsComponentProps> = props => {
  const { ReadOnly, WebLevel, Id, LevelName } = props;
  const client = useFlightApiClient();
  const dispatch = useDispatch();
  const [Settings, setSettings] = useState<
    UpdateFlightApiSettingsModel | undefined
  >(undefined);


  const [loading, setLoading] = useState(true);
  const [validationState, setValidationState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);
  const [serverError, setServerError] = useState(false);
  useEffect(() => {
    GetSettings(WebLevel, Id);
  }, [WebLevel, Id]);

  function OnUpdate() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .update(Settings)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully updated.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be updated.";
          }
          if (response.serverError) {
            serverSideError = true;
          } else {
            setValidationState(response.validationResult);
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be updated. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Update Multichannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function Reload() {
    GetSettings(WebLevel, Id);
  }

  function CreateSettings() {
    if (Id) {
      const createRequest: CreateSettingsRequest = {
        id: Id,
        sortOrder: WebLevel
      };
      setLoading(true);
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      client
        .create(createRequest)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully created.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be created.";
          }
          if (response.serverError) {
            serverSideError = true;
          }
        })
        .catch(e => {
          serverSideError = true;
          console.error(e);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be created. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Create Mulitchannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setLoading(false);
          if (transactionSuccess) {
            Reload();
          }
        });
    }
  }

  function GetSettings(order: SettingsOrder, Id: string | null) {
    setLoading(true);

    client
      .get(order, Id)
      .then(settings => {
        setSettings(settings);
        if (settings !== null) {
          props.OnNotifyIfSettingsExist(true);
        } else {
          props.OnNotifyIfSettingsExist(false);
        }
      })
      .catch(e => {
        console.error(e);
        setServerError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function DeleteSettings() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .delete(Settings?.id!)
        .then(response => {
          if (response.success) {
            transactionMsg = "successfully deleted.";
            transactionSuccess = true;
          } else {
            transactionMsg = `could not be deleted. ${response.errorMessage}`;
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg = "could not be deleted. A server error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Delete Multichannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  const userNameError: string | undefined = validationState
    ? validationState.properyErrors!["username"]
    : undefined;

  const PasswordError: string | undefined = validationState
    ? validationState.properyErrors!["password"]
    : undefined;


  return (
    <Box boxShadow={ReadOnly ? undefined : 3}>
      <Card style={{ opacity: ReadOnly ? 0.5 : 1 }}>
        <CardHeader
          title={
            <span>
              {props.Applies && (
                <span>
                  <ApplyIndicator />
                </span>
              )}
              {props.IsOverwritten && (
                <span>
                  <OverriddenIndicator />
                </span>
              )}
              <span>Multichannel settings for </span>
              <WebLevelLabel Level={WebLevel} Name={LevelName} />{" "}
              {/* {props.IsOverwritten ? "Overwritten" : ""} */}
            </span>
          }
        />
        {!loading && (
          <CardContent>
            {Settings ? (
              <Grid container spacing={2}>
                {serverError && <ErrorMessages ServerError={true} />}
                {validationState && validationState.errorSummary && (
                  <ErrorMessages Messages={validationState.errorSummary} />
                )}

                <Grid container item xs={12} spacing={6}>
                  <Grid item>
                    <Box mb={2}>
                      <TextField
                        error={userNameError !== undefined}
                        InputProps={{ readOnly: props.ReadOnly }}
                        required
                        label="Username"
                        value={Settings.username}
                        helperText={userNameError}
                        onChange={e =>
                          setSettings({ ...Settings, username: e.target.value })
                        }
                      />

                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mb={2}>


                      <TextField
                        error={PasswordError !== undefined}
                        InputProps={{ readOnly: props.ReadOnly }}
                        required
                        label="Password"
                        value={Settings.password}
                        helperText={PasswordError}
                        onChange={e =>
                          setSettings({ ...Settings, password: e.target.value })
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mb={2}>
                      <TextField
                        InputProps={{ readOnly: props.ReadOnly }}
                        required
                        label="Currency"
                        value={Settings.currencyCode}
                        onChange={e =>
                          setSettings({
                            ...Settings,
                            currencyCode: e.target.value
                          })
                        }
                      />
                    </Box>
                  </Grid>


                </Grid>
                <Grid item xs={12}>
                  <Box m={2}>
                    <Divider></Divider>
                  </Box>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h5">Booking manager Membership filters</Typography>
                    <Typography variant="caption">Comma separated. Use the "Get FlightApi User Information" button to see available Membership groups.</Typography>
                  </Grid>
                  <Grid item container xs={12} spacing={4}>
                    <Grid item>
                      <Box mb={2}>
                        <TextField
                          InputProps={{ readOnly: props.ReadOnly }}

                          label="Membership groups"
                          value={Settings.bookingManagerMembershipGroupsFilter}
                          onChange={e =>
                            setSettings({
                              ...Settings,
                              bookingManagerMembershipGroupsFilter: e.target.value
                            })
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box mb={2}>
                        <TextField
                          InputProps={{ readOnly: props.ReadOnly }}

                          label="Membership members"
                          value={Settings.bookingManagerMembershipMembersFilter}
                          onChange={e =>
                            setSettings({
                              ...Settings,
                              bookingManagerMembershipMembersFilter: e.target.value
                            })
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {(Settings.username && Settings.password) &&
                    <FlightApiUserInformationFetchComponent username={Settings.username} password={Settings.password} />
                  }

                </Grid>


              </Grid>
            ) : (
              <CreateSettingsButton
                OnClick={CreateSettings}
                Readonly={props.ReadOnly}
              />
            )}
          </CardContent>
        )}
        {loading && (
          <CardContent>
            <LinearProgress />
          </CardContent>
        )}
        {!ReadOnly && Settings && (
          <SettingsActions
            OnDelete={DeleteSettings}
            OnReset={Reload}
            OnSave={OnUpdate}
          />
        )}
      </Card>
    </Box>
  );
};

export default MultichannelSettings;
