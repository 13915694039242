import { Card, CardContent, CardHeader, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from "react";
import { BonusProgramModel, PublicServicesClient, ApiCredentialsOptions } from "WebApiClient";
import { Multiselect } from 'react-widgets';
import { AgentApiURL } from 'Constants';

interface dropdownItem {
    text: string;
    value: string;
    image: string;
}

export const CustomerProfileFrequentFlyerCard: React.FC<{ errorTrigger?: any, frequentFlyerCardsData?: any, Fulldata?: any, data: BonusProgramModel; index: number; OnUpdate: (data: BonusProgramModel) => void; OnRemove: () => void; setbuttonDB: any }> = props => {
    const { carrierCodes, extensions, holderFirstName, holderLastName, holderTitle, programNumber, programType } = props.data;
    const [items, setItems] = useState<dropdownItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);


    function OnSearch(val: string) {
        if (val.length > 1) {
            setIsLoading(true);
            const client: PublicServicesClient = new PublicServicesClient(new ApiCredentialsOptions(AgentApiURL, ""))
            client.getAirlinesByPrefix(val).then((result) => {
                if (result.length > 0) {
                    const map: dropdownItem[] = [];
                    result.forEach(e => {
                        map.push({ value: e.code!, text: e.hint!, image: "XXXX" })
                    })
                    setItems(map);
                }
                else {
                    setItems([]);
                }
            }).catch(() => {
                setItems([]);
            }).finally(() => {
                setIsLoading(false);
            })
        }

    }

    function OnIncludeUpdate(e: any[]) {
        setItems([]);
        let sArr: string[] = [];
        e.forEach(v => {
            if (v.value) {
                sArr.push(v.value);
            }
            else {
                sArr.push(v);
            }
        })
        props.OnUpdate({ ...props.data, carrierCodes: sArr })
    }

    const ListItem = ({ item }: any) => (
        <span>
            <img width={60} height={20} src={`${AgentApiURL}/publicservices/images/airline/${item.value}`} alt="airlineimage"></img>
            <strong> {item.text} ({item.value})</strong>
        </span>
    );

    //tracking newlyCreated card error
    function errorTTracker() {
        return true
    }
    function errorTTrackerReverse() {
        return false
    }

    const checkerr = async () => {
        props.OnRemove();
    }

    function airlineData(item: any) {
        let j = [];
        let iString = JSON.stringify(item);
        let i = JSON.parse(iString);
        j.push(i);
        return j.map((itr: any) => {
            if (itr.length > 2) {
                let airCode = JSON.parse(itr)
                return airCode.value
            }
            return itr
        })
    }

    return <Card elevation={6}>
        <CardHeader
            title={<Typography variant="h5"> Frequent Flyer Cards {props.index}</Typography>}
            action={<IconButton onClick={checkerr}
                color="secondary"><DeleteIcon /></IconButton>} />
        <CardContent>
            <Grid spacing={2} >
                <Grid item xs={12}>
                    <TextField className="flyerCardtextField" style={{ width: "100%" }}
                        value={programNumber}
                        onChange={e => {
                            props.OnUpdate({
                                ...props.data,
                                programNumber: e.target.value
                            })
                        }}
                        variant="outlined"
                        label="programNumber"
                        error={props.errorTrigger && (programNumber?.length ? (programNumber?.length >2 && programNumber?.length<51?errorTTrackerReverse():true) : errorTTracker())}
                        helperText={`${props.errorTrigger ? (programNumber ? (programNumber?.length >2 && programNumber?.length<51? '':'Character should be between 3 - 50') : 'This Field is required') : ''}`}
                    />
                </Grid>
                <Grid item xs={12} style={{ margin: "12px 0px 12px 0px" }}>
                    <div className='col-12 multiFlyerCardContainer'>
                        <label className="multiLabel" >Airlines</label>
                        <Multiselect
                            containerClassName={`form-control form-control-sm ${carrierCodes.length === 0 ? "is-invalid" : "is-valid"}`}
                            onChange={(e) => OnIncludeUpdate(e)}
                            value={carrierCodes}
                            dataKey="ffairlines"
                            textField={(item: any) => `${airlineData(item)}`}
                            busy={isLoading} filter={false}
                            data={items} onSearch={(e) => OnSearch(e)}
                            renderListItem={ListItem}
                            placeholder="Airlines"
                            messages={{ emptyList: "type to see airlines..." }}
                        />
                        {props.errorTrigger ? (items?.length == 0 && carrierCodes?.length == 0) && <span style={{ paddingLeft: '14px', fontSize: '12px', color: 'red' }}>This Field is required</span> : ''}
                    </div>
                </Grid>
                <Grid container xs={12} spacing={2}>
                    <Grid item xs={3}>
                        <TextField className="flyerCardtextField textFieldMarginBFlyerCard"
                            value={holderTitle}
                            onChange={e => props.OnUpdate({
                                ...props.data,
                                holderTitle: e.target.value
                            })}
                            variant="outlined"
                            label="holderTitle"
                            error={props.errorTrigger && (holderTitle?.length ? (holderTitle?.length >1 && holderTitle?.length<7?errorTTrackerReverse():true) : errorTTracker())}
                        helperText={`${props.errorTrigger ? (holderTitle ? (holderTitle?.length >1 && holderTitle?.length<7? '':'Character should be between 2 - 6') : 'This Field is required') : ''}`}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField className="flyerCardtextField textFieldMarginBFlyerCard"
                            value={holderFirstName}
                            onChange={e => props.OnUpdate({
                                ...props.data,
                                holderFirstName: e.target.value
                            })}
                            variant="outlined"
                            label="holderFirstName"
                            error={props.errorTrigger && (holderFirstName?.length ? (holderFirstName?.length >2 && holderFirstName?.length<51?errorTTrackerReverse():true) : errorTTracker())}
                            helperText={`${props.errorTrigger ? (holderFirstName ? (holderFirstName?.length >2 && holderFirstName?.length<51? '':'Character should be between 3 - 50') : 'This Field is required') : ''}`}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField className="flyerCardtextField textFieldMarginBFlyerCard"
                            value={holderLastName}
                            onChange={e => props.OnUpdate({
                                ...props.data,
                                holderLastName: e.target.value
                            })}
                            variant="outlined"
                            label="holderLastName"
                            error={props.errorTrigger && (holderLastName?.length ? (holderLastName?.length >2 && holderLastName?.length<51?errorTTrackerReverse():true) : errorTTracker())}
                        helperText={`${props.errorTrigger ? (holderLastName ? (holderLastName?.length >2 && holderLastName?.length<51? '':'Character should be between 3 - 50') : 'This Field is required') : ''}`}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <TextField className="flyerCardtextField" style={{ width: "100%" }}
                            value={programType}
                            onChange={e => props.OnUpdate({
                                ...props.data,
                                programType: e.target.value
                            })}
                            variant="outlined"
                            label="programType"
                            error={props.errorTrigger && (programType?.length ? (programType?.length >2 && programType?.length<51?errorTTrackerReverse():true) : errorTTracker())}
                        helperText={`${props.errorTrigger ? (programType ? (programType?.length >2 && programType?.length<51? '':'Character should be between 3 - 50') : 'This Field is required') : ''}`}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField className="flyerCardtextField" style={{ width: "100%" }}
                            value={extensions}
                            onChange={e => props.OnUpdate({
                                ...props.data,
                                extensions: e.target.value
                            })}
                            variant="outlined"
                            label="Extensions"
                            error={props.errorTrigger && (extensions?.length ? (extensions?.length >2 && extensions?.length<51?errorTTrackerReverse():true) : errorTTracker())}
                        helperText={`${props.errorTrigger ? (extensions ? (extensions?.length >2 && extensions?.length<51? '':'Character should be between 3 - 50') : 'This Field is required') : ''}`}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </CardContent>

    </Card>;
};