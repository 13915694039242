import { Card, CardContent, CardHeader, Grid, SvgIconProps, Typography } from "@material-ui/core";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AppsIcon from "@material-ui/icons/Apps";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GroupIcon from "@material-ui/icons/Group";
import LanguageIcon from '@material-ui/icons/Language';
import TreeView from '@material-ui/lab/TreeView';
import StyledTreeItem from "components/shared/StyledTreeItem";
import * as React from "react";
export interface IHomeGettingStartedProps { }

export default function HomeGettingStartedPage(
  props: IHomeGettingStartedProps
) {
  return <Grid container direction="row" spacing={2}>
    <Grid item xs={12}>
      <Typography align="center" variant="h3" gutterBottom>
        AgentPlus Administration - Quick start
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Card>
        <CardHeader title={"Navigation"} />
        <CardContent>
          <Typography variant="body1">
            On the left you can navigate between different areas. Each area has an "Getting started" page where you can find further information about the settings you can configure in that area.
      </Typography>
        </CardContent>
      </Card>
    </Grid>

    <Grid item xs={12}>
      <Card>
        <CardHeader title={"How can I manage my organization?"} />
        <CardContent>
          <Grid container spacing={2} item xs={12} direction="row">
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                To accommodate requirements from small to large companies, AgentPlus provides a containerized structure.
      </Typography>

            </Grid>



            <Grid item xs={12}>
              <Typography variant="h5"><LanguageIcon />  Web</Typography>
              <Typography variant="body1" >
                Your overall AgentPlus Account is called your "Web". It holds all your AgentPlus data. <br />
            It is divided into organisational units called Branchgroups.
      </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5"><AppsIcon /> Branchgroup</Typography>
              <Typography variant="body1" >
                A Branchgroup in itself is nothing more than a further container for Branches, where your AgentPlus Users are getting assigned to.
      </Typography>

            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5"><AccountTreeIcon />  Branch</Typography>
              <Typography variant="body1" >
                A Branch belongs to a Branchgroup and contains Users.
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5"><GroupIcon />  User</Typography>
              <Typography variant="body1">
                You use an User to login into AgentPlus.
      </Typography>

            </Grid>


            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Example</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <WebHierachyExample />

                </ExpansionPanelDetails>
              </ExpansionPanel>



            </Grid>

          </Grid>

        </CardContent>
      </Card>
    </Grid>

    <Grid item xs={12}>
      <Card>
        <CardHeader title={"How does settings assigment work?"} />
        <CardContent>
          {/* <Typography variant="body1"> */}
            Depending on how you wish to configure AgentPlus, most settings can be configured independently on the three containers: <br></br>
          <ul>
            <li>
              <LanguageIcon />  Web
          </li>
            <li>
              <AppsIcon /> Branchgroup
          </li>
            <li>
              <AccountTreeIcon />  Branch
          </li>
          </ul>
        Here the following rules apply:
        <ul>
            <li>Settings can be overwritten bottom to top. <br></br>
          That means that  Settings configured on a Branch level will overwrite settings on a Branchgroup level. If no Branchgroup settings exist, the Branch settings will overwrite the Web settings.<br></br>
          Branchgroup settings will overwrite settings an the Web level.</li>
            <li>A fallback Web default always has to exist</li>
          </ul>


          {/* </Typography> */}

        </CardContent>
      </Card>



    </Grid>
  </Grid>;
}



interface WebExampleTreeItem {
  Label: string, Icon: React.ElementType<SvgIconProps>, LabelText?: string, Children?: WebExampleTreeItem[]
}
export function WebHierachyExample() {

  const ExampleItems: WebExampleTreeItem[] = [
    {
      Label: "Germany",
      Icon: AppsIcon,
      Children: [
        {
          Label: "Germany North", Icon: AccountTreeIcon, Children: [
            { Label: "Mr Müller", Icon: GroupIcon },
            { Label: "Mrs Bauer", Icon: GroupIcon },
          ]
        },
        {
          Label: "Germany South", Icon: AccountTreeIcon, Children: [
            { Label: "Mr Schmidt", Icon: GroupIcon },
            { Label: "Mrs Schneider", Icon: GroupIcon },
          ]
        },
      ]
    },
    {
      Label: "United States",
      Icon: AppsIcon,
      Children: [
        {
          Label: "US North", Icon: AccountTreeIcon, Children: [
            { Label: "Mr Doe", Icon: GroupIcon },
            { Label: "Mrs Smith", Icon: GroupIcon },
          ]
        },
      ]
    },
  ]



  return (
    <TreeView
      defaultExpanded={['root']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >

      <StyledTreeItem nodeId="root" labelText="YourCompany Inc." labelIcon={LanguageIcon}>
        {ExampleItems.map((e, index) => <RenderNode index={index} item={e} key={index} />
        )}

      </StyledTreeItem>
    </TreeView>
  );
}

const RenderNode: React.FC<{ item: WebExampleTreeItem, index: number, parentIndex?: number }> = props => {
  const { item, index, parentIndex } = props;
  const idString = parentIndex !== undefined ? `${parentIndex.toString()}_${index.toString()}` : index.toString();
  return <StyledTreeItem
    nodeId={item.Label}
    labelText={item.Label}
    labelIcon={item.Icon}
    color="#1a73e8"
    bgColor="#e8f0fe"
  >
    {item.Children !== undefined &&
      item.Children.map((y, childIndex) =>
        <RenderNode index={childIndex} item={y} parentIndex={index} key={childIndex} />
      )
    }

  </StyledTreeItem>

}


