import { FormControl, InputLabel } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import * as React from "react";
import { HelpTextButton } from "../various";

export interface ITimeZoneSelectProps {
  Value: number;
  OnChange(value: number): void;
  // Label: string;
  // Description: string;
}

export default function TimezoneSelect(props: ITimeZoneSelectProps) {
  const { Value, OnChange } = props;
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    OnChange(event.target.value as number);
  };
  return (
    <FormControl>
      <InputLabel>Point of sale Timezone <HelpTextButton HelpText={<div>
        <p>
          <b>Point of sale Timezone</b>
        </p>

        <ul>
          <li>
            If you set a valid from or valid to date, that date is in UTC. Enter the timezone you want the filter to applied in here.
        </li>



        </ul>

      </div>} /></InputLabel>
      <Select value={Value} onChange={handleChange} >
        <MenuItem value={-720}>[UTC - 12] Baker Island Time</MenuItem>
        <MenuItem value={-660}>[UTC - 11] Niue Time, Samoa Standard Time</MenuItem>
        <MenuItem value={-600}>[UTC - 10] Hawaii-Aleutian Standard Time, Cook Island Time</MenuItem>
        <MenuItem value={-570}>[UTC - 9:30] Marquesas Islands Time</MenuItem>
        <MenuItem value={-540}>[UTC - 9] Alaska Standard Time, Gambier Island Time</MenuItem>
        <MenuItem value={-480}>[UTC - 8] Pacific Standard Time</MenuItem>
        <MenuItem value={-420}>[UTC - 7] Mountain Standard Time</MenuItem>
        <MenuItem value={-360}>[UTC - 6] Central Standard Time</MenuItem>
        <MenuItem value={-300}>[UTC - 5] Eastern Standard Time</MenuItem>
        <MenuItem value={-270}>[UTC - 4:30] Venezuelan Standard Time</MenuItem>
        <MenuItem value={-240}>[UTC - 4] Atlantic Standard Time</MenuItem>
        <MenuItem value={-210}>[UTC - 3:30] Newfoundland Standard Time</MenuItem>
        <MenuItem value={-180}>[UTC - 3] Amazon Standard Time, Central Greenland Time</MenuItem>
        <MenuItem value={-120}>[UTC - 2] Fernando de Noronha Time, South Georgia &amp; the South Sandwich Islands Time</MenuItem>
        <MenuItem value={-60}>[UTC - 1] Azores Standard Time, Cape Verde Time, Eastern Greenland Time</MenuItem>
        <MenuItem value={0}>[UTC] Western European Time, Greenwich Mean Time</MenuItem>
        <MenuItem value={60}>[UTC + 1] Central European Time, West African Time</MenuItem>
        <MenuItem value={120}>[UTC + 2] Eastern European Time, Central African Time</MenuItem>
        <MenuItem value={180}>[UTC + 3] Moscow Standard Time, Eastern African Time</MenuItem>
        <MenuItem value={210}>[UTC + 3:30] Iran Standard Time</MenuItem>
        <MenuItem value={240}>[UTC + 4] Gulf Standard Time, Samara Standard Time</MenuItem>
        <MenuItem value={270}>[UTC + 4:30] Afghanistan Time</MenuItem>
        <MenuItem value={300}>[UTC + 5] Pakistan Standard Time, Yekaterinburg Standard Time</MenuItem>
        <MenuItem value={330}>[UTC + 5:30] Indian Standard Time, Sri Lanka Time</MenuItem>
        <MenuItem value={345}>[UTC + 5:45] Nepal Time</MenuItem>
        <MenuItem value={360}>[UTC + 6] Bangladesh Time, Bhutan Time, Novosibirsk Standard Time</MenuItem>
        <MenuItem value={390}>[UTC + 6:30] Cocos Islands Time, Myanmar Time</MenuItem>
        <MenuItem value={420}>[UTC + 7] Indochina Time, Krasnoyarsk Standard Time</MenuItem>
        <MenuItem value={480}>[UTC + 8] Chinese Standard Time, Australian Western Standard Time, Irkutsk Standard Time</MenuItem>
        <MenuItem value={525}>[UTC + 8:45] Southeastern Western Australia Standard Time</MenuItem>
        <MenuItem value={540}>[UTC + 9] Japan Standard Time, Korea Standard Time, Chita Standard Time</MenuItem>
        <MenuItem value={570}>[UTC + 9:30] Australian Central Standard Time</MenuItem>
        <MenuItem value={600}>[UTC + 10] Australian Eastern Standard Time, Vladivostok Standard Time</MenuItem>
        <MenuItem value={630}>[UTC + 10:30] Lord Howe Standard Time</MenuItem>
        <MenuItem value={660}>[UTC + 11] Solomon Island Time, Magadan Standard Time</MenuItem>
        <MenuItem value={690}>[UTC + 11:30] Norfolk Island Time</MenuItem>
        <MenuItem value={720}>[UTC + 12] New Zealand Time, Fiji Time, Kamchatka Standard Time</MenuItem>
        <MenuItem value={765}>[UTC + 12:45] Chatham Islands Time</MenuItem>
        <MenuItem value={780}>[UTC + 13] Tonga Time, Phoenix Islands Time</MenuItem>
        <MenuItem value={840}>[UTC + 14] Line Island Time</MenuItem>
      </Select>
      {/* <FormHelperText>{props.Description}</FormHelperText> */}
    </FormControl>
  );
}
