import { Session_Saga_TryDeepLogin } from "actions/SessionSagactions";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import { State } from "rootExports/rootReducer";
import { DeepLoginData } from "types/SessionTypes";

const DeepLoginPage = ({
  match,
  DeepLogin: TryLogin,
  ErrorMessages
}: AllProps) => {
  const webid: string = match.params.webid;
  const deeplinkid: string = match.params.deeplinkid;
  useEffect(() => {
    document.title = `Admin`;
    TryLogin({ WebID: webid, Deeplink: deeplinkid });
  }, []);

  return (
    <React.Fragment>
      {ErrorMessages.length > 0 && (
        <div className="alert alert-danger mt-2">
          <ul>
            {ErrorMessages.map((msg, index) => (
              <li key={`loginError_${index}`}>{msg}</li>
            ))}
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};

interface RouteParams {
  webid: string;
  deeplinkid: string;
}

type AllProps = PropsFromState &
  DispatchProps &
  RouteComponentProps<RouteParams>;

interface PropsFromState {
  LoginFailed: boolean;
  IsLoggedIn: boolean;
  ErrorMessages: string[];
}
const mapStateToProps = (state: State) => ({
  LoginFailed: state.Session.LoginFailed,
  IsLoggedIn: state.Session.isLoggedIn,
  ErrorMessages: state.Session.ErrorMessages
});

interface DispatchProps {
  DeepLogin: typeof Session_Saga_TryDeepLogin;
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  DeepLogin: (e: DeepLoginData) => dispatch(Session_Saga_TryDeepLogin(e))
});
export default connect(mapStateToProps, mapDispatchToProps)(DeepLoginPage);
