import { Box, Grid, LinearProgress, Paper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import WebLevelSelect from "components/general/WebLevelSelect";
import { useCalcCurrenciesClient, useCalcFormulasClient, useCalcRulesClient } from "hooks/useHttpClient";
import { useCreateSettingsRequest } from "hooks/useStateSelection";
import { ScrollToTop } from "hooks/various";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, BaseUpdateCalcRuleModel, CreateCalcRuleModel, CreateSettingsRequest, DomesticFareRestriction, SingleOrMultiLeg, UpdateCalcCurrencyModel, UpdateCalcFormulaModel, UpdateCalcRuleModel } from "WebApiClient";
import { CalcRuleInputComponent } from "./rules.edit";
import RulesTable from "./rules.table";

export interface ICalcrulesPageProps { }
const ActionTitle = "Calculation Rule";
export default function CalcrulesPage(props: ICalcrulesPageProps) {
  const stateSelection = useSelector((state: State) => state.Filter.Selection);
  const selectedLevel = useCreateSettingsRequest();
  const client = useCalcRulesClient();
  const calcFormulaClient = useCalcFormulasClient();
  const calcCurrencyClient = useCalcCurrenciesClient();
  const dispatch = useDispatch();

  const [data, setData] = useState<UpdateCalcRuleModel[]>([]);
  const [calcFormulaOptions, setCalcFormulaOptions] = useState<
    UpdateCalcFormulaModel[]
  >([]);
  const [calcCurrencyOptions, setCalcCurrencyOptions] = useState<
    UpdateCalcCurrencyModel[]
  >([]);
  const [editData, setEditData] = useState<BaseUpdateCalcRuleModel | undefined>(
    undefined
  );
  const [editId, setEditId] = useState<string | undefined>(undefined);
  const [editErrorState, setEditErrorState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);
  const [editServerError, setEditServerError] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  function Refresh() {
    GetData(selectedLevel);
  }
  useEffect(() => {
    OnCancelEdit().then(() => {
      GetData(selectedLevel);
    });
  }, [stateSelection]);

  function GetData(selection: CreateSettingsRequest) {
    setLoading(true);
    client
      .get(selection.sortOrder, selection.id)
      .then(result => {
        setData(result);
      })
      .then(() => {
        calcFormulaClient
          .get(selection.sortOrder, selection.id)
          .then(formulas => {
            setCalcFormulaOptions(formulas);
          });
      })
      .then(() => {
        calcCurrencyClient
          .get(selection.sortOrder, selection.id)
          .then(currencies => {
            setCalcCurrencyOptions(currencies);
          });
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function OnSetEditData(data: UpdateCalcRuleModel | undefined) {
    OnCancelEdit().then(() => {
      ScrollToTop();
      if (data) {
        setEditData(data);
        setEditId(data.id);
      } else {
        const newData: BaseUpdateCalcRuleModel = {
          active: true,
          bookingClass: "",
          brandNames: "",
          cabinClass: "",
          calcFormulaID: calcFormulaOptions[0].id,
          corporateCodes: "",
          destinations: "",
          fareTypes: "",
          farebase: "",
          filterSortOrder: 9999,
          firstDepartureDate: "",
          firstTravelCompletionDate: "",
          flightsegments: "",
          flightsegmentsRemarks: "",
          gds: "",
          lastDepartureDate: "",
          lastTravelCompletionDate: "",
          marketingCarrier: "",
          matchCodes: "",
          name: "",
          operatingCarrier: "",
          origins: "",
          passengers: "",
          pcc: "",
          singleLegOrMultiLeg: SingleOrMultiLeg.NoRestriction,
          stops: "",
          tariffCarrier: "",
          utcOffsetMinutes: 0,
          validFromDate: undefined,
          validUntilDate: undefined,
          vias: "",
          domesticFaresOrNonDomesticFares: DomesticFareRestriction.NoRestriction
        };
        setEditData(newData);
        setEditId(undefined);
      }
    });
  }

  function OnDelete(id: string) {
    let transactionSuccess = false;
    let transactionTime = new Date().toString();
    let transactionId = "";
    let transactionMsg = "MSG NOT SET";
    let serverSideError = false;
    client
      .delete(id)
      .then(e => {
        transactionId = e.transactionId;
        if (e.success) {
          transactionSuccess = true;
          transactionMsg = `${ActionTitle} deleted.`;
        } else {
          transactionMsg = `${ActionTitle} could not be deleted. ${e.errorMessage}`;
        }
      })
      .catch(e => {
        console.error(e);
        serverSideError = true;
      })
      .finally(() => {
        if (serverSideError) {
          transactionMsg = `${ActionTitle} could not be deleted. An server-side error has occured.`;
        }
        let transaction: TransactionIDInfo = {
          Label: `Delete ${ActionTitle}`,
          Success: transactionSuccess,
          Time: transactionTime,
          TransactionID: transactionId,
          Message: transactionMsg
        };
        dispatch(Session_Reducer_PushTransactionID(transaction));
        Refresh();
      });
  }

  function OnSubmitEdit(data: BaseUpdateCalcRuleModel) {
    const transactionTime = new Date().toString();
    let transactionSuccess = false;
    let transactionId = "";
    let transactionMsg = "MSG NOT SET";
    let serverSideError = false;
    setEditLoading(true);
    if (editId) {
      const request: UpdateCalcRuleModel = { ...data, id: editId };
      client
        .update(request)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionSuccess = true;
            transactionMsg = `${ActionTitle} succuessfully updated.`;
            OnCancelEdit();
            Refresh();
          } else {
            if (response.validationResult) {
              transactionMsg = `${ActionTitle} could not be updated: Invalid fields`;
              setEditErrorState(response.validationResult);
            }
            if (response.serverError) {
              serverSideError = true;
            }
          }
        })
        .catch(e => {
          console.error(e);
          serverSideError = true;
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              `${ActionTitle} could not be updated: A serverside error has occured.`;
            setEditServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Update ${ActionTitle}`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: transactionMsg
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setEditLoading(false);
        });
    } else {
      const request: CreateCalcRuleModel = {
        ...data,
        createSettingsRequest: selectedLevel
      };
      client
        .create(request)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionSuccess = true;
            transactionMsg = `${ActionTitle} succuessfully created.`;
            OnCancelEdit();
            Refresh();
          } else {
            if (response.validationResult) {
              transactionMsg = `${ActionTitle} could not be created: Invalid fields`;
              setEditErrorState(response.validationResult);
            }
            if (response.serverError) {
              serverSideError = true;
            }
          }
        })
        .catch(e => {
          console.error(e);
          serverSideError = true;
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              `${ActionTitle} could not be created: A serverside error has occured.`;
            setEditServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Create ${ActionTitle}`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: transactionMsg
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setEditLoading(false);
        });
    }
  }

  async function OnCancelEdit() {
    setEditData(undefined);
    setEditId(undefined);
    setEditServerError(false);
    setEditErrorState(undefined);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WebLevelSelect />
      </Grid>
      {!loading && (
        <React.Fragment>
          {calcFormulaOptions.length === 0 && (
            <Grid item xs={12}>
              <Alert severity={"error"}>
                No formula found. You have to create an formula before you can
                create a rule.
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Alert severity={"info"}>
              <b>Important:</b>
              <ul>
                <li>This is feature that needs to be activated for the FlightAPI User that is used by your filter selection. You can check the status in the Multichannel configuration page.</li>
                <li>
                  If a single fare does not match to any rule the whole fare result gets discarded. This why
                  it is highly recommended to create a capture-all-filter at the
                  bottom with no criterias.
                </li>
              </ul>

            </Alert>
          </Grid>
          {editData && (
            <Grid item xs={12}>
              <Box boxShadow={3}>
                <Paper>
                  <CalcRuleInputComponent
                    Data={editData}
                    DataId={editId}
                    OnSave={OnSubmitEdit}
                    OnCancel={OnCancelEdit}
                    ServerError={editServerError}
                    ValidationResult={editErrorState}
                    IsLoading={editLoading}
                    FormulaOptions={calcFormulaOptions}
                    ExistingRules={data}
                    CurrencyOptions={calcCurrencyOptions}
                  />
                </Paper>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <RulesTable
              Data={data}
              IsLoading={loading}
              OnDelete={OnDelete}
              OnEdit={OnSetEditData}
              OnRefresh={Refresh}
              FormulaOptions={calcFormulaOptions}
              CurrencyOptions={calcCurrencyOptions}
              LevelSelection={selectedLevel}
            />
          </Grid>
        </React.Fragment>
      )}
      {loading && (
        <Grid item xs={12}>
          <Paper>
            <LinearProgress />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
