import BudgetingRoutes from "components/budgeting/budgeting.Routes";
import Calculation from "components/calculation/calculation.Routes";
import CorporateIdentity from "components/corporateIdentity/corporateIdentity.routes";
import DisplaySettings from "components/displaySettings/displaySettings.Routes";
import Export from "components/export/export.routes";
import UnauthorizedAlert from "components/general/Unauthorized";
import Home from "components/home/home.routes";
import Import from "components/import/import.routes";
import DeepLoginPage from "components/login/DeepLogin.Page";
import LoginPage from "components/login/Login.Page";
import Masterdata from "components/masterdata/masterdata.routes";
import Salespreference from "components/salespreference/salespreference.Routes";
import UniversalTempDataRoutes from "components/universaltempdata/universalTempData.Routes";
import { Location as HistoryLocation } from "history";
import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router";
import { State } from "rootExports/rootReducer";
import NoMatch from "routes/NoMatch";
import { ConditionalRoute, RouteGuard } from "./ConditionalRoute";
import "./pageTransition.css";

const routes: React.FC<AllProps> = Allprops => {
  const isLoggedIn = Allprops.IsLoggedIn;
  const standardCon: RouteGuard[] = [{ redirectTo: "/login", routeCondition: isLoggedIn }, { redirectTo: "/unauthorized", routeCondition: Allprops.IsAdmin }]
  const notLoggedInCondition: RouteGuard[] = [{ redirectTo: "/home", routeCondition: !isLoggedIn }];
  return (
    // <TransitionGroup>
    //   <CSSTransition
    //     key={Allprops.Location.key}
    //     classNames="page"
    //     timeout={300}
    //   >
    <Switch location={Allprops.Location}>
      <Route exact path="/" component={LoginPage} />
      <ConditionalRoute
        path="/login"
        component={LoginPage}
        guards={notLoggedInCondition}

      />
      <ConditionalRoute
        path="/account/login/:routeWebname"
        component={LoginPage}
        guards={notLoggedInCondition}

      />
      <ConditionalRoute
        path="/weblogin/:routeWebname"
        component={LoginPage}
        guards={notLoggedInCondition}

      />
      <ConditionalRoute
        path="/deep/:webid/:deeplinkid"
        component={DeepLoginPage}
        guards={notLoggedInCondition}

      />
      <Route path="/unauthorized" component={UnauthorizedAlert} />
      <ConditionalRoute
        path="/home"
        component={Home}
        guards={standardCon}

      />
      <ConditionalRoute
        path="/masterdata"
        component={Masterdata}
        guards={standardCon}
      />
      <ConditionalRoute
        path="/displaysettings"
        component={DisplaySettings}
        guards={standardCon}
      />
      <ConditionalRoute
        path="/calculation"
        component={Calculation}
        guards={standardCon}
      />
      <ConditionalRoute
        path="/salespreference"
        component={Salespreference}
        guards={standardCon}
      />

      <ConditionalRoute
        path="/corporateidentity"
        component={CorporateIdentity}
        guards={standardCon}
      />
      <ConditionalRoute
        path="/export"
        component={Export}
        guards={standardCon}
      />
      <ConditionalRoute
        path="/import"
        component={Import}
        guards={standardCon}
      />
      <ConditionalRoute
        path="/universaltempdata"
        component={UniversalTempDataRoutes}
        guards={standardCon}
      />
      <ConditionalRoute
        path="/budgeting"
        component={BudgetingRoutes}
        guards={standardCon}
      />


      <Route component={NoMatch} />
    </Switch>
    //   </CSSTransition>
    // </TransitionGroup>
  );
};

type AllProps = PropsFromState;

interface PropsFromState {
  IsLoggedIn: boolean;
  IsAdmin: boolean;
  IsHitchHiker: boolean;
  Location: HistoryLocation<any>;
  JwtToken: string;
}
const mapStateToProps = (state: State) => ({
  IsLoggedIn: state.Session.isLoggedIn,
  IsAdmin: state.Session.IsAdmin,
  IsHitchHiker: state.Session.IsHitchHiker,

  Location: state.router.location,
  JwtToken: state.Session.JwtToken
});

export default withRouter(connect(mapStateToProps)(routes) as any);
