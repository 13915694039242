import React from "react";
export const SalesPrefActionsIntroHelptext = <div>
    <p>
        <b>Sales preference actions</b>
    </p>

    <ul>
        <li>
            Manage the actions which will be applied to your sales preference rules.
        </li>


    </ul>

</div>

export const SalesPrefActionsTitleHelpText = <div>
    <p>
        <b>Title</b>
    </p>

    <ul>
        <li>
            A text field to identify a action. It has no effect in AgentPlus.
        </li>


    </ul>

</div>

export const SalesPrefActionsActionDoDeleteHelptext = <div>
    <p>
        <b>Delete fare from response</b>
    </p>

    <ul>
        <li>
            If checked, the fare which applies to this action will be removed from the response.
        </li>


    </ul>

</div>

export const SalesPrefActionsActionTargetNameHelptext = <div>
    <p>
        <b>Target display name</b>
    </p>

    <ul>
        <li>
            You can display a custom fare type name for fares which apply for this action. Leave empty to apply the default fare type name.
        </li>


    </ul>

</div>

export const SalesPrefActionsActionTargetPriorityHelptext = <div>
    <p>
        <b>Target priority</b>
    </p>

    <ul>
        <li>
            Numeric value for the execution priority if multiple actions apply. Higher number means higher priority.
        </li>


    </ul>

</div>

