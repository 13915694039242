import { Button, Card, CardContent, CardHeader, Chip, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SaveIcon from "@material-ui/icons/Save";
import { Alert } from "@material-ui/lab";
import { TableIcons } from "components/shared/tableIcons";
import { BooleanIcon, ImportStateCellStyle, ImportStateRenderer } from "components/shared/various";
import { useBranchGroupClient, useUserClient } from "hooks/useHttpClient";
import MaterialTable, { Column } from "material-table";
import { DropzoneArea } from "material-ui-dropzone";
import { parse, ParseResult } from "papaparse";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { ImportStatus, ImportUserModel, UserImportResultModel } from "WebApiClient";
export const UserImportHeaderRow = ["branchGroupName", "branchName", "username", "password", "firstName", "lastName", "isActive", "isAdmin", "title", "telephone", "fax", "email", "mobilePhone", "customField1"];



const HeaderRowExplanations =
  <ExpansionPanel>
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
    >
      <Typography ><span style={{ verticalAlign: "bottom" }}><HelpOutlineIcon></HelpOutlineIcon> Expand to see Help about CSV fields</span></Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Explanation</TableCell>
              <TableCell>Possible values</TableCell>
              <TableCell>Comments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>branchGroupName</TableCell>
              <TableCell>Name of the Branchgroup of the Branch, which the User belongs to</TableCell>
              <TableCell></TableCell>
              <TableCell>If a user existed previously before, and the Branch name is different in the import the User will be moved to the new Branch. The Branchgroup/Branch needs to exist in this Web.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>branchName</TableCell>
              <TableCell>Name of the Branch, which the User belongs to</TableCell>
              <TableCell>The Branchgroup/Branch needs to exist in this Web.</TableCell>
              <TableCell>If a user existed previously before, and the Branch name is different in the import the User will be moved to the new Branch.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>username</TableCell>
              <TableCell>The Username of the user.</TableCell>
              <TableCell></TableCell>
              <TableCell>The Username is unique. If the username existed previously, the old Userdata will be overwritten with the import data. If the Username did not exist, a new User will be created.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>password</TableCell>
              <TableCell>Used for Login.</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>firstName</TableCell>
              <TableCell>First name of a User.</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>lastName</TableCell>
              <TableCell>Last name of a User.</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>isActive</TableCell>
              <TableCell>Determines if a User can login into AgentPlus or not.</TableCell>
              <TableCell>true or false</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>isAdmin</TableCell>
              <TableCell>Determines if a User has administrative priviliges in a Web.</TableCell>
              <TableCell>true or false</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>title</TableCell>
              <TableCell>Title of a User.</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>telephone</TableCell>
              <TableCell>Telephone numberv of a User.</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>fax</TableCell>
              <TableCell>Fax number of a User.</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>email</TableCell>
              <TableCell>Email adress of a User.</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>mobilePhone</TableCell>
              <TableCell>Mobile Phone number of a User.</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>customField1</TableCell>
              <TableCell>Free field to store information about the user. This information will be included in the CSV if you export the user.</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>




          </TableBody>
        </Table>
      </TableContainer>
    </ExpansionPanelDetails>
  </ExpansionPanel>




const ImportMasterDataUsers: React.FC<{}> = props => {
  const [invalidCSV, setInvalidCSV] = useState(false);
  const [isProcessing, setisProcessing] = useState(false);
  const [delimiter, setDelimiter] = useState(";");
  const [parsedImports, setParsedImports] = useState<UserImportResultModel[]>([])
  // const [parsedImports, setParsedImports] = useState<ParsedUserImportResult[]>([]);
  const branchGroupClient = useBranchGroupClient();
  const userClient = useUserClient();

  const hasPendingUploads = parsedImports.length > 0;
  const pendingImports = parsedImports.filter(e => e.status === ImportStatus.Pending).length;
  const errorImports = parsedImports.filter(e => e.status === ImportStatus.Failure).length;
  const successImports = parsedImports.filter(e => e.status === ImportStatus.Imported).length;

  function onUpload(files: File[]): void {
    if (files.length > 0) {

      setisProcessing(true);
      const upload = files[0];
      if (upload) {
        parse(upload, { delimitersToGuess: [";", ",", "\t", "\u001e", "\u001f", "|"], header: true, skipEmptyLines: true, complete: OnParseComplete });
      }
    }
  }
  function OnParseComplete(results: ParseResult<unknown>, file: File) {
    let casted: ImportUserModel[] = [];
    try {
      casted = results.data as ImportUserModel[];
      SendData(false, casted);


    }
    catch {
      setInvalidCSV(true);
    }


  }

  function SendData(submit: boolean, imports: ImportUserModel[]) {
   
    setisProcessing(true);
    userClient.parseImports({ imports: imports, submit: submit }).then((e) => {

      setParsedImports(e);
      setInvalidCSV(false);
    }).catch(() => {
      setInvalidCSV(true);
    }).finally(() => {
      setisProcessing(false);
    })
  }

  function SubmitImports() {
    const data = parsedImports.map(e => e.result!);
    SendData(true, data);
  }



  function OnReset() {
    setInvalidCSV(false);
    setParsedImports([]);
  }

  function GetRowStyle(
    data: any,
    index: number,
    level: number
  ): React.CSSProperties {
    const bg = data as UserImportResultModel;
    let result: React.CSSProperties = ImportStateCellStyle(bg.status);

    return result;
  }

  function GetTableHeader(): JSX.Element {
    return <Grid item container spacing={4}>
      <Grid item container spacing={4}>
        <Grid item>
          Pending: <Chip style={{ backgroundColor: "yellow" }} label={pendingImports}></Chip>
        </Grid>
        <Grid item>
          Error: <Chip style={{ backgroundColor: "red" }} label={errorImports}></Chip>
        </Grid>
        <Grid item>
          Success: <Chip style={{ backgroundColor: "green" }} label={successImports}></Chip>
        </Grid>

      </Grid>
      <Grid item>
        <Button color="secondary" variant="contained" onClick={OnReset} startIcon={<CancelIcon />}>Discard uploads</Button>

      </Grid>
      <Grid item>
        <Button color="primary" variant="contained" onClick={SubmitImports} startIcon={<SaveIcon />}>Submit pending Imports</Button>

      </Grid>
    </Grid>

  }


  return (

    <Grid container spacing={4}>
      <Grid xs={12} item>
        {HeaderRowExplanations}
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={"Import Users"} />
          <CardContent>
            <Grid container spacing={4}>
              {isProcessing &&
                <Grid item xs={12}>

                  <LinearProgress />
                </Grid>




              }

              {parsedImports.length === 0 ?
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      On this page you can mass import Users via CSV file upload. <br></br>
                      First, download the CSV Template with a delimiter of your choice. <br></br>
                      Then upload your file with the upload field below. <br></br>
                      You will get a validated result of your file, which you can check and confirm. <br></br>
                      You can see further explanations about the CSV contents in the Help Box on top of the page.

                    </Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item>

                      <SaveIcon /> <CSVLink data={UserImportHeaderRow.join(delimiter)} filename="user_template.csv">Download CSV Template</CSVLink>
                    </Grid>

                    <Grid item>
                      <FormControl>
                        <InputLabel id="demo-simple-select-helper-label">Delimiter</InputLabel>
                        <Select
                          value={delimiter}
                          onChange={(e) => { setDelimiter(e.target.value as string) }}
                        >
                          <MenuItem value={","}>,</MenuItem>
                          <MenuItem value={";"}>;</MenuItem>
                          <MenuItem value={"|"}>|</MenuItem>
                        </Select>
                      </FormControl>

                    </Grid>




                  </Grid>

                  <Grid item xs={12}>

                    <DropzoneArea
                      acceptedFiles={['.csv']}
                      filesLimit={1}
                      onChange={onUpload}
                      showPreviews={false}
                      dropzoneText="Drag and Drop an CSV file here or click to Import Users"


                    />
                  </Grid>
                  {invalidCSV &&
                    <Grid item xs={12}>
                      <Alert severity="error">Invalid CSV. Please check you file and try again.</Alert>
                    </Grid>
                  }

                </React.Fragment>
                : <React.Fragment>

                  {GetTableHeader()}

                  <Grid spacing={4} container>



                    <Grid item xs={12}>
                      <MaterialTable
                        icons={TableIcons}
                        title={hasPendingUploads ? "Validate your upload and confirm above." : "Upload you imports above to see pending imports."}

                        columns={ColumnDefinitions}
                        data={parsedImports}
                        isLoading={isProcessing}
                        options={{
                          selection: false,
                          grouping: false,
                          paging: false,
                          doubleHorizontalScroll: true
                          // rowStyle: GetRowStyle,
                        }}



                      />
                    </Grid>




                  </Grid>

                </React.Fragment>
              }

            </Grid>
          </CardContent>
        </Card>
      </Grid>


    </Grid>
  );
};

export default ImportMasterDataUsers;

const ColumnDefinitions: Array<Column<UserImportResultModel>> = [
  { title: "Status", render: (val) => <ImportStateRenderer status={val.status} />, cellStyle: (data, row) => ImportStateCellStyle(row.status) },
  { title: "Errors", render: (val) => <ul>{val.validationResult?.errorSummary?.map((err, index) => <li key={index}>{err}</li>)}</ul> },
  { title: "Branchgroup", render: (val) => <span>{val.result?.branchGroupName}</span> },
  { title: "Branch", render: (val) => <span>{val.result?.branchName}</span> },
  { title: "Username", render: (val) => <span>{val.result?.username}</span> },
  { title: "Password", render: (val) => <span>{val.result?.password}</span> },
  { title: "Title", render: (val) => <span>{val.result?.title}</span> },
  { title: "Firstname", render: (val) => <span>{val.result?.firstName}</span> },
  { title: "Lastname", render: (val) => <span>{val.result?.lastName}</span> },
  { title: "Active", render: (val) => <BooleanIcon value={val.result?.isActive} /> },
  { title: "Admin", render: (val) => <BooleanIcon value={val.result?.isAdmin} /> },
  { title: "Telephone", render: (val) => <span>{val.result?.telephone}</span> },
  { title: "Fax", render: (val) => <span>{val.result?.fax}</span> },
  { title: "Email", render: (val) => <span>{val.result?.email}</span> },
  { title: "Mobile", render: (val) => <span>{val.result?.mobilePhone}</span> },
  { title: "CustomField1", render: (val) => <span>{val.result?.customField1}</span> },
];