import React from "react"

export const UsersUserNameHelpText = <div>
    <p>
        <b>Username</b>
    </p>
    <ul>
        <li>
            The Username of a user. It is used to login into AgentPlus.
        </li>
    </ul>
</div>
export const UsersPasswordHelpText = <div>
    <p>
        <b>Password</b>
    </p>
    <ul>
        <li>
            The password of a user. It is used to login into AgentPlus.
        </li>
    </ul>
</div>
export const UsersTitleHelpText = <div>
    <p>
        <b>Title</b>
    </p>
    <ul>
        <li>
            The Title of a user.
        </li>
    </ul>
</div>
export const UsersFirstnameHelpText = <div>
    <p>
        <b>First name</b>
    </p>
    <ul>
        <li>
            The first name of a user.
        </li>
    </ul>
</div>
export const UsersLastNameHelpText = <div>
    <p>
        <b>Last name</b>
    </p>
    <ul>
        <li>
            The last name of a user.
        </li>
    </ul>
</div>
export const UsersIsActiveHelpText = <div>
    <p>
        <b>Active</b>
    </p>
    <ul>
        <li>
            Activates or deactivates the user.
        </li>
    </ul>
</div>
export const UsersIsAdminHelpText = <div>
    <p>
        <b>Administrator</b>
    </p>
    <ul>
        <li>
            Enables access to the AgentPlus Administration.
        </li>
    </ul>
</div>
export const UsersTelephoneHelpText = <div>
    <p>
        <b>Telephone</b>
    </p>
    <ul>
        <li>
            The Telephone number of a user.
        </li>
    </ul>
</div>
export const UsersMobilephoneHelpText = <div>
    <p>
        <b>Mobile phone</b>
    </p>
    <ul>
        <li>
            The Mobile phone number of a user.
        </li>
    </ul>
</div>
export const Customfield1HelpText = <div>
    <p>
        <b>Custom field 1</b>
    </p>
    <ul>
        <li>
            Free field to store information about the user. This information will be included in the CSV if you export the user.
        </li>
    </ul>
</div>
export const UsersFaxHelpText = <div>
    <p>
        <b>Fax</b>
    </p>
    <ul>
        <li>
            The Fax number of a user.
        </li>
    </ul>
</div>
export const UsersEmailHelpText = <div>
    <p>
        <b>Email</b>
    </p>
    <ul>
        <li>
            The Email of a user
        </li>
    </ul>
</div>