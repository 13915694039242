import React from "react";
export const IsEnabled = <div>
    <p>
        <b>Enabled</b>
    </p>

    <ul>
        <li>
            Turns feature on or off
        </li>


    </ul>

</div>

