import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { FilterReducer } from "reducers/FilterReducer";
import { combineReducers } from "redux";
import { FilterState } from "types/FilterTypes";
import { StateType } from "typesafe-actions";
import { sessionReducer } from "../reducers/SessionReducer";
import { SessionState } from "../types/SessionTypes";

const rootReducer = (history: History) =>
  combineReducers<State>({
    router: connectRouter(history),
    Session: sessionReducer,
    Filter: FilterReducer
  });

export interface State {
  router: RouterState;
  Session: SessionState;
  Filter: FilterState
}

export type RootState = StateType<typeof rootReducer>;
export default rootReducer;
