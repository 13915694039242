import { Avatar, Button, Card, CardContent, CardHeader, Grid, Typography, TextField } from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleIcon from '@material-ui/icons/People';
import ReceiptIcon from '@material-ui/icons/Receipt';
import React, { useEffect, useState } from "react";
import { CustomerProfileData, CustomerProfilePassengers, CustomerProfilesFlightDetails } from "WebApiClient";
import { defaultAdultObject, useStyles } from "./customerprofiles.Details";
import { CustomerProfileAdressInput } from "./CustomerProfilesAdressInput";
import { CustomerProfilesFlightDetailsComponent } from "./CustomerProfilesFlightDetailsComponent";
import { CustomerProfilesPassengersComponent } from "./CustomerProfilesPassengersComponent";
export const CustomerProfileDataComponent: React.FC<{titleGlobal?:any,dateGlobal?:any,setValidationTriggerParent?:any,globalValidationTrigger?:any,setglobalValidationTrigger?:any,errorTrigger?:any, data: CustomerProfileData; OnUpdate: (data: CustomerProfileData) => void;setbuttonDB?:any }> = props => {
    const data = props.data;
    const [CustomerProfileData, setCustomerProfileData] = useState(data);
    useEffect(() => {
        setCustomerProfileData(data);
    }, [])
    const classes = useStyles();
    function OnUpdatePassengers(passengers?: CustomerProfilePassengers) {
        props.OnUpdate({ ...data, passengers: passengers });
    }

    function OnUpdateFlightDetails(flightDetails?: CustomerProfilesFlightDetails) {
        props.OnUpdate({ ...data, flightDetails: flightDetails });
    }

    function OnRemovePassengers() {
        props.OnUpdate({ ...data, passengers: undefined });
    }

    function OnAddPassengers() {
        props.OnUpdate({ ...data, passengers: { adults: { ...defaultAdultObject }, children: undefined, infants: undefined } });
    }

    return <Grid item xs={12} container spacing={2}>
        <Grid item container spacing={3}>
            <Grid item>
                <TextField
                    // error={nameError !== undefined}
                    value={data?.travellerID}
                    onChange={e =>
                        props.OnUpdate({
                            ...data,
                            travellerID: e.target.value
                        })
                    }
                    // helperText={nameError}
                    label="Traveller ID"
                    error={props.errorTrigger && (data?.travellerID?.length ? (data?.travellerID?.length >2 && data?.travellerID?.length<16?false:true) : true)}
                    helperText={`${props.errorTrigger ? (data?.travellerID ? (data?.travellerID?.length >2 && data?.travellerID?.length<16? '':'Character should be between 3 - 15') : 'This Field is required') : ''}`}
                // InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.TitleHelpText} /> }}
                />
            </Grid>
            <Grid item>
                <TextField
                    // error={nameError !== undefined}
                    value={data?.emergencyNumber}
                    onChange={e =>
                        props.OnUpdate({
                            ...data,
                            emergencyNumber: e.target.value
                        })
                    }
                    error={props.errorTrigger && (data?.emergencyNumber?.length ? (data?.emergencyNumber?.length >2 && data?.emergencyNumber?.length<16?false:true) : true)}
                    helperText={`${props.errorTrigger ? (data?.emergencyNumber ? (data?.emergencyNumber?.length >2 && data?.emergencyNumber?.length<16? '':'Character should be between 3 - 15') : 'This Field is required') : ''}`}
                    // helperText={nameError}
                    label="Emergency Number"
                // InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.TitleHelpText} /> }}
                />
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <Card elevation={4} className={classes.lightBlueBackground}>
                        <CardHeader
                            avatar={<Avatar className={data.passengers ? classes.activeAvatar : ""}><PeopleIcon /></Avatar>}
                            title={<Typography variant="h5">Passengers</Typography>}
                            action={data.passengers ? <Button onClick={OnRemovePassengers}
                                startIcon={<DeleteIcon />} color="secondary">remove all passengers</Button> : null} />
                        <CardContent>
                            {data.passengers ?
                                <CustomerProfilesPassengersComponent globalValidationTrigger={props.globalValidationTrigger!} setglobalValidationTrigger={props.setglobalValidationTrigger!} errorTrigger={props.errorTrigger!} setbuttonDB={props.setbuttonDB} data={data.passengers} OnUpdate={OnUpdatePassengers} />
                                :
                                <Button startIcon={<AddCircleOutlineIcon />} variant="contained" color="primary" onClick={OnAddPassengers}>Add passengers</Button>}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <CustomerProfilesFlightDetailsComponent data={data.flightDetails} OnUpdate={OnUpdateFlightDetails} />
            </Grid>
            <Grid item xs={12}>
                <CustomerProfileAdressInput OnUpdate={(e) => { props.OnUpdate({ ...data, delivery: e }) }} Title="Delivery" data={data.delivery} Icon={<AllInboxIcon />} />
            </Grid>
            <Grid item xs={12}>
                <CustomerProfileAdressInput OnUpdate={(e) => { props.OnUpdate({ ...data, invoice: e }) }} Title="Invoice" data={data.invoice} Icon={<ReceiptIcon />} />
            </Grid>
        </Grid>
    </Grid>;
};
