import { DeepLoginData } from "types/SessionTypes";
import { createAction } from "typesafe-actions";
import { LoginModel } from "WebApiClient";

export const Session_Saga_TryLogin = createAction(
  "@@session/SAGA_TRYLOGIN",
  (data: LoginModel) => data
)<LoginModel>();
export const Session_Saga_TryDeepLogin = createAction(
  "@@session/SAGA_TRYDEEPLOGIN",
  (data: DeepLoginData) => data
)<DeepLoginData>();
export const Session_Saga_RefreshToken = createAction(
  "@@session/SAGA_REFRESHTOKEN",
  () => { }
)<void>();
export const Session_Saga_Logout = createAction(
  "@@session/SAGA_LOGOUT",
  () => { }
)<void>();
