import { Button, Card, CardContent, CardHeader, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFlightApiClient } from "hooks/useHttpClient";
import React, { useState } from "react";
import { FlightApiUserAccessEnum, FlightApiUserInformationResponseModel } from "WebApiClient";


export const FlightApiUserInformationFetchComponent: React.FC<{ username: string, password: string }> = props => {
    const client = useFlightApiClient();
    const [flightApiUserInfo, setflightApiUserInfo] = useState<
        FlightApiUserInformationResponseModel | undefined
    >(undefined);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    function GetFlightApiUserInformation() {
        setLoading(true);
        client.getUserInfo(props.username, props.password)
            .then(response => {
                setflightApiUserInfo(response);
                setError(false);
            }).catch(() => {
                setError(true);
            }).finally(() => {
                setLoading(false);
            })
    }
    return <Grid container item xs={12}>
        <Grid item xs={12}>
            <Button onClick={GetFlightApiUserInformation} color="primary" variant="outlined"> Get FlightApi user information</Button>
        </Grid>
        {loading &&
            <Grid item xs={12}>
                <CircularProgress />
            </Grid>
        }
        {error &&
            <Grid item xs={12}>
                <Alert severity="error">
                    An serverside error has occured.
                </Alert>
            </Grid>
        }
        {flightApiUserInfo &&
            <Grid item xs={12}>

                <FlightApiUserInformationDisplay flightApiUserInfo={flightApiUserInfo} />
            </Grid>
        }
    </Grid>
}

export const FlightApiUserInformationDisplay: React.FC<{ flightApiUserInfo: FlightApiUserInformationResponseModel }> = props => {
    const { flightApiUserInfo } = props;
    const { success, flightApiUserInformation, errorMessage } = flightApiUserInfo;
    return <Card elevation={3}>
        <CardHeader title={(success && flightApiUserInformation) ? `${flightApiUserInformation.username}` : "FlightApi user information"} />
        {(success && flightApiUserInformation) ?
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item>
                        General information
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="left">Username</TableCell>
                                        <TableCell align="right">{flightApiUserInformation.username}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">Active</TableCell>
                                        <TableCell align="right">{flightApiUserInformation.isActive ? "Yes" : "No"}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">Calculation enabled</TableCell>
                                        <TableCell align="right">{flightApiUserInformation.calculationEnabled ? "Yes" : "No"}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left">Sales preference enabled</TableCell>
                                        <TableCell align="right">{flightApiUserInformation.salesPreferenceEnabled ? "Yes" : "No"}</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="left">Allowed AgentPlus Webs</TableCell>
                                        <TableCell align="right">{flightApiUserInformation.allowedWebs}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>


                        </TableContainer>


                    </Grid>
                    <Grid item>
                        Membership groups
                        {flightApiUserInformation.membershipGroups &&
                            <React.Fragment>
                                {flightApiUserInformation.membershipGroups.map((group, groupIndex) =>
                                    <Card key={groupIndex}>
                                        <CardHeader title={group.name}></CardHeader>
                                        <CardContent>
                                            <TableContainer component={Paper}>
                                                {group.members &&
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell >Username</TableCell>
                                                                <TableCell>Access rights</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {group.members.map((member, memberIndex) =>
                                                                <TableRow key={memberIndex}>
                                                                    <TableCell align="left">{member.username}</TableCell>
                                                                    <TableCell align="right">{GetAccessLabel(member.access)}</TableCell>
                                                                </TableRow>
                                                            )}


                                                        </TableBody>
                                                    </Table>
                                                }


                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                )}
                            </React.Fragment>
                        }
                    </Grid>
                </Grid>
            </CardContent> :
            <CardContent>

                <Alert severity="error">
                    An error has occured. <br></br>
                    {errorMessage}
                </Alert>
            </CardContent>
        }
    </Card>
}

function GetAccessLabel(access: FlightApiUserAccessEnum): string {
    let result = "";
    switch (access) {
        case FlightApiUserAccessEnum.None:
            result = "None";
            break;
        case FlightApiUserAccessEnum.Read:
            result = "Read";
            break;
        case FlightApiUserAccessEnum.Unknown:
            result = "Unknown";
            break;
        case FlightApiUserAccessEnum.Write:
            result = "Write";
            break;
    }
    return result;
}