import { Checkbox, FormControlLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Session_Saga_TryLogin } from "actions/SessionSagactions";
import { AgentApiURL } from "Constants";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import { State } from "rootExports/rootReducer";
import Logo from "Styles/images/logo.png";
import { ApiCredentialsOptions, LoginClient, LoginModel } from "WebApiClient";
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  center: {
    textAlign: "center"
  }
}));

const LoginPage = ({
  match,
  TryLogin,
  LoginFailed,
  IsLoggedIn,
  ErrorMessages
}: AllProps) => {
  const [userName, changeUserName] = useState("");
  const [supportUserName, changeSupportUserName] = useState("");
  const [password, changePassword] = useState("");
  const [supportLogin, setSupportLogin] = useState(false);
  const webName: string =
    match.params.routeWebname !== undefined
      ? match.params.routeWebname
      : "eov";

  useEffect(() => {
    let w: LoginClient = new LoginClient(
      new ApiCredentialsOptions(AgentApiURL, "")
    );
    w.webNameExists(webName).then(e => {
      setWebExists(e);
    });
    document.title = `Login`;
  }, [webName]);

  const [webExists, setWebExists] = useState(true);
  const classes = useStyles();

  return (

    <Container component="main" maxWidth="xs">


      <div className={classes.paper}>
        <img src={Logo} alt="HitchHiker Logo" />
        {webExists ? (
          <React.Fragment>
            <Typography component="h1" variant="h5" align="center">
              AgentPlus Administration<hr></hr>{webName}
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault();
                TryLogin({
                  username: userName,
                  password: password,
                  support: supportLogin,
                  supportname: supportUserName,
                  webname: webName,
                  universalTempDataID: undefined
                });
              }}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={userName}
                onChange={e => changeUserName(e.target.value)}
                error={LoginFailed}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={e => changePassword(e.target.value)}
                error={LoginFailed}
              />
              <FormControlLabel
                control={<Checkbox checked={supportLogin} onChange={() => { setSupportLogin(!supportLogin) }} color="primary" />}
                label="Login as support"
              />
              {supportLogin &&
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="supportusername"
                  label="Support Username"
                  name="supportname"
                  helperText="If you want to log in as HitchHiker support staff, instead of using the normal user password use your HitchHiker credentials."
                  value={supportUserName}
                  onChange={e => changeSupportUserName(e.target.value)}
                  error={LoginFailed}
                />
              }
              {LoginFailed && (
                <Paper className={classes.center} elevation={3}>
                  <ul>

                    {ErrorMessages.map((e, index) =>
                      <li key={index}>{e}</li>
                    )}
                  </ul>
                </Paper>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                LOGIN
              </Button>
            </form>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography component="h1" variant="h5">
              The Web <b>{webName}</b> does not exist.
            </Typography>
          </React.Fragment>
        )}
      </div>

    </Container>

  );
};

interface RouteParams {
  routeWebname: string;
}

type AllProps = PropsFromState &
  DispatchProps &
  RouteComponentProps<RouteParams>;

interface PropsFromState {
  LoginFailed: boolean;
  IsLoggedIn: boolean;
  ErrorMessages: string[];
}
const mapStateToProps = (state: State) => ({
  LoginFailed: state.Session.LoginFailed,
  IsLoggedIn: state.Session.isLoggedIn,
  ErrorMessages: state.Session.ErrorMessages
});

interface DispatchProps {
  TryLogin: typeof Session_Saga_TryLogin;
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  TryLogin: (e: LoginModel) => dispatch(Session_Saga_TryLogin(e))
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
