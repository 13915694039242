import { Grid } from "@material-ui/core";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { useCustomerProfilesClient } from "hooks/useHttpClient";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, UpdateCustomerProfileDataRequest } from "WebApiClient";
import CustomerProfileDataEditComponent from "./customerprofiles.Details";
import CustomerProfileDataTable from "./customerprofiles.Table";



interface ICustomerProfileDataTableProps { myRef?:any }
const ActionTitle = "CustomerProfileData";
export const CustomerProfileDataPage: React.FC<ICustomerProfileDataTableProps> = props => {

  const CustomerProfileDataClient = useCustomerProfilesClient();
  const dispatch = useDispatch();
  const myRef = useRef(undefined)


  const [lastedit, setlastedit] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [editCustomerProfileData, setEditCustomerProfileData] = useState<UpdateCustomerProfileDataRequest | undefined>(
    undefined
  );
  const [editCustomerProfileDataErrorState, setEditCustomerProfileDataErrorState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);

  const [CustomerProfileData, setCustomerProfileData] = useState<UpdateCustomerProfileDataRequest[]>([]);



  function RefreshCustomerProfileData() {
    setIsLoading(true);
    CustomerProfileDataClient
      .list()
      .then(response => {
        setCustomerProfileData(response);
      })
      .catch(() => { })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function DeleteCustomerProfileDataAsync(id: string) {
    let transactionSuccess = false;
    let transactionTime = new Date().toString();
    let transactionId = "";
    let transactionMsg = "MSG NOT SET";
    let serverSideError = false;
    CustomerProfileDataClient
      .delete(id)
      .then(() => {
        transactionMsg = `CustomerProfileData deleted.`;

      })
      .catch(e => {
        console.error(e);
        serverSideError = true;
      })
      .finally(() => {
        if (serverSideError) {
          transactionMsg = `CustomerProfileData could not be deleted. An server-side error has occured.`;
        }
        let transaction: TransactionIDInfo = {
          Label: `Delete CustomerProfileDatagroup`,
          Success: transactionSuccess,
          Time: transactionTime,
          TransactionID: transactionId,
          Message: transactionMsg
        };
        dispatch(Session_Reducer_PushTransactionID(transaction));
        RefreshCustomerProfileData();
      });
  }

  function UpdateCustomerProfileDataAsync(CustomerProfileData: UpdateCustomerProfileDataRequest) {
    const isUpdate = CustomerProfileData.id !== undefined;
    const actionTypeLabel = isUpdate ? "updated" : "created";
    document.getElementById("top-anchor")!.scrollIntoView({ behavior: 'smooth', block: 'center' });

    let transactionSuccess = false;
    let transactionTime = new Date().toString();
    let transactionId = "";
    let transactionMsg = "MSG NOT SET";
    let serverSideError = false;

    CustomerProfileDataClient
      .update(CustomerProfileData)
      .then(result => {
        transactionSuccess = true;
        transactionMsg = `CustomerProfileData successfully ${actionTypeLabel}.`;
        CloseEditCustomerProfileDatagroup();
        setlastedit(result);
      })
      .catch(e => {
        console.error(e);
        serverSideError = true;
      })
      .finally(() => {
        if (serverSideError) {
          transactionMsg = `CustomerProfileData could not be ${actionTypeLabel}. An server-side error has occured.`;
        }
        let transaction: TransactionIDInfo = {
          Label: `${isUpdate ? "Update" : "Create"} CustomerProfileData`,
          Success: transactionSuccess,
          Time: transactionTime,
          TransactionID: transactionId,
          Message: transactionMsg
        };
        dispatch(Session_Reducer_PushTransactionID(transaction));
        RefreshCustomerProfileData();
      });
  }

  function SetEditCustomerProfileData(data: UpdateCustomerProfileDataRequest | undefined) {
    setEditCustomerProfileDataErrorState(undefined);
    if (data) {
      setEditCustomerProfileData(data);
    } else {
      const newBg: UpdateCustomerProfileDataRequest = {
        id: undefined,
        data: { passengers: undefined, flightDetails: undefined, delivery: undefined, invoice: undefined, travellerID: undefined, emergencyNumber: undefined },
        title: "",
        validUntil: undefined,
      };
      setEditCustomerProfileData(newBg);
    }
  }

  function CloseEditCustomerProfileDatagroup() {
    setEditCustomerProfileDataErrorState(undefined);
    setEditCustomerProfileData(undefined);
  }

  useEffect(() => { RefreshCustomerProfileData() }, [])

  return (

    <Grid container spacing={3}>



      <React.Fragment>
        {editCustomerProfileData && (
          <Grid item xs={12}>
            <CustomerProfileDataEditComponent
              Data={editCustomerProfileData}
              OnSave={UpdateCustomerProfileDataAsync}
              OnCancel={CloseEditCustomerProfileDatagroup}
              ValidationResult={editCustomerProfileDataErrorState}
              myRef={myRef!}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomerProfileDataTable
            myRef={myRef!}
            LastEdit={lastedit}
            IsLoading={loading}
            Data={CustomerProfileData}
            OnRefresh={() => RefreshCustomerProfileData()}
            OnDelete={DeleteCustomerProfileDataAsync}
            OnEdit={SetEditCustomerProfileData}
          />
        </Grid>
      </React.Fragment>

    </Grid>

  );
};

export default CustomerProfileDataPage;

