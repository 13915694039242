import React from "react"

export const SalesPrefRulesIntroHelptext = <div>
    <p>
        <b>Sales preference rules</b>
    </p>

    <ul>
        <li>
            The regulations are evaluated from top to the bottom by comparing their
            attributes to the fare result delivered by our flight engine the FAPI.
        </li>
        <li>
            The first matching regulation is the winner and provides the sales preference
            actions to be applied.
        </li>
        <li>
            <b>
                If a single fare does not match to any rule the whole fare result gets discarded. This why
                it is highly recommended to create a capture-all-filter at the
                bottom with no criterias.
            </b>
        </li>

    </ul>

</div>
export const SalesPrefRuleGeneralHelptext = <div><p>
    <b>General</b>
</p>

    <ul>

        <li>
            All fields with an "*" marker, allow multiple values which can be seperated by "," or " ".
            The "-" prefix can be used to exclude a single value. <br />
            Examples: <br />
            1) All german airports without Berlin and Munich <br />
            /DE -BER -MUC <br />
            2) All Star alliance carriers without Lufthansa <br />
            *SA -LH
        </li>


    </ul>
</div>

export const SalesPrefRuleSortOrderHelptext = <div><p>
    <b>Sort order</b>
</p>

    <ul>
        <li>
            The filters are applied in a ascending order.
            </li>


    </ul>
</div>

export const SalesPrefRuleActionHelptext = <div>
    <p>
        <b>Action</b>
    </p>

    <ul>
        <li>The action to be applied with this filter.</li>

    </ul>

</div>



