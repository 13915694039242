import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Session_Reducer_SetDeployInfo } from 'actions/SessionActions';
import { Navbar } from "components/general/Navbar";
import { ConnectedRouter } from "connected-react-router";
import { Environment } from 'Constants';
import { History } from "history";
import { GetDeployInfo } from 'hooks/GetAppInfo';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { State } from "rootExports/rootReducer";
import Routes from "routes/index";


function useTheme() {
  const themeOptions = useSelector((state: State) => state.Session.Theme);
  return createMuiTheme(themeOptions);
}
interface AppProps {
  history: History;
}
const App = (props: AppProps) => {
  const theme = useTheme();
  const deployInfo = useSelector((state: State) => state.Session.DeployInfo);

  const NewBackendVersion: string = useSelector((state: State) => state.Session.BackendVersion);
  const VersionDifferenceToBackend: boolean = DetectBackendDifference();
  const IsLoggedIn: boolean = useSelector((state: State) => state.Session.isLoggedIn);
  const dispatch = useDispatch();

  function DetectBackendDifference(): boolean {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return false;
    }
    if (deployInfo && NewBackendVersion.length > 0) {
      if (deployInfo.Version !== NewBackendVersion) {
        return true;
      }
    }
    return false;
  }
  useEffect(() => {
    console.log("AgentPlus initialized. Loading Environment...")
    GetDeployInfo().then((info) => {
      dispatch(Session_Reducer_SetDeployInfo(info));
      console.log(`Environment ${info.DeployedEnvironment} and Build configuration ${Environment} detected. AgentPlus is ready.`)

    })
  }, [])
  function Render() {
    if (!VersionDifferenceToBackend) {
      if (IsLoggedIn) {
        return <Navbar>
          <Routes />
        </Navbar>
      }
      else {
        return <Routes />
      }

    }
    else {
      return <div className="container xl-wrapper">
        <div className="vertical-center">
          <div className="row">
            <div className="col-12 content-wrapper">
              <div className="card">
                <div className="card-header card-header-primary text-center text-success font-weight-bold">
                  <i className="fas fa-exclamation-triangle pr-2"></i> A new version of Agent is available!
                  <i className="fas fa-exclamation-triangle pl-2"></i></div>
                <div className="card-body card-body-primary">
                  <p className="text-center">You are using an outdated version of Agent.</p>
                  <p className="text-center">Your Version: {deployInfo?.Version}</p>
                  <p className="text-center">New Version: {NewBackendVersion}</p>
                  <p>In order to continue using Agent, <b className="font-weight-bold">please refresh the page completely.</b></p>
                  <p>If this message keeps appearing, try the following steps in the following order:</p>
                  <ul>
                    <li>Open the page in a new tab or window.</li>
                    <li>If on a desktop computer: press STRG+F5</li>
                    <li>Ask your IT support for help.</li>
                    <li>If no IT support is available, Instructions for different browsers can be found on https://www.refreshyourcache.com/en/home/</li>
                    <li>If none of the above steps worked: contact Hitchhiker support.</li>
                  </ul>

                </div>
              </div>


            </div>
          </div>

        </div>

      </div>
    }
  }


  return (
    <ConnectedRouter history={props.history}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer position="top-center" />
        {Render()}
      </ThemeProvider>
    </ConnectedRouter>
  );
};

export default App;



