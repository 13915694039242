import { Box, Card, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Input, LinearProgress } from "@material-ui/core";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { SettingsActions } from "components/shared/CardActions";
import { ErrorMessages } from "components/shared/ErrorMessages";
import { ApplyIndicator, CreateSettingsButton, ISettingsComponentProps, OverriddenIndicator } from "components/shared/SettingPagesVarious";
import { WebLevelLabel } from "components/shared/WebLevelLabel";
import { usePassengerTypesSettingsClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, CreateSettingsRequest, SettingsOrder, UpdatePassengerTypeSettingsModel } from "WebApiClient";


const PassengerTypeSettings: React.FC<ISettingsComponentProps> = props => {
  const { ReadOnly, WebLevel, Id, LevelName } = props;
  const client = usePassengerTypesSettingsClient();
  const dispatch = useDispatch();
  const [Settings, setSettings] = useState<
    UpdatePassengerTypeSettingsModel | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [validationState, setValidationState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);
  const [serverError, setServerError] = useState(false);
  useEffect(() => {
    GetSettings(WebLevel, Id);
  }, [WebLevel, Id]);

  function OnUpdate() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .update(Settings)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully updated.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be updated.";
          }
          if (response.serverError) {
            serverSideError = true;
          } else {
            setValidationState(response.validationResult);
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be updated. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Update Multichannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function Reload() {
    GetSettings(WebLevel, Id);
  }

  function CreateSettings() {
    if (Id) {
      const createRequest: CreateSettingsRequest = {
        id: Id,
        sortOrder: WebLevel
      };
      setLoading(true);
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      client
        .create(createRequest)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully created.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be created.";
          }
          if (response.serverError) {
            serverSideError = true;
          }
        })
        .catch(e => {
          serverSideError = true;
          console.error(e);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be created. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Create Mulitchannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setLoading(false);
          if (transactionSuccess) {
            Reload();
          }
        });
    }
  }

  function GetSettings(order: SettingsOrder, Id: string | null) {
    setLoading(true);

    client
      .get(order, Id)
      .then(settings => {
        setSettings(settings);
        if (settings !== null) {
          props.OnNotifyIfSettingsExist(true);
        } else {
          props.OnNotifyIfSettingsExist(false);
        }
      })
      .catch(e => {
        console.error(e);
        setServerError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function DeleteSettings() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .delete(Settings?.id!)
        .then(response => {
          if (response.success) {
            transactionMsg = "successfully deleted.";
            transactionSuccess = true;
          } else {
            transactionMsg = `could not be deleted. ${response.errorMessage}`;
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg = "could not be deleted. A server error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Delete Multichannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  const userNameError: string | undefined = validationState
    ? validationState.properyErrors!["username"]
    : undefined;

  const PasswordError: string | undefined = validationState
    ? validationState.properyErrors!["password"]
    : undefined;
  return (
    <Box boxShadow={ReadOnly ? undefined : 3}>
      <Card style={{ opacity: ReadOnly ? 0.5 : 1 }}>
        <CardHeader
          title={
            <span>
              {props.Applies && (
                <span>
                  <ApplyIndicator />
                </span>
              )}
              {props.IsOverwritten && (
                <span>
                  <OverriddenIndicator />
                </span>
              )}
              <span>Passenger types settings for </span>
              <WebLevelLabel Level={WebLevel} Name={LevelName} />{" "}
              {/* {props.IsOverwritten ? "Overwritten" : ""} */}
            </span>
          }
        />
        {!loading && (
          <CardContent>
            {Settings ? (
              <Grid container spacing={2}>
                {serverError && <ErrorMessages ServerError={true} />}
                {validationState && validationState.errorSummary && (
                  <ErrorMessages Messages={validationState.errorSummary} />
                )}
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Maximum total amount of passengers in search</FormLabel>
                    <Input

                      value={Settings.maxTotalPassengerAmount}
                      margin="dense"

                      onChange={(e) => {
                        let val = e.target.value;
                        setSettings({ ...Settings, maxTotalPassengerAmount: parseFloat(val) })
                      }}


                      inputProps={{
                        step: 1,
                        min: 1,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset" >
                    <FormLabel component="legend">Available passenger types in fare query</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={Settings.adultActive} onChange={() => { setSettings({ ...Settings, adultActive: !Settings.adultActive }) }} />}
                        label="Adult"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={Settings.childActive} onChange={() => { setSettings({ ...Settings, childActive: !Settings.childActive }) }} />}
                        label="Child"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={Settings.infantActive} onChange={() => { setSettings({ ...Settings, infantActive: !Settings.infantActive }) }} />}
                        label="Infant"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={Settings.studentActive} onChange={() => { setSettings({ ...Settings, studentActive: !Settings.studentActive }) }} />}
                        label="Student"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={Settings.youthActive} onChange={() => { setSettings({ ...Settings, youthActive: !Settings.youthActive }) }} />}
                        label="Youth"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={Settings.seniorActive} onChange={() => { setSettings({ ...Settings, seniorActive: !Settings.seniorActive }) }} />}
                        label="Senior"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={Settings.partnerActive} onChange={() => { setSettings({ ...Settings, partnerActive: !Settings.partnerActive }) }} />}
                        label="Partner"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={Settings.ethnicActive} onChange={() => { setSettings({ ...Settings, ethnicActive: !Settings.ethnicActive }) }} />}
                        label="Ethnic"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={Settings.seamanActive} onChange={() => { setSettings({ ...Settings, seamanActive: !Settings.seamanActive }) }} />}
                        label="Seaman"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={Settings.agentActive} onChange={() => { setSettings({ ...Settings, agentActive: !Settings.agentActive }) }} />}
                        label="Agent"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={Settings.militaryActive} onChange={() => { setSettings({ ...Settings, militaryActive: !Settings.militaryActive }) }} />}
                        label="Military"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={Settings.groupActive} onChange={() => { setSettings({ ...Settings, groupActive: !Settings.groupActive }) }} />}
                        label="Group"
                      />

                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <CreateSettingsButton
                OnClick={CreateSettings}
                Readonly={props.ReadOnly}
              />
            )}
          </CardContent>
        )}
        {loading && (
          <CardContent>
            <LinearProgress />
          </CardContent>
        )}
        {!ReadOnly && Settings && (
          <SettingsActions
            OnDelete={DeleteSettings}
            OnReset={Reload}
            OnSave={OnUpdate}
          />
        )}
      </Card>
    </Box>
  );
};

export default PassengerTypeSettings;
