import { Box, Button, Card, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Select, Typography } from "@material-ui/core";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { SettingsActions } from "components/shared/CardActions";
import { ErrorMessages } from "components/shared/ErrorMessages";
import { ApplyIndicator, CreateSettingsButton, ISettingsComponentProps, OverriddenIndicator } from "components/shared/SettingPagesVarious";
import { WebLevelLabel } from "components/shared/WebLevelLabel";
import { useBookingManagerVisibilitySettingsClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, AgentPlusBookingManagerFeature, AgentPlusBookingManagerGlobalFeature, CreateSettingsRequest, SettingsOrder, UpdateBookingManagerVisibilitySettingsModel, WebAccessEnum } from "WebApiClient";


const allStatus: AgentPlusBookingManagerFeature[] = [
  AgentPlusBookingManagerFeature.RefreshPnr,
  AgentPlusBookingManagerFeature.CancelBooking,
  AgentPlusBookingManagerFeature.OrderReshop,
  AgentPlusBookingManagerFeature.VoidCancelTicket,
  AgentPlusBookingManagerFeature.ManageServices,
  AgentPlusBookingManagerFeature.ManageElements,
  AgentPlusBookingManagerFeature.Seatmap,
  AgentPlusBookingManagerFeature.TicketIssue,
  AgentPlusBookingManagerFeature.RetrievePNR,
  AgentPlusBookingManagerFeature.DownloadBackOfficeFile,

]
const BookingManagerVisibilitySettings: React.FC<ISettingsComponentProps> = props => {
  const { ReadOnly, WebLevel, Id, LevelName } = props;
  const client = useBookingManagerVisibilitySettingsClient();
  const dispatch = useDispatch();
  const [Settings, setSettings] = useState<
    UpdateBookingManagerVisibilitySettingsModel | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [validationState, setValidationState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);
  const [serverError, setServerError] = useState(false);
  useEffect(() => {
    GetSettings(WebLevel, Id);
  }, [WebLevel, Id]);

  function OnUpdate() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .update(Settings)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully updated.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be updated.";
          }
          if (response.serverError) {
            serverSideError = true;
          } else {
            setValidationState(response.validationResult);
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be updated. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Update Bookingmanager Visibility settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Bookingmanager Visibility settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function Reload() {
    GetSettings(WebLevel, Id);
  }


  function CreateSettings() {
    if (Id) {
      const createRequest: CreateSettingsRequest = {
        id: Id,
        sortOrder: WebLevel
      };
      setLoading(true);
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      client
        .create(createRequest)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully created.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be created.";
          }
          if (response.serverError) {
            serverSideError = true;
          }
        })
        .catch(e => {
          serverSideError = true;
          console.error(e);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be created. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Create Bookingmanager Visibility settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Bookingmanager Visibility settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setLoading(false);
          if (transactionSuccess) {
            Reload();
          }
        });
    }
  }

  function GetSettings(order: SettingsOrder, Id: string | null) {
    setLoading(true);

    client
      .get(order, Id)
      .then(settings => {
        setSettings(settings);
        if (settings !== null) {
          props.OnNotifyIfSettingsExist(true);
        } else {
          props.OnNotifyIfSettingsExist(false);
        }
      })
      .catch(e => {
        console.error(e);
        setServerError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function DeleteSettings() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .delete(Settings?.id!)
        .then(response => {
          if (response.success) {
            transactionMsg = "successfully deleted.";
            transactionSuccess = true;
          } else {
            transactionMsg = `could not be deleted. ${response.errorMessage}`;
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg = "could not be deleted. A server error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Delete Bookingmanager Visibility settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Bookingmanager Visibility settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function OnToogleBookingStatus(toggleStatus: AgentPlusBookingManagerFeature) {
    if (Settings) {
      let oldArr = [...Settings.allowedFeatures];
      const iOf = oldArr.indexOf(toggleStatus);
      if (iOf === -1) {
        oldArr.push(toggleStatus);
      }
      else {
        oldArr.splice(iOf, 1)
      }
      setSettings({ ...Settings, allowedFeatures: oldArr });
    }
  }
  function OnToogleGlobalFeature(toggleStatus: AgentPlusBookingManagerGlobalFeature) {
    if (Settings) {

      let oldArr = [...Settings.allowedGlobalFeatures];
      const iOf = oldArr.indexOf(toggleStatus);
      if (iOf === -1) {
        oldArr.push(toggleStatus);
      }
      else {
        oldArr.splice(iOf, 1)
      }
      setSettings({ ...Settings, allowedGlobalFeatures: oldArr });
    }
  }
  function OnSetAllBookingStatus() {
    if (Settings) {

      setSettings({ ...Settings, allowedFeatures: allStatus });
    }


  }
  function OnSetAllNoneBookingStatus() {
    if (Settings) {

      setSettings({ ...Settings, allowedFeatures: [] });

    }
  }
  return (
    <Box boxShadow={ReadOnly ? undefined : 3}>
      <Card style={{ opacity: ReadOnly ? 0.5 : 1 }}>
        <CardHeader
          title={
            <span>
              {props.Applies && (
                <span>
                  <ApplyIndicator />
                </span>
              )}
              {props.IsOverwritten && (
                <span>
                  <OverriddenIndicator />
                </span>
              )}
              <span>Bookingmanager Visibility settings for </span>
              <WebLevelLabel Level={WebLevel} Name={LevelName} />{" "}
              {/* {props.IsOverwritten ? "Overwritten" : ""} */}
            </span>
          }
        />
        {!loading && (
          <CardContent>
            {Settings ? (
              <Grid container spacing={2}>
                {serverError && <ErrorMessages ServerError={true} />}
                {validationState && validationState.errorSummary && (
                  <ErrorMessages Messages={validationState.errorSummary} />
                )}

                <Grid item xs={4}>
                  <WebAccessInput Title="Users can view Bookings from..." Value={Settings.bookingVisibility} OnChange={(e) => { setSettings({ ...Settings, bookingVisibility: e }) }} />
                </Grid>
                <Grid item xs={4}>
                  <WebAccessInput Title="Administrators can view Bookings from..." Value={Settings.adminBookingVisibility} OnChange={(e) => { setSettings({ ...Settings, adminBookingVisibility: e }) }} />
                </Grid>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Allowed options for bookings <Button variant="outlined" onClick={OnSetAllBookingStatus}>all</Button> <Button variant="outlined" onClick={OnSetAllNoneBookingStatus}>none</Button></Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={4} container>
                      <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={Settings.allowedFeatures.includes(AgentPlusBookingManagerFeature.RefreshPnr)} onChange={() => { OnToogleBookingStatus(AgentPlusBookingManagerFeature.RefreshPnr) }} />} label="Refresh PNR" />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={Settings.allowedFeatures.includes(AgentPlusBookingManagerFeature.RetrievePNR)} onChange={() => { OnToogleBookingStatus(AgentPlusBookingManagerFeature.RetrievePNR) }} />} label="View PNR" />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={Settings.allowedFeatures.includes(AgentPlusBookingManagerFeature.Seatmap)} onChange={() => { OnToogleBookingStatus(AgentPlusBookingManagerFeature.Seatmap) }} />} label="Seatmap" />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={Settings.allowedFeatures.includes(AgentPlusBookingManagerFeature.DownloadBackOfficeFile)} onChange={() => { OnToogleBookingStatus(AgentPlusBookingManagerFeature.DownloadBackOfficeFile) }} />} label="Download backoffice file" />
                      </Grid>


                    </Grid>
                    <Grid item xs={4} container>
                      <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={Settings.allowedFeatures.includes(AgentPlusBookingManagerFeature.TicketIssue)} onChange={() => { OnToogleBookingStatus(AgentPlusBookingManagerFeature.TicketIssue) }} />} label="Issue ticket" />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={Settings.allowedFeatures.includes(AgentPlusBookingManagerFeature.CancelBooking)} onChange={() => { OnToogleBookingStatus(AgentPlusBookingManagerFeature.CancelBooking) }} />} label="Cancel booking" />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={Settings.allowedFeatures.includes(AgentPlusBookingManagerFeature.VoidCancelTicket)} onChange={() => { OnToogleBookingStatus(AgentPlusBookingManagerFeature.VoidCancelTicket) }} />} label="Void / cancel ticket" />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={Settings.allowedFeatures.includes(AgentPlusBookingManagerFeature.ManageServices)} onChange={() => { OnToogleBookingStatus(AgentPlusBookingManagerFeature.ManageServices) }} />} label="Manage services" />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={Settings.allowedFeatures.includes(AgentPlusBookingManagerFeature.ManageElements)} onChange={() => { OnToogleBookingStatus(AgentPlusBookingManagerFeature.ManageElements) }} />} label="Manage elements" />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5">Allowed global options</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel control={<Checkbox checked={Settings.allowedGlobalFeatures.includes(AgentPlusBookingManagerGlobalFeature.ImportBooking)} onChange={() => { OnToogleGlobalFeature(AgentPlusBookingManagerGlobalFeature.ImportBooking) }} />} label="Import booking" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel control={<Checkbox checked={Settings.allowedGlobalFeatures.includes(AgentPlusBookingManagerGlobalFeature.DownloadSalesReport)} onChange={() => { OnToogleGlobalFeature(AgentPlusBookingManagerGlobalFeature.DownloadSalesReport) }} />} label="Download sales report" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <CreateSettingsButton
                OnClick={CreateSettings}
                Readonly={props.ReadOnly}
              />
            )}
          </CardContent>
        )}
        {loading && (
          <CardContent>
            <LinearProgress />
          </CardContent>
        )}
        {!ReadOnly && Settings && (
          <SettingsActions
            OnDelete={DeleteSettings}
            OnReset={Reload}
            OnSave={OnUpdate}
          />
        )}
      </Card>
    </Box>
  );
};

export default BookingManagerVisibilitySettings;

export function WebAccessLabel(props: WebAccessEnum): string {
  let result = "";
  switch (props) {
    case WebAccessEnum.Web:
      result = "Web";
      break;
    case WebAccessEnum.Branchgroup:
      result = "Branchgroup";
      break;
    case WebAccessEnum.Branch:
      result = "Branch";
      break;
    case WebAccessEnum.User:
      result = "User";
      break;
  }
  return result;
}
interface BookingAdressBehaviorInputProps {
  Value: WebAccessEnum;
  OnChange: (newVal: WebAccessEnum) => void;
  Title: string
}
const WebAccessInput: React.FC<BookingAdressBehaviorInputProps> = props => {

  return (
    <FormControl fullWidth>
      <InputLabel>{props.Title}</InputLabel>
      <Select
        value={props.Value}
        onChange={e => {
          props.OnChange(e.target.value as WebAccessEnum);
        }}>
        <MenuItem value={WebAccessEnum.Web}>
          {WebAccessLabel(WebAccessEnum.Web)}
        </MenuItem>
        <MenuItem value={WebAccessEnum.Branchgroup}>
          {WebAccessLabel(WebAccessEnum.Branchgroup)}
        </MenuItem>
        <MenuItem value={WebAccessEnum.Branch}>
          {WebAccessLabel(WebAccessEnum.Branch)}
        </MenuItem>
        <MenuItem value={WebAccessEnum.User}>
          {WebAccessLabel(WebAccessEnum.User)}
        </MenuItem>
      </Select>
      <FormHelperText></FormHelperText>
    </FormControl>
  );
};
