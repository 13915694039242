import { DeployInfo } from "hooks/GetAppInfo";
import { TransactionIDInfo } from "types/SessionTypes";
import { createAction } from "typesafe-actions";
import { LoginResult } from "WebApiClient";

export const Session_Reducer_Reset = createAction(
  "@@session/REDUCER_RESET",
  () => { }
  // () => "",
)();

export const Session_Reducer_SetLoginResult = createAction(
  "@@session/REDUCER_SETTOKEN",
  (loginresult: LoginResult | undefined) => loginresult
)<LoginResult | undefined>();
export const Session_Reducer_SetNotificationOpen = createAction(
  "@@session/REDUCER_OPENNOTIFICATION",
  (open: boolean) => open
  // (token: string) => token,
)<boolean>();

export const Session_Reducer_LoginFailed = createAction(
  "@@session/REDUCER_LOGINFAILED",
  () => { }
  // () => "",
)();
export const Session_Reducer_SetDeployInfo = createAction("@@session/REDUCER_SETDEPLOYINFO", (info: DeployInfo) => info)<DeployInfo>();




export const Session_Reducer_SetLoginErrorMessages = createAction(
  "@@session/REDUCER_SETLOGINERRORMESSAGES",
  (msg: string[]) => msg
  // (msg: string[]) => "",
)();

export const Session_Reducer_PushTransactionID = createAction(
  "@@session/@@session/REDUCER_PUSHTRANSACTIONID",
  (id: TransactionIDInfo) => id
  // (msg: string[]) => "",
)();
