import { Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import ListItemLink from "./Navbar.ListItemLink";




export interface NavBarLinkProps {
    Icon: JSX.Element,
    Title: string,
    Path: string

}
export interface NavBarAreaProps {
    AreaIcon: JSX.Element,
    Title: string,
    RootPath: string
    Links: NavBarLinkProps[]
}

const useStyles = makeStyles(theme => ({

    activeArea: {
        background: "#5a7393",
        color: "white"
    },

}));
export const NavbarArea: React.FC<NavBarAreaProps> = ({ AreaIcon, Title, RootPath, Links }) => {
    const [isExpanded, setExpanded] = React.useState(false);
    let currLocation = useLocation();
    const classes = useStyles();


    const ToggleExpanded = () => {
        setExpanded(!isExpanded);
    };
    function IsActiveArea(area: string): boolean {
        if (currLocation.pathname.includes(area)) return true;
        return false;
    }
    return <Fragment>
        <ListItem
            button
            onClick={ToggleExpanded}
            className={clsx(IsActiveArea(RootPath) && classes.activeArea)}
        >
            <ListItemIcon>
                {AreaIcon}
            </ListItemIcon>
            <ListItemText primary={Title} />
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {Links.map((link, index) =>
                    <ListItemLink
                        key={index}
                        primary={link.Title}
                        to={`/${RootPath}/${link.Path}`}
                        icon={link.Icon}
                    />

                )}
            </List>
        </Collapse>
    </Fragment>

}