import { Button, Card, CardActions, CardContent, Dialog, IconButton, Tooltip } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { useState } from "react";
import { OperationState } from "types/Various";
import { ImportStatus } from "WebApiClient";

export const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}))(Tooltip);
export const HelpTextButton: React.FC<{ HelpText: JSX.Element }> = props => {
    const [modalOpen, setModalOpen] = useState(false);
    function handleClose() {
        setModalOpen(false);
    }
    return <React.Fragment>
        <Tooltip title={props.HelpText} arrow placement="top">
            <IconButton onClick={(e) => { e.stopPropagation(); setModalOpen(true) }} size="small">
                <HelpOutlineIcon />
            </IconButton>
        </Tooltip>
        <Dialog onClose={handleClose} open={modalOpen} scroll="body">
            <Card>
                {/* <CardHeader title={<span><HelpOutlineIcon /></span>}></CardHeader> */}
                <CardContent>
                    {/* {props.DetailedHelpText ? props.DetailedHelpText : props.HelpText} */}
                    {props.HelpText}
                </CardContent>
                <CardActions>
                    <Button variant="contained" color="primary" onClick={handleClose}>Ok</Button>
                </CardActions>
            </Card>
        </Dialog>
    </React.Fragment>
}

export const ImportStateRenderer: React.FC<{ status: ImportStatus }> = props => {
    function render(): string {
        switch (props.status) {
            case ImportStatus.Imported:
                return "Success";
            case ImportStatus.Failure:
                return "Failure";
            case ImportStatus.Pending:
                return "Pending";
            default:
                return "Unkown"
        }
    }
    return <span>
        {render()}
    </span>
}

export function ImportStateCellStyle(status: ImportStatus): React.CSSProperties {
    let color = "";
    switch (status) {
        case ImportStatus.Imported:
            color = "green";
            break;
        case ImportStatus.Failure:
            color = "red";
            break;
        case ImportStatus.Pending:
            color = "yellow";
            break;
        default:
            color = "";
    }
    const result: React.CSSProperties = { backgroundColor: color };
    return result;
}

export const OperationStateRenderer: React.FC<{ status: OperationState }> = props => {
    function render(): string {
        switch (props.status) {
            case OperationState.Success:
                return "Success";
            case OperationState.Failure:
                return "Failure";
            case OperationState.Pending:
                return "Pending";
            case OperationState.Progress:
                return "Progress";
            default:
                return "Unkown"
        }
    }
    
    return <span>
        {render()}
    </span>
}

export const BooleanIcon: React.FC<{ value: boolean | undefined }> = props => {

    return <span>
        {props.value ? <CheckIcon></CheckIcon> : <RemoveIcon></RemoveIcon>}
    </span>
}


