// import { useBranchClient } from "hooks/useHttpClient";
import { CircularProgress, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { useAdminFrontendServicesClient } from "hooks/useHttpClient";
import React, { useState } from "react";
import { BranchOption } from "WebApiClient";

interface IBranchesTableProps {
  OnSelect: (Branch: BranchOption | null) => void;
  BranchGroupID: string;
  Value: BranchOption | null;
  Selected: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.secondary.main
  },
  input: {
    color: "white"
  },
  endAdornment: {
    color: "white"
  },
  clearIndicatorDirty: {
    color: "white"
  },
  inputFocused: {
    color: "white"
  },
  selected: {
    background: theme.palette.primary.main,
    color: "white!important"
  }
}));

export const BranchSelect: React.FC<IBranchesTableProps> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [BrancheselectOpen, setBrancheselectOpen] = useState(false);
  const [errorOccured, setErrorOccured] = useState(true);
  const theme = useStyles();

  const [Branches, setBranches] = useState<BranchOption[]>([]);
  // const [selectedBranch, setSelectedBranch] = useState<BranchOption | null>(
  //   null
  // );
  const client = useAdminFrontendServicesClient();
  function GetBranchesAsync() {
    setIsLoading(true);
    client.getBranchOptionsByBranchgroupID(props.BranchGroupID)
      .then(response => {
        setBranches(response);
      })
      .catch(() => {
        setErrorOccured(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  function OnBrancheselect(Branch: BranchOption | null) {
    props.OnSelect(Branch);
  }
  return (
    <Autocomplete
      // style={{ width: 300 }}
      open={BrancheselectOpen}
      onOpen={() => {
        setBrancheselectOpen(true);
        GetBranchesAsync();
      }}
      onClose={() => {
        setBrancheselectOpen(false);
      }}
      getOptionSelected={(option: BranchOption, value: BranchOption) =>
        option.id! === value.id!
      }
      onChange={(event: any, value: BranchOption | null) =>
        OnBrancheselect(value)
      }
      value={props.Value}
      size="small"
      getOptionLabel={(option: BranchOption) => option.label!}
      options={Branches}
      loading={isLoading}
      renderInput={params => (
        <TextField
          {...params}
          label={`${props.Selected ? "" : "Branch"}`}
          fullWidth
          variant="outlined"
          autoFocus={false}
          className={props.Selected ? theme.selected : ""}
          InputProps={{
            ...params.InputProps,
            className: props.Selected
              ? clsx(
                theme.endAdornment,
                theme.clearIndicatorDirty,
                theme.selected
              )
              : "",
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
};

export default BranchSelect;
