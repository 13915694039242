import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SaveIcon from "@material-ui/icons/Save";
import { ErrorMessages } from "components/shared/ErrorMessages";
import { HelpTextButton } from "components/shared/various";
import React, { useState } from "react";
import { AdminEntityValidationResult, BaseUpdateCalcFormulaModel, RoundDirection, UpdateCalcCurrencyModel } from "WebApiClient";
import * as HelpTexts from "./formulas.HelpTexts";
interface ICalcFormulaEditProps {
  Data: BaseUpdateCalcFormulaModel;
  DataId: string | undefined;
  ValidationResult: AdminEntityValidationResult | undefined;
  ServerError: boolean;
  OnSave: (data: BaseUpdateCalcFormulaModel) => void;
  OnCancel: () => void;
  IsLoading: boolean;
  CurrencyOptions: UpdateCalcCurrencyModel[];
}
export const CalcFormulaInputComponent: React.FC<ICalcFormulaEditProps> = props => {
  const [data, setData] = useState({ ...props.Data });

  const titleError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["title"]
    : undefined;
  const formulaError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["formula"]
    : undefined;
  const formulaChildError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["formulachild"]
    : undefined;
  const formulaInfantError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["formulainfant"]
    : undefined;

  return (
    <Card>
      <CardHeader
        title={`${props.DataId !== undefined ? "Edit" : "Create"} formula`}
      />
      <CardContent>
        <Grid container spacing={2}>
          {props.ServerError && <ErrorMessages ServerError={true} />}
          {props.ValidationResult && props.ValidationResult.errorSummary && (
            <ErrorMessages Messages={props.ValidationResult.errorSummary} />
          )}

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <TextField
                  error={titleError !== undefined}
                  required
                  disabled={props.IsLoading}
                  label="Title"
                  value={data.title}
                  helperText={titleError}
                  onChange={e => setData({ ...data, title: e.target.value })}
                  InputProps={{
                    endAdornment: <HelpTextButton HelpText={HelpTexts.CalcFormulasTitleHelpText} />
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Currency <HelpTextButton HelpText={HelpTexts.CalcFormulasCurrencyHelpText} /></InputLabel>
                  <Select
                    value={data.currencyID}
                    onChange={e =>
                      setData({ ...data, currencyID: e.target.value as string })
                    }
                  >
                    {props.CurrencyOptions.map((currency, index) => (
                      <MenuItem value={currency.id} key={index}>
                        {currency.currencyCode}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box m={2}>
                <Divider></Divider>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.taxAffectsServiceFee}
                      onChange={() =>
                        setData({
                          ...data,
                          taxAffectsServiceFee: !data.taxAffectsServiceFee
                        })
                      }
                    />
                  }

                  label={<span>Tax affects service fee <HelpTextButton HelpText={HelpTexts.CalcFormulasTaxAffectsServiceFeeHelpText} /></span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.qSurchargeAffectsServiceFee}
                      onChange={() =>
                        setData({
                          ...data,
                          qSurchargeAffectsServiceFee: !data.qSurchargeAffectsServiceFee
                        })
                      }
                    />
                  }

                  label={<span>Q-Surcharge affects service fee <HelpTextButton HelpText={HelpTexts.CalcFormulasQsurchargeAffectsServiceFeeHelpText} /></span>}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box m={2}>
              <Divider></Divider>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={formulaError !== undefined}
              helperText={formulaError}
              label="Formula"
              multiline
              fullWidth
              required
              value={data.functionDefinition}
              onChange={e =>
                setData({
                  ...data,
                  functionDefinition: e.target.value
                })
              }
              variant="outlined"
              InputProps={{
                endAdornment: <HelpTextButton HelpText={HelpTexts.CalcFormulasFormulaDefinitionHelpText} />
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Formula (Child)"
              error={formulaChildError !== undefined}
              helperText={formulaChildError}
              multiline
              fullWidth
              value={data.functionDefinitionChild}
              onChange={e =>
                setData({
                  ...data,
                  functionDefinitionChild: e.target.value
                })
              }
              variant="outlined"
              InputProps={{
                endAdornment: <HelpTextButton HelpText={HelpTexts.CalcFormulasFormulaDefinitionHelpText} />
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Formula (Infant)"
              error={formulaInfantError !== undefined}
              helperText={formulaInfantError}
              multiline
              fullWidth
              value={data.functionDefinitionInfant}
              onChange={e =>
                setData({
                  ...data,
                  functionDefinitionInfant: e.target.value
                })
              }
              variant="outlined"
              InputProps={{
                endAdornment: <HelpTextButton HelpText={HelpTexts.CalcFormulasFormulaDefinitionHelpText} />
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Round direction <HelpTextButton HelpText={HelpTexts.CalcFormulasRoundingHelpText} /></InputLabel>
              <Select
                value={data.functionRoundDirection}
                onChange={e =>
                  setData({ ...data, functionRoundDirection: e.target.value as RoundDirection })
                }
              >

                <MenuItem value={RoundDirection.Up}>
                  Up
                      </MenuItem>
                <MenuItem value={RoundDirection.UpDown}>
                  Up/Down
                      </MenuItem>
                <MenuItem value={RoundDirection.Down}>
                  Down
                      </MenuItem>

              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel>Round to decimals <HelpTextButton HelpText={HelpTexts.CalcFormulasRoundingHelpText} /></InputLabel>
              <Select
                value={data.functionRoundDecimals}
                onChange={e =>
                  setData({ ...data, functionRoundDecimals: e.target.value as number })
                }
              >

                <MenuItem value={0}>
                  0
                      </MenuItem>
                <MenuItem value={1}>
                  1
                      </MenuItem>
                <MenuItem value={2}>
                  2
                      </MenuItem>

              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.showCalculationResultInDisplay}
                  onChange={() =>
                    setData({
                      ...data,
                      showCalculationResultInDisplay: !data.showCalculationResultInDisplay
                    })
                  }
                />
              }

              label={<span>Show Calculation result in display <HelpTextButton HelpText={HelpTexts.CalcFormulasDisplayCheckBoxesHelpText} /></span>}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            props.OnSave(data);
          }}
          disabled={props.IsLoading}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => { }}
          disabled={props.IsLoading}
          startIcon={<RotateLeftIcon />}
        >
          Reset
        </Button>
      </CardActions>
    </Card>
  );
};

export function GetRoundDirectionLabel(rounddirection: RoundDirection) {
  let result: string = "";
  switch (rounddirection) {
    case RoundDirection.Down:
      result = "Down";
      break;
    case RoundDirection.Up:
      result = "Up";
      break;
    case RoundDirection.UpDown:
      result = "Up/Down";
      break;
  }
  return result;
}
