import { SettingsPage } from "components/shared/SettingPagesVarious";
import React from "react";
import CalculationDisplaySettings from "./displaySettings.calculation.Settings";
interface ICalculationDisplaySettingsPageProps { }

const CalculationDisplaySettingsPage: React.FC<ICalculationDisplaySettingsPageProps> = props => {
  return <SettingsPage Component={CalculationDisplaySettings} Label="Calculation display" />;
};

export default CalculationDisplaySettingsPage;
