import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Session_Reducer_SetNotificationOpen } from "actions/SessionActions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { TransactionIDInfo } from "types/SessionTypes";

interface ActionFeedBackProps {}
export const ActionFeedBack: React.FC<ActionFeedBackProps> = props => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: State) => state.Session.NotificationOpen);
  const transaction: TransactionIDInfo[] = useSelector(
    (state: State) => state.Session.TransactionIDs
  );
  const LastTransaction: TransactionIDInfo | undefined =
    transaction.length > 0 ? transaction[0] : undefined;

  function HandleClose() {
    dispatch(Session_Reducer_SetNotificationOpen(false));
  }

  return (
    <Snackbar
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={HandleClose}
    >
      {LastTransaction && (
        <Alert
          onClose={HandleClose}
          severity={LastTransaction.Success ? "success" : "error"}
        >
          {LastTransaction.Message}
        </Alert>
      )}
    </Snackbar>
  );
};
