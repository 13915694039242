import { push } from "connected-react-router";
import { AgentApiURL } from "Constants";
import { call, put, putResolve, takeEvery } from "redux-saga/effects";
import { ApiCredentialsOptions, ApiException, LoginClient, LoginResult } from "WebApiClient";
import { Session_Reducer_SetLoginResult } from "../actions/SessionActions";
import { Session_Saga_TryLogin } from "../actions/SessionSagactions";

function* handleTryLogin(action: ReturnType<typeof Session_Saga_TryLogin>) {
  const loginClient: LoginClient = new LoginClient(
    new ApiCredentialsOptions(AgentApiURL, "")
  );
  let loginResult: LoginResult | undefined = undefined;
  try {
    loginResult = yield call(
      [loginClient, loginClient.loginByCredentials],
      action.payload
    );

  } catch (e) {
    let exception: ApiException = e as ApiException;
    console.log(`${exception.status} - ${exception.message}`);
  } finally {
    yield putResolve(Session_Reducer_SetLoginResult(loginResult));
    if (loginResult && loginResult.success && loginResult.token) {
      yield put(push("/home"));
    }
  }

}

export function* watch_tryLogin() {
  yield takeEvery(Session_Saga_TryLogin, handleTryLogin);
}
