import { Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import * as React from "react";

export interface IMasterDataGettingsStartedProps { }

export default function MasterDataGettingsStarted(
  props: IMasterDataGettingsStartedProps
) {
  return <Grid container>
    <Grid item xs={12}>
      <Card>
        <CardHeader title={"Corporate Identity"} />
        <CardContent>
          <Typography variant="body1">
            In this section you can adjust AgentPlus to the <b>appearance</b> of your company.  <br></br>
        At the moment you can set a custom Waitscreen and Logo, more options are planned for future releases.
      </Typography>
        </CardContent>
      </Card>
    </Grid>

  </Grid>;
}
