import React from "react"

export const BranchgroupsIsActiveHelpText = <div>
    <p>
        <b>Active</b>
    </p>
    <ul>
        <li>
            Activates or deactivates the branchgroup.
        </li>
    </ul>
</div>