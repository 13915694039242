import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";
import SalesPrefActionsPage from "./actions/actions.Page";
import SalesPrefRulesPage from "./rules/rules.page";
import GettingStarted from "./salespreference.GettingStarted";


interface ICalculationProps { }
const Calculation: React.FC<ICalculationProps> = props => {
  let { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/gettingstarted`} />
      </Route>
      <Route exact path={`${path}/gettingstarted`} component={GettingStarted} />
      <Route exact path={`${path}/rules`} component={SalesPrefRulesPage} />
      <Route exact path={`${path}/actions`} component={SalesPrefActionsPage} />
    </Switch>
  );
};

export default Calculation;
