import { Button, Grid } from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import PersonIcon from '@material-ui/icons/Person';
import React, { useEffect } from "react";
import { CustomerProfilePassengerGroup, CustomerProfilePassengers } from "WebApiClient";
import { defaultAdultObject, defaultPassenger } from "./customerprofiles.Details";
import { CustomerProfilesPassengersGroupComponent } from "./CustomerProfilesPassengersGroupComponent";


export const CustomerProfilesPassengersComponent: React.FC<{globalValidationTrigger?:any,setglobalValidationTrigger?:any,errorTrigger?:any, data: CustomerProfilePassengers; OnUpdate: (data: CustomerProfilePassengers | undefined) => void; setbuttonDB?:any}> = props => {
    const { adults, children, infants } = props.data;
    function OnAddAdults() {
        props.OnUpdate({ ...props.data, adults: { ...defaultAdultObject } });
    }
    function OnAddChildren() {
        props.OnUpdate({ ...props.data, children: { passengerType: "CHD", passengers: [{ ...defaultPassenger }] } });
    }

    function OnAddInfants() {
        props.OnUpdate({ ...props.data, infants: { passengerType: "INF", passengers: [{ ...defaultPassenger }] } });
    }

    function OnUpdateAdults(data: CustomerProfilePassengerGroup | undefined) {
        if (data?.passengers.length === 0) {
            data = undefined;
        }
        props.OnUpdate({ ...props.data, adults: data });
    }
    function OnUpdateChildren(data: CustomerProfilePassengerGroup | undefined) {
        if (data?.passengers.length === 0) {
            data = undefined;
        }
        props.OnUpdate({ ...props.data, children: data });
    }

    function OnUpdateInfants(data: CustomerProfilePassengerGroup | undefined) {
        if (data?.passengers.length === 0) {
            data = undefined;
        }
        props.OnUpdate({ ...props.data, infants: data });
    }
    useEffect(() => {
        if (props.data) {
            if (props.data.adults?.passengers.length === undefined && props.data.children?.passengers.length === undefined && props.data.infants?.passengers.length === undefined) {
                props.OnUpdate(undefined);
            }
        }
    }, [props.data])
    
    return <Grid item container xs={12}>
        <Grid item container xs={12} spacing={3}>
            <Grid item xs={12}>
                {adults ? <CustomerProfilesPassengersGroupComponent globalValidationTrigger={props.globalValidationTrigger!} setglobalValidationTrigger={props.setglobalValidationTrigger!} errorTrigger={props.errorTrigger!} Fulldata={props.data!} setbuttonDB={props.setbuttonDB} icon={<PersonIcon />} plural="Adults" singular="Adult" data={adults} OnUpdate={OnUpdateAdults} /> : <Button startIcon={<AddCircleOutlineIcon />} variant="contained" color="primary" onClick={OnAddAdults}>Add Adults</Button>}
            </Grid>
            <Grid item xs={12}>
                {children ? <CustomerProfilesPassengersGroupComponent globalValidationTrigger={props.globalValidationTrigger!} setglobalValidationTrigger={props.setglobalValidationTrigger!} errorTrigger={props.errorTrigger!} Fulldata={props.data!} setbuttonDB={props.setbuttonDB} icon={<ChildCareIcon />} plural="Children" singular="Child" data={children} OnUpdate={OnUpdateChildren} /> : <Button startIcon={<AddCircleOutlineIcon />} variant="contained" color="primary" onClick={OnAddChildren}>Add Children</Button>}
            </Grid>
            <Grid item xs={12}>
                {infants ? <CustomerProfilesPassengersGroupComponent globalValidationTrigger={props.globalValidationTrigger!} setglobalValidationTrigger={props.setglobalValidationTrigger!} errorTrigger={props.errorTrigger!} Fulldata={props.data!} setbuttonDB={props.setbuttonDB} icon={<ChildFriendlyIcon />} plural="Infants" singular="Infant" data={infants} OnUpdate={OnUpdateInfants} /> : <Button startIcon={<AddCircleOutlineIcon />} variant="contained" color="primary" onClick={OnAddInfants}>Add Infants</Button>}
            </Grid>

        </Grid>
    </Grid>;
};
