import React from "react";
export const FareFilterSettingsOpenFilterByDefaultHelpText = <div>
    <p>
        <b>Open filter by default</b>
    </p>

    <ul>
        <li>
            Controls if the filter is open by default on the Available fares page.
        </li>


    </ul>

</div>
