import React from "react"

export const BranchIsActiveHelpText = <div>
    <p>
        <b>Active</b>
    </p>
    <ul>
        <li>
            Activates or deactivates the branch.
        </li>
    </ul>
</div>