
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "Styles/fonts.css";
import "Styles/index.css"
import "react-widgets/styles.css";
import App from "./App";
import configureStore, { history } from "./configureStore";


const store = configureStore();
const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App history={history} />
    </Provider>,
    document.getElementById("root")
  );
};

render();


