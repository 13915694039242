import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";
import GettingStarted from "./export.GettingStarted";
import ExportDeeplinksPage from "./users/export.deeplinks.Page";

interface IMasterDataProps { }
const Masterdata: React.FC<IMasterDataProps> = props => {
  let { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/gettingstarted`} />
      </Route>
      <Route exact path={`${path}/gettingstarted`} component={GettingStarted} />
      <Route exact path={`${path}/deeplinks`} component={ExportDeeplinksPage} />

    </Switch>
  );
};

export default Masterdata;
