import { Box, Card, CardContent, CardHeader, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, LinearProgress, TextField, Typography } from "@material-ui/core";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { SettingsActions } from "components/shared/CardActions";
import { ErrorMessages } from "components/shared/ErrorMessages";
import { ApplyIndicator, CreateSettingsButton, ISettingsComponentProps, OverriddenIndicator } from "components/shared/SettingPagesVarious";
import { WebLevelLabel } from "components/shared/WebLevelLabel";
import { useCalculationDisplaySettingsClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, CreateSettingsRequest, SettingsOrder, UpdateCalculationDisplaySettingsModel } from "WebApiClient";

const CalculationDisplaySettings: React.FC<ISettingsComponentProps> = props => {
  const { ReadOnly, WebLevel, Id, LevelName } = props;
  const client = useCalculationDisplaySettingsClient();
  const dispatch = useDispatch();
  const [Settings, setSettings] = useState<
    UpdateCalculationDisplaySettingsModel | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [validationState, setValidationState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);
  const [serverError, setServerError] = useState(false);
  useEffect(() => {
    GetSettings(WebLevel, Id);
  }, [WebLevel, Id]);

  function OnUpdate() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .update(Settings)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully updated.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be updated.";
          }
          if (response.serverError) {
            serverSideError = true;
          } else {
            setValidationState(response.validationResult);
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be updated. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Update Multichannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function Reload() {
    GetSettings(WebLevel, Id);
  }

  function CreateSettings() {
    if (Id) {
      const createRequest: CreateSettingsRequest = {
        id: Id,
        sortOrder: WebLevel
      };
      setLoading(true);
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      client
        .create(createRequest)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully created.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be created.";
          }
          if (response.serverError) {
            serverSideError = true;
          }
        })
        .catch(e => {
          serverSideError = true;
          console.error(e);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be created. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Create Mulitchannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setLoading(false);
          if (transactionSuccess) {
            Reload();
          }
        });
    }
  }

  function GetSettings(order: SettingsOrder, Id: string | null) {
    setLoading(true);

    client
      .get(order, Id)
      .then(settings => {
        setSettings(settings);
        if (settings !== null) {
          props.OnNotifyIfSettingsExist(true);
        } else {
          props.OnNotifyIfSettingsExist(false);
        }
      })
      .catch(e => {
        console.error(e);
        setServerError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function DeleteSettings() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .delete(Settings?.id!)
        .then(response => {
          if (response.success) {
            transactionMsg = "successfully deleted.";
            transactionSuccess = true;
          } else {
            transactionMsg = `could not be deleted. ${response.errorMessage}`;
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg = "could not be deleted. A server error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Delete Multichannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  const grossDisplayNameError: string | undefined = validationState
    ? validationState.properyErrors!["grossdisplayname"]
    : undefined;
  const netDisplayNameError: string | undefined = validationState
    ? validationState.properyErrors!["netdisplayname"]
    : undefined;
  const netnetDisplayNameError: string | undefined = validationState
    ? validationState.properyErrors!["netnetdisplayname"]
    : undefined;
  const qsurchargeDisplayNameError: string | undefined = validationState
    ? validationState.properyErrors!["qsurchargedisplayname"]
    : undefined;

  return (
    <Box boxShadow={ReadOnly ? undefined : 3}>
      <Card style={{ opacity: ReadOnly ? 0.5 : 1 }}>
        <CardHeader
          title={
            <span>
              {props.Applies && (
                <span>
                  <ApplyIndicator />
                </span>
              )}
              {props.IsOverwritten && (
                <span>
                  <OverriddenIndicator />
                </span>
              )}
              <span>Calculation display settings for </span>
              <WebLevelLabel Level={WebLevel} Name={LevelName} />{" "}
              {/* {props.IsOverwritten ? "Overwritten" : ""} */}
            </span>
          }
        />
        {!loading && (
          <CardContent>
            {Settings ? (
              <Grid container spacing={2}>
                {serverError && <ErrorMessages ServerError={true} />}
                {validationState && validationState.errorSummary && (
                  <ErrorMessages Messages={validationState.errorSummary} />
                )}
                <Grid item xs={12}>
                  <Box>
                    <Typography variant="h5">Column Visibility</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Settings.showNetColumn}
                            onChange={() => {
                              setSettings({
                                ...Settings,
                                showNetColumn: !Settings.showNetColumn
                              });
                            }}
                            readOnly={props.ReadOnly}
                          />
                        }
                        label="Net"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Settings.showNetNetColumn}
                            onChange={() => {
                              setSettings({
                                ...Settings,
                                showNetNetColumn: !Settings.showNetNetColumn
                              });
                            }}
                            readOnly={props.ReadOnly}
                          />
                        }
                        label="NetNet"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box m={2}>
                    <Divider></Divider>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Typography variant="h5">Custom display names</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={grossDisplayNameError !== undefined}
                        InputProps={{ readOnly: props.ReadOnly }}
                        label="Gross"
                        value={Settings.grossDisplayName ? Settings.grossDisplayName : ""}
                        helperText={grossDisplayNameError}
                        onChange={e =>
                          setSettings({
                            ...Settings,
                            grossDisplayName: e.target.value
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={netDisplayNameError !== undefined}
                        InputProps={{ readOnly: props.ReadOnly }}
                        label="Net"
                        value={Settings.netDisplayName ? Settings.netDisplayName : ""}
                        helperText={netDisplayNameError}
                        onChange={e =>
                          setSettings({
                            ...Settings,
                            netDisplayName: e.target.value
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={netnetDisplayNameError !== undefined}
                        InputProps={{ readOnly: props.ReadOnly }}
                        label="NetNet"
                        value={Settings.netNetDisplayName ? Settings.netNetDisplayName : ""}
                        helperText={netnetDisplayNameError}
                        onChange={e =>
                          setSettings({
                            ...Settings,
                            netNetDisplayName: e.target.value
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={qsurchargeDisplayNameError !== undefined}
                        InputProps={{ readOnly: props.ReadOnly }}
                        label="Q-Surcharge"
                        value={Settings.qSurchargeDisplayName ? Settings.netNetDisplayName : ""}
                        helperText={qsurchargeDisplayNameError}
                        onChange={e =>
                          setSettings({
                            ...Settings,
                            qSurchargeDisplayName: e.target.value
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box m={2}>
                    <Divider></Divider>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Typography variant="h5">Tooltip visibility</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Settings.showAgencyCalcTooltip}
                            onChange={() => {
                              setSettings({
                                ...Settings,
                                showAgencyCalcTooltip: !Settings.showAgencyCalcTooltip
                              });
                            }}
                            readOnly={props.ReadOnly}
                          />
                        }
                        label="Show Agency tooltip"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Settings.showConsolidatorCalcTooltip}
                            onChange={() => {
                              setSettings({
                                ...Settings,
                                showConsolidatorCalcTooltip: !Settings.showConsolidatorCalcTooltip
                              });
                            }}
                            readOnly={props.ReadOnly}
                          />
                        }
                        label="Show Consolidator tooltip"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box m={2}>
                    <Divider></Divider>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Typography variant="h5">Debugging</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Settings.enableCalculationDebugger}
                            onChange={() => {
                              setSettings({
                                ...Settings,
                                enableCalculationDebugger: !Settings.enableCalculationDebugger
                              });
                            }}
                            readOnly={props.ReadOnly}
                          />
                        }
                        label="Enable debugger"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <CreateSettingsButton
                OnClick={CreateSettings}
                Readonly={props.ReadOnly}
              />
            )}
          </CardContent>
        )}
        {loading && (
          <CardContent>
            <LinearProgress />
          </CardContent>
        )}
        {!ReadOnly && Settings && (
          <SettingsActions
            OnDelete={DeleteSettings}
            OnReset={Reload}
            OnSave={OnUpdate}
          />
        )}
      </Card>
    </Box>
  );
};

export default CalculationDisplaySettings;
