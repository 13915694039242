import { makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RefreshIcon from "@material-ui/icons/Refresh";
import { TableIcons } from "components/shared/tableIcons";
import MaterialTable from "material-table";
import React, { useEffect } from "react";
import { UpdateUserModel } from "WebApiClient";
//import { useUserClient } from "hooks/useHttpClient";
import { TransactionIDInfo } from "types/SessionTypes";
interface IUseresTableProps {
  BranchID: string;
  BranchName: string;
  LastEdit: string;
  OnRefresh: () => void;
  OnDelete: (data: UpdateUserModel[]) => void;
  OnDeleteUser:(data:any) => void;
  OnEdit: (data: UpdateUserModel | undefined) => void;
  ToggleImport: () => void;
  IsLoading: boolean;
  Data: UpdateUserModel[];
  myRef?:any;
}
// export const UsersTableColumns: Array<Column<UpdateUserModel>> = [
//   { title: "Username", field: "username" },
//   { title: "Password", field: "password" },
//   { title: "Title", field: "title" },
//   { title: "Firstname", field: "firstname" },
//   { title: "Lastname", field: "lastname" },
//   { title: "Active", field: "isActive", type: "boolean" },
//   { title: "Admin", field: "isAdmin", type: "boolean" },
//   { title: "Telephone", field: "telephone" },
//   { title: "Fax", field: "fax" },
//   { title: "Email", field: "email" },
//   { title: "Mobile", field: "mobilePhone" },

// ];
const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  }
}));

//const userClient = useUserClient();

export const UsersTable: React.FC<IUseresTableProps> = props => {

  const classes = useStyles();
  const { BranchID, BranchName } = props;
  // const columns = [...UsersTableColumns];
  function GetRowStyle(
    data: any,
    index: number,
    level: number
  ): React.CSSProperties {
    const bg = data as UpdateUserModel;
    let result: React.CSSProperties = {
      backgroundColor: bg.id === props.LastEdit ? "#a8c8dd" : undefined
    };
    return result;
  }

//   function DeleteUserAsync(id: string): Promise<TransactionIDInfo> {
//     return new Promise<TransactionIDInfo>((resolve, reject) => {
//         let transactionSuccess = false;
//         let transactionTime = new Date().toString();
//         let transactionId = "";
//         let transactionMsg = "MSG NOT SET";
//         let serverSideError = false;
//         userClient
//             .delete(id)
//             .then(e => {
//                 transactionId = e.transactionId;
//                 if (e.success) {
//                     transactionSuccess = true;
//                     transactionMsg = "User deleted.";
//                    // setIsOpen((isOpen)=> !isOpen);
//                     // props.OnDeleteComplete();
//                 } else {
//                     transactionMsg = `User could not be deleted. ${e.errorMessage}`;
//                 }
//             })
//             .catch(e => {
//                 console.error(e);
//                 serverSideError = true;
//             })
//             .finally(() => {
//                 if (serverSideError) {
//                     transactionMsg = `User could not be deleted. An server-side error has occured.`;
//                 }
//                 const transaction: TransactionIDInfo = {
//                     Label: `Delete User`,
//                     Success: transactionSuccess,
//                     Time: transactionTime,
//                     TransactionID: transactionId,
//                     Message: transactionMsg
//                 };
//                 resolve(transaction);
//                 // dispatch(Session_Reducer_PushTransactionID(transaction));
//                // setIsOpen((isOpen)=> !isOpen);
//                // props.OnDeleteComplete();
//             });
//     })
// }



  function DeleteRow(data: any): Promise<void> {
    console.log("deleterow calling++", data)
    props.OnDeleteUser(data)
    return new Promise(resolve => {
     // props.OnDelete(data.id!);
    // call delete api here 
      resolve();
    });
  }

  const handleButtonClick = () =>{
    //console.log("++--", props?.myRef?.current);
    
    props?.myRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  
  }


  return (
    <MaterialTable
      icons={TableIcons}
      title={`Users in ${BranchName}`}
      columns={[
        { title: "Username", field: "username" },
        { title: "Password", field: "password" },
        { title: "Title", field: "title" },
        { title: "Firstname", field: "firstname" },
        { title: "Lastname", field: "lastname" },
        { title: "Active", field: "isActive", type: "boolean" },
        { title: "Admin", field: "isAdmin", type: "boolean" },
        { title: "Telephone", field: "telephone" },
        { title: "Fax", field: "fax" },
        { title: "Email", field: "email" },
        { title: "Mobile", field: "mobilePhone" },
      ]}
      data={props.Data}
      isLoading={props.IsLoading}
      options={{ paging: false, selection: true }}
      editable={{
        isDeletable: oldData => true,
        onRowDelete: DeleteRow
      }}
      actions={[
        {
          icon: () => <RefreshIcon />,
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () => props.OnRefresh()
        },
        {
          icon: () => <EditIcon />,
          tooltip: "Edit",
          position: "row",
          onClick: (event, row) => {
            const data = row as UpdateUserModel;
            props.OnEdit(data);
            handleButtonClick();
            
          }
        },
        {
          icon: () => <DeleteOutline />,
          tooltip: "Delete",
          position: "toolbarOnSelect",
          onClick: (event, row) => {
            if (row instanceof Array) {
              props.OnDelete(row);
            }
            else {

            }

          }
        },
        // {
        //   icon: () => <DeleteOutline />,
        //   tooltip: "Delete",
        //   position: "row",
        //   onClick: (event, row) => {
        //     if (!(row instanceof Array)) {
        //       props.OnDelete([row]);
        //     }


        //   }
        // },
        {
          icon: () => <FileCopyIcon />,
          tooltip: "Duplicate",
          position: "row",
          onClick: (event, row) => {
            const data = row as UpdateUserModel;
            props.OnEdit({ ...data, id: undefined });
          }
        },
        {
          icon: () => <AddIcon />,
          tooltip: "Add",
          isFreeAction: true,
          position: "toolbar",
          onClick: () => {
            props.OnEdit(undefined);
          }
        },
        // {
        //   icon: () => <PublishIcon />,
        //   tooltip: "Import",
        //   isFreeAction: true,
        //   position: "toolbar",
        //   onClick: props.ToggleImport

        // },


      ]}

    />

  );
};

export default UsersTable;


