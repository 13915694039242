import { Button, Card, CardActions, CardContent, CardHeader, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SaveIcon from "@material-ui/icons/Save";
import { ErrorMessages } from "components/shared/ErrorMessages";
import { HelpTextButton } from "components/shared/various";
import React, { useState } from "react";
import { AdminEntityValidationResult, BaseUpdateCurrencyModel } from "WebApiClient";
import * as HelpTexts from "./currencies.HelpTexts";
interface ICalcCurrencyEditProps {
  Data: BaseUpdateCurrencyModel;
  DataId: string | undefined;
  ValidationResult: AdminEntityValidationResult | undefined;
  ServerError: boolean;
  OnSave: (data: BaseUpdateCurrencyModel) => void;
  OnCancel: () => void;
  IsLoading: boolean;
}
export const CalcCurrencyInputComponent: React.FC<ICalcCurrencyEditProps> = props => {
  const [data, setData] = useState({ ...props.Data });
  const codeError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["code"]
    : undefined;
  const nameError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["name"]
    : undefined;

  return (
    <Card>
      <CardHeader
        title={`${props.DataId !== undefined ? "Edit" : "Create"} currency`}
      />
      <CardContent>
        <Grid container spacing={2}>
          {props.ServerError && <ErrorMessages ServerError={true} />}
          {props.ValidationResult && props.ValidationResult.errorSummary && (
            <ErrorMessages Messages={props.ValidationResult.errorSummary} />
          )}
          <Grid item xs={12} md={4}>
            <TextField
              error={codeError !== undefined}
              required
              disabled={props.IsLoading}
              label="Currency code"
              value={data.currencyCode}
              helperText={codeError}
              onChange={e => setData({ ...data, currencyCode: e.target.value })}
              InputProps={{
                endAdornment: <HelpTextButton HelpText={HelpTexts.CalcCurrenciesCodeHelptext} />
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              error={nameError !== undefined}
              required
              disabled={props.IsLoading}
              label="Name"
              value={data.name}
              helperText={nameError}
              onChange={e => setData({ ...data, name: e.target.value })}
              InputProps={{
                endAdornment: <HelpTextButton HelpText={HelpTexts.CalcCurrenciesNameHelptext} />
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Decimal places <HelpTextButton HelpText={HelpTexts.CalcCurrenciesDecimalPlacesHelptext} /></FormLabel>
              <RadioGroup
                row
                value={data.displayDecimals}
                onChange={e =>
                  setData({
                    ...data,
                    displayDecimals: parseInt(e.target.value as any)
                  })
                }

              >
                <FormControlLabel value={0} control={<Radio />} label="0" />
                <FormControlLabel value={1} control={<Radio />} label="1" />
                <FormControlLabel value={2} control={<Radio />} label="2" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            props.OnSave(data);
          }}
          disabled={props.IsLoading}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => { }}
          disabled={props.IsLoading}
          startIcon={<RotateLeftIcon />}
        >
          Reset
        </Button>
      </CardActions>
    </Card>
  );
};
