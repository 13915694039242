import { Box, Grid, Paper } from "@material-ui/core";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import WebLevelSelect from "components/general/WebLevelSelect";
import { useCalcCurrenciesClient } from "hooks/useHttpClient";
import { useCreateSettingsRequest } from "hooks/useStateSelection";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, BaseUpdateCurrencyModel, CreateCalcCurrencyModel, CreateSettingsRequest, UpdateCalcCurrencyModel } from "WebApiClient";
import { CalcCurrencyInputComponent } from "./currencies.Edit";
import CurrenciesTable from "./currencies.Table";

export interface ICalcCurrenciesPageProps { }
const ActionTitle = "Calculation Currency";
export default function CalcCurrenciesPage(props: ICalcCurrenciesPageProps) {
  const stateSelection = useSelector((state: State) => state.Filter.Selection);
  const selectedLevel = useCreateSettingsRequest();
  const client = useCalcCurrenciesClient();
  const dispatch = useDispatch();

  const [data, setData] = useState<UpdateCalcCurrencyModel[]>([]);
  const [editData, setEditData] = useState<BaseUpdateCurrencyModel | undefined>(
    undefined
  );
  const [editId, setEditId] = useState<string | undefined>(undefined);
  const [editErrorState, setEditErrorState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);
  const [editServerError, setEditServerError] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  function Refresh() {
    GetData(selectedLevel);
  }
  useEffect(() => {
    GetData(selectedLevel);
  }, [stateSelection]);

  function GetData(selection: CreateSettingsRequest) {
    setLoading(true);
    client
      .get(selection.sortOrder, selection.id)
      .then(result => {
        setData(result);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function OnSetEditData(data: UpdateCalcCurrencyModel | undefined) {
    if (data) {
      setEditData(data);
      setEditId(data.id);
    } else {
      const newData: BaseUpdateCurrencyModel = {
        currencyCode: "",
        displayDecimals: 0,
        name: ""
      };
      setEditData(newData);
      setEditId(undefined);
    }
  }

  function OnDelete(id: string) {
    let transactionSuccess = false;
    let transactionTime = new Date().toString();
    let transactionId = "";
    let transactionMsg = "MSG NOT SET";
    let serverSideError = false;
    client
      .delete(id)
      .then(e => {
        transactionId = e.transactionId;
        if (e.success) {
          transactionSuccess = true;
          transactionMsg = `${ActionTitle} deleted.`;
        } else {
          transactionMsg = `${ActionTitle} could not be deleted. ${e.errorMessage}`;
        }
      })
      .catch(e => {
        console.error(e);
        serverSideError = true;
      })
      .finally(() => {
        if (serverSideError) {
          transactionMsg = `${ActionTitle} could not be deleted. An server-side error has occured.`;
        }
        let transaction: TransactionIDInfo = {
          Label: `Delete ${ActionTitle}`,
          Success: transactionSuccess,
          Time: transactionTime,
          TransactionID: transactionId,
          Message: transactionMsg
        };
        dispatch(Session_Reducer_PushTransactionID(transaction));
        Refresh();
      });
  }

  function OnSubmitEdit(data: BaseUpdateCurrencyModel) {
    const transactionTime = new Date().toString();
    let transactionSuccess = false;
    let transactionId = "";
    let transactionMsg = "MSG NOT SET";
    let serverSideError = false;
    setEditLoading(true);
    if (editId) {
      const request: UpdateCalcCurrencyModel = { ...data, id: editId };
      client
        .update(request)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionSuccess = true;
            transactionMsg = `${ActionTitle} succuessfully updated.`;
            OnCancelEdit();
            Refresh();
          } else {
            if (response.validationResult) {
              transactionMsg = `${ActionTitle} could not be updated: Invalid fields`;
              setEditErrorState(response.validationResult);
            }
            if (response.serverError) {
              serverSideError = true;
            }
          }
        })
        .catch(e => {
          console.error(e);
          serverSideError = true;
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              `${ActionTitle} could not be updated: A serverside error has occured.`;
            setEditServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Update ${ActionTitle}`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: transactionMsg
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setEditLoading(false);
        });
    } else {
      const request: CreateCalcCurrencyModel = {
        ...data,
        createSettingsRequest: selectedLevel
      };
      client
        .create(request)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionSuccess = true;
            transactionMsg = `${ActionTitle} successfully created.`;
            OnCancelEdit();
            Refresh();
          } else {
            if (response.validationResult) {
              transactionMsg = `${ActionTitle} could not be created: Invalid fields`;
              setEditErrorState(response.validationResult);
            }
            if (response.serverError) {
              serverSideError = true;
            }
          }
        })
        .catch(e => {
          console.error(e);
          serverSideError = true;
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              `${ActionTitle} could not be created: A serverside error has occured.`;
            setEditServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Create ${ActionTitle}`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: transactionMsg
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setEditLoading(false);
        });
    }
  }

  function OnCancelEdit() {
    setEditData(undefined);
    setEditId(undefined);
    setEditServerError(false);
    setEditErrorState(undefined);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WebLevelSelect />
      </Grid>
      {editData && (
        <Grid item xs={12}>
          <Box boxShadow={3}>
            <Paper>
              <CalcCurrencyInputComponent
                Data={editData}
                DataId={editId}
                OnSave={OnSubmitEdit}
                OnCancel={OnCancelEdit}
                ServerError={editServerError}
                ValidationResult={editErrorState}
                IsLoading={editLoading}
              />
            </Paper>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <CurrenciesTable
          Data={data}
          IsLoading={loading}
          OnDelete={OnDelete}
          OnEdit={OnSetEditData}
          OnRefresh={Refresh}
          LevelSelection={selectedLevel}
        />
      </Grid>
    </Grid>
  );
}
