import { Box, Button, Card, CardContent, CardHeader, Grid, LinearProgress, Paper, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { SettingsActions } from "components/shared/CardActions";
import { ErrorMessages } from "components/shared/ErrorMessages";
import BranchGroupSelect from "components/shared/inputs/BranchGroupSelect";
import BranchSelect from "components/shared/inputs/BranchSelect";
import { ApplyIndicator, CreateSettingsButton, ISettingsComponentProps, OverriddenIndicator } from "components/shared/SettingPagesVarious";
import { WebLevelLabel } from "components/shared/WebLevelLabel";
import { useAdminFrontendServicesClient, useConsolidatorCalculationSettingsClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, BranchGroupOption, BranchOption, CreateSettingsRequest, SettingsOrder, UpdateConsolidatorCalculationSettingsModel } from "WebApiClient";

const ConsolidatorCalculationSettings: React.FC<ISettingsComponentProps> = props => {
  const { ReadOnly, WebLevel, Id, LevelName } = props;
  const client = useConsolidatorCalculationSettingsClient();
  const servicesClient = useAdminFrontendServicesClient();
  const dispatch = useDispatch();

  const [consoSettings, setSettings] = useState<UpdateConsolidatorCalculationSettingsModel | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [validationState, setValidationState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);
  const [serverError, setServerError] = useState(false);

  const consoDisabled: boolean = (consoSettings?.useWeb === false && consoSettings?.sourceBranchGroupID === undefined && consoSettings?.sourceBranchID === undefined);

  useEffect(() => {
    GetSettings(WebLevel, Id);
  }, [WebLevel, Id]);

  function OnUpdate() {
    if (consoSettings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .update(consoSettings)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully updated.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be updated.";
          }
          if (response.serverError) {
            serverSideError = true;
          } else {
            setValidationState(response.validationResult);
          }
        })
        .then(() => {

        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be updated. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Update Consolidator Calculation settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Consolidator Calculation settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function Reload() {
    GetSettings(WebLevel, Id);
  }

  function CreateSettings() {
    if (Id) {
      const createRequest: CreateSettingsRequest = {
        id: Id,
        sortOrder: WebLevel
      };
      setLoading(true);
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      client
        .create(createRequest)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully created.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be created.";
          }
          if (response.serverError) {
            serverSideError = true;
          }
        })
        .catch(e => {
          serverSideError = true;
          console.error(e);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be created. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Create Consolidator calculation settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Consolidator Calculation settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setLoading(false);
          if (transactionSuccess) {
            Reload();
          }
        });
    }
  }

  function GetSettings(order: SettingsOrder, Id: string | null) {
    setLoading(true);

    client
      .get(order, Id)
      .then(settings => {
        setSettings(settings);
        if (settings !== null) {
          props.OnNotifyIfSettingsExist(true);
          if (settings.sourceBranchGroupID) {
            servicesClient.getBranchGroupOptionById(settings.sourceBranchGroupID)
              .then(response => {
                setselectedBranchGroup(response)
                setselectedBranch(null);
              });
          }
          if (settings.sourceBranchID) {
            servicesClient.getBranchOptionById(settings.sourceBranchID)
              .then(response => {
                setselectedBranchGroup({ id: response.branchGroupId, label: response.branchGroupName })
                setselectedBranch(response)
              });
          }
        } else {
          props.OnNotifyIfSettingsExist(false);
        }
      })
      .catch(e => {
        console.error(e);
        setServerError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function DeleteSettings() {
    if (consoSettings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .delete(consoSettings?.id!)
        .then(response => {
          if (response.success) {
            transactionMsg = "successfully deleted.";
            transactionSuccess = true;
          } else {
            transactionMsg = `could not be deleted. ${response.errorMessage}`;
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg = "could not be deleted. A server error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Delete ConsolidatorCalculation settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `ConsolidatorCalculation settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }








  function OnWebSelect() {
    const update: UpdateConsolidatorCalculationSettingsModel = { ...consoSettings!, useWeb: !consoSettings?.useWeb, sourceBranchGroupID: undefined, sourceBranchID: undefined }
    setselectedBranch(null);
    setselectedBranchGroup(null);
    setSettings(update);

  }

  function OnBranchGroupSelect(branchgroup: BranchGroupOption | null) {
    let update: UpdateConsolidatorCalculationSettingsModel | undefined = undefined;
    if (branchgroup === null) {
      update = { ...consoSettings!, useWeb: true, sourceBranchGroupID: undefined, sourceBranchID: undefined }
    }
    else {
      update = { ...consoSettings!, useWeb: false, sourceBranchGroupID: branchgroup?.id, sourceBranchID: undefined }
    }
    setselectedBranchGroup(branchgroup);
    setselectedBranch(null);
    setSettings(update);
  }
  function OnBranchSelect(branch: BranchOption | null) {
    let update: UpdateConsolidatorCalculationSettingsModel | undefined = undefined;
    if (branch === null) {
      update = { ...consoSettings!, useWeb: false, sourceBranchGroupID: selectedBranchGroup?.id, sourceBranchID: undefined }
    }
    else {
      update = { ...consoSettings!, useWeb: false, sourceBranchGroupID: undefined, sourceBranchID: branch.id }

    }
    setselectedBranch(branch);
    setSettings(update);
  }

  function DeactivateConsolidatorCalculation() {
    setSettings({ ...consoSettings!, useWeb: false, sourceBranchGroupID: undefined, sourceBranchID: undefined });
    setselectedBranch(null);
    setselectedBranchGroup(null);
  }

  const [selectedBranchGroup, setselectedBranchGroup] = useState<BranchGroupOption | null>(null)
  const [selectedBranch, setselectedBranch] = useState<BranchOption | null>(null)


  return (
    <Box boxShadow={ReadOnly ? undefined : 3}>
      <Card style={{ opacity: ReadOnly ? 0.5 : 1 }}>
        <CardHeader
          title={
            <span>
              {props.Applies && (
                <span>
                  <ApplyIndicator />
                </span>
              )}
              {props.IsOverwritten && (
                <span>
                  <OverriddenIndicator />
                </span>
              )}
              <span>Consolidator Calculation settings for </span>
              <WebLevelLabel Level={WebLevel} Name={LevelName} />{" "}

              {/* {props.IsOverwritten ? "Overwritten" : ""} */}
            </span>
          }
        />
        {!loading && (
          <CardContent>
            <Box mb={4}>

              <Alert severity="info">
                <Typography variant="body1" gutterBottom>
                  The consolidator calculation is an additional calculation which can be enabled "on top" of a regular agency calculation. <br></br>
                  To apply an consolidator calculation, configure a calculation in the Calculation Rules section for the Web, an Branchgroup or an Branch. <br></br>
                  Select the Branch, Branchgroup or the Web for <b>which the consolidator calculation should apply to in the filter on top of the page</b>. <br></br>
                  Then select the Calculation which should be applied to the filter selection above <b> in the filter below</b>.<br></br>

                </Typography>
                <Typography variant="body1" component="span">
                  The Consolidator calculation can have 3 states:

                  <ul>
                    <li><b>(Branch and Branchgroup only)</b> No Consolidator Calculation settings are set.<br></br>
                      That means the Consolidator Calculation settings on a higher Web hirarchy will be used.</li>
                    <li><b>(Default)</b> The Consolidator Calculation is deactivated. <br></br>
                      Only the Agency Calculation will be applied.
                    </li>
                    <li>The Consolidator Calculation is set to the Web, an Branchgroup or an Branch.</li>
                  </ul>
                </Typography>
              </Alert>
            </Box>
            {consoSettings ? (<React.Fragment>

              <Grid container spacing={2}>
                {serverError && <ErrorMessages ServerError={true} />}
                {validationState && validationState.errorSummary && (
                  <ErrorMessages Messages={validationState.errorSummary} />
                )}

                {consoDisabled ?
                  <Grid item xs={12}><Typography style={{ color: "red" }}>The consolidator calculation is deactivated.</Typography></Grid> :
                  <Grid item xs={12}>
                    <Button variant="outlined" style={{ color: "red" }} onClick={DeactivateConsolidatorCalculation} color="primary">Deactivate Consolidator calculation</Button>
                  </Grid>
                }
                <Grid item md={4} xs={12}>
                  <Paper elevation={3}>
                    <Button
                      size="medium"
                      color={consoSettings.useWeb ? "primary" : undefined}
                      fullWidth
                      variant="contained"
                      onClick={OnWebSelect}
                    >
                      Use Web
                    </Button>
                  </Paper>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Paper>
                    <BranchGroupSelect
                      Value={selectedBranchGroup}
                      Selected={consoSettings.sourceBranchGroupID != undefined}
                      AutoFocus={false}
                      OnSelect={OnBranchGroupSelect}
                    />
                  </Paper>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Paper>
                    {selectedBranchGroup ? (
                      <BranchSelect
                        BranchGroupID={selectedBranchGroup.id!}
                        OnSelect={OnBranchSelect}
                        Value={selectedBranch}
                        Selected={consoSettings.sourceBranchID != undefined}
                      />
                    ) : (
                      <span></span>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </React.Fragment>) : (
              <CreateSettingsButton
                OnClick={CreateSettings}
                Readonly={props.ReadOnly}
              />
            )}
          </CardContent>

        )}
        {loading && (
          <CardContent>
            <LinearProgress />
          </CardContent>
        )}
        {!ReadOnly && consoSettings && (
          <SettingsActions
            OnDelete={DeleteSettings}
            OnReset={Reload}
            OnSave={OnUpdate}
          />
        )}
      </Card>
    </Box>
  );
};

export default ConsolidatorCalculationSettings;
