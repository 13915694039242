import { Button, Card, CardContent, CardHeader, Chip, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SaveIcon from "@material-ui/icons/Save";
import { Alert } from "@material-ui/lab";
import { GetBookingAdressBehaviorLabel } from "components/masterdata/branches/branch.Details";
import { TableIcons } from "components/shared/tableIcons";
import { ImportStateCellStyle, ImportStateRenderer } from "components/shared/various";
import { useBranchClient, useBranchGroupClient } from "hooks/useHttpClient";
import MaterialTable, { Column } from "material-table";
import { DropzoneArea } from "material-ui-dropzone";
import { parse, ParseResult } from "papaparse";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { BookingAddressBehavior, BranchImportResultModel, ImportBranchModel, ImportStatus } from "WebApiClient";
export const BranchImportHeaderRow = ["branchGroupName", "name", "branchNumberPrimary", "branchNumberSecondary", "streetAddress1", "streetAddress2", "zip", "city", "country", "email", "bookingConfirmationEmail", "website", "bookingAddressBehavior", "webfarePasswordBehavior", "webfarePassword", "emergencyPhoneBehavior", "emergencyPhone"];

const BookingAdressBehaviorPossibleValues = <TableCell>
  0: Use Branch Data (not visible on Booking page)<br></br>
  1: Prefill with Branch Adress (visible on Booking page)<br></br>
  2: Empty input (visible on Booking page)<br></br>
</TableCell>
const HeaderRowExplanations =
  <ExpansionPanel>
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
    >
      <Typography ><span style={{ verticalAlign: "bottom" }}><HelpOutlineIcon></HelpOutlineIcon> Expand to see Help about CSV fields</span></Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Explanation</TableCell>
              <TableCell>Possible values</TableCell>
              <TableCell>Comments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>branchGroupName</TableCell>
              <TableCell>Name of the Branchgroup of the Branch</TableCell>
              <TableCell></TableCell>
              <TableCell>The Branchgroup needs to exist in this Web.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>name</TableCell>
              <TableCell>The name of the Branch.</TableCell>
              <TableCell></TableCell>
              <TableCell>A Branch name is unique in a Branchgroup. If you import a Branch in the same Branchgroup with the same name, the data from old Branch will be updated. Otherwise a new Branch will get created.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>branchNumberPrimary</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>branchNumberSecondary</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>streetAddress1</TableCell>
              <TableCell>The primary Street Adress.</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>streetAddress2</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>zip</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>city</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>country</TableCell>
              <TableCell>The Country of the Branch.</TableCell>
              <TableCell>2-Letter country codes</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>email</TableCell>
              <TableCell>The general Email Adress of the Branch.</TableCell>
              <TableCell>A valid email Adress.</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>bookingConfirmationEmail</TableCell>
              <TableCell>The Email Adress of the Branch where Booking confirmations will be sent to.</TableCell>
              <TableCell>A valid email Adress.</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>website</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>bookingAddressBehavior</TableCell>
              <TableCell>Determines the Behavior of the Booking Adress input on the Booking page.</TableCell>
              {BookingAdressBehaviorPossibleValues}
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>webfarePasswordBehavior</TableCell>
              <TableCell>Determines the Behavior of the Webfare password input on the Booking page.</TableCell>
              {BookingAdressBehaviorPossibleValues}
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>webfarePassword</TableCell>
              <TableCell>Password for Webfare Bookings.</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>emergencyPhoneBehavior</TableCell>
              <TableCell>Determines the Behavior of the Emergency phone input on the Booking page.</TableCell>
              {BookingAdressBehaviorPossibleValues}
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>emergencyPhone</TableCell>
              <TableCell>Emergency Phone number.</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>


          </TableBody>
        </Table>
      </TableContainer>
    </ExpansionPanelDetails>
  </ExpansionPanel>

const ImportMasterDataBranches: React.FC<{}> = props => {
  const [invalidCSV, setInvalidCSV] = useState(false);
  const [isProcessing, setisProcessing] = useState(false);
  const [delimiter, setDelimiter] = useState(";");
  const [parsedImports, setParsedImports] = useState<BranchImportResultModel[]>([])
  // const [parsedImports, setParsedImports] = useState<ParsedBranchImportResult[]>([]);
  const branchGroupClient = useBranchGroupClient();
  const branchClient = useBranchClient();

  const hasPendingUploads = parsedImports.length > 0;
  const pendingImports = parsedImports.filter(e => e.status === ImportStatus.Pending).length;
  const errorImports = parsedImports.filter(e => e.status === ImportStatus.Failure).length;
  const successImports = parsedImports.filter(e => e.status === ImportStatus.Imported).length;

  function onUpload(files: File[]): void {
    if (files.length > 0) {

      setisProcessing(true);
      const upload = files[0];
      if (upload) {
        parse(upload, { delimitersToGuess: [";", ",", "\t", "\u001e", "\u001f", "|"], header: true, skipEmptyLines: true, complete: OnParseComplete });
      }
    }
  }
  function OnParseComplete(results: ParseResult<unknown>, file: File) {
    let casted: ImportBranchModel[] = [];
    try {
      casted = results.data as ImportBranchModel[];
      SendData(false, casted);


    }
    catch {
      setInvalidCSV(true);
    }


  }

  function SendData(submit: boolean, imports: ImportBranchModel[]) {
    setisProcessing(true);
    branchClient.parseImports({ imports: imports, submit: submit }).then((e) => {

      setParsedImports(e);
      setInvalidCSV(false);
    }).catch(() => {
      setInvalidCSV(true);
    }).finally(() => {
      setisProcessing(false);
    })
  }

  function SubmitImports() {
    const data = parsedImports.map(e => e.result!);
    SendData(true, data);
  }



  function OnReset() {
    setInvalidCSV(false);
    setParsedImports([]);
  }

  function GetRowStyle(
    data: any,
    index: number,
    level: number
  ): React.CSSProperties {
    const bg = data as BranchImportResultModel;
    let result: React.CSSProperties = ImportStateCellStyle(bg.status);

    return result;
  }

  function GetTableHeader(): JSX.Element {
    return <Grid item container spacing={4}>
      <Grid item container spacing={4}>
        <Grid item>
          Pending: <Chip style={{ backgroundColor: "yellow" }} label={pendingImports}></Chip>
        </Grid>
        <Grid item>
          Error: <Chip style={{ backgroundColor: "red" }} label={errorImports}></Chip>
        </Grid>
        <Grid item>
          Success: <Chip style={{ backgroundColor: "green" }} label={successImports}></Chip>
        </Grid>

      </Grid>
      <Grid item>
        <Button color="secondary" variant="contained" onClick={OnReset} startIcon={<CancelIcon />}>Discard uploads</Button>

      </Grid>
      <Grid item>
        <Button color="primary" variant="contained" onClick={SubmitImports} startIcon={<SaveIcon />}>Submit pending Imports</Button>

      </Grid>
    </Grid>

  }


  return (

    <Grid container spacing={4}>
      <Grid xs={12} item>
        {HeaderRowExplanations}
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={"Import Branches"} />
          <CardContent>
            <Grid container spacing={4}>

              {(isProcessing === true) &&
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>

              }

              {parsedImports.length === 0 ?
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      On this page you can mass import Branches via CSV file upload. <br></br>
                      First, download the CSV Template with a delimiter of your choice. <br></br>
                      Then upload your file with the upload field below. <br></br>
                      You will get a validated result of your file, which you can check and confirm. <br></br>
                      You can see further explanations about the CSV contents in the Help Box on top of the page.

                    </Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item>

                      <SaveIcon /> <CSVLink data={BranchImportHeaderRow.join(delimiter)} filename="branch_template.csv">Download CSV Template</CSVLink>
                    </Grid>

                    <Grid item>
                      <FormControl>
                        <InputLabel id="demo-simple-select-helper-label">Delimiter</InputLabel>
                        <Select
                          value={delimiter}
                          onChange={(e) => { setDelimiter(e.target.value as string) }}
                        >
                          <MenuItem value={","}>,</MenuItem>
                          <MenuItem value={";"}>;</MenuItem>
                          <MenuItem value={"|"}>|</MenuItem>
                        </Select>
                      </FormControl>

                    </Grid>




                  </Grid>
                  <Grid item xs={12}>

                    <DropzoneArea
                      acceptedFiles={['.csv']}
                      filesLimit={1}
                      onChange={onUpload}
                      showPreviews={false}
                      dropzoneText="Drag and Drop an CSV file here or click to Import Branches"


                    />
                  </Grid>
                  {invalidCSV &&
                    <Grid item xs={12}>
                      <Alert severity="error">Invalid CSV. Please check you file and try again.</Alert>
                    </Grid>
                  }

                </React.Fragment>
                : <React.Fragment>

                  {GetTableHeader()}

                  <Grid spacing={4} container>



                    <Grid item xs={12}>
                      <MaterialTable
                        icons={TableIcons}
                        title={hasPendingUploads ? "Validate your upload and confirm above." : "Upload you imports above to see pending imports."}

                        columns={ColumnDefinitions}
                        data={parsedImports}
                        isLoading={isProcessing}
                        options={{
                          selection: false,
                          grouping: false,
                          paging: false,
                          doubleHorizontalScroll: true
                          // rowStyle: GetRowStyle,
                        }}



                      />
                    </Grid>




                  </Grid>

                </React.Fragment>
              }

            </Grid>
          </CardContent>
        </Card>
      </Grid>


    </Grid>
  );
};

export default ImportMasterDataBranches;

const ColumnDefinitions: Array<Column<BranchImportResultModel>> = [
  { title: "Status", render: (val) => <ImportStateRenderer status={val.status} />, cellStyle: (data, row) => ImportStateCellStyle(row.status) },
  { title: "Errors", render: (val) => <ul>{val.validationResult?.errorSummary?.map((err, index) => <li key={index}>{err}</li>)}</ul> },
  { title: "Branchgroup", render: (val) => <span>{val.result?.branchGroupName}</span> },
  { title: "Name", render: (val) => <span>{val.result?.name}</span> },
  { title: "Primary number", render: (val) => <span>{val.result?.branchNumberPrimary}</span> },
  {
    title: "Secondary number", render: (val) => <span>{val.result?.branchNumberSecondary}</span>
  },
  { title: "Care of", field: "careOf", render: (val) => <span>{val.result?.careOf}</span> },
  { title: "Street 1", render: (val) => <span>{val.result?.streetAddress1}</span> },
  {
    title: "Street 2", render: (val) => <span>{val.result?.streetAddress2}</span>
  },
  { title: "ZIP", render: (val) => <span>{val.result?.zip}</span> },
  { title: "City", render: (val) => <span>{val.result?.city}</span> },
  {
    title: "Country", render: (val) => <span>{val.result?.country}</span>
  },
  { title: "Telephone", render: (val) => <span>{val.result?.telephone}</span> },
  { title: "Fax", render: (val) => <span>{val.result?.fax}</span> },
  { title: "Email", render: (val) => <span>{val.result?.email}</span> },
  {
    title: "Booking confimration email", render: (val) => <span>{val.result?.bookingConfirmationEmail}</span>
  },
  { title: "Website", render: (val) => <span>{val.result?.website}</span> },
  {
    title: "Booking adress behavior",
    field: "bookingAddressBehavior",
    render: (rowData, type) => {
      if (type === "group") {
        const d = rowData as any;
        const label = d as BookingAddressBehavior;
        return GetBookingAdressBehaviorLabel(label);
      } else {

        return GetBookingAdressBehaviorLabel(rowData.result?.bookingAddressBehavior ?? 0)
      }
    }
  },
  {
    title: "Webfare password behavior",
    field: "webfarePasswordBehavior",
    render: (rowData, type) => {
      if (type === "group") {
        const d = rowData as any;
        const label = d as BookingAddressBehavior;
        return GetBookingAdressBehaviorLabel(label);
      } else {

        return GetBookingAdressBehaviorLabel(rowData.result?.webfarePasswordBehavior ?? 0)
      }
    }
  },
  { title: "Webfare password", render: (val) => <span>{val.result?.webfarePassword}</span> },
  {
    title: "Emergency phone behavior",
    field: "emergencyPhoneBehavior",
    render: (rowData, type) => {
      if (type === "group") {
        const d = rowData as any;
        const label = d as BookingAddressBehavior;
        return GetBookingAdressBehaviorLabel(label);
      } else {

        return GetBookingAdressBehaviorLabel(rowData.result?.emergencyPhoneBehavior ?? 0)
      }
    }
  },
  { title: "Emergency phone", render: (val) => <span>{val.result?.emergencyPhone}</span> }
];