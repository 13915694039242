import DateFnsUtils from "@date-io/date-fns";
import { parseISO } from 'date-fns';
import { IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as moment from "moment";
import React from "react";
import { InvariantDateModel, InvariantDateTimeModel } from "WebApiClient";
import { HelpTextButton } from "../various";

export const InvariantDateInput: React.FC<{ date: InvariantDateModel | undefined, OnUpdate: (date: InvariantDateModel | undefined) => void, label: string, helpButton: JSX.Element | undefined }> = props => {
    const { OnUpdate, date, label, helpButton } = props;
    let newDate = date === undefined ? null : moment({ day: date.day, month: date.month, year: date.year}).toDate();
    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
            InputProps={{
                startAdornment: date ? (
                    <IconButton onClick={(e) => { e.stopPropagation(); OnUpdate(undefined) }} size="small"><ClearIcon></ClearIcon></IconButton>
                ) : null,
                endAdornment: helpButton ? (
                    <HelpTextButton HelpText={helpButton}></HelpTextButton>
                ) : null,
            }}

            // margin="normal"
            label={label}
            format="dd.MM.yyyy"
            required={false}
            placeholder=""
            value={
                date === undefined ? null : moment({ day: date.day, month: date.month -1, year: date.year }).toDate()
            }

            onChange={(date) => {
                const d = moment(date);
                const f: InvariantDateModel = { day: d.date(), month : d.month(), year: d.year() };
                let D: any = f;
                D = { ...D, month: f.month+1 }
                OnUpdate(D)
                console.log("timedate", D);
            }}
        // InputAdornmentProps={{ position: "start" }}
        />
    </MuiPickersUtilsProvider>

}

export const InvariantDateTimeInput: React.FC<{ date: InvariantDateTimeModel | undefined, OnUpdate: (date: InvariantDateTimeModel | undefined) => void, label: string, helpButton: JSX.Element | undefined }> = props => {
    const { OnUpdate, date, label, helpButton } = props;
    let newDate = date === undefined ? null : moment({ day: date.day, month: date.month, year: date.year, hour: date.hour, minute: date.minute, second: date.second }).toDate();
    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
            ampm={false}
            InputProps={{
                startAdornment: date ? (
                    <IconButton onClick={(e) => { e.stopPropagation(); OnUpdate(undefined) }} size="small"><ClearIcon></ClearIcon></IconButton>
                ) : null,
                endAdornment: helpButton ? (
                    <HelpTextButton HelpText={helpButton}></HelpTextButton>
                ) : null,
            }}
            // margin="normal"
            label={label}
            format="dd.MM.yyyy HH:mm"
            required={false}
            placeholder=""
            value={
                date === undefined ? null : moment({ day: date.day, month: date.month -1, year: date.year, hour: date.hour, minute: date.minute, second: date.second }).toDate()
            }
            onChange={(date) => {
                const d = moment(date);
                const f: InvariantDateTimeModel = { day: d.date(), month: d.month(), year: d.year(), hour: d.hour(), minute: d.minute(), second: d.second() };
                let D: any = f;
                D = { ...D, month: f.month + 1 }
                console.log(D);
                OnUpdate(D);
            }}
        // InputAdornmentProps={{ position: "start" }}
        />
    </MuiPickersUtilsProvider>
}