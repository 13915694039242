import { Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import * as React from "react";



export default function ExportGettingStarted() {
  return <Grid container>
    <Grid item xs={12}>
      <Card>
        <CardHeader title={"Import"} />
        <CardContent>
          <Typography variant="body1">
            In this section you can import various information.  <br></br>

          </Typography>
        </CardContent>
      </Card>
    </Grid>

  </Grid>;
}
