import { Box, Card, CardContent, CardHeader, Divider, Grid, LinearProgress } from "@material-ui/core";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { SettingsActions } from "components/shared/CardActions";
import { ErrorMessages } from "components/shared/ErrorMessages";
import { ApplyIndicator, CreateSettingsButton, ISettingsComponentProps, OverriddenIndicator } from "components/shared/SettingPagesVarious";
import { WebLevelLabel } from "components/shared/WebLevelLabel";
import { useCiLogosClient } from "hooks/useHttpClient";
import { ToBase64 } from "hooks/various";
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, CreateSettingsRequest, SettingsOrder, UpdateCiLogosModel } from "WebApiClient";

const CiLogos: React.FC<ISettingsComponentProps> = props => {
  const { ReadOnly, WebLevel, Id, LevelName } = props;
  const client = useCiLogosClient();
  const dispatch = useDispatch();
  const [Settings, setSettings] = useState<
    UpdateCiLogosModel | undefined
  >(undefined);
  const [oldSettings, setOldSettings] = useState<
    UpdateCiLogosModel | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [validationState, setValidationState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);
  const [serverError, setServerError] = useState(false);
  useEffect(() => {
    GetSettings(WebLevel, Id);
  }, [WebLevel, Id]);

  function OnUpdate() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .update(Settings)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully updated.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be updated.";
          }
          if (response.serverError) {
            serverSideError = true;
          } else {
            setValidationState(response.validationResult);
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be updated. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Update Multichannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function Reload() {
    GetSettings(WebLevel, Id);
  }

  function CreateSettings() {
    if (Id) {
      const createRequest: CreateSettingsRequest = {
        id: Id,
        sortOrder: WebLevel
      };
      setLoading(true);
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      client
        .create(createRequest)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully created.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be created.";
          }
          if (response.serverError) {
            serverSideError = true;
          }
        })
        .catch(e => {
          serverSideError = true;
          console.error(e);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be created. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Create Mulitchannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setLoading(false);
          if (transactionSuccess) {
            Reload();
          }
        });
    }
  }

  function GetSettings(order: SettingsOrder, Id: string | null) {
    setLoading(true);

    client
      .get(order, Id)
      .then(settings => {
        setSettings(settings);
        setOldSettings(settings);
        if (settings !== null) {
          props.OnNotifyIfSettingsExist(true);
        } else {
          props.OnNotifyIfSettingsExist(false);
        }
      })
      .catch(e => {
        console.error(e);
        setServerError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function DeleteSettings() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .delete(Settings?.id!)
        .then(response => {
          if (response.success) {
            transactionMsg = "successfully deleted.";
            transactionSuccess = true;
          } else {
            transactionMsg = `could not be deleted. ${response.errorMessage}`;
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg = "could not be deleted. A server error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Delete Multichannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }



  function onHeaderImageUpload(files: File[]): void {
    const upload = files[0];
    if (upload) {
      ToBase64(upload).then(e => {

        if (Settings) {
          setSettings({ ...Settings, logoCorporateHeaderData: e as string })
        }

      })
    }
  }
  function onPDFImageUpload(files: File[]): void {
    const upload = files[0];
    if (upload) {
      ToBase64(upload).then(e => {

        if (Settings) {
          setSettings({ ...Settings, logoPdfConfirmationData: e as string })
        }

      })
    }
  }


  return (
    <Box boxShadow={ReadOnly ? undefined : 3}>
      <Card style={{ opacity: ReadOnly ? 0.5 : 1 }}>
        <CardHeader
          title={
            <span>
              {props.Applies && (
                <span>
                  <ApplyIndicator />
                </span>
              )}
              {props.IsOverwritten && (
                <span>
                  <OverriddenIndicator />
                </span>
              )}
              <span>Company Logos for </span>
              <WebLevelLabel Level={WebLevel} Name={LevelName} />{" "}
              {/* {props.IsOverwritten ? "Overwritten" : ""} */}
            </span>
          }
        />
        {!loading && (
          <CardContent>
            {(Settings && oldSettings) ? (
              <Grid container spacing={2}>
                {serverError && <ErrorMessages ServerError={true} />}
                {validationState && validationState.errorSummary && (
                  <ErrorMessages Messages={validationState.errorSummary} />
                )}
                <Grid container xs={12}>

                  <Grid item xs={6}>
                    <h4>Current Corporate Header</h4>
                    <img src={oldSettings.logoCorporateHeaderData} />
                  </Grid>
                  {Settings.logoCorporateHeaderData !== oldSettings.logoCorporateHeaderData &&
                    <Grid item xs={6}>
                      <h4>New Corporate Header</h4>
                      <img src={Settings.logoCorporateHeaderData} />
                    </Grid>
                  }

                  <Grid item xs={12}>

                    <DropzoneArea
                      acceptedFiles={['image/*']}
                      filesLimit={1}
                      onChange={onHeaderImageUpload}
                      showPreviews={false}
                    // initialFiles={[`${AgentApiURL}/publicservices/waitscreen/${Settings.id}`]}

                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box m={2}>
                    <Divider></Divider>
                  </Box>
                </Grid>
                <Grid container xs={12}>
                  <Grid item xs={6}>
                    <h4>Current PDF confirmation Logo</h4>
                    <img src={oldSettings.logoPdfConfirmationData} />
                  </Grid>
                  {Settings.logoPdfConfirmationData !== oldSettings.logoPdfConfirmationData &&
                    <Grid item xs={6}>
                      <h4>New PDF confirmation Logo</h4>
                      <img src={Settings.logoPdfConfirmationData} />
                    </Grid>
                  }

                  <Grid item xs={12}>

                    <DropzoneArea
                      acceptedFiles={['image/*']}
                      filesLimit={1}
                      onChange={onPDFImageUpload}
                      showPreviews={false}
                    // initialFiles={[`${AgentApiURL}/publicservices/waitscreen/${Settings.id}`]}

                    />
                  </Grid>
                </Grid>



              </Grid>
            ) : (
              <CreateSettingsButton
                OnClick={CreateSettings}
                Readonly={props.ReadOnly}
              />
            )}
          </CardContent>
        )}
        {loading && (
          <CardContent>
            <LinearProgress />
          </CardContent>
        )}
        {!ReadOnly && Settings && (
          <SettingsActions
            OnDelete={DeleteSettings}
            OnReset={Reload}
            OnSave={OnUpdate}
          />
        )}
      </Card>
    </Box>
  );
};

export default CiLogos;
