import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { HelpTextButton } from "components/shared/various";
import React, { useEffect, useState } from "react";
import { AdminEntityValidationResult, UpdateUserModel } from "../../../WebApiClient";
import * as HelpTexts from "./users.HelpTexts";
interface UserDataInputProps {
  Data: UpdateUserModel;
  OnSave: (data: UpdateUserModel) => void;
  OnCancel: () => void;
  ValidationResult: AdminEntityValidationResult | undefined;
  myRef?:any;
}
const UserDataInputComponent: React.FC<UserDataInputProps> = props => {
  const [User, setUser] = useState(props.Data);
  const IsEdit = User.id !== undefined;
  useEffect(() => {
    setUser(props.Data);
  }, [props.Data]);
  const usernameError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["username"]
    : undefined;
  const titleError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["title"]
    : undefined;
  const passwordError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["password"]
    : undefined;
  const firstnameError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["firstname"]
    : undefined;
  const lastnameError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["lastname"]
    : undefined;
  const emailError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["email"]
    : undefined;
  const telephoneError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["telephone"]
    : undefined;
  const mobilephoneError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["mobilephone"]
    : undefined;
  const customField1Error: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["customfield1"]
    : undefined;
  const faxError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["fax"]
    : undefined;

  return (
    <Card ref={props.myRef}>
      <CardHeader
        title={
          <span>
            {IsEdit
              ? `Edit User ${props.Data.username}`
              : `Create User ${User.username}`}
          </span>
        }
      />
      <CardContent>
        <Grid container direction="column">
          {props.ValidationResult && props.ValidationResult.errorSummary && (
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <ul>
                  {props.ValidationResult.errorSummary.map((e, index) => (
                    <li key={index}>{e}</li>
                  ))}
                </ul>
              </Alert>
            </Grid>
          )}

          <Grid container item xs={12} spacing={6}>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={usernameError !== undefined}
                  value={User.username}
                  onChange={e =>
                    setUser({
                      ...User,
                      username: e.target.value
                    })
                  }
                  helperText={usernameError}
                  label="Username"
                  InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.UsersUserNameHelpText} /> }}
                />

              </Box>
            </Grid>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={passwordError !== undefined}
                  value={User.password}
                  onChange={e =>
                    setUser({
                      ...User,
                      password: e.target.value
                    })
                  }
                  helperText={passwordError}
                  label="Password"
                  InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.UsersPasswordHelpText} /> }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={6}>
            <Grid item xs={2}>
              <Box marginBottom={2}>

                <TextField
                  error={titleError !== undefined}
                  value={User.title}
                  onChange={e =>
                    setUser({
                      ...User,
                      title: e.target.value
                    })
                  }
                  helperText={titleError}
                  label="Title"
                  InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.UsersTitleHelpText} /> }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={6}>
            <Grid item>
              <Box marginBottom={2}>

                <TextField
                  error={firstnameError !== undefined}
                  value={User.firstName}
                  onChange={e =>
                    setUser({
                      ...User,
                      firstName: e.target.value
                    })
                  }
                  helperText={firstnameError}
                  label="First name"
                  InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.UsersFirstnameHelpText} /> }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={lastnameError !== undefined}
                  value={User.lastName}
                  onChange={e =>
                    setUser({
                      ...User,
                      lastName: e.target.value
                    })
                  }
                  helperText={lastnameError}
                  label="Last name"
                  InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.UsersLastNameHelpText} /> }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={6}>
            <Grid item>
              <Box marginBottom={2}>

                <TextField
                  error={emailError !== undefined}
                  value={User.email}
                  onChange={e =>
                    setUser({
                      ...User,
                      email: e.target.value
                    })
                  }
                  helperText={emailError}
                  label="Email"
                  InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.UsersEmailHelpText} /> }}
                />
              </Box>
            </Grid>

          </Grid>

          <Grid container item xs={12} spacing={6}>
            <Grid item>
              <Box marginBottom={2}>

                <TextField
                  error={telephoneError !== undefined}
                  value={User.telephone}
                  onChange={e =>
                    setUser({
                      ...User,
                      telephone: e.target.value
                    })
                  }
                  helperText={telephoneError}
                  label="Telephone"
                  InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.UsersTelephoneHelpText} /> }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={faxError !== undefined}
                  value={User.fax}
                  onChange={e =>
                    setUser({
                      ...User,
                      fax: e.target.value
                    })
                  }
                  helperText={faxError}
                  label="Fax"
                  InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.UsersFaxHelpText} /> }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={6}>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={mobilephoneError !== undefined}
                  value={User.mobilePhone}
                  onChange={e =>
                    setUser({
                      ...User,
                      mobilePhone: e.target.value
                    })
                  }
                  helperText={mobilephoneError}
                  label="Mobile phone"
                  InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.UsersMobilephoneHelpText} /> }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box marginBottom={2}>

                <TextField
                  error={customField1Error !== undefined}
                  value={User.customField1}
                  onChange={e =>
                    setUser({
                      ...User,
                      customField1: e.target.value
                    })
                  }
                  helperText={customField1Error}
                  label="Custom field 1"
                  InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.Customfield1HelpText} /> }}
                />
              </Box>
            </Grid>
          </Grid>


          <Grid container item xs={12} spacing={6}>
            <Grid item>
              <Box marginBottom={2}>

                <FormControlLabel
                  control={<Checkbox checked={User.isActive} onChange={() => {
                    setUser({
                      ...User,
                      isActive: !User.isActive
                    })
                  }} />}
                  label={<span>Active <HelpTextButton HelpText={HelpTexts.UsersIsActiveHelpText} /></span>}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box marginBottom={2}>

                <FormControlLabel
                  control={<Checkbox checked={User.isAdmin} onChange={() => {
                    setUser({
                      ...User,
                      isAdmin: !User.isAdmin
                    })
                  }} />}
                  label={<span>Administrator <HelpTextButton HelpText={HelpTexts.UsersIsAdminHelpText} /></span>}
                />
              </Box>
            </Grid>
          </Grid>


        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Button variant="contained" onClick={() => setUser(props.Data)}>
          Reset
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => props.OnSave(User)}
        >
          Save
        </Button>
        <Button color="secondary" variant="contained" onClick={props.OnCancel}>
          Close
        </Button>
      </CardActions>
    </Card>
  );
  // <Box boxShadow={2} p={2}>
  // </Box>
};

export default UserDataInputComponent;
