import { Button, Card, CardActions, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { HelpTextButton } from "components/shared/various";
import React, { useEffect, useState } from "react";
import { AdminEntityValidationResult, UpdateBranchgroupModel } from "../../../WebApiClient";
import * as HelpTexts from "./branchgroups.HelpTexts";

interface BranchgroupDataInputProps {
  Data: UpdateBranchgroupModel;
  OnSave: (data: UpdateBranchgroupModel) => void;
  OnCancel: () => void;
  ValidationResult: AdminEntityValidationResult | undefined;
}
const BranchgroupDataInputComponent: React.FC<BranchgroupDataInputProps> = props => {
  const [branchgroup, setBranchGroup] = useState(props.Data);
  const IsEdit = branchgroup.id !== undefined;
  useEffect(() => {
    setBranchGroup(props.Data);
  }, [props.Data]);
  const nameError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["name"]
    : undefined;
  const posError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["pos"]
    : undefined;

  return (
    <Card>
      <CardHeader
        title={
          <span>
            {IsEdit
              ? `Edit Branchgroup ${props.Data.name}`
              : `Create Branchgroup ${branchgroup.name}`}
          </span>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          {props.ValidationResult && props.ValidationResult.errorSummary && (
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <ul>
                  {props.ValidationResult.errorSummary.map((e, index) => (
                    <li key={index}>{e}</li>
                  ))}
                </ul>
              </Alert>
            </Grid>
          )}
          <Grid item>
            <TextField
              error={nameError !== undefined}
              value={branchgroup.name}
              onChange={e =>
                setBranchGroup({
                  ...branchgroup,
                  name: e.target.value
                })
              }
              helperText={nameError}
              label="Name"
            />
          </Grid>

          <Divider />
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={branchgroup.isActive} onChange={() => {
                setBranchGroup({
                  ...branchgroup,
                  isActive: !branchgroup.isActive
                })
              }} />}
              label={<span>Active <HelpTextButton HelpText={HelpTexts.BranchgroupsIsActiveHelpText} /></span>}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Button variant="contained" onClick={() => setBranchGroup(props.Data)}>
          Reset
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => props.OnSave(branchgroup)}
        >
          Save
        </Button>
        <Button color="secondary" variant="contained" onClick={props.OnCancel}>
          Close
        </Button>
      </CardActions>
    </Card>
  );
  // <Box boxShadow={2} p={2}>
  // </Box>
};

export default BranchgroupDataInputComponent;
