import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import createRootReducer from './rootExports/rootReducer'
import {  rootSaga} from "./rootExports/rootSagas";
import { composeWithDevTools } from 'redux-devtools-extension';

export const history = createBrowserHistory()
const composeEnhancers = composeWithDevTools({
  maxAge:20
});
export default function configureStore(preloadedState?: any) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),sagaMiddleware
      ),
    ),
  )

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootExports/rootReducer', () => {
      store.replaceReducer(createRootReducer(history));
    });
  }
  sagaMiddleware.run(rootSaga)
  return store
}
