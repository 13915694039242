import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import WebLevelSelect from "components/general/WebLevelSelect";
import { useUserClient } from "hooks/useHttpClient";
import { ScrollToTop } from "hooks/various";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { WebLevelEnum } from "types/FilterTypes";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, UpdateUserModel } from "WebApiClient";
import UsersDeleteConfirmation from "./users.DeleteConfirmationModal";
// import UsersImport from "./users.Import";
import UsersTable from "./users.Table";
import UserDataInputComponent from "./users.Update";

interface IUserTableProps { myRef?:any }
export const UsersPage: React.FC<IUserTableProps> = props => {
  const stateSelection = useSelector((state: State) => state.Filter.Selection);

  const userClient = useUserClient();
  const dispatch = useDispatch();


  const [lastedit, setlastedit] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [editUser, setEditUser] = useState<UpdateUserModel | undefined>(
    undefined
  );

  const [editUserErrorState, setEditUserErrorState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);

  const [Users, setUsers] = useState<UpdateUserModel[]>([]);
  const [importOpen, setImportOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [usersToBeDeleted, setUserToBeDeleted] = useState<UpdateUserModel[]>([]);
  const myRef = useRef(undefined);
  
  

  function ToggleImport() {
    setImportOpen(!importOpen);
  }

  useEffect(() => {
    RefreshUsers();
  }, [stateSelection]);

  function RefreshUsers() {
    if ((stateSelection.Level === WebLevelEnum.Branch) && stateSelection.Branch) {
      setIsLoading(true);
      userClient
        .getByUsersByBranch(stateSelection.Branch?.id!)
        .then(response => {
          setUsers(response);
        })
        .catch(() => { })
        .finally(() => {
          setIsLoading(false);
        });
    }
    else {
      setUsers([]);
      setEditUser(undefined);
      setEditUserErrorState(undefined);
    }
  }

  function OnDeleteMultipleUserAsync(data: UpdateUserModel[]) {
    setUserToBeDeleted(data);
    setDeleteModalOpen(true);
  }

  function UpdateUserAsync(user: UpdateUserModel) {
    const isUpdate = user.id !== undefined;
    const actionTypeLabel = isUpdate ? "updated" : "created";
    ScrollToTop();
    let transactionSuccess = false;
    let transactionTime = new Date().toString();
    let transactionId = "";
    let transactionMsg = "MSG NOT SET";
    let serverSideError = false;

    userClient
      .update(user)
      .then(result => {
        setEditUserErrorState(result.validationResult);
        transactionSuccess = result.success;
        transactionId = result.transactionId;
        if (result.success === true) {
          transactionMsg = `User successfully ${actionTypeLabel}.`;
          CloseEditUser();
        } else {
          transactionMsg = `User could not be ${actionTypeLabel}.`;
          if (result.serverError) {
            serverSideError = true;
          }
        }
        setlastedit(result.resultId);
      })
      .catch(e => {
        console.error(e);
        serverSideError = true;
      })
      .finally(() => {
        if (serverSideError) {
          transactionMsg = `User could not be ${actionTypeLabel}. An server-side error has occured.`;
        }
        let transaction: TransactionIDInfo = {
          Label: `${isUpdate ? "Update" : "Create"} User`,
          Success: transactionSuccess,
          Time: transactionTime,
          TransactionID: transactionId,
          Message: transactionMsg
        };
        dispatch(Session_Reducer_PushTransactionID(transaction));
        RefreshUsers();
      });
  }
  
  function SetEditUser(data: UpdateUserModel | undefined) {
    setEditUserErrorState(undefined);
    if (data) {
      setEditUser(data);
    } else {
      const newBg: UpdateUserModel = {
        id: undefined,
        branchID: stateSelection.Branch?.id!,
        deeplink: undefined,
        email: "",
        fax: "",
        firstName: "",
        isActive: true,
        isAdmin: false,
        lastName: "",
        mobilePhone: "",
        password: "",
        telephone: "",
        title: "Mr.",
        username: "",
        customField1: ""
      };
      setEditUser(newBg);
    }
  }

  function CloseEditUser() {
    setEditUserErrorState(undefined);
    setEditUser(undefined);
  }

  function DeleteUserAsync(id: string): Promise<TransactionIDInfo> {
    return new Promise<TransactionIDInfo>((resolve, reject) => {
        let transactionSuccess = false;
        let transactionTime = new Date().toString();
        let transactionId = "";
        let transactionMsg = "MSG NOT SET";
        let serverSideError = false;
        userClient
            .delete(id)
            .then(e => {
                transactionId = e.transactionId;
                if (e.success) {
                    transactionSuccess = true;
                    transactionMsg = "User deleted.";
                    //setIsOpen((isOpen)=> !isOpen);
                    // props.OnDeleteComplete();
                } else {
                    transactionMsg = `User could not be deleted. ${e.errorMessage}`;
                }
            })
            .catch(e => {
                console.error(e);
                serverSideError = true;
            })
            .finally(() => {
                if (serverSideError) {
                    transactionMsg = `User could not be deleted. An server-side error has occured.`;
                }
                const transaction: TransactionIDInfo = {
                    Label: `Delete User`,
                    Success: transactionSuccess,
                    Time: transactionTime,
                    TransactionID: transactionId,
                    Message: transactionMsg
                };
                resolve(transaction);
                RefreshUsers();
                // dispatch(Session_Reducer_PushTransactionID(transaction));
               // setIsOpen((isOpen)=> !isOpen);
                //props.OnDeleteComplete();
            });
    })
}

  function OnDeleteUser(data:any) {
    DeleteUserAsync(data.id)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <WebLevelSelect IsSettingsPage={false} ShowWebButton={false} />
      </Grid>
      {stateSelection.Branch ?
        <React.Fragment>
          {editUser && (
            <Grid item xs={12}>
              <UserDataInputComponent
                Data={editUser}
                OnSave={UpdateUserAsync}
                OnCancel={CloseEditUser}
                ValidationResult={editUserErrorState}
                myRef={myRef!} 
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <UsersTable
              myRef={myRef!}
              BranchID={stateSelection.Branch.id!}
              BranchName={stateSelection.Branch.label!}
              LastEdit={lastedit}
              IsLoading={loading}
              Data={Users}
              OnRefresh={() => RefreshUsers()}
              OnDelete={OnDeleteMultipleUserAsync}
              OnDeleteUser={OnDeleteUser}
              OnEdit={SetEditUser}
              ToggleImport={ToggleImport}
            />
          </Grid>

          <Grid item xs={12}>
            {/* {importOpen &&
              <UsersImport BranchId={stateSelection.Branch?.id!} />
            } */}
          </Grid>

        </React.Fragment> :
        <Grid item xs={12}>
          <Alert severity={"info"}>
            To manage users, please select a Branch from the filter above.
          </Alert>
        </Grid>
      }
      <UsersDeleteConfirmation Data={usersToBeDeleted} IsOpen={deleteModalOpen} OnDeleteComplete={RefreshUsers} Toggle={() => { setDeleteModalOpen(!deleteModalOpen) }} />
    </Grid>
  );
};

export default UsersPage;
