import { SettingsPage } from "components/shared/SettingPagesVarious";
import React from "react";
import GeneralDisplaySettings from "./displaySettings.general.Settings";
interface IMulichannelPageProps { }

const GeneralDisplaySettingsPage: React.FC<IMulichannelPageProps> = props => {
  return <SettingsPage Component={GeneralDisplaySettings} Label="General Display" />;
};

export default GeneralDisplaySettingsPage;
