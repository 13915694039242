
import { ThemeOptions } from "@material-ui/core";
import { DeployInfo } from "hooks/GetAppInfo";
import DefaultTheme from "Styles/defaultTheme";


export const DefaultSessionState: SessionState = {
  isLoggedIn: false,
  JwtToken: "",
  WebName: "",
  WebID: "",
  UserId: "",
  UserName: "",
  LoginFailed: false,
  ErrorMessages: [],
  ShowGeneralWaitScreen: false,
  WaitscreenProgress: { Percentage: 0, Label: "" },
  DeeplinkError: false,
  BackendVersion: "",
  Theme: DefaultTheme,
  TransactionIDs: [],
  NotificationOpen: false,
  SessionExpires: undefined,
  IsAdmin: false,
  IsHitchHiker: false,
  DeployInfo: undefined


};
export interface SessionState {
  readonly isLoggedIn: boolean;
  readonly JwtToken: string;
  readonly WebName: string;
  readonly WebID: string;
  readonly UserId: string;
  readonly UserName: string;
  readonly IsHitchHiker: boolean;
  readonly IsAdmin: boolean;
  readonly LoginFailed: boolean;
  readonly ErrorMessages: string[];
  readonly WaitscreenProgress: WaitScreenProgress;
  readonly ShowGeneralWaitScreen: boolean;
  readonly SessionExpires: Date | undefined;

  readonly DeeplinkError: boolean;
  readonly BackendVersion: string;
  readonly Theme: ThemeOptions;
  readonly TransactionIDs: TransactionIDInfo[];
  readonly NotificationOpen: boolean;
  readonly DeployInfo: DeployInfo | undefined
}

export interface TransactionIDInfo {
  TransactionID: string;
  Time: string;
  Label: string;
  Success: boolean;
  Message: string;
}

export interface WaitScreenProgress {
  Percentage: number;
  Label: string;
}


export interface DeepLoginData {
  WebID: string;
  Deeplink: string;
}
