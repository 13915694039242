import React from "react";
export const CalcFormulasIntroHelptext = <div>
    <p>
        <b>Calculation formulas</b>
    </p>

    <ul>
        <li>
            Manage the formulas which will be applied to your calculation rules.
        </li>


    </ul>

</div>

export const CalcFormulasTitleHelpText = <div>
    <p>
        <b>Title</b>
    </p>

    <ul>
        <li>
            A text field to identify a formula. It has no effect in AgentPlus.
        </li>


    </ul>

</div>

export const CalcFormulasCurrencyHelpText = <div>
    <p>
        <b>Currency</b>
    </p>

    <ul>
        <li>
            The currency which will be applied for the formula. You can create calculation currencies in the "Currencies" section to the left.
        </li>


    </ul>

</div>

export const CalcFormulasQsurchargeAffectsServiceFeeHelpText = <div>


    <p>
        <b>Q-Surcharge affects service fee</b>
    </p>

    Controls how the service fee is calculated.
    <ul>
        <li>checked: f(x+q) - (x+q)</li>
        <li>unchecked: f(x) + q - (x+q) = f(x) - x</li>
    </ul>
    Where f denotes the calculation formula,
    q denotes the Q-Surcharge and x the input price.

</div>

export const CalcFormulasTaxAffectsServiceFeeHelpText = <div>
    <p>
        <b>Tax affects service fee</b>
    </p>

    Controls how the service fee is calculated.
    <ul>
        <li>checked: f(x+t) - (x+t)</li>
        <li>unchecked: f(x) + t - (x+t) = f(x) - x</li>
    </ul>
    Where f denotes the calculation formula,
    t denotes the tax and x the input price.

</div>

export const CalcFormulasDisplayCheckBoxesHelpText = <div>
    <p>
        <b>Show calculation result in display</b>
    </p>
</div>

export const CalcFormulasFormulaDefinitionHelpText = <div>
    <p>
        <b>Formula definition</b>
    </p>
    <br />

    <ul>
        <li>You can use an arbitrary mathematical formula in x (the given value of the fare).</li>
        <li>There are simple formulas and piecewise formulas supported. For piecewise formulas see the explanation below.</li>
        <li>The decimal separator is always a point (".")</li>
        <li>In addition to x there’s another predefined variable N, which is set to the number of legs. This can be used to specify amounts which shall be applied per whole-trip instead of per leg.</li>
    </ul>
    <ul>
        <li>
            In general the formula is applicable for all values including the negatives. <br />
        Be aware, that a fare of -100 with an formula  of "x * 1.1" results in -110. <br />
        In case you want to achieve a result of “-90”, you can use the formula “x + 0.1 * abs(x)”, where “abs” is a shorthand for the absolute value of x. <br />
        </li>
    </ul>
    <br />
    <b>Examples for markup / margin calculation</b>

    <p>If you decide to add an 10% markup or margin then choose one of the following formulas:</p>
    <ul>
        <li>markup percentage calculation: x * 1.1</li>
        <li>margin percentage calculation: x / 0.9</li>
    </ul>
    <br />
    <b>About piecewise formulas</b>
    <p>
        A piecewise formula is a formula defined by multiple sub-formulas, each sub-formula applying to a certain interval. <br />

    </p>
    <ul>
        <li>
            For each sub-formula a separate row is used.

    </li>
        <li>
            Each row contains a startvalue, followed by a "::" and a formula.
            In case no startvalue is specified (please see example 2) the startvalue is assumed to be negative infinity.
    </li>
        <li>
            {`For a given x the calculation is done by the sub-formula with the highest startvalue s, which is lower or equal x (s <= x).`}
        </li>

    </ul>
    <p>
        <i>Example 1</i><br />
    100:: x * 1.25 - 30<br />
    200:: x * 1.1<br />
    500:: x * 1.05 + 25<br />
    1000:: x + 75<br />
    </p>
    <p>
        <i>Example 2</i><br />
    :: x * 1.1<br />
    120:: x * 1.2 - 12 <br />
    1000:: x + 188
</p>

</div>

export const CalcFormulasRoundingHelpText = <div>
    <p>
        <b>Rounding</b>
    </p>

    Rounds a number to a specified number of digits (Round to decimals), <br />
    using the following options (Round direction):<br />
    <br />
    <ul>
        <li>
            Up: to always round up (away from zero)
        </li>
        <li>
            Down: to always round down (toward zero)
        </li>
        <li>
            Up/Down: commercial round
        </li>
    </ul>

</div>

