import { all, fork } from "redux-saga/effects";
import { watch_tryDeepLogin } from "./DeepLogin";
import { watch_Logout } from "./Logout";
import { watch_RefreshToken } from "./RefreshToken";
import { watch_tryLogin } from "./TryLogin";

function* sessionSaga() {
  yield all([
    fork(watch_tryLogin),
    fork(watch_tryDeepLogin),
    fork(watch_RefreshToken),
    fork(watch_Logout)

  ]);
}

export default sessionSaga;
