import { SettingsPage } from "components/shared/SettingPagesVarious";
import React from "react";
import DepositsSettings from "./deposits.Settings";


const DepositsPage: React.FC<{}> = props => {
  return <SettingsPage Component={DepositsSettings} Label="Deposits" />;
};

export default DepositsPage;
