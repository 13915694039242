import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { Omit } from "@material-ui/types";
import clsx from "clsx";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation
} from "react-router-dom";

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}
const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4)
  },
  listItem: {
    background: "#e3f2fd"
  },
  selectedItem: {
    background: "white"
  }
}));
function ListItemLink(props: ListItemLinkProps) {
  const classes = useStyles();
  let location = useLocation();
  const { icon, primary, to } = props;
  const isActive = location.pathname.includes(props.to);
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li className={clsx(isActive ? classes.selectedItem : classes.listItem)}>
      <ListItem
        button
        component={renderLink}
        className={classes.nested}
        // selected={isActive}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

export default ListItemLink;
