import { Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";

export interface IDisplaySettingsGettingsStartedProps { }

export default function DisplaySettingsGettingsStarted(
  props: IDisplaySettingsGettingsStartedProps
) {
  return <Grid container>
    <Grid item xs={12}>    <Card>
      <CardHeader title={"Display settings"} />
      <CardContent>
        <Typography variant="body1">
          In this section you can configure the <b>display behavior</b> of AgentPlus.  <br></br>
        You can configure for example what Dateformat AgentPlus should use, which Passengertypes should be available on the query page or which calculation information should be visible. <br></br>
        Visit each section for learn more.

      </Typography>
      </CardContent>
    </Card></Grid>

  </Grid>;
}
