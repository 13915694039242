import { BranchGroupOption, BranchOption } from "WebApiClient";
export interface FilterState {
  Selection: WebLevelSelection;
}
export enum WebLevelEnum {
  Web,
  BranchGroup,
  Branch
}
export const DefaultFilterState: FilterState = {
  Selection: { Level: WebLevelEnum.Web, Branchgroup: null, Branch: null }
};

export interface WebLevelSelection {
  Level: WebLevelEnum;
  Branchgroup: BranchGroupOption | null;
  Branch: BranchOption | null;
}
