import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Grid, Input, LinearProgress, makeStyles, Paper, Slider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { SettingsActions } from "components/shared/CardActions";
import { ErrorMessages } from "components/shared/ErrorMessages";
import { ApplyIndicator, CreateSettingsButton, ISettingsComponentProps, OverriddenIndicator } from "components/shared/SettingPagesVarious";
import { HelpTextButton } from "components/shared/various";
import { WebLevelLabel } from "components/shared/WebLevelLabel";
import { useDepositSettingsClient } from "hooks/useHttpClient";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, CreateSettingsRequest, DepositTransactionType, DepositVersionHistoryModel, SettingsOrder, UpdateDepositFundsModel, UpdateDepositSettingsModel } from "WebApiClient";
import * as HelpTexts from "./deposits.HelpTexts";
const DepositsSettings: React.FC<ISettingsComponentProps> = props => {
  const { ReadOnly, WebLevel, Id, LevelName } = props;
  const client = useDepositSettingsClient();
  const dispatch = useDispatch();
  const [Settings, setSettings] = useState<
    UpdateDepositSettingsModel | undefined
  >(undefined);
  const [latestDepositVersion, setLatestDepositVersion] = useState<
    DepositVersionHistoryModel | undefined
  >(undefined);





  const [loading, setLoading] = useState(true);
  const [validationState, setValidationState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);
  const [serverError, setServerError] = useState(false);
  useEffect(() => {
    GetSettings(WebLevel, Id);
  }, [WebLevel, Id]);

  function OnUpdate(quickUpdate?: UpdateDepositSettingsModel) {
    let updateModel: UpdateDepositSettingsModel | undefined = undefined;
    if (quickUpdate) {
      updateModel = { ...quickUpdate };
    }
    else {
      if (Settings) {

        updateModel = { ...Settings };
      }
    }
    if (updateModel) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .update(updateModel)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully updated.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be updated.";
          }
          if (response.serverError) {
            serverSideError = true;
          } else {
            setValidationState(response.validationResult);
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be updated. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Update Deposit settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Deposit settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function Reload() {
    GetSettings(WebLevel, Id);
  }

  function CreateSettings() {
    if (Id) {
      const createRequest: CreateSettingsRequest = {
        id: Id,
        sortOrder: WebLevel
      };
      setLoading(true);
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      client
        .create(createRequest)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully created.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be created.";
          }
          if (response.serverError) {
            serverSideError = true;
          }
        })
        .catch(e => {
          serverSideError = true;
          console.error(e);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be created. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Create deposit settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Deposit settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setLoading(false);
          if (transactionSuccess) {
            Reload();
          }
        });
    }
  }

  useEffect(() => {
    if (Settings) {
      if (Settings.isEnabled) {
        client.getBudgetHistory(Settings.id, null).then(e => {
          if (e) {
            setLatestDepositVersion(e)
          }
        })
      }
    }
  }, [Settings])

  function GetSettings(order: SettingsOrder, Id: string | null) {
    setLoading(true);

    client
      .get(order, Id)
      .then(settings => {
        setSettings(settings);
        if (settings !== null) {
          props.OnNotifyIfSettingsExist(true);
        } else {
          props.OnNotifyIfSettingsExist(false);
        }

      })
      .catch(e => {
        console.error(e);
        setServerError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function DeleteSettings() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .delete(Settings?.id!)
        .then(response => {
          if (response.success) {
            transactionMsg = "successfully deleted.";
            transactionSuccess = true;
          } else {
            transactionMsg = `could not be deleted. ${response.errorMessage}`;
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg = "could not be deleted. A server error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Delete Deposit settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Deposit settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  const userNameError: string | undefined = validationState
    ? validationState.properyErrors!["username"]
    : undefined;

  const PasswordError: string | undefined = validationState
    ? validationState.properyErrors!["password"]
    : undefined;


  function OnSetMaxBudget(value: number) {
    if (Settings) {

      setSettings({ ...Settings, maxBudget: value, });
    }

  }



  return (
    <Box boxShadow={ReadOnly ? undefined : 3}>
      <Card style={{ opacity: ReadOnly ? 0.5 : 1 }}>
        <CardHeader
          title={
            <span>
              {props.Applies && (
                <span>
                  <ApplyIndicator />
                </span>
              )}
              {props.IsOverwritten && (
                <span>
                  <OverriddenIndicator />
                </span>
              )}
              <span>Deposit settings for </span>
              <WebLevelLabel Level={WebLevel} Name={LevelName} />{" "}
              {/* {props.IsOverwritten ? "Overwritten" : ""} */}
            </span>
          }
        />
        {!loading && (
          <CardContent>
            {Settings ? (
              <Grid container spacing={2}>
                {serverError && <ErrorMessages ServerError={true} />}
                {validationState && validationState.errorSummary && (
                  <ErrorMessages Messages={validationState.errorSummary} />
                )}

                <Grid container item xs={12} spacing={6}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Settings.isEnabled}
                          onChange={() => {

                            OnUpdate({ ...Settings, isEnabled: !Settings.isEnabled });
                          }

                          }
                        />
                      }

                      label={<span>Enable budgeting <HelpTextButton HelpText={HelpTexts.IsEnabled} /></span>}
                    />
                  </Grid>
                  {Settings.isEnabled ?


                    <Fragment>
                      {latestDepositVersion &&
                        <DepositVersionManagement id={Settings.id} latestVersion={latestDepositVersion} OnRefresh={Reload} />
                      }
                    </Fragment>


                    :
                    <Grid item xs={12}>
                      <Alert severity="info">Enable budgeting to set a deposit value.</Alert>
                    </Grid>
                  }


                </Grid>





              </Grid>
            ) : (
              <CreateSettingsButton
                OnClick={CreateSettings}
                Readonly={props.ReadOnly}
              />
            )}
          </CardContent>
        )}
        {loading && (
          <CardContent>
            <LinearProgress />
          </CardContent>
        )}
        {!ReadOnly && Settings && (
          <SettingsActions
            OnDelete={DeleteSettings}
            OnReset={Reload}
          // OnSave={OnUpdate}
          />
        )}
      </Card>
    </Box>
  );
};

const DepositVersionManagement: React.FC<{ id: string, latestVersion: DepositVersionHistoryModel, OnRefresh: () => void }> = ({ id, latestVersion, OnRefresh }) => {
  const dispatch = useDispatch();

  const client = useDepositSettingsClient();
  const [loading, setLoading] = useState(false);
  const [maxVersion, setMaxVersion] = useState(1);
  const [currentVersion, setCurrentVersion] = useState(1);
  const [response, setResponse] = useState<DepositVersionHistoryModel | undefined>(undefined);

  const [updateDepositFundsModel, setUpdateDepositFundsModel] = useState<
    UpdateDepositFundsModel
  >({ createNewDepositVersion: false, depositID: id, transferRemainingFunds: true, value: 0 });
  function OnSetCurrentBudget() {

    client.grantBudget(updateDepositFundsModel).then((e) => {
      let transaction: TransactionIDInfo = {
        Label: `Grant deposit funds`,
        Success: true,
        Time: new Date().toString(),
        TransactionID: e.transactionId,
        Message: `Deposit updated.`
      };
      dispatch(Session_Reducer_PushTransactionID(transaction));
      OnRefresh();
    })

  }
  function Fetch(version?: number) {
    setLoading(true);
    client.getBudgetHistory(id, version).then((r) => {
      if (!version) {
        setMaxVersion(r.version);
      }
      setCurrentVersion(r.version);
      setResponse(r);
    }).catch(() => {
      setResponse(undefined);

    }).finally(() => {
      setLoading(false);
    })
  }
  useEffect(() => { Fetch() }, [])


  function DisableSubmitButton(): boolean {
    let result = false;
    if (Number.isNaN(updateDepositFundsModel.value)) {
      result = true;
    }
    else {
      if (!updateDepositFundsModel.createNewDepositVersion) {
        if (updateDepositFundsModel.value === 0) {
          result = true;
        }
        else {

          result = updateDepositFundsModel.value + latestVersion.remainingSaldo <= 0;
        }
      }
      else {
        let v = updateDepositFundsModel.value;
        if (updateDepositFundsModel.transferRemainingFunds) {
          v += latestVersion.remainingSaldo;
        }
        if (v <= 0) {
          result = true;
        }
      }

    }
    return result;
  }

  return <Fragment>

    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Manage deposit funds"></CardHeader>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Alert severity="info">
                  <AlertTitle>How it works</AlertTitle>
                  Add funds by entering the amount below and confirm the amount.<br></br>
                  After granting a deposit funds, you can trace how the deposit was spent in the table below. <br></br>
                  If you want to reset the transaction history, you can also create a new version. <br></br>
                </Alert>
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <ButtonGroup>
                    <Button
                      size="small"
                      color="secondary"

                      variant={!updateDepositFundsModel.createNewDepositVersion ? "contained" : "outlined"}
                      onClick={() => {
                        if (updateDepositFundsModel.createNewDepositVersion) {
                          setUpdateDepositFundsModel({ ...updateDepositFundsModel, createNewDepositVersion: false });
                        }
                      }}>Update current deposit</Button>
                    <Button size="small" color="secondary" variant={updateDepositFundsModel.createNewDepositVersion ? "contained" : "outlined"}

                      onClick={() => {
                        if (!updateDepositFundsModel.createNewDepositVersion) {
                          setUpdateDepositFundsModel({ ...updateDepositFundsModel, createNewDepositVersion: true, value: updateDepositFundsModel.value < 0 ? 0 : updateDepositFundsModel.value });
                        }
                      }}
                    >Create new deposit version</Button>
                  </ButtonGroup>
                </Grid>
                <Grid item>
                  <ButtonGroup disabled={updateDepositFundsModel.value === 0 || updateDepositFundsModel.createNewDepositVersion} onClick={() => { setUpdateDepositFundsModel({ ...updateDepositFundsModel, value: updateDepositFundsModel.value * -1 }) }}>
                    <Button size="small" color="secondary" variant={updateDepositFundsModel.value < 0 ? "outlined" : "contained"}>Add</Button>
                    <Button size="small" color="secondary" variant={updateDepositFundsModel.value < 0 ? "contained" : "outlined"}>Subtract</Button>
                  </ButtonGroup>


                </Grid>
                <Grid item xs={4}>
                  <Input

                    value={updateDepositFundsModel.value}
                    margin="dense"

                    onChange={(e) => {
                      let val = e.target.value;
                      setUpdateDepositFundsModel({ ...updateDepositFundsModel, value: parseFloat(val) })
                    }}
                    onBlur={(e) => {
                      let newVal = parseFloat(e.target.value) || 0;
                      if (updateDepositFundsModel.createNewDepositVersion) {
                        if (newVal < 0) {
                          newVal = 0;
                        }
                      }
                      setUpdateDepositFundsModel({ ...updateDepositFundsModel, value: newVal })
                    }}

                    inputProps={{
                      step: 100,
                      min: updateDepositFundsModel.createNewDepositVersion ? 0 : undefined,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>

                {updateDepositFundsModel.createNewDepositVersion &&
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={updateDepositFundsModel.transferRemainingFunds}
                          onChange={() => {

                            setUpdateDepositFundsModel({ ...updateDepositFundsModel, transferRemainingFunds: !updateDepositFundsModel.transferRemainingFunds });
                          }

                          }
                        />
                      }

                      label={<span>Transfer also remaining {latestVersion.remainingSaldo} to new version</span>}
                    />
                  </Grid>
                }
                <Grid item xs={12}>
                  <Button disabled={DisableSubmitButton()} fullWidth onClick={OnSetCurrentBudget} color="primary" variant="contained">
                    {updateDepositFundsModel.createNewDepositVersion ?
                      <span>
                        Create new deposit version with {updateDepositFundsModel.transferRemainingFunds ? (updateDepositFundsModel.value + latestVersion.remainingSaldo) : updateDepositFundsModel.value}
                      </span>
                      : <span>
                        Confirm new balance: {updateDepositFundsModel.value + latestVersion.remainingSaldo}
                      </span>
                    }

                  </Button>
                </Grid>

              </Grid>
            </Grid>
          </CardContent>


          {/* <Grid item xs={12}>
                        <Button color="primary" variant="contained" onClick={OnResetCurrentBudget}>Add default maximum budget {Settings.maxBudget}</Button>
                      </Grid>
                      <Grid item xs={12}>
                        <InputSlider value={Settings.maxBudget} OnChange={OnSetMaxBudget} label="Default deposit" min={0} max={100000} />
                      </Grid> */}
        </Card>

      </Grid>
      {loading ?
        <Grid item xs={12}><LinearProgress></LinearProgress></Grid> :
        <Fragment>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs="auto">
                <Typography variant="h6">Deposit version {currentVersion} / {maxVersion}</Typography>

              </Grid>
              <Grid item xs="auto">

                <Button disabled={currentVersion === 1} size="small" variant="contained" color="primary" onClick={() => { Fetch(currentVersion - 1) }}>{"<"}</Button>


              </Grid>
              <Grid item xs="auto">
                <Button disabled={currentVersion >= maxVersion} size="small" variant="contained" color="primary" onClick={() => { Fetch(currentVersion + 1) }}>{" >"}</Button>

              </Grid>
            </Grid>
          </Grid>

          {response ?
            <Fragment>
              <Grid item xs={12}>
                Funds remaining {response.remainingSaldo.toFixed(2)} / {response.maxSaldo.toFixed(2)}
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>

                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Type</TableCell>
                        <TableCell align="right">Value</TableCell>
                        <TableCell align="right">Title</TableCell>
                        <TableCell align="right">Filekey</TableCell>
                        <TableCell align="right">BookingID</TableCell>
                        <TableCell align="right">User</TableCell>
                        <TableCell align="right">Details</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {response.transactionHistory.map((row, index) => (
                        <TableRow key={index}>

                          <TableCell style={{ color: row.value > 0 ? "green" : "red" }} align="right">
                            {row.type === DepositTransactionType.CounterEntry &&
                              <span>Counter Entry</span>
                            }
                            {row.type === DepositTransactionType.Entry &&
                              <span>Entry</span>
                            }
                            {row.type === DepositTransactionType.GrantBudget &&
                              <span>Grant budget</span>
                            }
                            {row.type === DepositTransactionType.SubtractBudget &&
                              <span>Subtract budget</span>
                            }
                          </TableCell>
                          <TableCell style={{ color: row.value > 0 ? "green" : "red" }} align="right">{row.value > 0 ? "+" : ""}{row.value.toFixed(2)}</TableCell>
                          <TableCell align="right">{row.title}</TableCell>
                          <TableCell align="right">{row.filekey}</TableCell>
                          <TableCell align="right">{row.bookingID}</TableCell>
                          <TableCell align="right">{row.responsibleUser}</TableCell>
                          <TableCell align="right">{row.details}</TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                    <Table>

                    </Table>
                  </Table>
                </TableContainer>
              </Grid>
            </Fragment>
            :
            <Grid item xs={12}>No data available</Grid>
          }
        </Fragment>
      }

    </Grid>
  </Fragment>
}

const useStyles = makeStyles({
  // root: {
  //   width: 250,
  // },
  // input: {
  //   width: 42,
  // },
});
const InputSlider: React.FC<{ value: number, OnChange: (val: number) => void, label: string, min: number, max: number }> = ({ OnChange, value, label, max, min }) => {

  const sliderStep = 100;

  const classes = useStyles();
  // const [value, setValue] = React.useState<number | string | Array<number | string>>(30);

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    OnChange(Number(newValue));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setValue(event.target.value === '' ? '' : Number(event.target.value));
    OnChange(event.target.value === '' ? 0 : Number(event.target.value));

  };

  const handleBlur = () => {
    if (value < 0) {
      OnChange(0);
    } else if (value > max) {
      OnChange(max);
    }
  };

  return (
    <div >
      <Typography gutterBottom>
        {label}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        {/* <Grid item>
          <VolumeUp />
        </Grid> */}
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            getAriaValueText={() => value.toString()}
            min={min}
            max={max}
            step={sliderStep}
          />
        </Grid>
        <Grid item>
          <Input

            value={value}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: sliderStep,
              min: min,
              max: max,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
export default DepositsSettings;
