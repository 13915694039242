import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";
import GettingStarted from "./import.GettingStarted";
import ImportMasterDataBranches from "./masterdata/import.masterdata.Branches";
import ImportMasterDataUsers from "./masterdata/import.masterdata.Users";



const ImportRoutes: React.FC<{}> = props => {
  let { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/gettingstarted`} />
      </Route>
      <Route exact path={`${path}/gettingstarted`} component={GettingStarted} />
      <Route exact path={`${path}/branches`} component={ImportMasterDataBranches} />
      <Route exact path={`${path}/users`} component={ImportMasterDataUsers} />

    </Switch>
  );
};

export default ImportRoutes;
