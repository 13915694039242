import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";
import UniversalTempDataPage from "./customerprofiles/customerprofiles.Page";
import UniversalTempDataGettingStarted from "./universalTempData.GettingStarted";



interface IUniversalTempDataRoutesProps { }
const UniversalTempDataRoutes: React.FC<IUniversalTempDataRoutesProps> = props => {
  let { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/gettingstarted`} />
      </Route>
      <Route exact path={`${path}/gettingstarted`} component={UniversalTempDataGettingStarted} />
      <Route exact path={`${path}/customerprofiles/list`} component={UniversalTempDataPage} />
    </Switch>
  );
};

export default UniversalTempDataRoutes;
