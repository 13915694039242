import { Button, CardActions, useTheme } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SaveIcon from "@material-ui/icons/Save";
import React from "react";
interface ISettingsActionsProps {
    OnSave?: () => void;
    OnReset: () => void;
    OnDelete: () => void;
}

export const SettingsActions: React.FC<ISettingsActionsProps> = props => {
    const theme = useTheme();
    return (
        <CardActions>
            {props.OnSave &&
                <Button
                    color="primary"
                    variant="contained"
                    onClick={props.OnSave}
                    startIcon={<SaveIcon />}
                >
                    Save
                </Button>
            }
            <Button
                color="primary"
                variant="contained"
                onClick={props.OnReset}
                startIcon={<RotateLeftIcon />}
            >
                Reset
            </Button>
            <Button
                color="primary"
                variant="contained"
                onClick={props.OnDelete}
                startIcon={<DeleteIcon />}
            >
                Delete
            </Button>
        </CardActions>
    );
};