import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";
import ConsolidatorCalculationSettings from "./consolidatorCalculation/consolidatorCalculation.Page";
import CalcCurrenciesPage from "./currencies/currencies.Page";
import CalcFormulasPage from "./formulas/formulas.Page";
import CalcRulesPage from "./rules/rules.page";

interface ICalculationProps { }
const Calculation: React.FC<ICalculationProps> = props => {
  let { path, url } = useRouteMatch();
 
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/gettingstarted`} />
      </Route>
      <Route exact path={`${path}/rules`} component={CalcRulesPage} />
      <Route exact path={`${path}/formulas`} component={CalcFormulasPage} />
      <Route exact path={`${path}/currencies`} component={CalcCurrenciesPage} />
      <Route exact path={`${path}/consolidator`} component={ConsolidatorCalculationSettings} />
    </Switch>
  );
};

export default Calculation;
