import * as H from 'history';
import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';

export interface RouteGuard {
  routeCondition: boolean;
  redirectTo: H.LocationDescriptor;
}
export interface ConditionalRouteProps extends RouteProps {
  guards: RouteGuard[];
}

export class ConditionalRoute extends React.Component<ConditionalRouteProps> {
  public render() {
    // Extract RouteProps without component property to rest.
    const { component: Component, guards, ...rest } = this.props;
    return <Route {...rest} render={this.renderFn} />
  }

  private renderFn = (renderProps: RouteComponentProps<any>) => {
    const unsatisfiedConstraints = this.props.guards.filter(e => e.routeCondition === false);
    if (unsatisfiedConstraints.length > 0) {
      const redirect = unsatisfiedConstraints[0].redirectTo;
      return <Redirect to={redirect} />;
    }
    else {
      const { component: Component } = this.props; // JSX accepts only upprcase.
      if (!Component) {
        return null;
      }
      return <Component {...renderProps} />

    }

  };
}

export function connectConditionalRoute<S>(mapStateToProps: MapStateToPropsParam<ConditionalRouteProps, RouteProps, S>) {
  return connect<ConditionalRouteProps, {}, RouteProps, S>(mapStateToProps)(ConditionalRoute);
}