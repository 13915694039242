import { Avatar, Button, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import TuneIcon from '@material-ui/icons/Tune';
import { HelpTextButton } from "components/shared/various";
import React from "react";
import { CabinClassInput, CustomerProfileSegment, CustomerProfilesFlightDetails } from "WebApiClient";
import { CustomerProfileDataCabinClasses } from "./CustomerProfileDataCabinClasses";
import { CustomerProfileDataSegments } from "./CustomerProfileDataSegments";
import { useStyles } from "./customerprofiles.Details";
import * as HelpTexts from "./customerprofiles.HelpTexts";

export const CustomerProfilesFlightDetailsComponent: React.FC<{ data: CustomerProfilesFlightDetails | undefined; OnUpdate: (data: CustomerProfilesFlightDetails | undefined) => void; }> = props => {
    const classes = useStyles();
    return <Card elevation={4} className={classes.lightBlueBackground}>
        <CardHeader
            avatar={<Avatar className={props.data ? classes.activeAvatar : ""}><SettingsApplicationsIcon /></Avatar>}
            title={<Typography variant="h5">Flight details</Typography>}
            action={props.data ? <Button onClick={() => { props.OnUpdate(undefined); }}
                startIcon={<DeleteIcon />} color="secondary">remove</Button> : null}
        />
        <CardContent>
            {props.data ?
                <CustomerProfilesFlightDetailsDefinesComponent data={props.data} OnUpdate={props.OnUpdate} />
                : <Button variant="contained" color="primary" onClick={() => { props.OnUpdate({ cabinClasses: undefined, directFlightsOnly: false, excludeAirlines: undefined, includeAirlines: undefined, segments: undefined, targetMultiChannelBranch: "", targetMultiChannelBranchgroup: "" }); }}>Add flight details</Button>}

        </CardContent>
    </Card>;
};

const CustomerProfilesFlightDetailsDefinesComponent: React.FC<{ data: CustomerProfilesFlightDetails; OnUpdate: (data: CustomerProfilesFlightDetails) => void; }> = props => {
    function OnUpdateSegments(segments: CustomerProfileSegment[] | undefined) {
        props.OnUpdate({ ...props.data, segments: segments });
    }
    function OnUpdateCabinclasses(data: CabinClassInput | undefined) {
        props.OnUpdate({ ...props.data, cabinClasses: data });
    }
    const classes = useStyles();
    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <Card>
                <CardHeader
                    avatar={<Avatar className={classes.activeAvatar}><TuneIcon /></Avatar>}
                    title={<Typography variant="h5">General</Typography>}
                />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={props.data.directFlightsOnly}
                                    onChange={() => { props.OnUpdate({ ...props.data, directFlightsOnly: !props.data.directFlightsOnly }); }}
                                    color="primary" />}
                                label="Direct flights only" />
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item>
                                <TextField
                                    onBlur={e => { props.OnUpdate({ ...props.data, includeAirlines: e.target.value.toUpperCase().trim().split(" ").filter(e => e.length > 0) }); }}
                                    value={props.data.includeAirlines ? props.data.includeAirlines.join(" ") : ""}
                                    onChange={e => { props.OnUpdate({ ...props.data, includeAirlines: e.target.value.split(" ") }); }}
                                    label="Include carriers"
                                    InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.IncludeCarrierHelpText} /> }}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    onBlur={e => { props.OnUpdate({ ...props.data, excludeAirlines: e.target.value.toUpperCase().trim().split(" ").filter(e => e.length > 0) }); }}
                                    value={props.data.excludeAirlines ? props.data.excludeAirlines.join(" ") : ""}
                                    onChange={e => { props.OnUpdate({ ...props.data, excludeAirlines: e.target.value.split(" ") }); }}
                                    label="Exclude carriers"
                                    InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.ExcludeCarrierHelpText} /> }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container spacing={4}>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    value={props.data.targetMultiChannelBranchgroup ? props.data.targetMultiChannelBranchgroup : ""}
                                    onChange={e => { props.OnUpdate({ ...props.data, targetMultiChannelBranchgroup: e.target.value }); }}
                                    label="Target MCRE Branchgroup"
                                    InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.TargetMultichannelBranchgroupHelpText} /> }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    value={props.data.targetMultiChannelBranch ? props.data.targetMultiChannelBranch : ""}
                                    onChange={e => { props.OnUpdate({ ...props.data, targetMultiChannelBranch: e.target.value }); }}
                                    label="Target MCRE Branch"
                                    InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.TargetMultichannelBranchHelpText} /> }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

        </Grid>
        <Grid item xs={12}>

            <CustomerProfileDataSegments data={props.data.segments} OnUpdate={OnUpdateSegments} />
        </Grid>
        <Grid item xs={12}>
            <CustomerProfileDataCabinClasses data={props.data.cabinClasses} OnUpdate={OnUpdateCabinclasses} Title="Cabin classes (default for all segments)" />
        </Grid>

    </Grid>
}
