export function ScrollToTop(): void {
    document.getElementById("top-anchor")!.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

export function ToBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
}