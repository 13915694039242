import { Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import * as React from "react";

export interface ISalesPrefGettingsStartedProps { }

export default function SalesPrefGettingStarted(
  props: ISalesPrefGettingsStartedProps
) {
  return <Grid container>
    <Grid item xs={12}>
      <Card>
        <CardHeader title={"Sales preference"} />
        <CardContent>
          <Typography variant="body1">
            <b>The sales preference needs to be activated for the FlightApi user wich is used. Check the Multichannel configuration page for information about your FlightApi User.</b> <br></br>
        With sales preference, you can target fares in the available fares query with Rules, which you can create in the "Rules" section. <br></br>
        A sales preference rule will then execute a Sales preference Action, which you can configurei n the "Action" section. <br></br>
        With a sales preference action you can rename the fare type of a fare or delete the fare from the response. <br></br>
        You can also set a target priority, which will affect how the action should be prioritized if multiple actions apply.


      </Typography>
        </CardContent>
      </Card>

    </Grid>
  </Grid>;
}
