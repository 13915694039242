import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import WebLevelSelect from "components/general/WebLevelSelect";
import { useBranchClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, BookingAddressBehavior, UpdateBranchModel } from "WebApiClient";
import BranchEditComponent from "./branch.Details";
import BranchesTable from "./branches.Table";
interface IBranchesTableProps { }
const ActionTitle = "Branch";
export const BranchesPage: React.FC<IBranchesTableProps> = props => {
  const stateSelection = useSelector((state: State) => state.Filter.Selection);
  const branchClient = useBranchClient();
  const dispatch = useDispatch();

  const selectedBranchgroup = stateSelection.Branchgroup;

  const [lastedit, setlastedit] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [editBranch, setEditBranch] = useState<UpdateBranchModel | undefined>(
    undefined
  );

  const [editBranchErrorState, setEditBranchErrorState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);

  const [branches, setBranches] = useState<UpdateBranchModel[]>([]);

  useEffect(() => {
    if (selectedBranchgroup) {
      RefreshBranches(selectedBranchgroup.id!);
    }
  }, [selectedBranchgroup]);

  function RefreshBranches(branchgroupID: string) {
    setIsLoading(true);
    branchClient
      .getByBranchgroup(branchgroupID)
      .then(response => {
        setBranches(response);
      })
      .catch(() => { })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function DeleteBranchAsync(id: string) {

    let transactionSuccess = false;
    let transactionTime = new Date().toString();
    let transactionId = "";
    let transactionMsg = "MSG NOT SET";
    let serverSideError = false;
    branchClient
      .delete(id)
      .then(e => {
        transactionId = e.transactionId;
        if (e.success) {
          transactionSuccess = true;
          transactionMsg = "Branch deleted.";
        } else {
          transactionMsg = `Branch could not be deleted. ${e.errorMessage}`;
        }
      })
      .catch(e => {
        console.error(e);
        serverSideError = true;
      })
      .finally(() => {
        if (serverSideError) {
          transactionMsg = `Branch could not be deleted. An server-side error has occured.`;
        }
        let transaction: TransactionIDInfo = {
          Label: `Delete branchgroup`,
          Success: transactionSuccess,
          Time: transactionTime,
          TransactionID: transactionId,
          Message: transactionMsg
        };
        dispatch(Session_Reducer_PushTransactionID(transaction));
        RefreshBranches(selectedBranchgroup!.id!);
      });
  }

  function UpdateBranchAsync(branch: UpdateBranchModel) {
    const isUpdate = branch.id !== undefined;
    const actionTypeLabel = isUpdate ? "updated" : "created";
    document.getElementById("top-anchor")!.scrollIntoView({ behavior: 'smooth', block: 'center' });

    let transactionSuccess = false;
    let transactionTime = new Date().toString();
    let transactionId = "";
    let transactionMsg = "MSG NOT SET";
    let serverSideError = false;

    branchClient
      .update(branch)
      .then(result => {
        setEditBranchErrorState(result.validationResult);
        transactionSuccess = result.success;
        transactionId = result.transactionId;
        if (result.success === true) {
          transactionMsg = `Branch successfully ${actionTypeLabel}.`;
          CloseEditBranchgroup();
        } else {
          transactionMsg = `Branch could not be ${actionTypeLabel}.`;
          if (result.serverError) {
            serverSideError = true;
          }
        }
        setlastedit(result.resultId);
      })
      .catch(e => {
        console.error(e);
        serverSideError = true;
      })
      .finally(() => {
        if (serverSideError) {
          transactionMsg = `Branch could not be ${actionTypeLabel}. An server-side error has occured.`;
        }
        let transaction: TransactionIDInfo = {
          Label: `${isUpdate ? "Update" : "Create"} Branch`,
          Success: transactionSuccess,
          Time: transactionTime,
          TransactionID: transactionId,
          Message: transactionMsg
        };
        dispatch(Session_Reducer_PushTransactionID(transaction));
        RefreshBranches(selectedBranchgroup!.id!);
      });
  }

  function SetEditBranchgroup(data: UpdateBranchModel | undefined) {
    setEditBranchErrorState(undefined);
    if (data) {
      setEditBranch(data);
    } else {
      const newBg: UpdateBranchModel = {
        id: undefined,
        branchGroupId: selectedBranchgroup!.id!,
        bookingAddressBehavior: BookingAddressBehavior.PrefillWithBranchAdress,
        bookingConfirmationEmail: "",
        name: "",
        branchNumberPrimary: "",
        branchNumberSecondary: "",
        careOf: "",
        city: "",
        country: "DE",
        email: "",
        emergencyPhone: "",
        emergencyPhoneBehavior: BookingAddressBehavior.PrefillWithBranchAdress,
        fax: "",
        streetAddress1: "",
        streetAddress2: "",
        telephone: "",
        webfarePassword: "",
        webfarePasswordBehavior: BookingAddressBehavior.PrefillWithBranchAdress,
        website: "",
        zip: "",
        isActive: true
      };
      setEditBranch(newBg);
    }
  }

  function CloseEditBranchgroup() {
    setEditBranchErrorState(undefined);
    setEditBranch(undefined);
  }

  return (

    <Grid container spacing={3}>
      <Grid item xs={12}>
        <WebLevelSelect IsSettingsPage={false} ShowWebButton={false} ShowBranchButton={false} />
      </Grid>
      {selectedBranchgroup ? (

        <React.Fragment>
          {editBranch && (
            <Grid item xs={12}>
              <BranchEditComponent
                Data={editBranch}
                OnSave={UpdateBranchAsync}
                OnCancel={CloseEditBranchgroup}
                ValidationResult={editBranchErrorState}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <BranchesTable
              BranchGroupID={selectedBranchgroup.id!}
              BranchgroupName={selectedBranchgroup.label!}
              LastEdit={lastedit}
              IsLoading={loading}
              Data={branches}
              OnRefresh={() => RefreshBranches(selectedBranchgroup!.id!)}
              OnDelete={DeleteBranchAsync}
              OnEdit={SetEditBranchgroup}
            />
          </Grid>
        </React.Fragment>
      ) : (
        <Grid item xs={12}>
          <Alert severity={"info"}>
            To manage Branches, please select a Branchgroup from the filter above.
          </Alert>
        </Grid>
      )}
    </Grid>

  );
};

export default BranchesPage;

