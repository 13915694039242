import React from "react";
export const CalcCurrenciesIntroHelptext = <div>
    <p>
        <b>Calculation currencies</b>
    </p>

    <ul>
        <li>
            Manage the currencies for for calculation formulas.
        </li>


    </ul>

</div>

export const CalcCurrenciesCodeHelptext = <div>
    <p>
        <b>Currency code</b>
    </p>

    <ul>
        <li>
            The 3 letter code of a currency.
        </li>


    </ul>

</div>

export const CalcCurrenciesNameHelptext = <div>
    <p>
        <b>Currency name</b>
    </p>

    <ul>
        <li>
            A text field to identify a currency. It has no effect in AgentPlus.
        </li>


    </ul>

</div>

export const CalcCurrenciesDecimalPlacesHelptext = <div>
    <p>
        <b>Decimal places</b>
    </p>

    <ul>
        <li>
            Sets the decimal places for rounding.
        </li>


    </ul>

</div>

