import React from "react";
import { Card, CardContent, CardHeader, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography, Button, CardActions } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { InvariantDateInput } from "components/shared/inputs/invariantDateInput";
import { defaultFrequentFlyerCards } from "./customerprofiles.Details";
import { AgentGenderEnum, CustomerProfilePassenger } from "WebApiClient";
import { CustomerProfileFrequentFlyerCard } from "./CustomerProfileFrequentFlyerCard";

export const CustomerProfileDataPassenger: React.FC<{ globalValidationTrigger?: any, setglobalValidationTrigger?: any, errorTrigger?: any, Fulldata?: any, data: CustomerProfilePassenger; label: string; index: number; OnUpdate: (data: CustomerProfilePassenger) => void; OnRemove: () => void; setbuttonDB?: any }> = props => {
    const { dateOfBirth, firstName, lastName, middleName, title, gender, frequentFlyerCards } = props.data;
    function OnAddPassenger() {
        let olds = frequentFlyerCards;
        olds.push({ ...defaultFrequentFlyerCards });
        props.OnUpdate({ ...props.data, frequentFlyerCards: olds });
    }


    function OnEditPassenger(seg: any, index: number) {
        let olds = [...frequentFlyerCards];
        olds[index] = seg;
        props.OnUpdate({ ...props.data, frequentFlyerCards: olds });
    }

    function OnRemovePassenger(index: number) {
        let olds = [...frequentFlyerCards];
        olds.splice(index, 1);
        props.OnUpdate({ ...props.data, frequentFlyerCards: olds });
    }


    return <Card elevation={6}>
        <CardHeader
            // avatar={<Avatar>{props.label} #{props.index}</Avatar>}
            title={<Typography variant="h5">{props.label} #{props.index}</Typography>}
            action={<IconButton onClick={() => { props.OnRemove(); }}
                color="secondary"><DeleteIcon /></IconButton>} />
        <CardContent>
            <Grid container spacing={2}>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={2}>
                        <TextField
                            value={title ? title : ""}
                            onChange={e => props.OnUpdate({
                                ...props.data,
                                title: e.target.value
                            })}
                            label="Title"
                            error={props.errorTrigger && (title?.length ? (title?.length > 1 && title?.length < 7 ? false : true) : true)}
                            helperText={`${props.errorTrigger ? (title ? (title?.length > 1 && title?.length < 7 ? '' : 'Character should be between 2 - 6') : 'This Field is required') : ''}`}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel>Gender</InputLabel>
                            <Select value={gender} onChange={e => props.OnUpdate({ ...props.data, gender: e.target.value as AgentGenderEnum })}>
                                <MenuItem value={AgentGenderEnum.Unknown}>
                                    Unknown
                                </MenuItem>
                                <MenuItem value={AgentGenderEnum.Male}>
                                    Male
                                </MenuItem>
                                <MenuItem value={AgentGenderEnum.Female}>
                                    Female
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        value={firstName ? firstName : ""}
                        onChange={e => props.OnUpdate({
                            ...props.data,
                            firstName: e.target.value
                        })}
                        label="First name"
                        error={props.errorTrigger && (firstName?.length ? (firstName?.length > 2 && firstName?.length < 51 ? false : true) : true)}
                        helperText={`${props.errorTrigger ? (firstName ? (firstName?.length > 2 && firstName?.length < 51 ? '' : 'Character should be between 3 - 50') : 'This Field is required') : ''}`}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        value={middleName ? middleName : ""}
                        onChange={e => props.OnUpdate({
                            ...props.data,
                            middleName: e.target.value
                        })}
                        label="Middle name"
                        error={props.errorTrigger && (middleName?.length ? (middleName?.length > 2 && middleName?.length < 51 ? false : true) : true)}
                        helperText={`${props.errorTrigger ? (middleName ? (middleName?.length > 2 && middleName?.length < 51 ? '' : 'Character should be between 3 - 50') : 'This Field is required') : ''}`}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        value={lastName ? lastName : ""}
                        onChange={e => props.OnUpdate({
                            ...props.data,
                            lastName: e.target.value
                        })}
                        label="Last name"
                        error={props.errorTrigger && (lastName?.length ? (lastName?.length > 2 && lastName?.length < 51 ? false : true) : true)}
                        helperText={`${props.errorTrigger ? (lastName ? (lastName?.length > 2 && lastName?.length < 51 ? '' : 'Character should be between 3 - 50') : 'This Field is required') : ''}`}
                    />
                </Grid>
                <Grid item xs={4} style={{ display: 'grid' }}>
                    <InvariantDateInput date={dateOfBirth} helpButton={undefined} label="Date of birth" OnUpdate={(e) => {
                        props.OnUpdate({
                            ...props.data,
                            dateOfBirth: e
                        });
                    }} />
                    {props.errorTrigger ? (dateOfBirth == null) && <span style={{ paddingLeft: '14px', fontSize: '12px', color: 'red' }}>This Field is required</span> : ''}
                </Grid>
                {frequentFlyerCards.map((val, i) => <Grid xs={12} item key={i}>
                    <CustomerProfileFrequentFlyerCard errorTrigger={props.errorTrigger!} frequentFlyerCardsData={frequentFlyerCards!} Fulldata={props.Fulldata!} setbuttonDB={props.setbuttonDB!} index={(i + 1)} OnUpdate={(e) => { OnEditPassenger(e, i); }} data={val} OnRemove={() => { OnRemovePassenger(i); }} />
                </Grid>
                )}
            </Grid>
        </CardContent>
        <CardActions>
            <Button onClick={OnAddPassenger} variant="contained" color="primary" startIcon={<AddCircleOutlineIcon />}>Add Frequent Flyer Card  </Button>
        </CardActions>
    </Card>;
};