import { Avatar, Button, Card, CardContent, CardHeader, Checkbox, FormControlLabel, FormGroup, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import DeleteIcon from '@material-ui/icons/Delete';
import React from "react";
import { CabinClassInput } from "WebApiClient";
import { useStyles } from "./customerprofiles.Details";


export const CustomerProfileDataCabinClasses: React.FC<{ data: CabinClassInput | undefined; OnUpdate: (data: CabinClassInput | undefined) => void; Title: string }> = props => {
    const classes = useStyles();

    return <Card elevation={3}>
        <CardHeader
            avatar={<Avatar className={props.data ? classes.activeAvatar : ""}><AirlineSeatReclineExtraIcon /></Avatar>}
            title={<Typography variant="h5">{props.Title}</Typography>}
            action={props.data ? <Button onClick={() => { props.OnUpdate(undefined); }}
                startIcon={<DeleteIcon />} color="secondary">remove</Button> : null} />
        <CardContent>
            {props.data ?
                <FormGroup row>
                    <FormControlLabel
                        control={<Checkbox
                            checked={props.data.economy}
                            onChange={() => { props.OnUpdate({ ...props.data!, economy: !props.data!.economy }); }}
                            color="primary" />}
                        label="Economy" />
                    <FormControlLabel
                        control={<Checkbox
                            checked={props.data.premiumEconomy}
                            onChange={() => { props.OnUpdate({ ...props.data!, premiumEconomy: !props.data!.premiumEconomy }); }}
                            color="primary" />}
                        label="Premium Economy" />
                    <FormControlLabel
                        control={<Checkbox
                            checked={props.data.business}
                            onChange={() => { props.OnUpdate({ ...props.data!, business: !props.data!.business }); }}
                            color="primary" />}
                        label="Business" />
                    <FormControlLabel
                        control={<Checkbox
                            checked={props.data.first}
                            onChange={() => { props.OnUpdate({ ...props.data!, first: !props.data!.first }); }}
                            color="primary" />}
                        label="First" />

                </FormGroup> : <Button startIcon={<AddCircleOutlineIcon />} variant="contained" color="primary" onClick={() => { props.OnUpdate({ business: false, economy: true, first: false, premiumEconomy: false }); }}>Add cabin classes</Button>}

        </CardContent>
    </Card>;
};
