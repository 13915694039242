import { Grid, makeStyles } from "@material-ui/core";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { useBranchGroupClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, UpdateBranchgroupModel } from "../../../WebApiClient";
import BranchgroupsTable from "./branchgroups.Table";
import BranchgroupDataInputComponent from "./branchgroups.Update";

interface NavbarProps { }


const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  }
}));

const Branchgroups: React.FC<NavbarProps> = props => {
  const classes = useStyles();
  const branchGroupClient = useBranchGroupClient();
  const dispatch = useDispatch();
  const [branchGroupTableIsLoading, setBranchTableIsLoading] = useState(true);
  const [errorOccured, setErrorOccured] = useState(false);
  const [lastedit, setlastedit] = useState("");
  const [branchgroups, setBranchGroups] = useState<UpdateBranchgroupModel[]>(
    []
  );
  const [editBranchGroup, setEditBranchGroup] = useState<
    UpdateBranchgroupModel | undefined
  >(undefined);

  const [editBranchGroupErrorState, setEditBranchGroupErrorState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);

  useEffect(() => {
    RefreshBranchgroupsAsync();
  }, []);

  function RefreshBranchgroupsAsync() {
    setBranchTableIsLoading(true);
    branchGroupClient
      .get()
      .then(response => {
        setBranchGroups(response);
      })
      .catch(() => {
        setErrorOccured(true);
      })
      .finally(() => {
        setBranchTableIsLoading(false);
      });
  }

  function DeleteBranchGroupAsync(id: string) {
    let transactionSuccess = false;
    let transactionTime = new Date().toString();
    let transactionId = "";
    let transactionMsg = "MSG NOT SET";
    let serverSideError = false;
    branchGroupClient
      .delete(id)
      .then(e => {
        transactionId = e.transactionId;
        if (e.success) {
          transactionSuccess = true;
          transactionMsg = "Branchgroup deleted.";
        } else {
          transactionMsg = `Branchgroup could not be deleted. ${e.errorMessage}`;
        }
      })
      .catch(e => {
        console.error(e);
        serverSideError = true;
      })
      .finally(() => {
        if (serverSideError) {
          transactionMsg = `Branchgroup could not be deleted. An server-side error has occured.`;
        }
        let transaction: TransactionIDInfo = {
          Label: `Delete branchgroup`,
          Success: transactionSuccess,
          Time: transactionTime,
          TransactionID: transactionId,
          Message: transactionMsg
        };
        dispatch(Session_Reducer_PushTransactionID(transaction));
        RefreshBranchgroupsAsync();
      });
  }

  function UpdateBranchGroupAsync(branchgroup: UpdateBranchgroupModel) {
    const isUpdate = branchgroup.id !== undefined;
    const actionTypeLabel = isUpdate ? "updated" : "created";

    let transactionSuccess = false;
    let transactionTime = new Date().toString();
    let transactionId = "";
    let transactionMsg = "MSG NOT SET";
    let serverSideError = false;

    branchGroupClient
      .update(branchgroup)
      .then(result => {
        setEditBranchGroupErrorState(result.validationResult);
        transactionSuccess = result.success;
        transactionId = result.transactionId;
        if (result.success === true) {
          transactionMsg = `Branchgroup successfully ${actionTypeLabel}.`;
          CloseEditBranchgroup();
        } else {
          transactionMsg = `Branchgroup could not be ${actionTypeLabel}.`;
          if (result.serverError) {
            serverSideError = true;
          }
        }
        setlastedit(result.resultId);
      })
      .catch(e => {
        console.error(e);
        serverSideError = true;
      })
      .finally(() => {
        if (serverSideError) {
          transactionMsg = `Branchgroup could not be ${actionTypeLabel}. An server-side error has occured.`;
        }
        let transaction: TransactionIDInfo = {
          Label: `${isUpdate ? "Update" : "Create"} Branchgroup`,
          Success: transactionSuccess,
          Time: transactionTime,
          TransactionID: transactionId,
          Message: transactionMsg
        };
        dispatch(Session_Reducer_PushTransactionID(transaction));
        RefreshBranchgroupsAsync();
      });
  }

  function SetEditBranchgroup(data: UpdateBranchgroupModel | undefined) {
    setEditBranchGroupErrorState(undefined);
    if (data) {
      setEditBranchGroup(data);
    } else {
      const newBg: UpdateBranchgroupModel = {
        id: undefined,
        name: "",
        isActive: true
      };
      setEditBranchGroup(newBg);
    }
  }

  function CloseEditBranchgroup() {
    setEditBranchGroupErrorState(undefined);
    setEditBranchGroup(undefined);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {editBranchGroup && (
          <BranchgroupDataInputComponent
            Data={editBranchGroup}
            OnSave={UpdateBranchGroupAsync}
            OnCancel={CloseEditBranchgroup}
            ValidationResult={editBranchGroupErrorState}
          />
        )}
      </Grid>
      <Grid item xs={12}>

        <BranchgroupsTable
          LastEdit={lastedit}
          IsLoading={branchGroupTableIsLoading}
          Data={branchgroups}
          OnRefresh={RefreshBranchgroupsAsync}
          OnDelete={DeleteBranchGroupAsync}
          OnEdit={SetEditBranchgroup}
        />
      </Grid>
    </Grid>
  );
};

export default Branchgroups;
