import { AgentApiURL } from "Constants";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { AdminFrontendServicesClient, ApiCredentialsOptions, AuthorizedServicesClient, BookingManagerVisibilitySettingsClient, BranchClient, BranchGroupClient, CalcCurrenciesClient, CalcFormulasClient, CalcRulesClient, CalculationDisplaySettingsClient, CiLogosClient, CiWaitscreenClient, CiBackgroundClient, ConsolidatorCalculationSettingsClient, CustomerProfilesClient, DepositSettingsClient, ExportClient, FareFilterSettingsClient, FlightApiSettingsClient, GeneralDisplaySettingsClient, PassengerTypeSettingsClient, SalesPrefActionsClient, SalesPrefRulesClient, UserClient, PaymentDataSettingsClient, CiTemplateClient, IconDataSettings } from "WebApiClient";

function useApiCredentials(): ApiCredentialsOptions {
  const jwtToken = useSelector((state: State) => state.Session.JwtToken);
  return new ApiCredentialsOptions(AgentApiURL, jwtToken);
}

export function useBranchGroupClient(): BranchGroupClient {
  const apiCredentials = useApiCredentials();
  const client = new BranchGroupClient(apiCredentials);
  return client;
}

export function useBranchClient(): BranchClient {
  const apiCredentials = useApiCredentials();
  const client = new BranchClient(apiCredentials);
  return client;
}
export function useUserClient(): UserClient {
  const apiCredentials = useApiCredentials();
  const client = new UserClient(apiCredentials);
  return client;
}
export function useFlightApiClient(): FlightApiSettingsClient {
  const apiCredentials = useApiCredentials();
  const client = new FlightApiSettingsClient(apiCredentials);
  return client;
}
export function useDepositSettingsClient(): DepositSettingsClient {
  const apiCredentials = useApiCredentials();
  const client = new DepositSettingsClient(apiCredentials);
  return client;
}
export function useGeneralDisplaySettingsClient(): GeneralDisplaySettingsClient {
  const apiCredentials = useApiCredentials();
  const client = new GeneralDisplaySettingsClient(apiCredentials);
  return client;
}

export function useIconDataSettingsClient(): IconDataSettings {
  const apiCredentials = useApiCredentials();
  const client = new IconDataSettings(apiCredentials);
  return client;
}
export function usePaymentDataSettingsClient():PaymentDataSettingsClient {
  const apiCredentials = useApiCredentials();
  const client = new PaymentDataSettingsClient(apiCredentials);
  return client;
}
export function useCalculationDisplaySettingsClient(): CalculationDisplaySettingsClient {
  const apiCredentials = useApiCredentials();
  const client = new CalculationDisplaySettingsClient(apiCredentials);
  return client;
}
export function useCalcCurrenciesClient(): CalcCurrenciesClient {
  const apiCredentials = useApiCredentials();
  const client = new CalcCurrenciesClient(apiCredentials);
  return client;
}
export function useCalcFormulasClient(): CalcFormulasClient {
  const apiCredentials = useApiCredentials();
  const client = new CalcFormulasClient(apiCredentials);
  return client;
}
export function useCalcRulesClient(): CalcRulesClient {
  const apiCredentials = useApiCredentials();
  const client = new CalcRulesClient(apiCredentials);
  return client;
}
export function useSalesPrefActionsClient(): SalesPrefActionsClient {
  const apiCredentials = useApiCredentials();
  const client = new SalesPrefActionsClient(apiCredentials);
  return client;
}
export function useSalesPrefRulesClient(): SalesPrefRulesClient {
  const apiCredentials = useApiCredentials();
  const client = new SalesPrefRulesClient(apiCredentials);
  return client;
}
export function useConsolidatorCalculationSettingsClient(): ConsolidatorCalculationSettingsClient {
  const apiCredentials = useApiCredentials();
  const client = new ConsolidatorCalculationSettingsClient(apiCredentials);
  return client;
}
export function useAuthorizedServicesClient(): AuthorizedServicesClient {
  const apiCredentials = useApiCredentials();
  const client = new AuthorizedServicesClient(apiCredentials);
  return client;
}
export function useCiWaitscreenClient(): CiWaitscreenClient {
  const apiCredentials = useApiCredentials();
  const client = new CiWaitscreenClient(apiCredentials);
  return client;
}
export function useCiBackgroundClient(): CiBackgroundClient {
  const apiCredentials = useApiCredentials();
  const client = new CiBackgroundClient(apiCredentials);
  return client;
}
export function useFareFilterClient(): FareFilterSettingsClient {
  const apiCredentials = useApiCredentials();
  const client = new FareFilterSettingsClient(apiCredentials);
  return client;
}
export function useCiLogosClient(): CiLogosClient {
  const apiCredentials = useApiCredentials();
  const client = new CiLogosClient(apiCredentials);
  return client;
}
export function useCiTemplateClient(): CiTemplateClient {
  const apiCredentials = useApiCredentials();
  const client = new CiTemplateClient(apiCredentials);
  return client;
}
export function usePassengerTypesSettingsClient(): PassengerTypeSettingsClient {
  const apiCredentials = useApiCredentials();
  const client = new PassengerTypeSettingsClient(apiCredentials);
  return client;
}
export function useExportClient(): ExportClient {
  const apiCredentials = useApiCredentials();
  const client = new ExportClient(apiCredentials);
  return client;
}
export function useBookingManagerVisibilitySettingsClient(): BookingManagerVisibilitySettingsClient {
  const apiCredentials = useApiCredentials();
  const client = new BookingManagerVisibilitySettingsClient(apiCredentials);
  return client;
}
export function useCustomerProfilesClient(): CustomerProfilesClient {
  const apiCredentials = useApiCredentials();
  const client = new CustomerProfilesClient(apiCredentials);
  return client;
}
export function useAdminFrontendServicesClient(): AdminFrontendServicesClient {
  const apiCredentials = useApiCredentials();
  const client = new AdminFrontendServicesClient(apiCredentials);
  return client;
}

