import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Grid, TextField, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import { HelpTextButton } from "components/shared/various";
import React, { useState } from "react";
import { CustomerProfilePassenger, CustomerProfilePassengerGroup, BonusProgramModel } from "WebApiClient";
import { CustomerProfileDataPassenger } from "./CustomerProfileDataPassenger";
import { defaultPassenger, useStyles } from "./customerprofiles.Details";
import * as HelpTexts from "./customerprofiles.HelpTexts";

export const CustomerProfilesPassengersGroupComponent: React.FC<{globalValidationTrigger?:any,setglobalValidationTrigger?:any,errorTrigger?:any,Fulldata?:any, data: CustomerProfilePassengerGroup; OnUpdate: (data: CustomerProfilePassengerGroup | undefined) => void; plural: string; singular: string; icon: JSX.Element;setbuttonDB?:any }> = props => {
    const { passengerType, passengers } = props.data;
    const [expanded, setExpanded] = useState(false);
    const classes = useStyles();


    function OnAddPassenger() {
        let olds = passengers;
        olds.push({ ...defaultPassenger });
        props.OnUpdate({ ...props.data, passengers: olds });
    }

    function OnEditPassenger(seg: CustomerProfilePassenger, index: number) {
        let olds = [...passengers];
        olds[index] = seg;
        props.OnUpdate({ ...props.data, passengers: olds });
    }

    function OnRemovePassenger(index: number) {
        let olds = [...passengers];
        olds.splice(index, 1);
        props.OnUpdate({ ...props.data, passengers: olds });
    }
    return <Card elevation={5}>
        <CardHeader
            avatar={<Avatar className={classes.activeAvatar}>{props.icon}</Avatar>}
            title={<Typography variant="h5">{props.plural}</Typography>}
            action={<Button onClick={() => { props.OnUpdate(undefined); }} startIcon={<DeleteIcon />} color="secondary">remove all</Button>} />
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <TextField
                        value={passengerType}
                        onChange={e => props.OnUpdate({
                            ...props.data,
                            passengerType: e.target.value
                        })}
                        label="Passenger type"
                        InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.PassengertypeHelpText} /> }} />
                </Grid>
                {passengers.map((val, i) => <Grid xs={12} item key={i}>
                    <CustomerProfileDataPassenger globalValidationTrigger={props.globalValidationTrigger!} setglobalValidationTrigger={props.setglobalValidationTrigger!} errorTrigger={props.errorTrigger!} Fulldata={props.Fulldata!} setbuttonDB={props.setbuttonDB!} label={passengerType} index={(i + 1)} OnUpdate={(e) => { OnEditPassenger(e, i); }} data={val} OnRemove={() => { OnRemovePassenger(i); }} />
                </Grid>
                )}
            </Grid>
        </CardContent>
        <CardActions>
            <Button onClick={OnAddPassenger} variant="contained" color="primary" startIcon={<AddCircleOutlineIcon />}>add {props.singular}</Button>
        </CardActions>
    </Card>;
};
