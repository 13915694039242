import { makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import CountrySelect from "components/shared/inputs/CountrySelect";
import { TableIcons } from "components/shared/tableIcons";
import MaterialTable, { Column } from "material-table";
import React from "react";
import { BookingAddressBehavior, UpdateBranchModel } from "WebApiClient";
import { GetBookingAdressBehaviorLabel } from "./branch.Details";
interface IBranchesTableProps {
  BranchGroupID: string;
  BranchgroupName: string;
  LastEdit: string;
  OnRefresh: () => void;
  OnDelete: (id: string) => void;
  OnEdit: (data: UpdateBranchModel | undefined) => void;
  IsLoading: boolean;
  Data: UpdateBranchModel[];
}


const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  }
}));
export const BranchesTable: React.FC<IBranchesTableProps> = props => {
  const classes = useStyles();
  const { BranchGroupID, BranchgroupName } = props;

  function GetRowStyle(
    data: any,
    index: number,
    level: number
  ): React.CSSProperties {
    const bg = data as UpdateBranchModel;
    let result: React.CSSProperties = {
      backgroundColor: bg.id === props.LastEdit ? "#a8c8dd" : undefined
    };
    return result;
  }

  const BranchTableColumns: Array<Column<UpdateBranchModel>> = [
    { title: "Name", field: "name", emptyValue: "" },
    { title: "Active", field: "isActive", type: "boolean" },
    { title: "Primary number", field: "branchNumberPrimary", emptyValue: "" },
    {
      title: "Secondary number",
      field: "branchNumberSecondary",
      emptyValue: ""
    },
    { title: "Care of", field: "careOf", emptyValue: "" },
    { title: "Street 1", field: "streetAddress1", emptyValue: "" },
    {
      title: "Street 2",
      field: "streetAddress2",
      emptyValue: "",
      hidden: true
    },
    { title: "ZIP", field: "zip", emptyValue: "" },
    { title: "City", field: "city", emptyValue: "" },
    {
      title: "Country",
      field: "country",
      emptyValue: "",
      editComponent: props => (
        <CountrySelect Value={props.value} OnChange={props.onChange} Label="Country" />
      )
    },
    { title: "Telephone", field: "telephone", emptyValue: "" },
    { title: "Fax", field: "fax", emptyValue: "" },
    { title: "Email", field: "email", emptyValue: "" },
    {
      title: "Booking confimration email",
      field: "bookingConfirmationEmail",
      emptyValue: ""
    },
    { title: "Website", field: "website", emptyValue: "" },
    {
      title: "Booking adress behavior",
      field: "bookingAddressBehavior",
      render: (rowData, type) => {
        if (type === "group") {
          const d = rowData as any;
          const label = d as BookingAddressBehavior;
          return GetBookingAdressBehaviorLabel(label);
        } else {

          return GetBookingAdressBehaviorLabel(rowData.bookingAddressBehavior)
        }
      }
    },
    {
      title: "Webfare password behavior",
      field: "webfarePasswordBehavior",
      render: (rowData, type) => {
        if (type === "group") {
          const d = rowData as any;
          const label = d as BookingAddressBehavior;
          return GetBookingAdressBehaviorLabel(label);
        } else {

          return GetBookingAdressBehaviorLabel(rowData.webfarePasswordBehavior)
        }
      }
    },
    { title: "Webfare password", field: "webfarePassword", emptyValue: "" },
    {
      title: "Emergency phone behavior",
      field: "emergencyPhoneBehavior",
      render: (rowData, type) => {
        if (type === "group") {
          const d = rowData as any;
          const label = d as BookingAddressBehavior;
          return GetBookingAdressBehaviorLabel(label);
        } else {

          return GetBookingAdressBehaviorLabel(rowData.emergencyPhoneBehavior)
        }
      }
    },
    { title: "Emergency phone", field: "emergencyPhone", emptyValue: "" }
  ];

  function DeleteRow(data: UpdateBranchModel): Promise<void> {
    console.log("deleterow calling++")
    return new Promise(resolve => {
      props.OnDelete(data.id!);
      resolve();
    });
  }

  return (
    <MaterialTable
      icons={TableIcons}
      title={`Branches in ${BranchgroupName}`}
      columns={BranchTableColumns}
      data={props.Data}
      isLoading={props.IsLoading}
      options={{
        selection: false,
        rowStyle: GetRowStyle,
        grouping: false,
        paging: false
      }}
      editable={{
        isDeletable: oldData => true,
        onRowDelete: DeleteRow
      }}
      actions={[
        {
          icon: () => <RefreshIcon />,
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () => props.OnRefresh()
        },
        {
          icon: () => <EditIcon />,
          tooltip: "View",
          onClick: (event, row) => {
            const data = row as UpdateBranchModel;
            props.OnEdit(data);
          }
        },
        {
          icon: () => <AddIcon />,
          tooltip: "Add",
          isFreeAction: true,
          onClick: () => {
            props.OnEdit(undefined);
          }
        },

      ]}

    />
  );
};

export default BranchesTable;
