import { Avatar, Button, Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import FlightIcon from '@material-ui/icons/Flight';
import React from "react";
import { CustomerProfileSegment } from "WebApiClient";
import { CustomerProfileDataSegment } from "./CustomerProfileDataSegment";
import { useStyles } from "./customerprofiles.Details";


export const CustomerProfileDataSegments: React.FC<{ data: CustomerProfileSegment[] | undefined; OnUpdate: (data: CustomerProfileSegment[] | undefined) => void; }> = props => {
    const segments = props.data;
    const classes = useStyles();

    function OnAddSegment() {
        let olds = segments ? [...segments] : [];
        olds.push({ arrival: "", departure: "", departureDate: undefined, radius: 0, cabinClasses: undefined });
        props.OnUpdate(olds);
    }

    function OnEditSegment(seg: CustomerProfileSegment, index: number) {
        let olds = [...segments!];
        olds[index] = seg;
        props.OnUpdate(olds);
    }

    function OnRemoveSegment(index: number) {
        let olds = segments ? [...segments] : [];
        olds.splice(index, 1);
        if (olds.length === 0) {
            props.OnUpdate(undefined);
        }
        else {

            props.OnUpdate(olds);
        }
    }
    return <Card>
        <CardHeader
            avatar={<Avatar className={segments ? classes.activeAvatar : ""}><FlightIcon /></Avatar>}
            title={<Typography variant="h5">Segments</Typography>}
            action={segments ? <Button onClick={() => { props.OnUpdate(undefined); }}
                startIcon={<DeleteIcon />} color="secondary">remove all segments</Button> : null} />
        <CardContent>
            <Grid container spacing={2}>
                {segments &&
                    <Grid item container spacing={2}>

                        {segments.map((val, i) => <Grid item xs={12} key={i}>
                            <CustomerProfileDataSegment index={(i + 1)} OnUpdate={(e) => { OnEditSegment(e, i); }} data={val} OnRemove={() => { OnRemoveSegment(i); }} />
                        </Grid>
                        )}
                    </Grid>}
                <Grid item xs={12}><Button startIcon={<AddCircleOutlineIcon />} variant="contained" color="primary" onClick={OnAddSegment}>Add segment</Button></Grid>
            </Grid>

        </CardContent>
    </Card>;
};
