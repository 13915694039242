// import { useBranchClient } from "hooks/useHttpClient";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CountrySelect from "components/shared/inputs/CountrySelect";
import { HelpTextButton } from "components/shared/various";
import React, { useEffect, useState } from "react";
import { AdminEntityValidationResult, BookingAddressBehavior, UpdateBranchModel } from "WebApiClient";
import * as HelpTexts from "./branches.HelpTexts";

interface IBrancheDetailsProps {
  Data: UpdateBranchModel;
  OnSave: (data: UpdateBranchModel) => void;
  OnCancel: () => void;
  ValidationResult: AdminEntityValidationResult | undefined;
}
export const BranchEditComponent: React.FC<IBrancheDetailsProps> = props => {
  const [branch, setBranch] = useState(props.Data);
  const IsEdit = branch.id !== undefined;
  useEffect(() => {
    setBranch(props.Data);
  }, [props.Data]);

  const nameError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["name"]
    : undefined;
  const posError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["pos"]
    : undefined;
  const street1Error: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["street1"]
    : undefined;
  const zipError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["zip"]
    : undefined;
  const cityError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["city"]
    : undefined;
  const emailError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["email"]
    : undefined;
  const CountryError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["country"]
    : undefined;
  const bookingConEmailError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["bookingconfemail"]
    : undefined;
  const webfarePasswordError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["webfarepassword"]
    : undefined;
  const emergencyPhoneError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["emergencyphone"]
    : undefined;

  return (
    <Card>
      <CardHeader
        title={
          <span>
            {IsEdit
              ? `Edit Branch ${props.Data.name}`
              : `Create Branch ${branch.name}`}
          </span>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          {props.ValidationResult && props.ValidationResult.errorSummary && (
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <ul>
                  {props.ValidationResult.errorSummary.map((e, index) => (
                    <li key={index}>{e}</li>
                  ))}
                </ul>
              </Alert>
            </Grid>
          )}

          <Grid item xs={12} spacing={6}>
            <Grid item>
              <TextField
                error={nameError !== undefined}
                value={branch.name}
                onChange={e =>
                  setBranch({
                    ...branch,
                    name: e.target.value
                  })
                }
                helperText={nameError}
                label="Branch Name"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={branch.isActive} onChange={() => {
                setBranch({
                  ...branch,
                  isActive: !branch.isActive
                })
              }} />}
              label={<span>Active <HelpTextButton HelpText={HelpTexts.BranchIsActiveHelpText} /></span>}
            />
          </Grid>
          <Grid item xs={12}>
            <Box m={2}>
              <Divider></Divider>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box marginBottom={2}>
              <Typography variant="h5">Address</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <BookingAdressBehaviorInput
              Value={branch.bookingAddressBehavior}
              OnChange={e => {
                setBranch({
                  ...branch,
                  bookingAddressBehavior: e
                });
              }}
            />
          </Grid>
          <Grid container item xs={12} spacing={6}>

            <Grid item>
              <Box marginBottom={2}>

                <TextField
                  error={undefined}
                  value={branch.branchNumberPrimary}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      branchNumberPrimary: e.target.value
                    })
                  }
                  helperText={undefined}
                  label="Branch number (Primary)"
                />
              </Box>
            </Grid>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={undefined}
                  value={branch.branchNumberSecondary}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      branchNumberSecondary: e.target.value
                    })
                  }
                  helperText={undefined}
                  label="Branch number (Sec.)"
                />

              </Box>
            </Grid>

          </Grid>

          <Grid container item xs={12} spacing={6}>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={street1Error !== undefined}
                  value={branch.streetAddress1}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      streetAddress1: e.target.value
                    })
                  }
                  helperText={street1Error}
                  label="Street address 1"
                />

              </Box>
            </Grid>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={undefined}
                  value={branch.streetAddress2}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      streetAddress2: e.target.value
                    })
                  }
                  helperText={undefined}
                  label="Street address 2"
                />

              </Box>
            </Grid>
          </Grid>



          <Grid container item xs={12} spacing={6}>
            <Grid item>
              <Box marginBottom={2}>

                <TextField
                  error={zipError !== undefined}
                  value={branch.zip}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      zip: e.target.value
                    })
                  }
                  helperText={zipError}
                  label="Zip"
                />
              </Box>
            </Grid>
            <Grid item >
              <Box marginBottom={2}>
                <TextField
                  error={cityError !== undefined}
                  value={branch.city}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      city: e.target.value
                    })
                  }
                  helperText={cityError}
                  label="City"
                />

              </Box>
            </Grid>
          </Grid>


          <Grid item xs={4}>
            <Box marginBottom={2}>
              <CountrySelect
                Value={branch.country}
                Label="Country"
                OnChange={e => {
                  setBranch({
                    ...branch,
                    country: e
                  });
                }}
              />

            </Box>

          </Grid>
          <Grid container item xs={12} spacing={6}>

            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={undefined}
                  value={branch.careOf}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      careOf: e.target.value
                    })
                  }
                  helperText={undefined}
                  label="Care of"
                />

              </Box>
            </Grid>
          </Grid>



          <Grid container item xs={12} spacing={6}>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={undefined}
                  value={branch.telephone}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      telephone: e.target.value
                    })
                  }
                  helperText={undefined}
                  label="Telephone"
                />

              </Box>
            </Grid>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={undefined}
                  value={branch.fax}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      fax: e.target.value
                    })
                  }
                  helperText={undefined}
                  label="Fax"
                />

              </Box>
            </Grid>
          </Grid>


          <Grid container item xs={12} spacing={6}>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={undefined}
                  value={branch.website}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      website: e.target.value
                    })
                  }
                  helperText={undefined}
                  label="Website"
                />

              </Box>
            </Grid>

          </Grid>


          <Grid item xs={12}>
            <Box m={2}>
              <Divider></Divider>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box marginBottom={2}>
              <Typography variant="h5">Email addresses</Typography>
            </Box>
          </Grid>

          <Grid container item xs={12} spacing={6}>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={emailError !== undefined}
                  value={branch.email}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      email: e.target.value
                    })
                  }
                  helperText={emailError}
                  label="E-Mail"
                />

              </Box>
            </Grid>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={bookingConEmailError !== undefined}
                  value={branch.bookingConfirmationEmail}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      bookingConfirmationEmail: e.target.value
                    })
                  }
                  helperText={bookingConEmailError}
                  label="Booking confirmation"
                />

              </Box>
            </Grid>
          </Grid>


          <Grid item xs={12}>
            <Box m={2}>
              <Divider></Divider>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box marginBottom={2}>
              <Typography variant="h5">Webfare password</Typography>
            </Box>
          </Grid>

          <Grid container item xs={12} spacing={6}>
            <Grid item>
              <Box marginBottom={2}>
                <TextField
                  error={webfarePasswordError !== undefined}
                  value={branch.webfarePassword}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      webfarePassword: e.target.value
                    })
                  }
                  helperText={webfarePasswordError}
                  label="Webfare password"
                />

              </Box>
            </Grid>
            <Grid item>
              <Box marginBottom={2}>
                <BookingAdressBehaviorInput
                  Value={branch.webfarePasswordBehavior}
                  OnChange={e => {
                    setBranch({
                      ...branch,
                      webfarePasswordBehavior: e
                    });
                  }}
                />

              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box m={2}>
              <Divider></Divider>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box marginBottom={2}>
              <Typography variant="h5">Emergency phone</Typography>
            </Box>
          </Grid>

          <Grid container item xs={12} spacing={6}>
            <Grid item>

              <Box marginBottom={2}>

                <TextField
                  error={emergencyPhoneError !== undefined}
                  value={branch.emergencyPhone}
                  onChange={e =>
                    setBranch({
                      ...branch,
                      emergencyPhone: e.target.value
                    })
                  }
                  helperText={emergencyPhoneError}
                  label="Emergency phone"
                />
              </Box>
            </Grid>
            <Grid item>
              <Box marginBottom={2}>
                <BookingAdressBehaviorInput
                  Value={branch.emergencyPhoneBehavior}
                  OnChange={e => {
                    setBranch({
                      ...branch,
                      emergencyPhoneBehavior: e
                    });
                  }}
                />

              </Box>
            </Grid>
          </Grid>

        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Button variant="contained" onClick={() => setBranch(props.Data)}>
          Reset
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => props.OnSave(branch)}
        >
          Save
        </Button>
        <Button color="secondary" variant="contained" onClick={props.OnCancel}>
          Close
        </Button>
      </CardActions>
    </Card>
  );
};

interface BookingAdressBehaviorInputProps {
  Value: BookingAddressBehavior;
  OnChange: (newVal: BookingAddressBehavior) => void;
}
export function GetBookingAdressBehaviorDescription(props: BookingAddressBehavior): string {
  let result = "";
  switch (props) {
    case BookingAddressBehavior.EmptyUserInput:
      result = "Input will be visible and empty";
      break;
    case BookingAddressBehavior.PrefillWithBranchAdress:
      result = "Input will be visible and prefilled with branch information";
      break;
    case BookingAddressBehavior.UseBranchAdress:
      result = "Input will not be visible. Branch data is automatically used";
      break;
  }
  return result;
}

export function GetBookingAdressBehaviorLabel(props: BookingAddressBehavior): string {
  let result = "";
  switch (props) {
    case BookingAddressBehavior.EmptyUserInput:
      result = "Empty input";
      break;
    case BookingAddressBehavior.PrefillWithBranchAdress:
      result = "Prefilled with Branch data";
      break;
    case BookingAddressBehavior.UseBranchAdress:
      result = "Use Branch data";
      break;
  }
  return result;
}

const BookingAdressBehaviorInput: React.FC<BookingAdressBehaviorInputProps> = props => {

  return (
    <FormControl>
      <InputLabel>Booking screen behavior</InputLabel>
      <Select
        value={props.Value}
        onChange={e => {
          props.OnChange(e.target.value as BookingAddressBehavior);
        }}
      >
        <MenuItem value={BookingAddressBehavior.EmptyUserInput}>
          Empty input
        </MenuItem>
        <MenuItem value={BookingAddressBehavior.PrefillWithBranchAdress}>
          Prefilled with Branch data
        </MenuItem>
        <MenuItem value={BookingAddressBehavior.UseBranchAdress}>
          Use Branch data
        </MenuItem>
      </Select>
      <FormHelperText>{GetBookingAdressBehaviorDescription(props.Value)}</FormHelperText>
    </FormControl>
  );
};

export default BranchEditComponent;
