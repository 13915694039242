import { makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import { TableIcons } from "components/shared/tableIcons";
import { HelpTextButton } from "components/shared/various";
import MaterialTable, { Column } from "material-table";
import React from "react";
import { CreateSettingsRequest, UpdateCalcCurrencyModel, UpdateCalcFormulaModel } from "WebApiClient";
import { GetRoundDirectionLabel } from "./formulas.Edit";
import { CalcFormulasIntroHelptext } from "./formulas.HelpTexts";
interface IFormulasTableProps {
  OnRefresh: () => void;
  OnDelete: (id: string) => void;
  OnEdit: (data: UpdateCalcFormulaModel | undefined) => void;
  IsLoading: boolean;
  Data: UpdateCalcFormulaModel[];
  CurrencyOptions: UpdateCalcCurrencyModel[];
  LevelSelection: CreateSettingsRequest;
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  }
}));
export const FormulasTable: React.FC<IFormulasTableProps> = props => {
  const classes = useStyles();
  const lastEdit: string = "";
  const hasCurrencies: boolean = props.CurrencyOptions.length > 0;
  function GetRowStyle(
    data: any,
    index: number,
    level: number
  ): React.CSSProperties {
    const bg = data as UpdateCalcFormulaModel;
    let result: React.CSSProperties = {
      backgroundColor: bg.id === lastEdit ? "#a8c8dd" : undefined
    };
    return result;
  }

  function DeleteRow(data: UpdateCalcFormulaModel): Promise<void> {
    return new Promise(resolve => {
      props.OnDelete(data.id);
      resolve();
    });
  }

  const BranchTableColumns: Array<Column<UpdateCalcFormulaModel>> = [
    { title: "Title", field: "title" },
    {
      title: "Currency",
      field: "currencyID",
      render: rowdata => {
        const curr = props.CurrencyOptions.filter(
          e => e.id === rowdata.currencyID
        )[0];
        if (curr !== undefined) {
          return curr.currencyCode;
        } else {
          return "Unknown currency";
        }
      }
    },
    {
      title: "Tax affects service fee",
      field: "taxAffectsServiceFee",
      type: "boolean"
    },
    {
      title: "Q-Surcharge affects service fee",
      field: "qSurchargeAffectsServiceFee",
      type: "boolean"
    },
    { title: "Formula", field: "functionDefinition" },
    {
      title: "Round direction",
      field: "functionRoundDirection",
      render: rowData => {
        return GetRoundDirectionLabel(rowData.functionRoundDirection);
      }
    },
    { title: "Formula (Child)", field: "functionDefinitionChild" },
    { title: "Formula (Infant)", field: "functionDefinitionInfant" },
    { title: "Round decimals", field: "functionRoundDecimals" },
    {
      title: "Show calculation result in display",
      field: "showCalculationResultInDisplay",
      type: "boolean"
    }
  ];

  return (
    <MaterialTable
      icons={TableIcons}
      title={<Typography variant="h5">Calculation Formulas <HelpTextButton HelpText={CalcFormulasIntroHelptext} /></Typography>}
      columns={BranchTableColumns}
      data={props.Data}
      isLoading={props.IsLoading}
      editable={{
        onRowDelete: DeleteRow
      }}
      options={{ paging: false }}
      actions={[
        {
          icon: () => <RefreshIcon />,
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () => props.OnRefresh()
        },
        {
          icon: () => <EditIcon />,
          tooltip: "View",
          onClick: (event, row) => {
            const data = row as UpdateCalcFormulaModel;
            props.OnEdit(data);
          }
        },
        {
          icon: () => <AddIcon color={hasCurrencies ? undefined : "error"} />,
          tooltip: hasCurrencies
            ? "Add"
            : "You have to create an currency before creating a formula.",
          isFreeAction: true,
          onClick: () => {
            if (hasCurrencies) {
              props.OnEdit(undefined);
            } else {
            }
          }
        },

      ]}
    />
  );
};

export default FormulasTable;
