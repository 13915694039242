import { Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";

// interface IAdminErrorSummaryProps {
// ServerError:boolean;
// ValidationResult?: AdminEntityValidationResult|undefined,
// DeleteResult?: AdminEntityValidationResult|undefined,

//   }
//   export const AdminErrorSummary: React.FC<IAdminErrorSummaryProps> = props => {
//       const {ServerError,ValidationResult,DeleteResult} = props;
//       const show:boolean = ServerError || (ValidationResult) || DeleteResult
//       return <React.Fragment>

//   </React.Fragment>}

interface ErrorMessagesProps {
  Message?: string | undefined;
  Messages?: string[] | undefined;
  ServerError?: boolean;
}

export const ErrorMessages: React.FC<ErrorMessagesProps> = props => {
  return (
    <Grid item xs={12}>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        <ul>
          {props.ServerError && <li>An server-side error has occured.</li>}
          {props.Message && <li>{props.Message}</li>}
          <React.Fragment>
            {props.Messages &&
              props.Messages.map((e, index) => <li key={index}>{e}</li>)}
          </React.Fragment>
        </ul>
      </Alert>
    </Grid>
  );
};
