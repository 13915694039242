import { Button, Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { WebLevelEnum } from "types/FilterTypes";
import { SettingsOrder } from "WebApiClient";
import WebLevelSelect from "../general/WebLevelSelect";

interface SettingOverrideGridItemProps { }

export const SettingOverrideGridItem: React.FC<SettingOverrideGridItemProps> = props => {
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Paper style={{ textAlign: "center" }}>
            <Typography variant="h5">
              <span style={{ float: "left" }}>
                <ArrowDownwardIcon />
              </span>
              <span>Overrides </span>
              <span style={{ float: "right" }}>
                <ArrowDownwardIcon />
              </span>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </Grid>
  );
};

interface ICreateSettingsButtonProps {
  OnClick: () => void;
  Readonly: boolean;
}

export const CreateSettingsButton: React.FC<ICreateSettingsButtonProps> = props => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <label>
          No settings were found. If not overriden above, the settings below
          will be applied.
        </label>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="contained"
          disabled={props.Readonly}
          onClick={props.OnClick}
          startIcon={<AddIcon />}
        >
          Create settings now
        </Button>
      </Grid>
    </Grid>
  );
};


interface IApplyIndicatorProps { }

export const ApplyIndicator: React.FC<IApplyIndicatorProps> = props => {
  return (
    <Tooltip title="These settings will apply.">
      <CheckCircleOutlineIcon style={{ color: "green" }} />
    </Tooltip>
  );
};
interface IOverrideIndicatorProps { }

export const OverriddenIndicator: React.FC<IOverrideIndicatorProps> = props => {
  return (
    <Tooltip title="These settings are overridden">
      <BlockIcon style={{ color: "red" }} />
    </Tooltip>
  );
};

export interface ISettingsComponentProps {
  ReadOnly: boolean;
  WebLevel: SettingsOrder;
  LevelName: string;
  Id: string | null;
  OnNotifyIfSettingsExist: (settingExists: boolean) => void;
  IsOverwritten: boolean;
  Applies: boolean;
  Label: string
}
interface ISettingsPageProps {
  Component: React.FunctionComponent<ISettingsComponentProps>;
  Label: string;
}

export const SettingsPage: React.FC<ISettingsPageProps> = props => {
  const stateSelection = useSelector((state: State) => state.Filter.Selection);
  const webId = useSelector((state: State) => state.Session.WebID);
  const WebSelected: boolean = stateSelection.Level === WebLevelEnum.Web;

  const BranchgroupSelected: boolean =
    stateSelection.Level === WebLevelEnum.BranchGroup;

  const BranchSelected: boolean = stateSelection.Level === WebLevelEnum.Branch;
  useEffect(() => {
    if (!BranchgroupSelected && !BranchSelected) {
      setBranchGroupHasSettings(false);
    }
    if (!BranchSelected) {
      setBranchHasSettings(false);
    }
  }, [BranchgroupSelected, BranchSelected]);

  const [branchHasSettings, setBranchHasSettings] = useState(false);

  const [branchGroupHasSettings, setBranchGroupHasSettings] = useState(false);

  const branchGroupOverwritten = branchHasSettings;
  const branchGroupSettingsApply = !branchHasSettings && branchGroupHasSettings;
  const branchSettingsApply = branchHasSettings;
  const webSettingsApply = !branchHasSettings && !branchGroupHasSettings;
  const webOverwritten = !webSettingsApply;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <WebLevelSelect />
      </Grid>
      <React.Fragment>
        {BranchSelected && (
          <React.Fragment>
            <Grid item xs={12}>
              <props.Component
                Label={props.Label}
                ReadOnly={!BranchSelected}
                WebLevel={SettingsOrder.Branch}
                Id={stateSelection.Branch?.id!}
                LevelName={stateSelection.Branch!.label!}
                OnNotifyIfSettingsExist={(exists: boolean) => {
                  setBranchHasSettings(exists);
                }}
                IsOverwritten={false}
                Applies={branchSettingsApply}
              />
            </Grid>
            <SettingOverrideGridItem />
          </React.Fragment>
        )}

        {(BranchgroupSelected || BranchSelected) && (
          <React.Fragment>
            <Grid item xs={12}>
              <props.Component
                Label={props.Label}
                ReadOnly={!BranchgroupSelected}
                WebLevel={SettingsOrder.BranchGroup}
                Id={stateSelection.Branchgroup?.id!}
                LevelName={stateSelection.Branchgroup?.label!}
                OnNotifyIfSettingsExist={(exists: boolean) => {
                  setBranchGroupHasSettings(exists);
                }}
                IsOverwritten={branchGroupOverwritten}
                Applies={branchGroupSettingsApply}
              />
            </Grid>
            <SettingOverrideGridItem />
          </React.Fragment>
        )}
        <Grid item xs={12}>
          <props.Component
            Label={props.Label}
            ReadOnly={!WebSelected}
            WebLevel={SettingsOrder.Web}
            Id={webId}
            LevelName=""
            OnNotifyIfSettingsExist={(exists: boolean) => { }}
            IsOverwritten={webOverwritten}
            Applies={webSettingsApply}
          />
        </Grid>
      </React.Fragment>
    </Grid>
  );
};
