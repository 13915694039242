import { Filter_Reducer_Reset } from "actions/FilterActions";
import { push } from "connected-react-router";
import { put, select, takeEvery } from "redux-saga/effects";
import { State } from "rootExports/rootReducer";
import { Session_Reducer_Reset } from "../actions/SessionActions";
import { Session_Saga_Logout } from "../actions/SessionSagactions";

function* handleLogout() {
  const selectWebName = (state: State) => state.Session.WebName;
  const webName: string = yield select(selectWebName);
  yield put(Session_Reducer_Reset());
  yield put(Filter_Reducer_Reset());
  yield put(push("/weblogin/" + webName));
}

export function* watch_Logout() {
  yield takeEvery(Session_Saga_Logout, handleLogout);
}
