import { Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel, FormGroup, Grid, TextField } from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SaveIcon from "@material-ui/icons/Save";
import { ErrorMessages } from "components/shared/ErrorMessages";
import { HelpTextButton } from "components/shared/various";
import React, { useState } from "react";
import { AdminEntityValidationResult, BaseUpdateSalesPrefActionModel, RoundDirection } from "WebApiClient";
import * as HelpTexts from "./actions.HelpTexts";
interface ISalesPrefActionEditProps {
  Data: BaseUpdateSalesPrefActionModel;
  DataId: string | undefined;
  ValidationResult: AdminEntityValidationResult | undefined;
  ServerError: boolean;
  OnSave: (data: BaseUpdateSalesPrefActionModel) => void;
  OnCancel: () => void;
  IsLoading: boolean;

}
export const SalesPrefActionInputComponent: React.FC<ISalesPrefActionEditProps> = props => {
  const [data, setData] = useState({ ...props.Data });

  const titleError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["title"]
    : undefined;
  const ActionError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["Action"]
    : undefined;
  const ActionChildError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["Actionchild"]
    : undefined;
  const ActionInfantError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["Actioninfant"]
    : undefined;

  return (
    <Card>
      <CardHeader
        title={`${props.DataId !== undefined ? "Edit" : "Create"} Action`}
      />
      <CardContent>
        <Grid container spacing={2}>
          {props.ServerError && <ErrorMessages ServerError={true} />}
          {props.ValidationResult && props.ValidationResult.errorSummary && (
            <ErrorMessages Messages={props.ValidationResult.errorSummary} />
          )}


          <Grid item xs={12}>
            <Box marginBottom={2}>
              <TextField
                error={titleError !== undefined}
                required
                disabled={props.IsLoading}
                label="Title"
                value={data.title}
                helperText={titleError}
                onChange={e => setData({ ...data, title: e.target.value })}
                InputProps={{
                  endAdornment: <HelpTextButton HelpText={HelpTexts.SalesPrefActionsTitleHelpText} />
                }}
              />
            </Box>
          </Grid>


          <Grid item xs={12}>
            <Box marginBottom={2}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.actionDoDelete}
                      onChange={() =>
                        setData({
                          ...data,
                          actionDoDelete: !data.actionDoDelete
                        })
                      }
                    />
                  }

                  label={<span>Delete fare from response <HelpTextButton HelpText={HelpTexts.SalesPrefActionsActionDoDeleteHelptext} /></span>}
                />
              </FormGroup>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box marginBottom={2}>
              <TextField
                error={titleError !== undefined}

                disabled={props.IsLoading}
                label="Target display name"
                value={data.actionTargetName}
                helperText={titleError}
                onChange={e => setData({ ...data, actionTargetName: e.target.value })}
                InputProps={{
                  endAdornment: <HelpTextButton HelpText={HelpTexts.SalesPrefActionsActionTargetNameHelptext} />
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box marginBottom={2}>
              <TextField
                error={titleError !== undefined}
                type="number"
                disabled={props.IsLoading}
                label="Target priority"
                value={data.actionTargetPosition}
                onChange={e => setData({ ...data, actionTargetPosition: e.target.value as any as number | undefined })}
                InputProps={{
                  endAdornment: <HelpTextButton HelpText={HelpTexts.SalesPrefActionsActionTargetPriorityHelptext} />
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            props.OnSave(data);
          }}
          disabled={props.IsLoading}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => { }}
          disabled={props.IsLoading}
          startIcon={<RotateLeftIcon />}
        >
          Reset
        </Button>
      </CardActions>
    </Card>
  );
};

export function GetRoundDirectionLabel(rounddirection: RoundDirection) {
  let result: string = "";
  switch (rounddirection) {
    case RoundDirection.Down:
      result = "Down";
      break;
    case RoundDirection.Up:
      result = "Up";
      break;
    case RoundDirection.UpDown:
      result = "Up/Down";
      break;
  }
  return result;
}
