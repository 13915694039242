import { Box, Button, Card, CardActions, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SaveIcon from "@material-ui/icons/Save";
import { ErrorMessages } from "components/shared/ErrorMessages";
import { BaseRuleInputComponent } from "components/shared/inputs/BaseRule/BaseRule";
import { HelpTextButton } from "components/shared/various";
import React, { useState } from "react";
import { AdminEntityValidationResult, BaseUpdateCalcRuleModel, UpdateCalcCurrencyModel, UpdateCalcFormulaModel, UpdateCalcRuleModel } from "WebApiClient";
import * as HelpTexts from "./rules.helpTexts";
interface ICalcRuleEditProps {
  Data: BaseUpdateCalcRuleModel;
  DataId: string | undefined;
  ValidationResult: AdminEntityValidationResult | undefined;
  ServerError: boolean;
  OnSave: (data: BaseUpdateCalcRuleModel) => void;
  OnCancel: () => void;
  IsLoading: boolean;
  FormulaOptions: UpdateCalcFormulaModel[];
  ExistingRules: UpdateCalcRuleModel[];
  CurrencyOptions: UpdateCalcCurrencyModel[];
}

export const CalcRuleInputComponent: React.FC<ICalcRuleEditProps> = props => {
  const [data, setData] = useState<BaseUpdateCalcRuleModel>({ ...props.Data });
  return (
    <Card>
      <CardHeader
        title={<span>{`${props.DataId !== undefined ? "Edit" : "Create"} rule`} <HelpTextButton HelpText={HelpTexts.CalcRuleGeneralHelptext} /></span>}
      />
      <CardContent>
        <Grid container spacing={2} direction="row">
          {props.ServerError && <ErrorMessages ServerError={true} />}
          {props.ValidationResult && props.ValidationResult.errorSummary && (
            <ErrorMessages Messages={props.ValidationResult.errorSummary} />
          )}

          <Grid container item xs={12} spacing={6}>


            <Grid item xs={12} md={4}>
              <Box marginBottom={2}>
                <FormControl fullWidth>
                  <InputLabel>Position <HelpTextButton HelpText={HelpTexts.CalcRuleSortOrderHelptext} /></InputLabel>
                  <Select

                    value={data.filterSortOrder}
                    onChange={e =>
                      setData({
                        ...data,
                        filterSortOrder: e.target.value as any as number
                      })
                    }


                  >
                    <MenuItem value={0}>
                      Insert at beginning
                  </MenuItem>
                    <MenuItem value={9999}>
                      Append to end
                  </MenuItem>
                    {props.ExistingRules.map((e, index) => <MenuItem key={e.filterSortOrder} value={e.filterSortOrder}>
                      {e.filterSortOrder} {e.name && e.name.length > 0 ? `- ${e.name}` : ""} {e.filterSortOrder === props.Data.filterSortOrder ? " (Current)" : ""}
                    </MenuItem>)}

                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box marginBottom={2}>
                <FormControl fullWidth>
                  <InputLabel>Formula <HelpTextButton HelpText={HelpTexts.CalcRuleFormulaHelptext} /></InputLabel>
                  <Select
                    value={data.calcFormulaID}
                    onChange={e =>
                      setData({
                        ...data,
                        calcFormulaID: e.target.value as string
                      })
                    }
                    autoWidth


                  >
                    {props.FormulaOptions.map((formula, index) => (
                      <MenuItem value={formula.id} key={index}>
                        Currency:{" "}
                        {
                          props.CurrencyOptions.find(
                            e => e.id === formula.currencyID
                          )?.currencyCode
                        }{" "}
                      - {formula.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>


          </Grid>
          <BaseRuleInputComponent Data={data} OnUpdate={(baseData) => { setData({ ...data, ...baseData }) }} DisablePassengers={false} />
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            props.OnSave(data);
          }}
          disabled={props.IsLoading}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => { }}
          disabled={props.IsLoading}
          startIcon={<RotateLeftIcon />}
        >
          Reset
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={props.OnCancel}
          disabled={props.IsLoading}
          startIcon={<RotateLeftIcon />}
        >
          Close
        </Button>
      </CardActions>
    </Card>
  );
};
