import { SettingsPage } from "components/shared/SettingPagesVarious";
import React from "react";
import ConsolidatorCalculationSettings from "./consolidatorCalculation.Settings";
interface IConsolidatorCalculationPageProps { }

const ConsolidatorCalculationPage: React.FC<IConsolidatorCalculationPageProps> = props => {
  return <SettingsPage Component={ConsolidatorCalculationSettings} Label="Consolidator calculation" />;
};

export default ConsolidatorCalculationPage;
