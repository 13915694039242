import { Card, CardContent, CardHeader,  Grid, Typography } from "@material-ui/core";
import * as React from "react";

export interface IDisplaySettingsGettingsStartedProps { }

export default function DisplaySettingsGettingsStarted(
  props: IDisplaySettingsGettingsStartedProps
) {
  return <Grid container>
    <Grid item xs={12}>    <Card>
      <CardHeader title={"Budgeting"} />
      <CardContent>
        <Typography variant="body1">
          With deposits, you can restrict how much money can be spent. <br></br>
          If the deposit is exhausted, it has to be manually be reset.

        </Typography>
      </CardContent>
    </Card></Grid>

  </Grid>;
}
