import { Card, CardContent, Checkbox, FormControlLabel, List, ListItem, ListItemIcon, ListItemText, makeStyles, Tooltip, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import RefreshIcon from "@material-ui/icons/Refresh";
import { TableIcons } from "components/shared/tableIcons";
import { HelpTextButton } from "components/shared/various";
import MaterialTable, { Column } from "material-table";
import * as moment from "moment";
import React from "react";
import { CreateSettingsRequest, UpdateSalesPrefActionModel, UpdateSalesPrefRuleModel } from "WebApiClient";
import { SalesPrefRulesIntroHelptext } from "./rules.helpTexts";

interface IRulesTableProps {
  OnRefresh: () => void;
  OnDelete: (id: string) => void;
  OnEdit: (data: UpdateSalesPrefRuleModel | undefined) => void;
  IsLoading: boolean;
  Data: UpdateSalesPrefRuleModel[];
  ActionOptions: UpdateSalesPrefActionModel[];
  LevelSelection: CreateSettingsRequest;
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  }
}));
export const RulesTable: React.FC<IRulesTableProps> = props => {
  const classes = useStyles();
  const lastEdit: string = "";

  const hasFormulas: boolean = props.ActionOptions.length > 0;
  function GetRowStyle(
    data: any,
    index: number,
    level: number
  ): React.CSSProperties {
    const bg = data as UpdateSalesPrefRuleModel;
    let result: React.CSSProperties = {
      backgroundColor: bg.id === lastEdit ? "#a8c8dd" : undefined
    };
    return result;
  }

  function RuleApplies(
    active: boolean,
    validFrom: string | undefined,
    validTo: string | undefined,
    utcOffset: number
  ): boolean {
    let parsedUtcOffset = Math.round((utcOffset / (60 * 10000000)) % 60);
    if (!active) {
      return false;
    }
    let parsedFrom: moment.Moment | undefined = undefined;
    let parsedTo: moment.Moment | undefined = undefined;
    if (validFrom) {
      parsedFrom = moment(validFrom, "YYYY-MM-DD").add("minutes", parsedUtcOffset);
    }
    if (validTo) {
      parsedTo = moment(validTo, "YYYY-MM-DD").add("minutes", parsedUtcOffset);
    }
    let nowUTC = moment.utc();
    if (nowUTC.isBefore(parsedFrom) || nowUTC.isAfter(parsedTo)) {
      return false;
    }
    return true;
  }

  const RulesColumns: Array<Column<UpdateSalesPrefRuleModel>> = [
    { title: "Sort order", field: "filterSortOrder" },
    {
      title: "Applicable",
      render: rowdata => {
        const utcOffset = rowdata.utcOffsetMinutes;
        const active = rowdata.active;
        const validFrom = rowdata.validFromDate;
        const validTo = rowdata.validUntilDate;
        // const applies = RuleApplies(active, validFrom, validTo, utcOffset);
        const applies = true;
        return (
          <Tooltip
            title={
              <Card>
                <CardContent>
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItem>
                      <ListItemText primary="Applicable" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <InfoIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <FormControlLabel
                            control={<Checkbox checked={active} />}
                            label="Active"
                          />
                        }
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            }
          >
            <span>{applies ? "Applies" : "NoApply"}</span>
          </Tooltip>
        );
      }
    },
    { title: "Passengers", field: "passengers" },
    { title: "Origins", field: "origins" },
    { title: "Destinations", field: "destinations" },
    { title: "Tariff carrier", field: "tariffCarrier" },
    { title: "Cabin class", field: "cabinClass" },
    { title: "Name", field: "name" },
    {
      title: "Action",
      field: "salesPrefActionID",
      render: rowdata => {
        const action = props.ActionOptions.filter(
          e => e.id === rowdata.salesPrefActionID
        )[0];
        if (action !== undefined) {
          return action.title;
        } else {
          return "Unknown formula";
        }
      }
    },

    { title: "Single/Multileg", field: "singleLegOrMultiLeg" },
    { title: "Match codes", field: "matchCodes" },
    { title: "Brand names", field: "brandNames" },
    { title: "Corporate codes", field: "corporateCodes" },
    { title: "GDS", field: "gds" },
    { title: "PCC", field: "pcc" },
    { title: "Fare types", field: "fareTypes" },
    { title: "Marketing carrier", field: "marketingCarrier" },
    { title: "Operating carrier", field: "operatingCarrier" },
    { title: "Booking class", field: "bookingClass" },
    { title: "Farebase", field: "farebase" },
    { title: "First departure date", field: "firstDepartureDate", type: "date" },
    { title: "Last departure date", field: "lastDepartureDate", type: "date" },
    {
      title: "First Travel completion date",
      field: "firstTravelCompletionDate",
      type: "date"
    },
    { title: "Last Travel completion date", field: "lastTravelCompletionDate", type: "date" },
    { title: "Vias", field: "vias" },
    { title: "Stops", field: "stops" },
    { title: "Flight segments", field: "flightsegments" },
    { title: "Flight segmensts remarks", field: "flightsegmentsRemarks" },
    { title: "Point of sale timezone", field: "utcOffset" }
  ];

  function DeleteRow(data: UpdateSalesPrefRuleModel): Promise<void> {
    return new Promise(resolve => {
      props.OnDelete(data.id);
      resolve();
    });
  }

  return (
    <MaterialTable
      icons={TableIcons}
      title={<Typography variant="h5">Sales preference Rules <HelpTextButton HelpText={SalesPrefRulesIntroHelptext} /></Typography>}
      columns={RulesColumns}
      data={props.Data}
      isLoading={props.IsLoading}
      options={{ paging: false }}

      editable={{
        onRowDelete: DeleteRow
      }}
      actions={[
        {
          icon: () => <RefreshIcon />,
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () => props.OnRefresh()
        },
        {
          icon: () => <EditIcon />,
          tooltip: "View",
          onClick: (event, row) => {
            const data = row as UpdateSalesPrefRuleModel;
            props.OnEdit(data);
          }
        },
        {
          icon: () => <AddIcon color={hasFormulas ? undefined : "error"} />,
          tooltip: hasFormulas
            ? "Add"
            : "You have to create an formula before creating a rule.",
          isFreeAction: true,
          onClick: () => {
            if (hasFormulas) {
              props.OnEdit(undefined);
            } else {
            }
          }
        },

      ]}
    />
  );
};

export default RulesTable;
