// Import the image
import image25Kg from '../../../Styles/images/IncludedServIcons/25K.png';
import image02P from '../../../Styles/images/IncludedServIcons/02P.png';
import baggage from '../../../Styles/images/IncludedServIcons/image.gif';
import bagGrey from '../../../Styles/images/IncludedServIcons/bag.gif';

import BREAKFAST from '../../../Styles/images/IncludedServIcons/Layer 10.png';
import DINNER from '../../../Styles/images/IncludedServIcons/Layer 3.png';
import SNACK from '../../../Styles/images/IncludedServIcons/Layer 2.png';
import LUNCH from '../../../Styles/images/IncludedServIcons/Layer 4.png';
import SANDWICH from '../../../Styles/images/IncludedServIcons/Layer 5.png';
import BEER from '../../../Styles/images/IncludedServIcons/Layer 6.png';
import DRINK from '../../../Styles/images/IncludedServIcons/Layer 7.png';
import POPCORN from '../../../Styles/images/IncludedServIcons/Layer 8.png';
import SOUP from '../../../Styles/images/IncludedServIcons/Layer 9.png';
import LUGGAGE from '../../../Styles/images/IncludedServIcons/Layer 11.png';
import BAGGAGE_NEW from '../../../Styles/images/IncludedServIcons/Layer 12.png';
import CHAIR from '../../../Styles/images/IncludedServIcons/Layer 13.png';
import MILES from '../../../Styles/images/IncludedServIcons/Layer 14.png';
import ISLAND from '../../../Styles/images/IncludedServIcons/Layer 21.png';
import CARIBBEAN from '../../../Styles/images/IncludedServIcons/Layer 1.png';
import COMP from '../../../Styles/images/IncludedServIcons/Layer 16.png';
import FAMILY from '../../../Styles/images/IncludedServIcons/Layer1Wide.png';
import Change from '../../../Styles/images/IncludedServIcons/Layer 18.png';
import TV from '../../../Styles/images/IncludedServIcons/Layer 20.png';
import Baggage2 from '../../../Styles/images/IncludedServIcons/Layer 15.png';
import WINE from '../../../Styles/images/IncludedServIcons/Layer 17.png';
import EXTRA_DRINK from '../../../Styles/images/IncludedServIcons/Layer 19.png';
import COLD_BRUNCH from '../../../Styles/images/IncludedServIcons/Layer 22.png';



export const SabreIconsEmoji: any = {
    "AP": { "icon": "🇺🇸", "hint": "AMERICAN" },
    "B": { "icon": BREAKFAST, "hint": "BREAKFAST" },
    "BP": { "icon": ISLAND, "hint": "BERMUDA" },
    "C": { "icon": DRINK, "hint": "ALCOHOL BEV/COMP" },
    "CB": { "icon": CARIBBEAN, "hint": "CARIBBEAN PLAN" },
    "CBA": { "icon": EXTRA_DRINK, "hint": "COMP DRINKS" },
    "CP": { "icon": COMP, "hint": "CONTINENTAL BFST" },
    "D": { "icon": DINNER, "hint": "DINNER" },
    "EDL": { "icon": SOUP, "hint": "COLD BREAKFAST" },
    "EP": { "icon": "🇪🇺", "hint": "EUROPEAN" },
    "F": { "icon": LUGGAGE, "hint": "FOOD FOR PURCHASE" },
    "FDL": { "icon": COLD_BRUNCH, "hint": "COLD BRUNCH" },
    "FP": { "icon": FAMILY, "hint": "FAMILY PLAN" },
    "G": { "icon": LUGGAGE, "hint": "FOOD FOR PURCHASE" },
    "H": { "icon": LUNCH, "hint": "HOT MEAL" },
    "K": { "icon": COMP, "hint": "CONTINENTAL BFAST" },
    "L": { "icon": LUNCH, "hint": "LUNCH" },
    "M": { "icon": LUNCH, "hint": "MEALS" },
    "BM": { "icon": BREAKFAST, "hint": "Breakfast Meal" },
    "BD": { "icon": DINNER, "hint": "Business  Dinner" },
    "MA": { "icon": "🇺🇸", "hint": "MOD AMERICAN" },
    "MD": { "icon": DINNER, "hint": "MAIN AND DINNER" },
    "BL": { "icon": SOUP, "hint": "Breakfast Light" },
    "O": { "icon": SNACK, "hint": "COLD MEAL" },
    "P": { "icon": BEER, "hint": "ALCOHOL BEV/PUR" },
    "R": { "icon": POPCORN, "hint": "REFRESHMENT" },
    "S": { "icon": POPCORN, "hint": "SNACK" },
    "RG": { "icon": SOUP, "hint": "Regular Gourmet meal" },
    "LD": { "icon": SANDWICH, "hint": "Light Dinner" },
    "V": { "icon": LUGGAGE, "hint": "REFRSHMNT/PUR" },
    "Alcoholic drinks": { "icon": WINE, "hint": "" },
    // "01P": { "icon": BAGGAGE_NEW, "hint": "" },
    // "02P": { "icon": image02P, "hint": "" },
    // "25K": { "icon": image25Kg, "hint": "" },
    "Seat": { "icon": CHAIR, "hint": "" },
    "MileageAccural": { "icon": MILES, "hint": "" },
    "Change": { "icon": Change, "hint": "" },
    "Baggage": { "icon": Baggage2, "hint": "" },
    "InFlightEntertainment": { "icon": TV, "hint": "" }
};


interface FoodItem {
    code: string;
    hint: string;
}

export const allDefaultCode: FoodItem[] = [
    { "code": "Alcoholic drinks", "hint": "" },
    { "code": "MD", "hint": "MAIN AND DINNER" },
    { "code": "CP", "hint": "CONTINENTAL BFST" },
    { "code": "RG", "hint": "Regular Gourmet meal" },
    { "code": "D", "hint": "DINNER" },
    { "code": "MileageAccural", "hint": "" },
    { "code": "FDL", "hint": "COLD BRUNCH" },
    { "code": "MA", "hint": "MOD AMERICAN" },
    { "code": "F", "hint": "FOOD FOR PURCHASE" },
    { "code": "O", "hint": "COLD MEAL" },
    { "code": "BM", "hint": "Breakfast Meal" },
    { "code": "EDL", "hint": "COLD BREAKFAST" },
    { "code": "CB", "hint": "CARIBBEAN PLAN" },
    { "code": "S", "hint": "SNACK" },
    { "code": "Carry-on baggage", "hint": "" },
    { "code": "Checked baggage", "hint": "" },
    { "code": "L", "hint": "LUNCH" },
    { "code": "LD", "hint": "Light Dinner" },
    { "code": "CBA", "hint": "COMP DRINKS" },
    { "code": "Change", "hint": "" },
    { "code": "Seat", "hint": "" },
    { "code": "C", "hint": "ALCOHOL BEV/COMP" },
    { "code": "BL", "hint": "Breakfast Light" },
    { "code": "EP", "hint": "EUROPEAN" },
    { "code": "InFlightEntertainment", "hint": "" },
    { "code": "G", "hint": "FOOD FOR PURCHASE" },
    { "code": "AP", "hint": "AMERICAN" },
    { "code": "BP", "hint": "BERMUDA" },
    { "code": "R", "hint": "REFRESHMENT" },
    { "code": "V", "hint": "REFRSHMNT/PUR" },
    { "code": "M", "hint": "MEALS" },
    { "code": "B", "hint": "BREAKFAST" },
    { "code": "P", "hint": "ALCOHOL BEV/PUR" },
    { "code": "H", "hint": "HOT MEAL" },
    { "code": "FP", "hint": "FAMILY PLAN" },
    { "code": "BD", "hint": "Business Dinner" },
    { "code": "K", "hint": "CONTINENTAL BFAST" }
];


//  "RG": {
//     "hint": "Regular Gourmet meal",
//     "icon": "🍜" // Steaming bowl to represent a more substantial meal
//   },
//   "LD": {
//     "hint": "Light Dinner",
//     "icon": "🍽️"
//   }
//   "BL": {
//     "hint": "Light Dinner",
//     "icon": "🍽️"
//   }
// "BM": {
//     "hint": "Light Dinner",
//     "icon": "🍽️"
//   }
// "BM": {
//     "hint": "Light Dinner",
//     "icon": "🍽️"
//   }
// "n": {
//     "hint": "",
//     "icon": ""
//   }