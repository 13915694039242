const sampleText =
    "Thank you for booking with HitchHiker Travel.<b>#FILEKEY#<b>You will receive a confirmation email soon.";
export const DisplaySettingsShowBookingmanagerTabHelpText = (
    <div>
        <p>
            <b>Show Booking Manager Tab</b>
        </p>

        <ul>
            <li>
                Controls the visibility of the Booking Manager Tab in Agentplus.
            </li>
        </ul>
    </div>
);
export const DisplaySettingsShowHotelTabHelpText = (
    <div>
        <p>
            <b>Show Hotel Tab</b>
        </p>

        <ul>
            <li>Controls the visibility of the Hotel Tab in Agentplus.</li>
        </ul>
    </div>
);
export const DisplaySettingsB2CAirportSearch = (
    <div>
        <p>
            <b>Show Airport Search Tab</b>
        </p>

        <ul>
            <li>
                Controls the visibility of the Airport Search Tab in B2C
                Agentplus.
            </li>
        </ul>
    </div>
);
export const DisplaySettingsB2CExtraService = (
    <div>
        <p>
            <b>Show Extra Ancillaries Tab</b>
        </p>

        <ul>
            <li>
                Controls the visibility of the Extra Ancillaries Tab in B2C
                Agentplus.
            </li>
        </ul>
    </div>
);
export const DisplaySettingsB2CModalColorB = (
    <div>
        <p>
            <b>Show Modal Background Tab</b>
        </p>

        <ul>
            <li>
                Controls the opacity and background of modal in B2C Agentplus.
            </li>
        </ul>
    </div>
);
export const DisplaySettingsB2CLinkContainer = (
    <div>
        <p>
            <b>Show Link Container Tab</b>
        </p>

        <ul>
            <li>Controls the link you want to include in B2C Agentplus.</li>
        </ul>
    </div>
);
export const DisplaySettingsB2CBrandName = (
    <div>
        <p>
            <b>Show BrandName Tab</b>
        </p>

        <ul>
            <li>
                Controls the Hide/Show of brand name in fare header B2C
                Agentplus.
            </li>
        </ul>
    </div>
);
export const DisplaySettingsB2CPassengerTitle = (
    <div>
        <p>
            <b>Show PassengerTitle Tab</b>
        </p>

        <ul>
            <li>
                Controls the Hide/Show of passenger title in fare header B2C
                Agentplus.
            </li>
        </ul>
    </div>
);
export const DisplaySettingsB2CFilterPosition = (
    <div>
        <p>
            <b>Show FilterPosition Tab</b>
        </p>

        <ul>
            <li>Controls the Hide/Show of filter position B2C Agentplus.</li>
        </ul>
    </div>
);
export const DisplaySettingsB2CAssistance = (
    <div>
        <p>
            <b>Show Assiatance Tab</b>
        </p>

        <ul>
            <li>Controls the add assistance in fare booking B2C Agentplus.</li>
        </ul>
    </div>
);
export const DisplaySettingsB2CMarqueeText = (
    <div>
        <p>
            <b>Show Marquee text Tab</b>
        </p>
        <ul>
            <li>Controls the Text you want to include in Agentplus.</li>
        </ul>
    </div>
);
export const DisplaySettingsB2CStyle = (
    <div>
        <p>
            <b>Show Style Tab</b>
        </p>
        <ul>
            <li>Controle Css style of B2C application</li>
        </ul>
    </div>
);
export const DisplaySettingsB2CConfirmationText = (
    <div>
        <p>
            <b>Show Confirmation Text</b>
        </p>
        <ul>
            <li>
                Sample : <br />
                {sampleText}
            </li>
        </ul>
    </div>
);
export const DisplaySettingsB2CPreferredLanguage = (
    <div>
        <p>
            <b>Show Preferred Language Tab</b>
        </p>

        <ul>
            <li>
                Controls the visibility of the Preferred Language Tab in B2C
                Agentplus.
            </li>
        </ul>
    </div>
);
export const DisplaySettingsShowCabinContainerHelpText = (
    <div>
        <p>
            <b>Show Cabin Container</b>
        </p>

        <ul>
            <li>
                Controls the visibility of the Cabin Container in Agentplus.
            </li>
        </ul>
    </div>
);
export const DisplaySettingEnableBookingDetailsHelpText = (
    <div>
        <p>
            <b>Enable booking details</b>
        </p>

        <ul>
            <li>Controls if BookingDetails can be accessed.</li>
        </ul>
    </div>
);
export const DisplaySettingsShowFareQueryTabHelpText = (
    <div>
        <p>
            <b>Show Fare Query Tab</b>
        </p>

        <ul>
            <li>Controls the visibility of the Fare Query Tab in Agentplus.</li>
        </ul>
    </div>
);
export const DisplaySettingsShowMenuHelpText = (
    <div>
        <p>
            <b>Show Menu On Page</b>
        </p>

        <ul>
            <li>Controls the visibility of the Menu On Page in Agentplus.</li>
        </ul>
    </div>
);
export const DisplaySettingsDateFormatHelpText = (
    <div>
        <p>
            <b>Dateformat</b>
        </p>

        <ul>
            <li>Sets the used dateformat for inputs and display of dates.</li>
        </ul>
    </div>
);
export const DisplaySettingsLocaleHelpText = (
    <div>
        <p>
            <b>Locale</b>
        </p>

        <ul>
            <li>
                Controls the the country-specific format of dates and numeric
                values.
            </li>
        </ul>
    </div>
);
export const DisplaySettingsStartPageHelpText = (
    <div>
        <p>
            <b>Start page</b>
        </p>

        <ul>
            <li>Controls the default startpage in AgentPlus after login.</li>
        </ul>
    </div>
);
export const DisplayFareHeader = (
    <div>
        <p>
            <b>Start page</b>
        </p>
        <ul>
            <li>Controls the default Fare Header Section in AgentPlus.</li>
        </ul>
    </div>
);
export const SmartCalender = (
    <div>
        <p>
            <b>Smart Calendar</b>
        </p>
        <ul>
            <li>Controls the default Smart Calendar Section in AgentPlus.</li>
        </ul>
    </div>
);
export const CabinClassViewAndSelection = (
    <div>
        <p>
            <b>CabinClasses</b>
        </p>
        <ul>
            <li>
                Controls the visibility of cabin classes and its Preselection.
            </li>
        </ul>
    </div>
);
export const FiltersOrderHelperTextB2B = (
    <div>
        <p>
            <b>Filters</b>
        </p>
        <ul>
            <li>Controls the visibility and order of filters.</li>
            <li>Drag items in the list to change their order.</li>
            <br />
        </ul>
        <p>
            <b>Default order:</b>
        </p>
        <ul>
            <li>order1 - Stops</li>
            <li>order2 - Baggage included</li>
            <li>order3 - Airports</li>
            <li>order4 - Airlines</li>
            <li>order5 - Alliances</li>
            <li>order6 - Departure time</li>
            <li>order7 - Arrival time</li>
            <li>order8 - Cabin class</li>
            <li>order9 - Fare type</li>
            <li>order10 - QueryPCC</li>
            <li>order11 - BookingPCC</li>
            <li>order12 - Source</li>
            <li>order13 - Allotments</li>
            <li>order14 - Included services</li>
        </ul>
    </div>
);
export const FiltersOrderHelperTextB2C = (
    <div>
        <p>
            <b>Filters</b>
        </p>
        <ul>
            <li>Controls the visibility and order of filters.</li>
            <li>Drag items in the list to change their order.</li>
            <br />
        </ul>
        <p>
            <b>Default order:</b>
        </p>
        <ul>
            <li>order1 - Departure time</li>
            <li>order2 - Arrival time</li>
            <li>order3 - Stops</li>
            <li>order4 - Baggage included</li>
            <li>order5 - Baggage type</li>
            <li>order6 - Airports</li>
            <li>order7 - Cabin class</li>
            <li>order8 - Allotments</li>
            <li>order9 - Included services</li>
            <li>order10 - Alliances</li>
            <li>order11 - Airlines</li>
        </ul>
    </div>
);
export const LanguageViewAndSelection = (
    <div>
        <p>
            <b>Language selection</b>
        </p>
        <ul>
            <li>Controls the visibility of language selection.</li>
        </ul>
    </div>
);

export const FareCollapseViewAndSelection = (
    <div>
        <p>
            <b>Flight details</b>
        </p>
        <ul>
            <li>Collapse on/off for show flight</li>
            <li>Collapse on/off for show flight calculation</li>
            <li>Collapse on/off for show flight time</li>
        </ul>
    </div>
);

export const DisplaySettingsAutoRefreshHelpText = (
    <div>
        <p>
            <b>Booking Manager</b>
        </p>
        <ul>
            <li>Auto Refresh Booking ticket list according to given time.</li>
        </ul>
    </div>
);

export const fieldContainer = (
    <div>
        <ul className="p-0">
            <li>
                <strong>#BRANCHCHANGE#</strong>: "Controls the visibility of the
                Branch change section in Top-bar"
            </li>
            <li>
                <strong>#CONFIRMATIONMAIL#</strong>: "Control the visibility of
                end confirmation tab in action column button"
            </li>
            <li>
                <strong>#HIDERULES#</strong>: "Control the visibility of Rules
                section in fare search page"
            </li>
            <li>
                <strong>#SENDAUTOCONFIRMATION#</strong>: "Control the visibility
                of Send Email Section in Booking confirmation Page"
            </li>
            <li>
                <strong>#SEATBOOKCONFIRMATION#</strong>: "Control the visibility
                of Seatmap Section in Booking Manager Page"
            </li>
            <li>
                <strong>#NOTESTBOOKINGS#</strong>: "Control the visibility of No
                test booking Section in Booking Manager Page"
            </li>
            <li>
                <strong>#MAIL=VW-emailTemplate.html#</strong>: "Set email
                template which we provide in this container it will automatic
                choose for send email confirmation"
            </li>
            <li>
                <strong>#NameChangeE9#</strong>: "Change Name of passenger in
                booking details"
            </li>
            <li>
                <strong>#REBOOKING#</strong>: "Control the visibility of rebook
                section in Booking Manager Page"
            </li>
            <li>
                <strong>#MUSTLOGIN#</strong>: "You must be logged into the
                application, even if you are using a deeplink."
            </li>
            <li>
                <strong>#SPECIALDISCOUNT#</strong>: "Control the visibility of
                the special discount section in the agent application."
            </li>
        </ul>
    </div>
);
