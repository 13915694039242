// import { useCustomerProfileDataClient } from "hooks/useHttpClient";
import { InvariantDateTimeInput } from "components/shared/inputs/invariantDateInput";
import { HelpTextButton } from "components/shared/various";
import React, { useEffect, useState } from "react";
import { AdminEntityValidationResult, AgentGenderEnum, CustomerProfilePassenger, CustomerProfilePassengerGroup, UpdateCustomerProfileDataRequest, BonusProgramModel } from "WebApiClient";
import { CustomerProfileDataComponent } from "./CustomerProfileDataComponent";
import * as HelpTexts from "./customerprofiles.HelpTexts";
//material-UI imports
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, makeStyles, TextField } from "@material-ui/core";
import { green, lightBlue } from "@material-ui/core/colors";
import { Alert, AlertTitle } from "@material-ui/lab";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface ICustomerProfileDataDetailsProps {
  Data: any;
  OnSave: (data: UpdateCustomerProfileDataRequest) => void;
  OnCancel: () => void;
  ValidationResult: AdminEntityValidationResult | undefined;
  myRef?: any
}

export const useStyles = makeStyles((theme) => ({

  activeAvatar: {
    color: theme.palette.getContrastText(green[400]),
    backgroundColor: green[400],
  },

  lightBlueBackground: {
    backgroundColor: lightBlue[50]
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

export const defaultPassenger: CustomerProfilePassenger = { dateOfBirth: undefined, firstName: "", lastName: "", middleName: "", title: "", gender: AgentGenderEnum.Unknown, frequentFlyerCards: [] };
export const defaultFrequentFlyerCards: BonusProgramModel = { carrierCodes: [], extensions: "", holderFirstName: "", holderLastName: "", holderTitle: "", programNumber: "", programType: "" };
export const defaultAdultObject: CustomerProfilePassengerGroup = { passengerType: "ADT", passengers: [{ ...defaultPassenger }] };
export const CustomerProfileDataEditComponent: React.FC<ICustomerProfileDataDetailsProps> = props => {
  const [CustomerProfileData, setCustomerProfileData] = useState(props.Data);
  const [buttonDB, setbuttonDB] = useState(false);
  const [errorTrigger, seterrorTrigger] = useState(false);
  const [globalValidationTrigger, setglobalValidationTrigger] = useState(false);
  const [ValidationTriggerParent, setValidationTriggerParent] = useState(false);
  const IsEdit = CustomerProfileData.id !== undefined;
  useEffect(() => {
    setCustomerProfileData(props.Data);
  }, [props.Data]);

  const nameError: string | undefined = props.ValidationResult
    ? props.ValidationResult.properyErrors!["name"]
    : undefined;

  //snackBar Added
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //helperFunction-validation(check validation other than empty)
  //flyerCard-Check
  const adultValidationFlyerCard = (fylerCardDataType: string, personType: any, i: number, j: number, min: number, max: number): boolean => {
    if (CustomerProfileData?.data?.passengers?.[personType]?.passengers[i]?.frequentFlyerCards[j]?.[fylerCardDataType].length > min && CustomerProfileData?.data?.passengers?.[personType]?.passengers[i]?.frequentFlyerCards[j]?.[fylerCardDataType].length < max) {
      return false
    } else {
      return true
    }
  }
  //innerCard-Check
  const adultValidationInnerCard = (innerCardDataType: string, personType: any, i: number, min: number, max: number): boolean => {
    if (CustomerProfileData?.data?.passengers?.[personType]?.passengers[i]?.[innerCardDataType].length > min && CustomerProfileData?.data?.passengers?.[personType]?.passengers[i]?.[innerCardDataType].length < max) {
      return false
    } else {
      return true
    }
  }
  //global-fields
  const globalFieldValidationChecker = () => {
    if ((CustomerProfileData?.data?.travellerID?.length > 2 && CustomerProfileData?.data?.travellerID?.length < 16) && (CustomerProfileData?.data?.emergencyNumber?.length > 6 && CustomerProfileData?.data?.emergencyNumber?.length < 16) && (CustomerProfileData?.title?.length > 2 && CustomerProfileData?.title?.length < 16)) {
      return false
    } else {
      return true
    }
  }

  const functionFinalChecker = () => {
    let arrCheck1: any = []//inner-FrequentFlyerCard-Checker-adultChecker
    let arrCheck2: any = []//inner-FrequentFlyerCard-Checker-childrenChecker
    let arrCheck3: any = []//inner-FrequentFlyerCard-Checker-infantChecker
    let arrCheck4: any = []//Global-card-Data-check array
    let arrCheck5: any = []//inner-Card-Checker-adultChecker
    let arrCheck6: any = []//inner-Card-Checker-childrenChecker
    let arrCheck7: any = []//inner-Card-Checker-infantChecker

    //inner-FrequentFylerCard-Checker
    //AdultError Handler
    for (let i = 0; i < CustomerProfileData?.data?.passengers?.adults?.passengers?.length; i++) {
      for (let j = 0; j < CustomerProfileData?.data?.passengers?.adults?.passengers[i]?.frequentFlyerCards?.length; j++) {
        if (Object.values(CustomerProfileData?.data?.passengers?.adults?.passengers[i]?.frequentFlyerCards[j]).includes('') || (CustomerProfileData?.data?.passengers?.adults?.passengers[i]?.frequentFlyerCards[j].carrierCodes.length == 0) || adultValidationFlyerCard('programNumber', 'adults', i, j, 3, 15) || adultValidationFlyerCard('holderTitle', 'adults', i, j, 1, 6) || adultValidationFlyerCard('holderFirstName', 'adults', i, j, 3, 50) || adultValidationFlyerCard('holderLastName', 'adults', i, j, 3, 50) || adultValidationFlyerCard('programType', 'adults', i, j, 3, 15) || adultValidationFlyerCard('extensions', 'adults', i, j, 3, 15)) {
          arrCheck1.push(true)
        } else {
          arrCheck1.push(false)
        }
      }
    }
    //childrenError Handler
    for (let i = 0; i < CustomerProfileData?.data?.passengers?.children?.passengers.length; i++) {
      for (let j = 0; j < CustomerProfileData?.data?.passengers?.children?.passengers[i]?.frequentFlyerCards.length; j++) {
        if (Object.values(CustomerProfileData?.data?.passengers?.children?.passengers[i]?.frequentFlyerCards[j]).includes('') || (CustomerProfileData?.data?.passengers?.children?.passengers[i]?.frequentFlyerCards[j].carrierCodes.length == 0) || adultValidationFlyerCard('programNumber', 'children', i, j, 3, 15) || adultValidationFlyerCard('holderTitle', 'children', i, j, 1, 6) || adultValidationFlyerCard('holderFirstName', 'children', i, j, 3, 50) || adultValidationFlyerCard('holderLastName', 'children', i, j, 3, 50) || adultValidationFlyerCard('programType', 'children', i, j, 3, 15) || adultValidationFlyerCard('extensions', 'children', i, j, 3, 15)) {
          arrCheck2.push(true)
        } else {
          arrCheck2.push(false)
        }
      }
    }
    //infantError Handler
    for (let i = 0; i < CustomerProfileData?.data?.passengers?.infants?.passengers?.length; i++) {
      for (let j = 0; j < CustomerProfileData?.data?.passengers?.infants?.passengers[i]?.frequentFlyerCards.length; j++) {
        if (Object.values(CustomerProfileData?.data?.passengers?.infants?.passengers[i]?.frequentFlyerCards[j]).includes('') || (CustomerProfileData?.data?.passengers?.infants?.passengers[i]?.frequentFlyerCards[j].carrierCodes.length == 0) || adultValidationFlyerCard('programNumber', 'infants', i, j, 3, 15) || adultValidationFlyerCard('holderTitle', 'infants', i, j, 1, 6) || adultValidationFlyerCard('holderFirstName', 'infants', i, j, 3, 50) || adultValidationFlyerCard('holderLastName', 'infants', i, j, 3, 50) || adultValidationFlyerCard('programType', 'infants', i, j, 3, 15) || adultValidationFlyerCard('extensions', 'infants', i, j, 3, 15)) {
          arrCheck3.push(true)
        } else {
          arrCheck3.push(false)
        }
      }
    }
    //innerCard-details checker
    //AdultError Handler
    for (let i = 0; i < CustomerProfileData?.data?.passengers?.adults?.passengers.length; i++) {
      if (CustomerProfileData?.data?.passengers?.adults?.passengers[i]?.dateOfBirth == null || CustomerProfileData?.data?.passengers?.adults?.passengers[i]?.firstName == '' || CustomerProfileData?.data?.passengers?.adults?.passengers[i]?.lastName == '' || CustomerProfileData?.data?.passengers?.adults?.passengers[i]?.middleName == '' || CustomerProfileData?.data?.passengers?.adults?.passengers[i]?.title == '' || adultValidationInnerCard('title', 'adults', i, 1, 6) || adultValidationInnerCard('firstName', 'adults', i, 2, 51) || adultValidationInnerCard('lastName', 'adults', i, 2, 51) || adultValidationInnerCard('middleName', 'adults', i, 2, 51)) {
        arrCheck5.push(true)
      }
      if (CustomerProfileData?.data?.passengers?.adults?.passengers[i]?.dateOfBirth != null && CustomerProfileData?.data?.passengers?.adults?.passengers[i]?.firstName != '' && CustomerProfileData?.data?.passengers?.adults?.passengers[i]?.lastName != '' && CustomerProfileData?.data?.passengers?.adults?.passengers[i]?.middleName != '' && CustomerProfileData?.data?.passengers?.adults?.passengers[i]?.title != '') {
        arrCheck5.push(false)
      }
    }
    //childrenError Handler
    for (let i = 0; i < CustomerProfileData?.data?.passengers?.children?.passengers.length; i++) {
      if (CustomerProfileData?.data?.passengers?.children?.passengers[i]?.dateOfBirth == null || CustomerProfileData?.data?.passengers?.children?.passengers[i]?.firstName == '' || CustomerProfileData?.data?.passengers?.children?.passengers[i]?.lastName == '' || CustomerProfileData?.data?.passengers?.children?.passengers[i]?.middleName == '' || CustomerProfileData?.data?.passengers?.children?.passengers[i]?.title == '' || adultValidationInnerCard('title', 'children', i, 3, 6) || adultValidationInnerCard('firstName', 'children', i, 2, 51) || adultValidationInnerCard('lastName', 'children', i, 2, 51) || adultValidationInnerCard('middleName', 'children', i, 2, 51)) {
        arrCheck6.push(true)
      }
      if (CustomerProfileData?.data?.passengers?.children?.passengers[i]?.dateOfBirth != null && CustomerProfileData?.data?.passengers?.children?.passengers[i]?.firstName != '' && CustomerProfileData?.data?.passengers?.children?.passengers[i]?.lastName != '' && CustomerProfileData?.data?.passengers?.children?.passengers[i]?.middleName != '' && CustomerProfileData?.data?.passengers?.children?.passengers[i]?.title != '') {
        arrCheck6.push(false)
      }
    }
    //infantsError Handler
    for (let i = 0; i < CustomerProfileData?.data?.passengers?.infants?.passengers.length; i++) {
      if (CustomerProfileData?.data?.passengers?.infants?.passengers[i]?.dateOfBirth == null || CustomerProfileData?.data?.passengers?.infants?.passengers[i]?.firstName == '' || CustomerProfileData?.data?.passengers?.infants?.passengers[i]?.lastName == '' || CustomerProfileData?.data?.passengers?.infants?.passengers[i]?.middleName == '' || CustomerProfileData?.data?.passengers?.infants?.passengers[i]?.title == '' || adultValidationInnerCard('title', 'infants', i, 3, 6) || adultValidationInnerCard('firstName', 'infants', i, 2, 51) || adultValidationInnerCard('lastName', 'infants', i, 2, 51) || adultValidationInnerCard('middleName', 'infants', i, 2, 51)) {
        arrCheck7.push(true)
      }
      if (CustomerProfileData?.data?.passengers?.infants?.passengers[i]?.dateOfBirth != null && CustomerProfileData?.data?.passengers?.infants?.passengers[i]?.firstName != '' && CustomerProfileData?.data?.passengers?.infants?.passengers[i]?.lastName != '' && CustomerProfileData?.data?.passengers?.infants?.passengers[i]?.middleName != '' && CustomerProfileData?.data?.passengers?.infants?.passengers[i]?.title != '') {
        arrCheck7.push(false)
      }
    }
    //Global-card Data check
    if (CustomerProfileData?.data?.travellerID == '' || CustomerProfileData?.data?.emergencyNumber == '' || CustomerProfileData?.validUntil == undefined || CustomerProfileData?.title == '' || globalFieldValidationChecker()) {
      arrCheck4.push(true)
    }
    if (CustomerProfileData?.data?.travellerID != '' && CustomerProfileData?.data?.emergencyNumber != '' && CustomerProfileData?.validUntil != undefined && CustomerProfileData?.title != '') {
      arrCheck4.push(false)
    }
    //final-Decision
    if (arrCheck1.includes(true) || arrCheck2.includes(true) || arrCheck3.includes(true) || arrCheck4.includes(true) || arrCheck5.includes(true) || arrCheck6.includes(true) || arrCheck7.includes(true)) {
      return false
    }
    if (!arrCheck1.includes(true) && !arrCheck2.includes(true) && !arrCheck3.includes(true) && !arrCheck4.includes(true) && !arrCheck5.includes(true) && !arrCheck6.includes(true) && !arrCheck7.includes(true)) {
      return true
    }
  }
  return (
    <Card ref={props.myRef}>
      <CardHeader
        title={
          <span>
            {IsEdit
              ? `Edit Customer Profile`
              : `Create Customer Profile`}
          </span>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          {props.ValidationResult && props.ValidationResult.errorSummary && (
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <ul>
                  {props.ValidationResult.errorSummary.map((e, index) => (
                    <li key={index}>{e}</li>
                  ))}
                </ul>
              </Alert>
            </Grid>
          )}

          <Grid item xs={12} container spacing={6}>

            <Grid item>
              <TextField
                // error={nameError !== undefined}
                value={CustomerProfileData?.title}
                onChange={e =>
                  setCustomerProfileData({
                    ...CustomerProfileData,
                    title: e.target.value
                  })
                }
                error={errorTrigger && (CustomerProfileData?.title?.length ? (CustomerProfileData?.title?.length > 2 && CustomerProfileData?.title?.length < 16 ? false : true) : true)}
                helperText={`${errorTrigger ? (CustomerProfileData?.title ? (CustomerProfileData?.title?.length > 2 && CustomerProfileData?.title?.length < 16 ? '' : 'Character should be between 3 - 15') : 'This Field is required') : ''}`}
                // helperText={nameError}
                label="Title"
                InputProps={{ endAdornment: <HelpTextButton HelpText={HelpTexts.TitleHelpText} /> }}
              />
            </Grid>
            <Grid item style={{ display: 'grid' }}>
              <InvariantDateTimeInput date={CustomerProfileData.validUntil} helpButton={HelpTexts.ValidUntilHelpText} label="Valid until" OnUpdate={(e) => {
                setCustomerProfileData({
                  ...CustomerProfileData,
                  validUntil: e
                })
              }} />
              {errorTrigger ? (CustomerProfileData.validUntil == undefined) && <span style={{ paddingLeft: '14px', fontSize: '12px', color: 'red' }}>This Field is required</span> : ''}
            </Grid>
            <Divider />

            <CustomerProfileDataComponent titleGlobal={CustomerProfileData.title} dateGlobal={CustomerProfileData.validUntil} setValidationTriggerParent={setValidationTriggerParent!} globalValidationTrigger={globalValidationTrigger!} setglobalValidationTrigger={setglobalValidationTrigger!} errorTrigger={errorTrigger!} setbuttonDB={setbuttonDB} data={CustomerProfileData.data!} OnUpdate={e =>
              setCustomerProfileData({
                ...CustomerProfileData,
                data: e
              })
            } />


          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Button variant="contained" onClick={() => setCustomerProfileData(props.Data)}>
          Reset
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            functionFinalChecker() ? props.OnSave(CustomerProfileData) : handleClick(); seterrorTrigger(true);
            // props.OnSave(CustomerProfileData) 
          }
          }
        >
          Save
        </Button>
        <Button color="secondary" variant="contained" onClick={props.OnCancel}>
          Close
        </Button>
      </CardActions>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity="error">Please fill the required Fields correctly <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton></Alert>
      </Snackbar>
    </Card>
  );
};

export default CustomerProfileDataEditComponent;
