import { SettingsPage } from "components/shared/SettingPagesVarious";
import React from "react";
import FareFilterSettings from "./displaySettings.FareFilter.Settings";


const FareFilterSettingsPage: React.FC<{}> = props => {
  return <SettingsPage Component={FareFilterSettings} Label="Fare filter" />;
};

export default FareFilterSettingsPage;
