import { Box, Button, Grid, Paper } from "@material-ui/core";
import { Filter_Reducer_SetWebLevel } from "actions/FilterActions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { WebLevelEnum, WebLevelSelection } from "types/FilterTypes";
import { BranchGroupOption, BranchOption } from "WebApiClient";
import { BranchGroupSelect } from "../shared/inputs/BranchGroupSelect";
import { BranchSelect } from "../shared/inputs/BranchSelect";
import { HelpTextButton } from "../shared/various";
interface IWebLevelSelectProps {
  IsSettingsPage?: boolean;
  ShowWebButton?: boolean;
  ShowBranchButton?: boolean;
}
const WebLevelSelect: React.FC<IWebLevelSelectProps> = props => {
  const dispatch = useDispatch();
  const stateSelection = useSelector((state: State) => state.Filter.Selection);

  const selectedBranchGroup = stateSelection.Branchgroup;
  const selectedBranch = stateSelection.Branch;
  const webSelected = selectedBranchGroup === null && selectedBranch === null;
  const branchgroupSelected =
    selectedBranchGroup !== null && selectedBranch === null;
  const branchSelected = selectedBranch !== null;

  function SetWebLevel(selection: WebLevelSelection) {
    dispatch(Filter_Reducer_SetWebLevel(selection));
  }
  function OnWebSelect() {
    const selection: WebLevelSelection = {
      Level: WebLevelEnum.Web,
      Branchgroup: null,
      Branch: null
    };
    SetWebLevel(selection);
  }
  function OnBranchGroupSelect(branchgroup: BranchGroupOption | null) {
    if (branchgroup === null) {
      OnWebSelect();
    } else {
      const newSelection: WebLevelSelection = {
        Level: WebLevelEnum.BranchGroup,
        Branchgroup: branchgroup,
        Branch: null
      };
      SetWebLevel(newSelection);
    }
  }
  function OnBranchSelect(branch: BranchOption | null) {
    if (branch === null) {
      const newSelection: WebLevelSelection = {
        ...stateSelection,
        Branch: null,
        Level: WebLevelEnum.BranchGroup
      };
      SetWebLevel(newSelection);
    } else {
      const newSelection: WebLevelSelection = {
        ...stateSelection,
        Level: WebLevelEnum.Branch,
        Branch: branch
      };
      SetWebLevel(newSelection);
    }
  }

  return (
    <Paper elevation={3}>
      <Box m={1}>
        <Grid container spacing={3} alignContent="center">
          {((props.IsSettingsPage === undefined) || (props.IsSettingsPage !== undefined && props.IsSettingsPage === true)) &&
            <Grid item xs={12}>
              <span>
                You can configure the settings on this page for different
                hirarchies.
              </span>
            </Grid>
          }
          {((props.ShowWebButton === undefined) || (props.ShowWebButton !== undefined && props.ShowWebButton === true)) &&
            <Grid item md={4} xs={12}>
              <Paper elevation={3}>
                <Button
                  size="medium"
                  color={webSelected ? "primary" : undefined}
                  fullWidth
                  variant="contained"
                  onClick={OnWebSelect}
                >
                  Web
                </Button>
              </Paper>
            </Grid>
          }
          <Grid item md={4} xs={12}>
            <Paper>
              <BranchGroupSelect
                Value={selectedBranchGroup}
                Selected={branchgroupSelected}
                AutoFocus={false}
                OnSelect={OnBranchGroupSelect}
              />
            </Paper>
          </Grid>
          {((props.ShowBranchButton === undefined) || (props.ShowBranchButton !== undefined && props.ShowBranchButton === true)) &&
            <Grid item md={4} xs={12}>
              <Paper>
                {selectedBranchGroup !== null ? (
                  <BranchSelect
                    BranchGroupID={selectedBranchGroup.id!}
                    OnSelect={OnBranchSelect}
                    Value={selectedBranch}
                    Selected={branchSelected}
                  />
                ) : (
                  <span></span>
                )}
              </Paper>
            </Grid>

          }
        </Grid>
      </Box>
    </Paper>
  );
};



export default WebLevelSelect;
