import { AgentApiURL } from "Constants";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { State } from "rootExports/rootReducer";
import { ApiCredentialsOptions, LoginClient, LoginResult } from "WebApiClient";
import { Session_Reducer_SetLoginResult } from "../actions/SessionActions";
import { Session_Saga_RefreshToken } from "../actions/SessionSagactions";

function* handleRefreshToken() {
  const selectToken = (state: State) => state.Session.JwtToken;
  const jwtToken: string = yield select(selectToken);

  const loginClient: LoginClient = new LoginClient(
    new ApiCredentialsOptions(AgentApiURL, jwtToken)
  );
  let request: LoginResult | undefined = undefined;
  try {
    request = yield call(
      [loginClient, loginClient.refreshToken],
      jwtToken
    );

  } catch {

  }
  finally {
    yield put(Session_Reducer_SetLoginResult(request));

  }
}

export function* watch_RefreshToken() {
  yield takeEvery(Session_Saga_RefreshToken, handleRefreshToken);
}
