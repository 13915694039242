import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";
import BranchesPage from "./branches/branches.Page";
import BranchgroupPage from "./branchgroups/branchgroups.Page";
import GettingStarted from "./masterdata.GettingStarted";
import MultichannelPage from "./multichannel/multichannel.Page";
import UserPage from "./users/users.Page";

interface IMasterDataProps { }
const Masterdata: React.FC<IMasterDataProps> = props => {
  let { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/gettingstarted`} />
      </Route>
      <Route exact path={`${path}/gettingstarted`} component={GettingStarted} />
      <Route exact path={`${path}/branchgroups`} component={BranchgroupPage} />
      <Route exact path={`${path}/branches`} component={BranchesPage} />
      <Route exact path={`${path}/users`} component={UserPage} />
      <Route exact path={`${path}/multichannel`} component={MultichannelPage} />
    </Switch>
  );
};

export default Masterdata;
