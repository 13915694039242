import { Card, CardContent, CardHeader, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import SaveAlt from "@material-ui/icons/SaveAlt";
import { Alert } from "@material-ui/lab";
import WebLevelSelect from "components/general/WebLevelSelect";
import { TableIcons } from "components/shared/tableIcons";
import { useExportClient } from "hooks/useHttpClient";
import MaterialTable, { Column } from "material-table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { WebLevelEnum } from "types/FilterTypes";
import { UserDeeplinkModel } from "WebApiClient";

interface DeeplinkListItem extends UserDeeplinkModel {
    Selected: boolean;
}




const ExportDeeplinksPage: React.FC<{}> = props => {
    const stateSelection = useSelector((state: State) => state.Filter.Selection);
    const exportClient = useExportClient();
    const webID = useSelector((state: State) => state.Session.WebID);
    const [loading, setIsLoading] = useState(false);

    const [deeplinks, setdeeplinks] = useState<UserDeeplinkModel[]>([]);
    const Columns: Array<Column<UserDeeplinkModel>> = [
        { title: "Username", field: "userName", },
        { title: "Custom field 1", field: "customField1" },
        { title: "Deeplink", field: "deeplink" },

    ];
    useEffect(() => {
        RefreshDeeplinks();
    }, [stateSelection]);
    function RefreshDeeplinks() {
        if ((stateSelection.Level === WebLevelEnum.Branch) && stateSelection.Branch) {

            setIsLoading(true);
            exportClient
                .deeplinks(stateSelection.Branch?.id!)
                .then(response => {
                    setdeeplinks(response);
                })
                .catch(() => { })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        else {
            setdeeplinks([]);

        }
    }




    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <WebLevelSelect IsSettingsPage={false} ShowWebButton={false} />
        </Grid>
        {stateSelection.Branch ?
            <React.Fragment>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={"About Deeplinks"} />
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom={true}>How deeplinks work</Typography>
                                    Deeplinks allow your user to login into AgentPlus with a link and without entering a password.<br></br>
                                    You can export the deeplinks by clicking the <SaveAlt></SaveAlt> button.
                                    In the list below you can see the deeplinks for the Users in the current Branch. <br></br>
                                    There are 2 ways to use a Deeplink:
                                    <ul>
                                        <li><b>Standard:</b> {`The deeplink in itself will login into AgentPlus. The startpage configured under Display settings -> General Settings will apply.`}</li>
                                        <li><b>Advanced:</b> You can append a query to the deeplink to directly login and for example jump to a booking with a Filekey.</li>
                                    </ul>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom={true}>Available deeplink queries</Typography>

                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Query</TableCell>
                                                    <TableCell align="right">Description</TableCell>
                                                    <TableCell align="right">Variables</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        ?startpage=availfares
                                                    </TableCell>
                                                    <TableCell align="right">Jumps to the faresearch. Optional parameters are possible.</TableCell>
                                                    <TableCell align="right">
                                                        <b>{"&branch=<VALUE>"}:</b> Prefills search with target branch {"<VALUE>"}(Optional) <br></br>
                                                        <b>{"&branchgroup=<VALUE>"}:</b> Prefills search with target branchgroup {"<VALUE>"}(Optional)
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        ?startpage=bookingmanager
                                                    </TableCell>
                                                    <TableCell align="right">Jumps to the Bookingmanager</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        {"?startpage=bookingdetails&filekey=<VALUE>"}
                                                    </TableCell>
                                                    <TableCell align="right">Opens the Booking details according to a filekey.</TableCell>
                                                    <TableCell align="right">{"<VALUE>"}: Filekey (Mandatory)</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        {"?universaltempdata=<CUSTOMERPROFILE_ID>"}
                                                    </TableCell>
                                                    <TableCell align="right">Loads a customer profile into the active session. Data from this profile will be used to prefill fields in the Agent frontend.</TableCell>
                                                    <TableCell align="right">{"<CUSTOMERPROFILE_ID>"}: ID of customer profile (Mandatory)</TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <MaterialTable
                        icons={TableIcons}
                        title={`Deeplinks`}
                        columns={Columns}
                        data={deeplinks}
                        isLoading={loading}
                        options={{
                            paging: false, exportButton: true, exportFileName: `${stateSelection.Branch.label}_deeplinks`, exportAllData: true
                        }}



                    />
                </Grid>
            </React.Fragment> :
            <Grid item xs={12}>
                <Alert severity={"info"}>
                    To export deeplinks, please select a Branch from the filter above.
                </Alert>
            </Grid>
        }
    </Grid>;
};

export default ExportDeeplinksPage;