import { Box, Card, CardContent, CardHeader, Grid, LinearProgress, makeStyles } from "@material-ui/core";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { SettingsActions } from "components/shared/CardActions";
import { ErrorMessages } from "components/shared/ErrorMessages";
import { ApplyIndicator, CreateSettingsButton, ISettingsComponentProps, OverriddenIndicator } from "components/shared/SettingPagesVarious";
import { WebLevelLabel } from "components/shared/WebLevelLabel";
import { useIconDataSettingsClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import { AdminEntityValidationResult, CreateSettingsRequest, IconDataSettingsModel, SettingsOrder } from "WebApiClient";
import { allDefaultCode } from "../IncludedServiceIcons/SabreIcons";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  column: {
    paddingRight: theme.spacing(3),
    '&:last-child': {
      paddingRight: 0,
    },
  },
}));

const IconSettings: React.FC<ISettingsComponentProps> = props => {
  const { ReadOnly, WebLevel, Id, LevelName } = props;
  const client = useIconDataSettingsClient();
  const dispatch = useDispatch();
  const [Settings, setSettings] = useState<IconDataSettingsModel[] | any>([]);
  const [loading, setLoading] = useState(true);
  const [validationState, setValidationState] = useState<AdminEntityValidationResult | undefined>(undefined);
  const [serverError, setServerError] = useState(false);
  const [defaultHeight, setDefaultHeight] = useState<number>(0);

  useEffect(() => {
    GetSettings(WebLevel, Id);
    sortByMealCode(Settings);
  }, [WebLevel, Id]);

  function OnUpdate() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client.update(WebLevel, Id, Settings).then(response => {
        transactionId = response.transactionId;
        if (response.success) {
          transactionMsg = "successfully updated.";
          transactionSuccess = true;
        } else {
          transactionMsg = "could not be updated.";
        }
        if (response.serverError) {
          serverSideError = true;
        } else {
          setValidationState(response.validationResult);
        }
      })
        .catch(e => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be updated. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Update Multichannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function Reload() {
    GetSettings(WebLevel, Id);
  }

  function CreateSettings() {
    if (Id) {
      const createRequest: CreateSettingsRequest = {
        id: Id,
        sortOrder: WebLevel
      };
      setLoading(true);
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      client
        .create(createRequest)
        .then(response => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully created.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be created.";
          }
          if (response.serverError) {
            serverSideError = true;
          }
        })
        .catch(e => {
          serverSideError = true;
          console.error(e);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be created. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Create Mulitchannel settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Multichannel settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setLoading(false);
          if (transactionSuccess) {
            Reload();
          }
        });
    }
  }

  function GetSettings(order: SettingsOrder, Id: string | null) {
    setLoading(true);
    setServerError(false); // Reset error state before API call
    client.get(order, Id)
      .then((response: any) => {
        if (response.message === "No records found for the specified criteria.") {
          console.log("No records found based on criteria.");
          setSettings([]); // Empty settings for no data case
          props.OnNotifyIfSettingsExist(false);
        } else if (Array.isArray(response) && response.length > 0) {
          const sortedData = sortIconDataSettings(response);
          setSettings(sortedData);
          setDefaultHeight(sortedData[0].defaultHeight);
          props.OnNotifyIfSettingsExist(true);
        } else {
          console.log("Unexpected data received:", response);
          setSettings([]);
          props.OnNotifyIfSettingsExist(false);
        }
      })
      .catch(e => {
        // Handle error cases
        console.error("Error fetching settings:", e);
        setServerError(true); // Set server error state
        setSettings([]); // Optionally reset settings on error
        props.OnNotifyIfSettingsExist(false); // Notify no settings exist
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function DeleteSettings() {
    if (Settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client.delete(WebLevel, Id)
        .then(response => {
          if (response.success) {
            transactionMsg = "successfully deleted.";
            transactionSuccess = true;
          } else {
            transactionMsg = `could not be deleted. ${response.errorMessage}`;
          }
        })
        .catch(e => {
          console.error(e);
          setServerError(false);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg = "could not be deleted. A server error has occured";
            setServerError(false);
          }
          let transaction: TransactionIDInfo = {
            Label: `Delete Icon settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Icon settings ${transactionMsg}`
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function convertCode(code: string) {
    // If the code has only 2 or 3 characters, return it in uppercase
    if (code.length <= 3) {
      return code.toUpperCase();
    }

    // For longer codes, convert camelCase to space-separated words
    const camelCaseRegex = /([a-z])([A-Z])/g;

    // Insert space between lowercase and uppercase letters
    let formattedCode = code.replace(camelCaseRegex, '$1 $2');

    // Capitalize the first letter and convert the rest to lowercase
    formattedCode = formattedCode.charAt(0).toUpperCase() + formattedCode.slice(1).toLowerCase();

    return formattedCode;
  }

  const classes = useStyles();

  const isEmoji = (str: string): boolean => {
    // Regular expression pattern to match most emojis
    const emojiRegex = /([\u231A-\u231B\u23E9-\u23EC\u23F0\u23F3\u25FD-\u25FE\u2600-\u26FF\u2700-\u27BF\u2B50\u2B55\u2934-\u2935\u2B05-\u2B07\u3030\u303D\u3297\u3299\uD83C\uDC04\uD83C\uDCCF\uD83C\uDE01-\uDE02\uD83C\uDE1A\uD83C\uDE2F\uD83C\uDE32-\uDE3A\uD83C\uDE50-\uDE51\uD83D\uDC00-\uDFFF\uD83E\uDD00-\uDDFF]+)/g;
    return emojiRegex.test(str);
  };

  // Delete icon data from the object
  const deleteIconData = (index: number) => {
    // Create a shallow copy of the Settings array
    const updatedSettings: IconDataSettingsModel[] = [...Settings];

    // Access the item directly using the index
    const code = updatedSettings[index]?.code; // Use optional chaining for safety

    if (code && allDefaultCode.find(item => item.code === code)) {
      // If the code is found in allDefaultCode, set newIcon to null
      updatedSettings[index].newIcon = null;
    } else {
      // If the code is not found, remove the item at the specified index
      updatedSettings.splice(index, 1); // Remove the item at the specified index
    }

    setSettings(updatedSettings); // Update the settings state
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, index: string) => {
    const file = event.target.files?.[0]; // Get the uploaded file
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string; // Convert the image to base64

        // Create a copy of the current settings and update the relevant icon
        const updatedSettings = [...Settings];
        updatedSettings[parseInt(index)] = {
          ...updatedSettings[parseInt(index)],
          newIcon: base64String, // Assuming you have a `newIcon` field in your `IconDataSettingsModel`
        };
        setSettings(updatedSettings); // Update the settings state
      };
      reader.readAsDataURL(file); // Read the file as a data URL (base64)
    }
  };


  function sortByMealCode(settings: IconDataSettingsModel[]) {
    return settings.sort((a, b) => {
      const lenA = a.code.length;
      const lenB = b.code.length;

      // First prioritize codes with length greater than 2
      if (lenA > 2 && lenB <= 2) return -1;
      if (lenA <= 2 && lenB > 2) return 1;

      // Then sort alphabetically if both lengths are the same
      return a.code.localeCompare(b.code);
    });
  }

  function sortIconDataSettings(settings: IconDataSettingsModel[]): IconDataSettingsModel[] {
    // Separate meal codes (length <= 3) and other codes (length > 3)
    const mealCodes: IconDataSettingsModel[] = settings.filter(item => item.code.length <= 3);
    const otherCodes: IconDataSettingsModel[] = settings.filter(item => item.code.length > 3);

    // Sort both arrays alphabetically by the code
    mealCodes.sort((a, b) => a.code.localeCompare(b.code));
    otherCodes.sort((a, b) => a.code.localeCompare(b.code));

    // Combine results (meal codes on top)
    return [...mealCodes, ...otherCodes];
  }

  const handleDefaultHeightChange = (value: number) => {
    setDefaultHeight(value); // Update the local state with input value
  };

  const applyDefaultHeight = () => {
    const updatedSettings: IconDataSettingsModel[] = Settings.map((item: IconDataSettingsModel) => ({
      ...item, // Keep existing properties
      defaultHeight: defaultHeight, // Apply the new height
    }));

    setSettings(updatedSettings); // Update Settings state
  };

  return (
    <Box boxShadow={ReadOnly ? undefined : 3}>
      <Card style={{ opacity: ReadOnly ? 0.5 : 1 }}>
        <CardHeader
          title={
            <div className="d-flex" style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {props.Applies && (
                  <span>
                    <ApplyIndicator />
                  </span>
                )}
                {props.IsOverwritten && (
                  <span>
                    <OverriddenIndicator />
                  </span>
                )}
                <span>Icon settings for </span>
                <WebLevelLabel Level={WebLevel} Name={LevelName} />{" "}
                {/* {props.IsOverwritten ? "Overwritten" : ""} */}
              </div>
              <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "20%" }}>
                <div style={{ fontSize: "1rem", textAlign: "center" }}>
                  <label>Icon height</label> <input
                    style={{ width: "50px" }}
                    className="FormControl"
                    type="number" // Change to type="number"
                    value={defaultHeight} // Bind input to the separate state
                    onChange={(e) => handleDefaultHeightChange(Number(e.target.value))} // Convert input value to number
                  />
                  <button onClick={applyDefaultHeight}>Apply Height</button> {/* Button to apply height */}
                </div>
              </div>
            </div>
          }
        />

        {!loading ? (
          <CardContent>
            {Settings.length ? (
              <Grid container spacing={2} >
                {/* Error and Validation Messages */}
                {serverError && <ErrorMessages ServerError={true} />}
                {validationState?.isValid === false && validationState.errorSummary.length && (
                  <ErrorMessages Messages={validationState.errorSummary} />
                )}
                {/* Table-like Grid Layout */}
                <Box style={{ width: '100%' }}>
                  {/* Header Row */}
                  <Grid container spacing={2} style={{ fontWeight: 'bold', borderBottom: '1px solid #ccc', paddingBottom: '8px' }}>
                    <Grid item xs={2} className={classes.column}>Service</Grid>
                    <Grid item xs={2} className={classes.column}>Default</Grid>
                    <Grid item xs={2} className={classes.column}>New </Grid>
                    <Grid item xs={4} className={classes.column}>Add/Update</Grid>
                  </Grid>
                  {/* Data Row */}
                  {Settings.map((icon: IconDataSettingsModel, index: number) => (
                    <Grid container spacing={2} style={{ paddingTop: '8px' }}>
                      <Grid item xs={2} className={classes.column}><span><strong>{convertCode(icon.code)} </strong>  {icon.hint.length ? `(${icon.hint.toLowerCase()})` : ""}
                      </span></Grid>
                      <Grid item xs={2} className={classes.column}> {icon.defaultIcon.length < 5 ? <span>{icon.defaultIcon}</span> : isEmoji(icon.defaultIcon) ? <span>{icon.defaultIcon}</span> : <img
                        style={{ margin: "2px 2px 0px" }}
                        src={`${icon.defaultIcon}`}
                        height={Settings[0].defaultHeight}
                        alt="hitchhiker"
                      />
                      } </Grid>
                      <Grid item xs={2} className={classes.column}>
                        {icon.newIcon !== null ? <img
                          style={{ margin: "2px 2px 0px" }}
                          src={`${icon.newIcon}`}
                          height={Settings[0].defaultHeight}
                          alt="hitchhiker"
                        /> : null}
                      </Grid>
                      <Grid item xs={3} className={classes.column}>
                        <input type="file" onChange={(e) => handleImageUpload(e, index.toString())} />
                        {icon.newIcon && (
                          <img
                            src={`${icon.newIcon}`}
                            height="18px"
                            width="auto"
                            alt="New Icon"
                            style={{ margin: '2px 5px 0px', position: "relative", top: "6px" }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={1} className={classes.column}><span onClick={() => deleteIconData(index)}> {allDefaultCode.find(item => item.code === (icon.newIcon && icon.newIcon.length ? "" : icon.code)) ? <DeleteIcon style={{ cursor: 'not-allowed', opacity: 0.5 }} /> : <DeleteIcon style={{ cursor: 'pointer' }} />} </span></Grid>
                      {/* <Grid item xs={2}>     </Grid> */}
                    </Grid>
                  ))}
                </Box>
              </Grid>
            ) : (
              <CreateSettingsButton
                OnClick={CreateSettings}
                Readonly={props.ReadOnly}
              />
            )}
          </CardContent>
        ) : null}
        {loading ? (
          <CardContent>
            <LinearProgress />
          </CardContent>
        ) : null}
        {!ReadOnly && Settings.length ? (
          <SettingsActions
            OnDelete={DeleteSettings}
            OnReset={Reload}
            OnSave={OnUpdate}
          />
        ) : null}
      </Card>
    </Box>
  );
};

export default IconSettings;