import { Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import * as React from "react";

export interface ICorporateIdentityGettingsStartedProps { }

export default function MasterDataGettingsStarted(
  props: ICorporateIdentityGettingsStartedProps
) {
  return <Grid container>
    <Grid item xs={12}>
      <Card>
        <CardHeader title={"Master data"} />
        <CardContent>
          <Typography variant="body1">
            In this section you can organize your company into <b>Branchgroups</b>, <b>Branches</b> and <b>Users.</b> <br></br>
          In the <b>Multichannel configuration</b> section you can configure your FlightApi access.

        </Typography>
        </CardContent>
      </Card>
    </Grid>

  </Grid>;
}
