import { makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import { TableIcons } from "components/shared/tableIcons";
import MaterialTable, { Column } from "material-table";
import React from "react";
import { InvariantDateTimeModel, UpdateCustomerProfileDataRequest } from "WebApiClient";


const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  }
}));

interface ICustomerProfileDataTableProps {

  LastEdit: string;
  OnRefresh: () => void;
  OnDelete: (id: string) => void;
  OnEdit: (data: UpdateCustomerProfileDataRequest | undefined) => void;
  IsLoading: boolean;
  Data: UpdateCustomerProfileDataRequest[];
  myRef?:any
}
export const CustomerProfileDataTable: React.FC<ICustomerProfileDataTableProps> = props => {
  const classes = useStyles();

  function GetRowStyle(
    data: any,
    index: number,
    level: number
  ): React.CSSProperties {
    const bg = data as UpdateCustomerProfileDataRequest;
    let result: React.CSSProperties = {
      backgroundColor: bg.id === props.LastEdit ? "#a8c8dd" : undefined
    };
    return result;
  }

  const CustomerProfileDataTableColumns: Array<Column<UpdateCustomerProfileDataRequest>> = [
    { title: "Title", field: "title", emptyValue: "" },
    { title: "ID", field: "id", emptyValue: "" },
    { 
      title: "Valid until", field: "validUntil", render: (rowData, type) => {
        if (type === "group") {
          const d = rowData as any;
          const label = d as InvariantDateTimeModel | undefined;
          return `${label?.day}.${label?.month}.${label?.year}`;
        } else {
          const label = rowData.validUntil;
          return `${label?.day}.${label?.month}.${label?.year}`;
        }
      }
    },

  ];

  function DeleteRow(data: UpdateCustomerProfileDataRequest): Promise<void> {
    return new Promise(resolve => {
      props.OnDelete(data.id!);
      resolve();
    });
  }

  const handleButtonClick = () =>{
    //console.log("--==", props?.myRef?.current);
    
    props?.myRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  

  }
  
  return (
    <MaterialTable
      icons={TableIcons}
      title={`List`}
      columns={CustomerProfileDataTableColumns}
      data={props.Data}
      isLoading={props.IsLoading}
      options={{
        selection: false,
        rowStyle: GetRowStyle,
        grouping: false,
        paging: false
      }}

      editable={{
        isDeletable: oldData => true,
        onRowDelete: DeleteRow
      }}
      actions={[
        {
          icon: () => <RefreshIcon />,
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () => props.OnRefresh()
        },
        {
          icon: () => <EditIcon />,
          tooltip: "View",
          onClick: (event, row) => {
            const data = row as UpdateCustomerProfileDataRequest;
            props.OnEdit(data);
            handleButtonClick()
          }
        },
        {
          icon: () => <AddIcon />,
          tooltip: "Add",
          isFreeAction: true,
          onClick: () => {
            props.OnEdit(undefined);
          }
        },

      ]}

    />
  );
};

export default CustomerProfileDataTable;
