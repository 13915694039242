import { Avatar, Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { Fragment } from "react";
import { Adress } from "WebApiClient";
import { useStyles } from "./customerprofiles.Details";

export const CustomerProfileAdressInput: React.FC<{ data: Adress | undefined; OnUpdate: (data: Adress | undefined) => void; Title: string; Icon: JSX.Element }> = props => {
    const classes = useStyles();
    return <Card elevation={4}>
        <CardHeader
            avatar={<Avatar className={props.data ? classes.activeAvatar : ""}>{props.Icon}</Avatar>}
            title={<Typography variant="h5">{props.Title}</Typography>}
            action={props.data ? <Button onClick={() => { props.OnUpdate(undefined); }}
                startIcon={<DeleteIcon />} color="secondary">remove</Button> : null}

        />
        <CardContent>
            {props.data ?
                <CustomerProfileAdressDataInput data={props.data} OnUpdate={props.OnUpdate} />
                : <Button variant="contained" color="primary" onClick={() => { props.OnUpdate({ city: "", country: "", firstName: "", lastName: "", street: "", title: "", zip: "" }) }}>Add flight details</Button>}

        </CardContent>
    </Card>;
}

export const CustomerProfileAdressDataInput: React.FC<{ data: Adress; OnUpdate: (data: Adress) => void }> = props => {
    const { city, country, firstName, lastName, street, title, zip } = props.data;
    return <Fragment>

        <Grid container spacing={2}>
            <Grid item xs={4}>
                <TextField
                    value={title ?? ""}
                    onChange={e => { props.OnUpdate({ ...props.data, title: e.target.value }); }}
                    label="Title"
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    value={firstName ?? ""}
                    onChange={e => { props.OnUpdate({ ...props.data, firstName: e.target.value }); }}
                    label="First name"
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    value={lastName ?? ""}
                    onChange={e => { props.OnUpdate({ ...props.data, lastName: e.target.value }); }}
                    label="Last name"
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    value={country ?? ""}
                    onChange={e => { props.OnUpdate({ ...props.data, country: e.target.value }); }}
                    label="Country code"
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    value={zip ?? ""}
                    onChange={e => { props.OnUpdate({ ...props.data, zip: e.target.value }); }}
                    label="ZIP"
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    value={city ?? ""}
                    onChange={e => { props.OnUpdate({ ...props.data, city: e.target.value }); }}
                    label="City name"
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    value={street ?? ""}
                    onChange={e => { props.OnUpdate({ ...props.data, street: e.target.value }); }}
                    label="Street"
                />
            </Grid>

        </Grid>
    </Fragment>
}