import { Card, CardContent, CardHeader, Grid, Link, Typography } from "@material-ui/core";
import * as React from "react";
import {
  Link as RouterLink
} from "react-router-dom";

export interface IUniversalTempDataGettingsStartedProps { }

export default function UniversalTempDataGettingStarted(
  props: IUniversalTempDataGettingsStartedProps
) {
  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Card>
        <CardHeader title={"Universal temporal data"} />
        <CardContent>
          <Typography variant="body1">
            Universal temporal data can be added before a session to affect certain behavior. It is loaded into an session by adding its ID into a <Link component={RouterLink} to="/export/deeplinks">deeplink</Link>
          </Typography>

        </CardContent>
      </Card>

    </Grid>
    <Grid item xs={12}>
      <Card>
        <CardHeader title={"Customer profile"} />
        <CardContent>
          <Typography variant="body1">
            By using a customer profile, data from passengers and flight details can be prefilled in the Agent frontend.
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  </Grid>;
}
