import { makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Refresh from "@material-ui/icons/Refresh";
import { TableIcons } from "components/shared/tableIcons";
import MaterialTable, { Column } from "material-table";
import React from "react";
import { UpdateBranchgroupModel } from "WebApiClient";

interface BranchgroupsTableProps {
    LastEdit: string;
    OnRefresh: () => void;
    OnDelete: (id: string) => void;
    OnEdit: (data: UpdateBranchgroupModel | undefined) => void;
    IsLoading: boolean;
    Data: UpdateBranchgroupModel[];
}



const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column"
    }
}));


const BranchgroupsTable: React.FC<BranchgroupsTableProps> = props => {
    const classes = useStyles();
    function GetRowStyle(
        data: any,
        index: number,
        level: number
    ): React.CSSProperties {
        const bg = data as UpdateBranchgroupModel;
        let result: React.CSSProperties = {
            backgroundColor: bg.id === props.LastEdit ? "#a8c8dd" : undefined
        };
        return result;
    }
    const BranchGroupTableColumns: Array<Column<UpdateBranchgroupModel>> = [
        { title: "Name", field: "name", searchable: true },
        { title: "Active", field: "isActive", type: "boolean" },
    ];

    function DeleteRow(data: UpdateBranchgroupModel): Promise<void> {
        return new Promise(resolve => {
            props.OnDelete(data.id!);
            resolve();
        });
    }
    return <MaterialTable
        icons={TableIcons}
        title="Manage Branchgroups"
        columns={BranchGroupTableColumns}
        data={props.Data}
        isLoading={props.IsLoading}
        options={{
            selection: false,
            rowStyle: GetRowStyle,
            grouping: false,
            paging: false
        }}
        editable={{
            isDeletable: oldData => true,
            onRowDelete: DeleteRow
        }}
        actions={[
            {
                icon: () => <Refresh />,
                tooltip: "Refresh Data",
                isFreeAction: true,
                onClick: () => props.OnRefresh()
            },
            {
                icon: () => <EditIcon />,
                tooltip: "View",
                onClick: (event, row) => {
                    const data = row as UpdateBranchgroupModel;
                    props.OnEdit(data);
                }
            },

            {
                icon: () => <AddIcon />,
                tooltip: "Add",
                isFreeAction: true,
                onClick: () => {
                    props.OnEdit(undefined);
                }
            },




        ]}
    />

}

export default BranchgroupsTable;