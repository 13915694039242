import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";
import GettingStarted from "./budgeting.GettingStarted";
import DepositsPage from "./deposits/deposits.Page";



const BudgetingRoutes: React.FC<{}> = props => {
  let { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/gettingstarted`} />
      </Route>
      <Route exact path={`${path}/gettingstarted`} component={GettingStarted} />
      <Route
        exact
        path={`${path}/deposits`}
        component={DepositsPage}
      />
    </Switch>
  );
};

export default BudgetingRoutes;
