import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { WebLevelEnum, WebLevelSelection } from "types/FilterTypes";
import { CreateSettingsRequest, SettingsOrder } from "WebApiClient";


export function useStateSelection(): WebLevelSelection {
  const stateSelection = useSelector((state: State) => state.Filter.Selection);
  return stateSelection;
}
export function useCreateSettingsRequest(): CreateSettingsRequest {
  const stateSelection = useStateSelection();
  const webId = useSelector((state: State) => state.Session.WebID);
  let levelId = webId;
  let levelSortOrder: SettingsOrder = SettingsOrder.Web;
  switch (stateSelection.Level) {
    case WebLevelEnum.BranchGroup:
      levelId = stateSelection.Branchgroup!.id!;
      levelSortOrder = SettingsOrder.BranchGroup;
      break;
    case WebLevelEnum.Branch:
      levelId = stateSelection.Branch!.id!;
      levelSortOrder = SettingsOrder.Branch;
      break;
  }

  const result: CreateSettingsRequest = { id: levelId, sortOrder: levelSortOrder };
  return result;
}
