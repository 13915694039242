import { makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import { TableIcons } from "components/shared/tableIcons";
import { HelpTextButton } from "components/shared/various";
import MaterialTable, { Column } from "material-table";
import React from "react";
import { CreateSettingsRequest, UpdateSalesPrefActionModel } from "WebApiClient";
import { SalesPrefActionsIntroHelptext } from "./actions.HelpTexts";
interface IActionsTableProps {
  OnRefresh: () => void;
  OnDelete: (id: string) => void;
  OnEdit: (data: UpdateSalesPrefActionModel | undefined) => void;
  IsLoading: boolean;
  Data: UpdateSalesPrefActionModel[];
  LevelSelection: CreateSettingsRequest;
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  }
}));
export const ActionsTable: React.FC<IActionsTableProps> = props => {
  const classes = useStyles();
  const lastEdit: string = "";
  function GetRowStyle(
    data: any,
    index: number,
    level: number
  ): React.CSSProperties {
    const bg = data as UpdateSalesPrefActionModel;
    let result: React.CSSProperties = {
      backgroundColor: bg.id === lastEdit ? "#a8c8dd" : undefined
    };
    return result;
  }

  const BranchTableColumns: Array<Column<UpdateSalesPrefActionModel>> = [
    { title: "Title", field: "title" },
    {
      title: "Delete fare from response",
      field: "actionDoDelete",
      type: "boolean"
    },
    { title: "Target display name", field: "actionTargetName" },
    {
      title: "Target priority",
      field: "actionTargetPosition",
      type: "numeric"
    },
  ];
  function DeleteRow(data: UpdateSalesPrefActionModel): Promise<void> {
    return new Promise(resolve => {
      props.OnDelete(data.id);
      resolve();
    });
  }

  return (
    <MaterialTable
      icons={TableIcons}
      title={<Typography variant="h5">Sales preference Actions <HelpTextButton HelpText={SalesPrefActionsIntroHelptext} /></Typography>}
      columns={BranchTableColumns}
      data={props.Data}
      isLoading={props.IsLoading}
      editable={{
        onRowDelete: DeleteRow
      }}
      options={{ paging: false }}
      actions={[
        {
          icon: () => <RefreshIcon />,
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () => props.OnRefresh()
        },
        {
          icon: () => <EditIcon />,
          tooltip: "View",
          onClick: (event, row) => {
            const data = row as UpdateSalesPrefActionModel;
            props.OnEdit(data);
          }
        },
        {
          icon: () => <AddIcon />,

          isFreeAction: true,
          onClick: () => {
            props.OnEdit(undefined);

          }
        },

      ]}
    />
  );
};

export default ActionsTable;
