import * as sessionActions from "actions/SessionActions";
import { produce } from "immer";
import jwtDecode from "jwt-decode";
import * as moment from "moment";
import { Reducer } from "redux";
import { DefaultSessionState, SessionState } from "types/SessionTypes";
import { ActionType, getType } from "typesafe-actions";

export type SessionAction = ActionType<typeof sessionActions>;

const reducer: Reducer<SessionState, SessionAction> = (
  state = DefaultSessionState,
  action: SessionAction
) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(sessionActions.Session_Reducer_Reset): {
        return DefaultSessionState;
      }
      case getType(sessionActions.Session_Reducer_SetLoginResult): {
        const LOGINRESULT = action.payload;

        if (LOGINRESULT) {
          const TOKEN_STRING = LOGINRESULT.token;
          if (TOKEN_STRING && TOKEN_STRING.length > 0) {
            const DECODED_TOKEN: any = jwtDecode(TOKEN_STRING);
            const WEBNAME: string = DECODED_TOKEN.WebName;
            const WEB_ID: string = DECODED_TOKEN.WebId;
            const USER_NAME: string = DECODED_TOKEN.UserName;
            const USER_ID: string = DECODED_TOKEN.sub;
            const BACKEND_APP_VERSION: string = DECODED_TOKEN.appversion;
            const SESSION_EXPIRES = moment.unix(DECODED_TOKEN.exp).toDate();
            // let VERSION_DIFFERENCE_BACKEND: boolean = false;
            // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            //   VERSION_DIFFERENCE_BACKEND = false;
            // }
            // else {

            // VERSION_DIFFERENCE_BACKEND = BACKEND_APP_VERSION !== draft.DeployInfo?.Version;
            // }

            const ROLES_RAW: string | string[] | undefined | null = DECODED_TOKEN.roles;
            let rolesArray: string[] = [];
            let isHitchHiker = false;
            let isAdmin = false;

            if ((ROLES_RAW !== undefined) && (ROLES_RAW !== null)) {
              if (typeof ROLES_RAW === "string") {
                rolesArray.push(ROLES_RAW);
              }
              else {
                rolesArray = ROLES_RAW;
              }

            }
            isAdmin = rolesArray.findIndex(e => e.toLowerCase() === 'ua') !== -1;
            isHitchHiker = rolesArray.findIndex(e => e.toLowerCase() === 'hh') !== -1;

            draft.BackendVersion = BACKEND_APP_VERSION;

            // if (VERSION_DIFFERENCE_BACKEND) {
            //   draft.VersionDifferenceToBackend = true;
            // }
            if (isAdmin) {
              draft.WebID = WEB_ID;
              draft.ErrorMessages = [];
              draft.isLoggedIn = true;
              draft.JwtToken = TOKEN_STRING;
              draft.LoginFailed = false;
              draft.WebName = WEBNAME;
              draft.UserId = USER_ID;
              draft.UserName = USER_NAME;
              draft.SessionExpires = SESSION_EXPIRES;
              draft.IsAdmin = isAdmin;
              draft.IsHitchHiker = isHitchHiker;
            }
            else {
              return { ...DefaultSessionState, LoginFailed: true, ErrorMessages: ["You do not have administrative access rights."] }

            }
          }
          else {
            return { ...DefaultSessionState, LoginFailed: true, ErrorMessages: ["Login failed. Wrong Username or password."] }

          }
          if (LOGINRESULT.agentFrontendSettings) {
            //TODO
          }
        }
        else {
          return { ...DefaultSessionState, LoginFailed: true, ErrorMessages: ["Connection to our backend services was not successfull."] }
        }
        return;
      }

      case getType(sessionActions.Session_Reducer_LoginFailed): {
        draft.LoginFailed = true;
        return;
      }
      case getType(sessionActions.Session_Reducer_SetDeployInfo): {
        draft.DeployInfo = action.payload;
        return;
      }
      case getType(sessionActions.Session_Reducer_SetLoginErrorMessages): {
        draft.ErrorMessages = action.payload;
        return;
      }
      case getType(sessionActions.Session_Reducer_PushTransactionID): {
        draft.TransactionIDs.unshift(action.payload);
        draft.TransactionIDs.splice(10, 1); // save max 10 TIDS
        draft.NotificationOpen = true;
        return;
      }
      case getType(sessionActions.Session_Reducer_SetNotificationOpen): {
        draft.NotificationOpen = action.payload;
        return;
      }
    }
  });

export { reducer as sessionReducer };

