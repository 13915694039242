import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getData as GetCountriesList } from "country-list";
import React from "react";

interface ICountryselectProps {
  Value: string | undefined | null;
  OnChange: (value: string) => void;
  Label: string;
}
interface ICountryOption {
  code: string;
  name: string;
}
const CountrySelect: React.FC<ICountryselectProps> = props => {
  const emptyLabel = `- None selected -`;
  const empty: ICountryOption = { name: emptyLabel, code: "" };

  function GetCountries(): ICountryOption[] {
    let countries = GetCountriesList();

    countries = countries.sort(function (a, b) {
      return a.name > b.name ? 1 : -1;
    });
    // const emptyLabel = `- None selected - (${props.Required ? "required" : "optional"})`

    countries = [empty].concat(countries);
    return countries;
  }
  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    if (value !== undefined && value !== null) {
      props.OnChange((value as ICountryOption).code);
    } else {
      props.OnChange("");
    }
  };

  function GetSelected(): ICountryOption {
    const findCountry = GetCountries().find(e => e.code === props.Value);
    if (findCountry) return findCountry;
    return empty;
  }

  return (
    <Autocomplete
      options={GetCountries()}
      getOptionLabel={(option: ICountryOption) =>
        `${option.name} (${option.code})`
      }
      renderInput={params => (
        <TextField {...params} label={props.Label} />
      )}
      value={GetSelected()}
      onChange={handleChange}
      getOptionSelected={(option: ICountryOption, value: ICountryOption) =>
        option.code === value.code
      }
    />
  );
};

export default CountrySelect;
