
import { Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle } from "@material-ui/core";

import Tune from "@material-ui/icons/Tune";
import { TableIcons } from "components/shared/tableIcons";
import { OperationStateRenderer } from "components/shared/various";
import { useUserClient } from "hooks/useHttpClient";
import MaterialTable, { Column } from "material-table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TransactionIDInfo } from "types/SessionTypes";
import { OperationState } from "types/Various";
import { UpdateUserModel } from "WebApiClient";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

interface UsersDeleteConfirmationProps {
    IsOpen: boolean;
    Toggle: () => void;
    Data: UpdateUserModel[];
    OnDeleteComplete: () => void;
}

//material design css
const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

export const UserDeleteColumns: Array<Column<UserDeleteState>> = [
    { title: "Status", field: "DeleteState", render: (e) => <OperationStateRenderer status={e.DeleteState} />, cellStyle: { background: "lightgrey" } },
    { title: "Username", field: "username" },
    { title: "Password", field: "password" },
    { title: "Title", field: "title" },
    { title: "Firstname", field: "firstname" },
    { title: "Lastname", field: "lastname" },
    { title: "Active", field: "isActive", type: "boolean" },
    { title: "Admin", field: "isAdmin", type: "boolean" },
    { title: "Telephone", field: "telephone" },
    { title: "Fax", field: "fax" },
    { title: "Email", field: "email" },
    { title: "Mobile", field: "mobilePhone" },
];

interface UserDeleteState extends UpdateUserModel {
    DeleteState: OperationState;
}
const UsersDeleteConfirmation: React.FC<UsersDeleteConfirmationProps> = props => {
    const columns = [...UserDeleteColumns];
    const [data, setData] = useState<UserDeleteState[]>([]);
    const [open, setOpen] = React.useState(false);
    const [isloading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const userClient = useUserClient();
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        const mapped = props.Data.map(e => { return { ...e, DeleteState: OperationState.Pending } });
        setData(mapped);
        setIsOpen(props.IsOpen);
    }, [props.Data])


    function OnDelete() {
        setIsLoading(true);
        data.forEach((e, index) => {
            switch (e.DeleteState) {
                case OperationState.Failure:
                case OperationState.Pending:
                    let newArr = [...data];
                    newArr[index].DeleteState = OperationState.Progress;
                    setData(newArr);
                    const result = DeleteUserAsync(e.id!).then((result => {
                        let newDeleteState = OperationState.Progress;
                        if (result.Success) {
                            newDeleteState = OperationState.Success;
                        }
                        else {
                            newDeleteState = OperationState.Failure;
                        }
                        newArr[index].DeleteState = newDeleteState;
                        setData(newArr);
                    }))
                    break;
            }
        })
        setIsLoading(false);
        // props.OnDeleteComplete();
    }


    function DeleteUserAsync(id: string): Promise<TransactionIDInfo> {
        return new Promise<TransactionIDInfo>((resolve, reject) => {
            let transactionSuccess = false;
            let transactionTime = new Date().toString();
            let transactionId = "";
            let transactionMsg = "MSG NOT SET";
            let serverSideError = false;
            userClient
                .delete(id)
                .then(e => {
                    transactionId = e.transactionId;
                    if (e.success) {
                        transactionSuccess = true;
                        transactionMsg = "User deleted.";
                        setIsLoading(false);
                        props.Toggle()
                        // props.OnDeleteComplete();
                    } else {
                        transactionMsg = `User could not be deleted. ${e.errorMessage}`;
                    }
                })
                .catch(e => {
                    console.error(e);
                    serverSideError = true;
                })
                .finally(() => {
                    if (serverSideError) {
                        transactionMsg = `User could not be deleted. An server-side error has occured.`;
                    }
                    const transaction: TransactionIDInfo = {
                        Label: `Delete User`,
                        Success: transactionSuccess,
                        Time: transactionTime,
                        TransactionID: transactionId,
                        Message: transactionMsg
                    };
                    resolve(transaction);
                    // dispatch(Session_Reducer_PushTransactionID(transaction));
                    setIsLoading(false);
                    props.OnDeleteComplete();
                    props.Toggle()
                });
        })
    }

    // return <Dialog fullWidth onClose={props.Toggle} open={props.IsOpen} disableBackdropClick={isloading}>
    //     <DialogTitle>Are you sure to delete the following users?</DialogTitle>
    //     <DialogActions style={{ justifyContent: "flex-start" }}>
    //         <Button disabled={isloading} onClick={props.Toggle} style={{ background: "red", color: "white" }}>
    //             Abort
    //         </Button>
    //         <Button disabled={isloading} onClick={OnDelete} style={{ background: "green", color: "white" }}>
    //             Yes, delete now
    //         </Button>
    //     </DialogActions>
    //     <MaterialTable
    //         key="xyxyy"
    //         title=""
    //         icons={TableIcons}
    //         columns={columns}
    //         data={data}
    //         //   isLoading={props.IsLoading}
    //         options={{ paging: false, selection: false }}
    //     />
    // </Dialog>

    return <Dialog
        open={props.IsOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={props.Toggle}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle>{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                {/* <MaterialTable
                    key="xyxyy"
                    title=""
                    icons={TableIcons}
                    columns={columns}
                    data={data}
                    isLoading={isloading}
                    options={{ paging: false, selection: false }}
                /> */}
                <TableContainer>
      <Table className={classes.table}  aria-label="simple table">
        <TableHead>
            <TableRow>
                {columns.map((column,index:any) => (
                    <TableCell key={index}>{column?.title}</TableCell>
                ))}
            </TableRow>
        </TableHead>
        <TableBody>
          {
          data.map((row,index:any) => (
              <TableRow key={index}>
                <TableCell>{row.DeleteState}</TableCell>
                <TableCell>{row.firstName}</TableCell>
                <TableCell>{row.password}</TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.firstName}</TableCell>
                <TableCell>{row.lastName}</TableCell>
                <TableCell>{row.isActive}</TableCell>
                <TableCell>{row.isAdmin}</TableCell>
                <TableCell>{row.telephone}</TableCell>
                <TableCell>{row.fax}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.mobilePhone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

                {/* {
                    data.map((e) => (
                        <table className="w-100 table-danger" key={e.id}>
                            <tr>
                                <th>Status</th>
                                <th>Username</th>
                                <th>Password</th>
                                <th>Title</th>
                            </tr>
                            <tr>
                                <OperationStateRenderer status={e.DeleteState} />
                                <td>{e.username}</td>
                                <td>{e.password}</td>
                                <td>{e.title}</td>
                            </tr>
                        </table>
                    ))
                } */}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button disabled={isloading} onClick={props.Toggle} style={{ background: "red", color: "white" }}>
                Abort
            </Button>
            <Button disabled={isloading} onClick={OnDelete} style={{ background: "green", color: "white" }}>
                Yes, delete now
            </Button>
        </DialogActions>
    </Dialog >
}

export default UsersDeleteConfirmation;