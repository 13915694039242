import { makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import { TableIcons } from "components/shared/tableIcons";
import { HelpTextButton } from "components/shared/various";
import MaterialTable, { Column } from "material-table";
import React from "react";
import { CreateSettingsRequest, UpdateCalcCurrencyModel } from "WebApiClient";
import { CalcCurrenciesIntroHelptext } from "./currencies.HelpTexts";

interface ICurrenciesTableProps {
  OnRefresh: () => void;
  OnDelete: (id: string) => void;
  OnEdit: (data: UpdateCalcCurrencyModel | undefined) => void;
  IsLoading: boolean;
  Data: UpdateCalcCurrencyModel[];
  LevelSelection: CreateSettingsRequest;
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  }
}));
export const CurrenciesTable: React.FC<ICurrenciesTableProps> = props => {
  const classes = useStyles();
  const lastEdit: string = "";

  function GetRowStyle(
    data: any,
    index: number,
    level: number
  ): React.CSSProperties {
    const bg = data as UpdateCalcCurrencyModel;
    let result: React.CSSProperties = {
      backgroundColor: bg.id === lastEdit ? "#a8c8dd" : undefined
    };
    return result;
  }

  const BranchTableColumns: Array<Column<UpdateCalcCurrencyModel>> = [
    { title: "Currency code", field: "currencyCode" },
    { title: "Name", field: "name" },
    { title: "Decimal places", field: "displayDecimals" }
  ];

  function DeleteRow(data: UpdateCalcCurrencyModel): Promise<void> {
    return new Promise(resolve => {
      props.OnDelete(data.id);
      resolve();
    });
  }

  return (
    <MaterialTable
      icons={TableIcons}
      title={<Typography variant="h5">Calculation Currencies <HelpTextButton HelpText={CalcCurrenciesIntroHelptext} /></Typography>}
      columns={BranchTableColumns}
      data={props.Data}
      isLoading={props.IsLoading}
      options={{ paging: false }}
      editable={{
        onRowDelete: DeleteRow
      }}
      actions={[
        {
          icon: () => <RefreshIcon />,
          tooltip: "Refresh Data",
          isFreeAction: true,
          onClick: () => props.OnRefresh()
        },
        {
          icon: () => <EditIcon />,
          tooltip: "View",
          onClick: (event, row) => {
            const data = row as UpdateCalcCurrencyModel;
            props.OnEdit(data);
          }
        },
        {
          icon: () => <AddIcon />,
          tooltip: "Add",
          isFreeAction: true,
          onClick: () => {
            props.OnEdit(undefined);
          }
        },

      ]}
    />
  );
};

export default CurrenciesTable;
