import { AgentApiURL } from "Constants";
import { call, putResolve, takeEvery } from "redux-saga/effects";
import { ApiCredentialsOptions, ApiException, LoginClient, LoginResult } from "WebApiClient";
import { Session_Reducer_SetLoginResult } from "../actions/SessionActions";
import { Session_Saga_TryDeepLogin } from "../actions/SessionSagactions";

function* handleTryDeepLogin(
  action: ReturnType<typeof Session_Saga_TryDeepLogin>
) {
  // yield put(Session_Reducer_ShowGeneralWaitScreen());
  const loginClient: LoginClient = new LoginClient(
    new ApiCredentialsOptions(AgentApiURL, "")
  );
  let loginResult: LoginResult | undefined = undefined;
  let validLogin: boolean = false;


  try {
    loginResult = yield call(
      [loginClient, loginClient.loginByDeeplink],
      action.payload.WebID,
      action.payload.Deeplink,
      undefined
    );

  } catch (e) {
    let exception: ApiException = e as ApiException;
    console.log(`${exception.status} - ${exception.message}`);

  } finally {
    yield putResolve(Session_Reducer_SetLoginResult(loginResult));

  }

}

export function* watch_tryDeepLogin() {
  yield takeEvery(Session_Saga_TryDeepLogin, handleTryDeepLogin);
}
