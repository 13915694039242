import { SettingsPage } from "components/shared/SettingPagesVarious";
import React from "react";
import MultichannelSettings from "./multichannel.Settings";
interface IMulichannelPageProps { }

const MultichannelPage: React.FC<IMulichannelPageProps> = props => {
  return <SettingsPage Component={MultichannelSettings} Label="Multichannel" />;
};

export default MultichannelPage;
